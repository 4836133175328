import { FC, SVGProps } from "react";

const ShoppingCart: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.375 15.75C3.375 16.9926 4.38244 18 5.625 18C6.86756 18 7.875 16.9926 7.875 15.75C7.875 14.5074 6.86756 13.5 5.625 13.5C4.38244 13.5 3.375 14.5074 3.375 15.75ZM5.625 14.625C6.24656 14.625 6.75 15.1284 6.75 15.75C6.75 16.3716 6.24656 16.875 5.625 16.875C5.00344 16.875 4.5 16.371 4.5 15.75C4.5 15.129 5.00344 14.625 5.625 14.625ZM10.125 15.75C10.125 16.9926 11.1324 18 12.375 18C13.6176 18 14.625 16.9926 14.625 15.75C14.625 14.5074 13.6176 13.5 12.375 13.5C11.1324 13.5 10.125 14.5074 10.125 15.75ZM12.375 14.625C12.9966 14.625 13.5 15.1284 13.5 15.75C13.5 16.3716 12.9966 16.875 12.375 16.875C11.7534 16.875 11.25 16.371 11.25 15.75C11.25 15.129 11.7534 14.625 12.375 14.625ZM4.5 12.375C3.87844 12.375 3.375 11.8716 3.375 11.25L16.3125 10.125C16.3125 10.125 18 3.30468 18 2.8125C18 2.502 17.748 2.25 17.4375 2.25H3.375V1.125H3.9375C4.248 1.125 4.5 0.873 4.5 0.5625C4.5 0.252 4.248 0 3.9375 0H0.5625C0.252 0 0 0.252 0 0.5625C0 0.873 0.252 1.125 0.5625 1.125H2.25V11.25C2.25 12.4926 3.25744 13.5 4.5 13.5H5.625H12.375H17.4375C17.4549 13.5 17.4375 12.942 17.4375 12.375H4.5ZM3.375 3.375H16.875L15.4367 9.12823L3.375 10.125V3.375Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ShoppingCart;
