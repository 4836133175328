import { PaymentChannelsInterface } from "@xendit/checkout-utilities";

/**
 *
 * @export
 * @interface AdditionalCreditCardDisplay
 */
export interface AdditionalCreditCardDisplay {
  /**
   *
   * @type {string}
   * @memberof AdditionalCreditCardDisplay
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof AdditionalCreditCardDisplay
   */
  position_index: number;
}
/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  country: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  street_line1?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  street_line2?: string | null;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  province?: string | null;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  postal_code?: string;
}
/**
 *
 * @export
 * @interface AsyncChannelObject
 */
export interface AsyncChannelObject {
  /**
   *
   * @type {string}
   * @memberof AsyncChannelObject
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AsyncChannelObject
   */
  payment_destination?: string | null;
  /**
   *
   * @type {string}
   * @memberof AsyncChannelObject
   */
  merchant_name: string;
  /**
   *
   * @type {CollectionType}
   * @memberof AsyncChannelObject
   */
  collection_type?: CollectionType;
  /**
   *
   * @type {AlternativeDisplayItem[]}
   * @memberof BanksInner
   */
  alternative_displays?: AlternativeDisplayItem[];
}
/**
 *
 * @export
 * @enum {string}
 */

export const BankCode = {
  Bca: "BCA",
  Bni: "BNI",
  Bri: "BRI",
  Mandiri: "MANDIRI",
  Permata: "PERMATA",
  Bsi: "BSI",
  Bjb: "BJB",
  SahabatSampoerna: "SAHABAT_SAMPOERNA",
  Dbs: "DBS",
  Cimb: "CIMB"
} as const;

export type BankCode = (typeof BankCode)[keyof typeof BankCode];

/**
 *
 * @export
 * @interface BanksByType
 */
export interface BanksByType {
  /**
   *
   * @type {Array<AsyncChannelObject>}
   * @memberof BanksByType
   */
  POOL?: Array<AsyncChannelObject>;
  /**
   *
   * @type {Array<AsyncChannelObject>}
   * @memberof BanksByType
   */
  CALLBACK_VIRTUAL_ACCOUNT?: Array<AsyncChannelObject>;
}
/**
 *
 * @export
 * @interface BanksInner
 */
export interface BanksInner {
  /**
   *
   * @type {string}
   * @memberof BanksInner
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BanksInner
   */
  payment_destination?: string | null;
  /**
   *
   * @type {string}
   * @memberof BanksInner
   */
  merchant_name: string;
  /**
   *
   * @type {CollectionType}
   * @memberof BanksInner
   */
  collection_type: CollectionType;
  /**
   *
   * @type {AlternativeDisplay[]}
   * @memberof BanksInner
   */
  alternative_displays?: AlternativeDisplayItem[];
}

/**
 *
 * @export
 * @interface BanksInnerAllOf
 */
export interface BanksInnerAllOf {
  /**
   *
   * @type {CollectionType}
   * @memberof BanksInnerAllOf
   */
  collection_type: CollectionType;
}

/**
 *
 * @export
 * @interface BrandingBadgeLogos
 */
export interface BrandingBadgeLogos {
  /**
   *
   * @type {string}
   * @memberof BrandingBadgeLogos
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof BrandingBadgeLogos
   */
  website?: string;
}
/**
 *
 * @export
 * @interface BrandingColors
 */
export interface BrandingColors {
  /**
   *
   * @type {string}
   * @memberof BrandingColors
   */
  primary?: string;
  /**
   *
   * @type {string}
   * @memberof BrandingColors
   */
  secondary?: string;
}
/**
 *
 * @export
 * @interface BrandingEmailSettings
 */
export interface BrandingEmailSettings {
  /**
   *
   * @type {string}
   * @memberof BrandingEmailSettings
   */
  support_email?: string;
  /**
   *
   * @type {string}
   * @memberof BrandingEmailSettings
   */
  sender_name?: string;
  /**
   *
   * @type {string}
   * @memberof BrandingEmailSettings
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof BrandingEmailSettings
   */
  reply_to?: string;
  /**
   *
   * @type {string}
   * @memberof BrandingEmailSettings
   */
  email_signature_name?: string;
}
/**
 *
 * @export
 * @interface CallbackVirtualAccountBanksInner
 */
export interface CallbackVirtualAccountBanksInner {
  /**
   *
   * @type {BankCode}
   * @memberof CallbackVirtualAccountBanksInner
   */
  name?: BankCode;
}

/**
 *
 * @export
 * @enum {string}
 */

export const CardsPaymentChannel = {
  Cybersource: "CYBERSOURCE",
  SafeAcceptance: "SAFE_ACCEPTANCE",
  CimbH2H: "CIMB_H2H"
} as const;

export type CardsPaymentChannel =
  (typeof CardsPaymentChannel)[keyof typeof CardsPaymentChannel];

/**
 *
 * @export
 * @interface CheckoutCustomization
 */
export interface CheckoutCustomization {
  /**
   *
   * @type {string}
   * @memberof CheckoutCustomization
   */
  header_fill_color?: string;
  /**
   *
   * @type {string}
   * @memberof CheckoutCustomization
   */
  header_text_color?: string;
  /**
   *
   * @type {string}
   * @memberof CheckoutCustomization
   */
  button_fill_color?: string;
  /**
   *
   * @type {string}
   * @memberof CheckoutCustomization
   */
  button_text_color?: string;
}

/**
 *
 * @export
 * @interface AnalyticsSettings
 */
export interface AnalyticsSettings {
  /**
   *
   * @type {string}
   * @memberof AnalyticsSettings
   */
  facebook?: string;
  /**
   *
   * @type {string}
   * @memberof AnalyticsSettings
   */
  google?: string;
}

export interface OnDemandCustomerFields {
  /**
   *
   * @type {string}
   * @memberof OnDemandCustomerFields
   */
  field: string;
  /**
   *
   * @type {boolean}
   * @memberof OnDemandCustomerFields
   */
  required: boolean;
}

/**
 *
 * @export
 * @enum {string}
 */

export const CollectionType = {
  Pool: "POOL",
  CallbackVirtualAccount: "CALLBACK_VIRTUAL_ACCOUNT"
} as const;

export type CollectionType =
  (typeof CollectionType)[keyof typeof CollectionType];

/**
 *
 * @export
 * @interface CreditCardSettings
 */
export interface CreditCardSettings {
  /**
   *
   * @type {CardsPaymentChannel}
   * @memberof CreditCardSettings
   */
  cards_payment_channel: CardsPaymentChannel;
  /**
   *
   * @type {boolean}
   * @memberof CreditCardSettings
   */
  should_authenticate: boolean;
}

/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  given_names?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  mobile_number?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  customer_id?: string;
  /**
   *
   * @type {Array<Address>}
   * @memberof Customer
   */
  addresses?: Array<Address>;
  /**
   *
   * @type {NotificationsPreference}
   * @memberof Customer
   */
  notification_preference?: NotificationsPreference;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DirectDebitChannel = {
  BaBri: "BA_BRI",
  DcBri: "DC_BRI",
  DdBri: "DD_BRI",
  BaBpi: "BA_BPI",
  DcBpi: "DC_BPI",
  DdBpi: "DD_BPI",
  BaUbp: "BA_UBP",
  DcUbp: "DC_UBP",
  DdUbp: "DD_UBP",
  BcaKlikpay: "BCA_KLIKPAY",
  BaBcaKlikpay: "BA_BCA_KLIKPAY",
  DcBcaKlikpay: "DC_BCA_KLIKPAY",
  DdBcaKlikpay: "DD_BCA_KLIKPAY",
  DdChinabank: "DD_CHINABANK",
  DdRcbc: "DD_RCBC"
} as const;

export type DirectDebitChannel =
  (typeof DirectDebitChannel)[keyof typeof DirectDebitChannel];

/**
 *
 * @export
 * @interface DirectDebitsInner
 */
export interface DirectDebitsInner {
  /**
   *
   * @type {DirectDebitChannel}
   * @memberof DirectDebitsInner
   */
  name?: DirectDebitChannel;
}

/**
 *
 * @export
 * @enum {string}
 */

export const EwalletChannel = {
  Ovo: "OVO",
  Dana: "DANA",
  Linkaja: "LINKAJA",
  Paymaya: "PAYMAYA",
  Shopeepay: "SHOPEEPAY",
  Gcash: "GCASH",
  Grabpay: "GRABPAY",
  Astrapay: "ASTRAPAY",
  Nexcash: "NEXCASH"
} as const;

export type EwalletChannel =
  (typeof EwalletChannel)[keyof typeof EwalletChannel];

/**
 *
 * @export
 * @interface EwalletsInner
 */
export interface EwalletsInner {
  /**
   *
   * @type {EwalletChannel}
   * @memberof EwalletsInner
   */
  name?: EwalletChannel;
}

/**
 *
 * @export
 * @interface ExchangeRates
 */
export interface ExchangeRates {
  /**
   *
   * @type {object}
   * @memberof ExchangeRates
   */
  rates: Record<string, number>;
  /**
   *
   * @type {string}
   * @memberof ExchangeRates
   */
  base: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ExpressCheckoutChannel = {
  CreditCard: "CREDIT_CARD",
  Ovo: "OVO",
  Shopeepay: "SHOPEEPAY",
  Dana: "DANA",
  DdBpi: "DD_BPI",
  DdUbp: "DD_UBP",
  DdChinabank: "DD_CHINABANK",
  DdRcbc: "DD_RCBC"
} as const;

export type ExpressCheckoutChannel =
  (typeof ExpressCheckoutChannel)[keyof typeof ExpressCheckoutChannel];

/**
 *
 * @export
 * @interface FeatureFlags
 */
export interface FeatureFlags {
  /**
   *
   * @type {boolean}
   * @memberof FeatureFlags
   */
  charge_service_dana_enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FeatureFlags
   */
  charge_service_ovo_enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FeatureFlags
   */
  remove_zero_from_mobile_number?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FeatureFlags
   */
  should_use_ec_frictionless_payment?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FeatureFlags
   */
  should_track_ip_address?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FeatureFlags
   */
  should_use_ubp_v5?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FeatureFlags
   */
  direct_debit_upapi_enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FeatureFlags
   */
  charge_service_ewallet_enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FeatureFlags
   */
  qr_code_upapi_enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FeatureFlags
   */
  checkout_ui_realtime_updates_enabled?: boolean;
}
/**
 *
 * @export
 * @interface Fee
 */
export interface Fee {
  /**
   *
   * @type {string}
   * @memberof Fee
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof Fee
   */
  value: number;
}
/**
 *
 * @export
 * @interface CheckoutInitialData
 */
export interface CheckoutInitialData {
  /**
   *
   * @type {Invoice}
   * @memberof CheckoutInitialData
   */
  invoice: Invoice;
  /**
   *
   * @type {InvoiceSettings}
   * @memberof CheckoutInitialData
   */
  invoice_settings: InvoiceSettings;
  /**
   *
   * @type {InvoiceSettings}
   * @memberof CheckoutInitialData
   */
  geo_location: GeoLocation;
  /**
   *
   * @type {FeatureFlags}
   * @memberof CheckoutInitialData
   */
  feature_flags?: FeatureFlags;
  /**
   *
   * @type {Array<CallbackVirtualAccountBanksInner>}
   * @memberof CheckoutInitialData
   */
  callback_virtual_account_banks?: Array<CallbackVirtualAccountBanksInner> | null;
  /**
   *
   * @type {CreditCardSettings}
   * @memberof CheckoutInitialData
   */
  cards_settings?: CreditCardSettings | null;
  /**
   *
   * @type {PublicApiKey}
   * @memberof CheckoutInitialData
   */
  public_api_key?: PublicApiKey;
  /**
   *
   * @type {PaymentChannelsInterface}
   * @memberof CheckoutInitialData
   */
  available_payment_methods?: PaymentChannelsInterface;
  /**
   *
   * @type {ExchangeRates}
   * @memberof CheckoutInitialData
   */
  exchange_rates?: ExchangeRates | null;
}
/**
 *
 * @export
 * @interface Invoice
 */
export interface Invoice {
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  id: string;
  /**
   *
   * @type {InvoiceStatus}
   * @memberof Invoice
   */
  status: InvoiceStatus;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  external_id: string;
  /**
   *
   * @type {InvoiceClientType}
   * @memberof Invoice
   */
  client_type: InvoiceClientType;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  merchant_name: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  merchant_profile_picture_url: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  success_redirect_url?: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  failure_redirect_url?: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  cancel_redirect_url?: string;
  /**
   *
   * @type {number}
   * @memberof Invoice
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  payer_email?: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  expiry_date: string;
  /**
   *
   * @type {boolean}
   * @memberof Invoice
   */
  should_exclude_credit_card?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Invoice
   */
  should_authenticate_credit_card?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Invoice
   */
  should_charge_multiple_use_token?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Invoice
   */
  fixed_va?: boolean;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  business_environment?: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  locale?: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  display_currency?: string;
  /**
   *
   * @type {InvoiceCurrency}
   * @memberof Invoice
   */
  currency: InvoiceCurrency;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  initial_currency?: string;
  /**
   *
   * @type {number}
   * @memberof Invoice
   */
  initial_amount?: number;
  /**
   *
   * @type {Array<BanksInner>}
   * @memberof Invoice
   */
  banks?: Array<BanksInner>;
  /**
   *
   * @type {BanksByType}
   * @memberof Invoice
   */
  banks_by_type?: BanksByType;
  /**
   *
   * @type {Array<AsyncChannelObject>}
   * @memberof Invoice
   */
  retail_outlets?: Array<AsyncChannelObject>;
  /**
   *
   * @type {Array<EwalletsInner>}
   * @memberof Invoice
   */
  ewallets?: Array<EwalletsInner>;
  /**
   *
   * @type {Array<QrCodesInner>}
   * @memberof Invoice
   */
  qr_codes?: Array<QrCodesInner>;
  /**
   *
   * @type {Array<DirectDebitsInner>}
   * @memberof Invoice
   */
  direct_debits?: Array<DirectDebitsInner>;
  /**
   *
   * @type {Array<PaylatersInner>}
   * @memberof Invoice
   */
  paylaters?: Array<PaylatersInner>;
  /**
   *
   * @type {Array<CryptocurrenciesInner>}
   * @memberof Invoice
   */
  cryptocurrencies?: Array<CryptocurrenciesInner>;
  /**
   *
   * @type {Array<ChannelPropertiesInner>}
   * @memberof Invoice
   */
  channel_properties?: ChannelPropertiesInner;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  paylater_charge_id?: string | null;
  /**
   *
   * @type {Array<InvoicePaymentMethod>}
   * @memberof Invoice
   */
  payment_methods?: Array<InvoicePaymentMethod>;
  /**
   *
   * @type {Array<InvoicePaymentChannel>}
   * @memberof Invoice
   */
  payment_channels?: Array<InvoicePaymentChannel>;
  /**
   *
   * @type {InvoiceDirectDebitCharge}
   * @memberof Invoice
   */
  direct_debit_charge?: InvoiceDirectDebitCharge;
  /**
   *
   * @type {Customer}
   * @memberof Invoice
   */
  customer?: Customer;
  /**
   *
   * @type {Array<Item>}
   * @memberof Invoice
   */
  items?: Array<Item>;
  /**
   *
   * @type {Array<Fee>}
   * @memberof Invoice
   */
  fees?: Array<Fee>;
  /**
   *
   * @type {NotificationsPreference}
   * @memberof Invoice
   */
  customer_notification_preference?: NotificationsPreference;
  /**
   *
   * @type {boolean}
   * @memberof Invoice
   */
  should_exclude_qris?: boolean;
  /**
   *
   * @type {number}
   * @memberof Invoice
   */
  amount_subtotal?: number;
  /**
   *
   * @type {number}
   * @memberof Invoice
   */
  paid_amount?: number;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  paid_at?: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  payment_channel?: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  payment_method?: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  installment?: Installment;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  callback_virtual_account?: CallbackVirtualAccount;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  callback_virtual_account_collection_id?: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  callback_virtual_account_collection?: CallbackVirtualAccountCollection;
}

/**
 *
 * @export
 * @interface InvoiceBranding
 */
export interface InvoiceBranding {
  /**
   *
   * @type {string}
   * @memberof InvoiceBranding
   */
  domain_name?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceBranding
   */
  link_name?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceBranding
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceBranding
   */
  brand_name?: string;
  /**
   *
   * @type {BrandingEmailSettings}
   * @memberof InvoiceBranding
   */
  email_settings?: BrandingEmailSettings;
  /**
   *
   * @type {BrandingColors}
   * @memberof InvoiceBranding
   */
  colors?: BrandingColors;
  /**
   *
   * @type {Array<BrandingBadgeLogos>}
   * @memberof InvoiceBranding
   */
  badge_logos?: Array<BrandingBadgeLogos>;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceBranding
   */
  maintenance_mode?: boolean;
  /**
   *
   * @type {string}
   * @memberof InvoiceBranding
   */
  maintenance_period?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const InvoiceClientType = {
  ApiGateway: "API_GATEWAY",
  Dashboard: "DASHBOARD",
  Integration: "INTEGRATION",
  OnDemand: "ON_DEMAND",
  Recurring: "RECURRING",
  Mobile: "MOBILE",
  Storefront: "STOREFRONT"
} as const;

export type InvoiceClientType =
  (typeof InvoiceClientType)[keyof typeof InvoiceClientType];

/**
 *
 * @export
 * @enum {string}
 */

export const InvoiceCurrency = {
  Idr: "IDR",
  Usd: "USD",
  Php: "PHP",
  Sgd: "SGD",
  Vnd: "VND"
} as const;

export type InvoiceCurrency =
  (typeof InvoiceCurrency)[keyof typeof InvoiceCurrency];

/**
 *
 * @export
 * @interface InvoiceDirectDebitCharge
 */
export interface InvoiceDirectDebitCharge {
  /**
   *
   * @type {string}
   * @memberof InvoiceDirectDebitCharge
   */
  linked_account_token_id?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const InvoicePaymentChannel = {
  Bri: "BRI",
  Bca: "BCA",
  Bni: "BNI",
  Bsi: "BSI",
  Bjb: "BJB",
  SahabatSampoerna: "SAHABAT_SAMPOERNA",
  Cimb: "CIMB",
  OtherBanks: "OTHER_BANKS",
  Mandiri: "MANDIRI",
  Permata: "PERMATA",
  CreditCard: "CREDIT_CARD",
  Qris: "QRIS",
  Ovo: "OVO",
  Dana: "DANA",
  Shopeepay: "SHOPEEPAY",
  Linkaja: "LINKAJA",
  Astrapay: "ASTRAPAY",
  Nexcash: "NEXCASH",
  Paymaya: "PAYMAYA",
  Gcash: "GCASH",
  Grabpay: "GRABPAY",
  Alfamart: "ALFAMART",
  Indomaret: "INDOMARET",
  _7Eleven: "7ELEVEN",
  Cebuana: "CEBUANA",
  Lbc: "LBC",
  DpMlhuillier: "DP_MLHUILLIER",
  DpPalawan: "DP_PALAWAN",
  DpEcpayLoan: "DP_ECPAY_LOAN",
  DcBri: "DC_BRI",
  DdBri: "DD_BRI",
  BaBpi: "BA_BPI",
  DdBpi: "DD_BPI",
  BaUbp: "BA_UBP",
  DdUbp: "DD_UBP",
  BcaKlikpay: "BCA_KLIKPAY",
  DdBcaKlikpay: "DD_BCA_KLIKPAY",
  DdChinabank: "DD_CHINABANK",
  DdRcbc: "DD_RCBC",
  Billease: "BILLEASE",
  Cashalo: "CASHALO",
  Kredivo: "KREDIVO",
  Akulaku: "AKULAKU",
  Uangme: "UANGME",
  Atome: "ATOME"
} as const;

export type InvoicePaymentChannel =
  (typeof InvoicePaymentChannel)[keyof typeof InvoicePaymentChannel];

/**
 *
 * @export
 * @enum {string}
 */

export const InvoicePaymentMethod = {
  Pool: "POOL",
  CallbackVirtualAccount: "CALLBACK_VIRTUAL_ACCOUNT",
  BankTransfer: "BANK_TRANSFER",
  CreditCard: "CREDIT_CARD",
  RetailOutlets: "RETAIL_OUTLETS",
  QrCode: "QR_CODE",
  Qris: "QRIS",
  Ewallet: "EWALLET",
  DirectDebits: "DIRECT_DEBITS",
  DirectDebit: "DIRECT_DEBIT",
  Paylater: "PAYLATER",
  Cryptocurrency: "CRYPTOCURRENCY"
} as const;

export type InvoicePaymentMethod =
  (typeof InvoicePaymentMethod)[keyof typeof InvoicePaymentMethod];

/**
 *
 * @export
 * @interface InvoiceSettings
 */
export interface InvoiceSettings {
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  should_charge_multiple_use_token?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  should_exclude_credit_card?: boolean;
  /**
   *
   * @type {string}
   * @memberof InvoiceSettings
   */
  display_currency?: string;
  /**
   *
   * @type {Array<InvoiceCurrency>}
   * @memberof InvoiceSettings
   */
  allowed_currency?: Array<InvoiceCurrency>;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  restrict_credit_card_usage?: boolean;
  /**
   *
   * @type {string}
   * @memberof InvoiceSettings
   */
  primary_color?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSettings
   */
  secondary_color?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSettings
   */
  invoice_primary_language?: string;
  /**
   *
   * @type {InvoicePaymentMethod}
   * @memberof InvoiceSettings
   */
  primary_payment_method?: InvoicePaymentMethod;
  /**
   *
   * @type {string}
   * @memberof InvoiceSettings
   */
  default_success_redirect_url?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSettings
   */
  default_failure_redirect_url?: string;
  /**
   *
   * @type {InvoiceBranding}
   * @memberof InvoiceSettings
   */
  branding?: InvoiceBranding;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  express_checkout_enabled?: boolean;
  /**
   *
   * @type {Array<ExpressCheckoutChannel>}
   * @memberof InvoiceSettings
   */
  express_checkout_channels?: Array<ExpressCheckoutChannel>;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  qris_enabled?: boolean;
  /**
   *
   * @type {Array<AdditionalCreditCardDisplay>}
   * @memberof InvoiceSettings
   */
  additional_credit_card_logo_display?: Array<AdditionalCreditCardDisplay>;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  should_show_expiry_date_time?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  should_show_cardholder_name_for_card_detail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  should_show_email_for_card_detail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  should_show_billing_details_for_cards?: boolean;
  /**
   *
   * @type {string}
   * @memberof InvoiceSettings
   */
  payment_due_time_format?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceSettings
   */
  master_account_user_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  detect_browser_language?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  charge_service_dana_enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  charge_service_ovo_enabled?: boolean;
  /**
   *
   * @type {CheckoutCustomization}
   * @memberof InvoiceSettings
   */
  checkout_customization?: CheckoutCustomization;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  remove_zero_from_mobile_number?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSettings
   */
  charge_service_ewallet_enabled?: boolean;
  /**
   *
   * @type {AnalyticsSettings}
   * @memberof InvoiceSettings
   */
  analytics?: AnalyticsSettings;
  /**
   *
   * @type {OnDemandCustomerFields[]}
   * @memberof InvoiceSettings
   */
  on_demand_customer_fields?: OnDemandCustomerFields[];
}

/**
 *
 * @export
 * @enum {string}
 */

export const InvoiceStatus = {
  Pending: "PENDING",
  PendingPaymentApproval: "PENDING_PAYMENT_APPROVAL",
  Paid: "PAID",
  Settled: "SETTLED",
  Expired: "EXPIRED"
} as const;

export type InvoiceStatus = (typeof InvoiceStatus)[keyof typeof InvoiceStatus];

/**
 *
 * @export
 * @interface Item
 */
export interface Item {
  /**
   *
   * @type {string}
   * @memberof Item
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  quantity: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const NotificationsChannel = {
  Email: "email",
  Sms: "sms",
  Whatsapp: "whatsapp",
  Viber: "viber"
} as const;

export type NotificationsChannel =
  (typeof NotificationsChannel)[keyof typeof NotificationsChannel];

/**
 *
 * @export
 * @interface NotificationsPreference
 */
export interface NotificationsPreference {
  /**
   *
   * @type {Array<NotificationsChannel>}
   * @memberof NotificationsPreference
   */
  invoice_created?: Array<NotificationsChannel>;
  /**
   *
   * @type {Array<NotificationsChannel>}
   * @memberof NotificationsPreference
   */
  invoice_reminder?: Array<NotificationsChannel>;
  /**
   *
   * @type {Array<NotificationsChannel>}
   * @memberof NotificationsPreference
   */
  invoice_expired?: Array<NotificationsChannel>;
  /**
   *
   * @type {Array<NotificationsChannel>}
   * @memberof NotificationsPreference
   */
  invoice_paid?: Array<NotificationsChannel>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const PaylaterChannel = {
  Kredivo: "KREDIVO",
  Akulaku: "AKULAKU",
  Uangme: "UANGME",
  Billease: "BILLEASE",
  Cashalo: "CASHALO",
  Atome: "ATOME"
} as const;

export type PaylaterChannel =
  (typeof PaylaterChannel)[keyof typeof PaylaterChannel];

/**
 *
 * @export
 * @interface PaylatersInner
 */
export interface PaylatersInner {
  /**
   *
   * @type {PaylaterChannel}
   * @memberof PaylatersInner
   */
  name?: PaylaterChannel;
}

/**
 *
 * @export
 * @enum {string}
 */

export const CryptocurrencyChannel = {
  Coinbase: "COINBASE"
} as const;

export type CryptocurrencyChannel =
  (typeof CryptocurrencyChannel)[keyof typeof CryptocurrencyChannel];

/**
 *
 * @export
 * @interface CryptocurrenciesInner
 */
export interface CryptocurrenciesInner {
  /**
   *
   * @type {CryptocurrencyChannel}
   * @memberof CryptocurrenciesInner
   */
  name?: CryptocurrencyChannel;
}
export interface ChannelPropertiesInner {
  /**
   *
   * @type {string}
   * @memberof ChannelPropertiesInner
   */
  cards?: ChannelPropertiesCardsInner;
}

export interface ChannelPropertiesCardsInner {
  /**
   *
   * @type {string[]}
   * @memberof ChannelPropertiesCardsInner
   */
  allowed_bins?: string[];
  /**
   *
   * @type {ChannelPropertiesCardsInstallmentConfigurationInner}
   * @memberof ChannelPropertiesCardsInner
   */
  installment_configuration?: ChannelPropertiesCardsInstallmentConfigurationInner;
}

export interface ChannelPropertiesCardsInstallmentConfigurationInner {
  /**
   *
   * @type {boolean}
   * @memberof ChannelPropertiesCardsInstallmentConfigurationInner
   */
  allow_full_payment?: boolean;
  /**
   *
   * @type {ChannelPropertiesCardsInstallmentConfigurationTermInner[]}
   * @memberof ChannelPropertiesCardsInstallmentConfigurationInner
   */
  allowed_terms?: ChannelPropertiesCardsInstallmentConfigurationTermInner[];
  /**
   *
   * @type {boolean}
   * @memberof ChannelPropertiesCardsInstallmentConfigurationInner
   */
  allow_installment?: boolean;
}

export interface ChannelPropertiesCardsInstallmentConfigurationTermInner {
  /**
   *
   * @type {string}
   * @memberof ChannelPropertiesCardsInstallmentConfigurationTermInner
   */
  issuer: string;
  /**
   *
   * @type {number[]}
   * @memberof ChannelPropertiesCardsInstallmentConfigurationTermInner
   */
  terms: number[];
}

/**
 *
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  payment_method?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  channel?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  country_of_operation?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  label?: string;
  /**
   *
   * @type {PaymentMethodMinAmount}
   * @memberof PaymentMethod
   */
  min_amount?: PaymentMethodMinAmount;
  /**
   *
   * @type {PaymentMethodMaxAmount}
   * @memberof PaymentMethod
   */
  max_amount?: PaymentMethodMaxAmount;
}
/**
 *
 * @export
 * @interface PaymentMethodMaxAmount
 */
export interface PaymentMethodMaxAmount {
  /**
   *
   * @type {number}
   * @memberof PaymentMethodMaxAmount
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentMethodMaxAmount
   */
  premium?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentMethodMaxAmount
   */
  non_premium?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentMethodMaxAmount
   */
  standard?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentMethodMaxAmount
   */
  upgraded?: number;
  /**
   *
   * @type {boolean}
   * @memberof PaymentMethodMaxAmount
   */
  validate_on_checkout?: boolean;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodMaxAmount
   */
  validate_param_name?: string;
}
/**
 *
 * @export
 * @interface PaymentMethodMinAmount
 */
export interface PaymentMethodMinAmount {
  /**
   *
   * @type {number}
   * @memberof PaymentMethodMinAmount
   */
  amount?: number;
  /**
   *
   * @type {boolean}
   * @memberof PaymentMethodMinAmount
   */
  validate_on_checkout?: boolean;
}
/**
 *
 * @export
 * @interface PublicApiKey
 */
export interface PublicApiKey {
  /**
   *
   * @type {string}
   * @memberof PublicApiKey
   */
  api_key?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const QrCodeChannel = {
  Qris: "QRIS"
} as const;

export type QrCodeChannel = (typeof QrCodeChannel)[keyof typeof QrCodeChannel];

/**
 *
 * @export
 * @interface QrCodesInner
 */
export interface QrCodesInner {
  /**
   *
   * @type {QrCodeChannel}
   * @memberof QrCodesInner
   */
  name?: QrCodeChannel;
}

export interface Installment {
  /**
   *
   * @type {number}
   * @memberof Installment
   */
  count: number;
  /**
   *
   * @type {string}
   * @memberof Installment
   */
  interval: string;
}
/**
 *
 * @export
 * @interface AlternativeDisplayItem
 */
export interface AlternativeDisplayItem {
  type: string;
  data: string;
}

export const AlternativeDisplayType = {
  QR_STRING: "QR_STRING"
} as const;

/**
 *
 * @export
 * @interface CallbackVirtualAccount
 */
export interface CallbackVirtualAccount {
  /**
   *
   * @type {string}
   * @memberof CallbackVirtualAccount
   */
  bank_code: string;
  /**
   *
   * @type {string}
   * @memberof CallbackVirtualAccount
   */
  account_number: string;
}

/**
 *
 * @export
 * @interface CallbackVirtualAccountCollection
 */
export interface CallbackVirtualAccountCollection {
  /**
   *
   * @type {string}
   * @memberof CallbackVirtualAccountCollection
   */
  account_number: string;
}

/**
 *
 * @export
 * @interface GeoLocation
 */
export interface GeoLocation {
  /**
   *
   * @type {string}
   * @memberof GeoLocation
   */
  country_code: string;
}
