import { useFeatureValue } from "@growthbook/growthbook-react";
import { NexBannerPlacements } from "../types/nex-banner";

const NEX_BANNER_FEATURE_FLAG = "nex_card_banner_v3";

export const useNexBannerControl = () => {
  return useFeatureValue<NexBannerPlacements>(NEX_BANNER_FEATURE_FLAG, {
    placement_pre: { enabled: false },
    placement_post: { enabled: false },
    placement_email: { enabled: false }
  });
};
