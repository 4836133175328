import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import Graphic from "../../../assets/graphics/Success";
import { MainView } from "../../../components/Layout";
import PoweredByXendit from "../../../components/PoweredByXendit";
import { formatDate } from "../../../helpers/date-strings";
import { useRedirect } from "../../../hooks/use-redirect";
import {
  isSessionAuthorizationOnly,
  isSessionPayOnly,
  isSessionSaveOnly,
  SessionContext
} from "../../context/context";

const SessionCompleted: React.FC = () => {
  const { i18n, t } = useTranslation("session");
  const { state } = useContext(SessionContext);
  const { session, succeededChannel } = state;

  if (!session) {
    throw new Error("There was a problem loading the state", {
      cause: state
    });
  }

  const secondsLeft = useRedirect(true, session.success_return_url);

  const generateSuccessDescription = () => {
    if (isSessionSaveOnly(session)) {
      return t("Your account has been successfully linked");
    }
    if (isSessionPayOnly(session)) {
      return t("Your payment has been paid for successfully");
    }
    if (isSessionAuthorizationOnly(session)) {
      return t("Your payment has been authorized successfully");
    }
    return t("Your session has been completed");
  };

  return (
    <MainView>
      <div className="flex-1 px-4 lg:px-0">
        <div className="pt-8 pb-12 space-y-8">
          <div className="flex flex-col items-center">
            <Graphic className="text-primary h-32 mb-4 hidden md:block" />
            <h1 className="text-3xl font-semibold mb-2 text-center">
              {t("Thank You!")}
            </h1>
            <p className="text-center" data-testid="success-description">
              {generateSuccessDescription()}
            </p>
          </div>
          <ul className="flex flex-col max-w-md mx-auto">
            {session.reference_id && (
              <li className="flex -mx-2">
                <div className="w-1/2 px-2 font-semibold py-2">
                  {t("Reference ID")}
                </div>
                <div className="w-1/2 px-2 py-2" data-testid="reference-id">
                  {session.reference_id}
                </div>
              </li>
            )}
            {session.updated && (
              <li className="flex -mx-2">
                <div className="w-1/2 px-2 font-semibold py-2">{t("Date")}</div>
                <div className="w-1/2 px-2 py-2" data-testid="date">
                  {formatDate(new Date(session.updated), i18n.language, null, {
                    dateStyle: "medium"
                  })}
                </div>
              </li>
            )}
            {succeededChannel?.channel_code && (
              <li className="flex -mx-2">
                <div className="w-1/2 px-2 font-semibold py-2">
                  {t("Payment channel")}
                </div>
                <div
                  className="w-1/2 px-2 flex items-center space-x-2"
                  data-testid="payment-channel-code"
                >
                  <div className="h-8 w-12 p-1 border border-xen-gray-400 rounded">
                    <img
                      src={succeededChannel?.logo_url ?? undefined}
                      alt={succeededChannel?.channel_code ?? undefined}
                      className="h-full w-full object-contain"
                    />
                  </div>
                  <span>{succeededChannel?.channel_code}</span>
                </div>
              </li>
            )}
          </ul>

          {session.success_return_url && (
            <div className="text-center text-xen-gray-700 italic">
              <Trans t={t} ns="checkout-paid">
                Redirecting back in {{ seconds: secondsLeft }}s, or click{" "}
                <a
                  href={session.success_return_url}
                  target="_top"
                  className="underline underline-offset-auto text-xen-blue-500"
                >
                  here
                </a>
              </Trans>
            </div>
          )}
        </div>

        <div className="flex justify-center pb-12">
          <PoweredByXendit />
        </div>
      </div>
    </MainView>
  );
};

export default SessionCompleted;
