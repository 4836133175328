import { FC, Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import Header, {
  HeaderContent,
  HeaderImage,
  HeaderTitle
} from "../../../../components/Header";
import { MainView } from "../../../../components/Layout";
import PaymentMethodList, {
  PaymentMethodListLoadingSkeleton
} from "../../../../components/PaymentMethodList";
import PoweredByXendit from "../../../../components/PoweredByXendit";
import { Business, PaymentMethod } from "../../../types";
import { mapSessionPaymentMethodsToPaymentMethodListItems } from "../../../adapter/session-to-payment-method-list";
import { DEFAULT_XENDIT_LOGO } from "../../../../utils/constants";
import { SecureDisclaimer } from "./SecureDisclaimer";
import { CustomerInformation } from "./CustomerInformation";

interface SelectPaymentMethodProps {
  business: Business;
  paymentMethods: PaymentMethod[];
  onSelectChannelCode: (channel: string) => void;
  onSelectPMType: (pmType: string) => void;
}

/**
 * Component for selecting payment method. Renders the accordion and grid of payment methods.
 */
const ActiveSaveOnlySelectPaymentMethod: FC<SelectPaymentMethodProps> = (
  props
) => {
  const { t } = useTranslation("session");
  const [selectedPMType, setSelectedPMType] = useState("");
  const { business, paymentMethods, onSelectChannelCode, onSelectPMType } =
    props;

  const paymentMethodItems =
    mapSessionPaymentMethodsToPaymentMethodListItems(paymentMethods);

  const selectPMType = (pmType: string) => {
    onSelectPMType(pmType);
    setSelectedPMType(pmType);
  };

  return (
    <MainView>
      <Header>
        <HeaderContent>
          <HeaderImage
            src={business.merchant_profile_picture_url ?? DEFAULT_XENDIT_LOGO}
            alt={business.name ?? ""}
          />
          <HeaderTitle>{business.name}</HeaderTitle>
        </HeaderContent>
      </Header>

      <section className="pt-12">
        <CustomerInformation />
      </section>

      <section className="py-6">
        <SecureDisclaimer />
      </section>

      <section className="py-6">
        <div className="w-full max-w-screen-sm mx-auto px-4 lg:px-0">
          <h2 className="font-semibold uppercase mb-4">
            {t("Payment Method")}
          </h2>
          <Suspense
            fallback={
              <PaymentMethodListLoadingSkeleton count={paymentMethods.length} />
            }
          >
            <PaymentMethodList
              items={paymentMethodItems}
              onSelectChannel={onSelectChannelCode}
              onSelectPaymentMethodType={selectPMType}
              value={selectedPMType}
            />
          </Suspense>
        </div>
      </section>
      <div className="flex justify-center pb-12">
        <PoweredByXendit />
      </div>
    </MainView>
  );
};

export default ActiveSaveOnlySelectPaymentMethod;
