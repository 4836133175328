import { FC, SVGProps } from "react";

const RetailOutlet: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 13.5H3A1.5 1.5 0 0 0 1.5 15v3A1.5 1.5 0 0 0 3 19.5h18a1.5 1.5 0 0 0 1.5-1.5v-3a1.5 1.5 0 0 0-1.5-1.5Z"
        fill="#fff"
      />
      <path
        d="M21 13.875H3c-.621 0-1.125.504-1.125 1.125v3c0 .621.504 1.125 1.125 1.125h18c.621 0 1.125-.504 1.125-1.125v-3c0-.621-.504-1.125-1.125-1.125Z"
        fill="#fff"
        stroke="#777"
      />
      <path d="M9 16.5H4.5" stroke="#777" strokeLinecap="round" />
      <path
        d="M21 13.5v-1.25a.25.25 0 0 0-.25-.25h-8.5a.25.25 0 0 0-.25.25v1.25h9Zm.75.75h-10.5v-2a1 1 0 0 1 1-1h8.5a1 1 0 0 1 1 1v2Z"
        fill="#777"
      />
      <path
        d="M16.5 7.5a.75.75 0 0 1 .75.75v3h-1.5v-3a.75.75 0 0 1 .75-.75Z"
        fill="#fff"
      />
      <path
        d="M16.5 7.875a.375.375 0 0 1 .375.375v2.5a.125.125 0 0 1-.125.125h-.5a.125.125 0 0 1-.125-.125v-2.5a.375.375 0 0 1 .375-.375Z"
        stroke="#777"
      />
      <path
        d="M19.5 4.875h-6c-.621 0-1.125.504-1.125 1.125v3c0 .621.504 1.125 1.125 1.125h6c.621 0 1.125-.504 1.125-1.125V6c0-.621-.504-1.125-1.125-1.125Z"
        stroke="#777"
      />
    </svg>
  );
};

export default RetailOutlet;
