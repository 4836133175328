import { FC, SVGProps } from "react";

const AlertCircle: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6004 10C19.6004 12.5461 18.589 14.9879 16.7886 16.7882C14.9883 18.5886 12.5465 19.6 10.0004 19.6C7.45431 19.6 5.01251 18.5886 3.21217 16.7882C1.41182 14.9879 0.400391 12.5461 0.400391 10C0.400391 7.45395 1.41182 5.01215 3.21217 3.2118C5.01251 1.41145 7.45431 0.400024 10.0004 0.400024C12.5465 0.400024 14.9883 1.41145 16.7886 3.2118C18.589 5.01215 19.6004 7.45395 19.6004 10ZM11.2004 14.8C11.2004 15.1183 11.074 15.4235 10.8489 15.6486C10.6239 15.8736 10.3187 16 10.0004 16C9.68213 16 9.37691 15.8736 9.15186 15.6486C8.92682 15.4235 8.80039 15.1183 8.80039 14.8C8.80039 14.4818 8.92682 14.1765 9.15186 13.9515C9.37691 13.7265 9.68213 13.6 10.0004 13.6C10.3187 13.6 10.6239 13.7265 10.8489 13.9515C11.074 14.1765 11.2004 14.4818 11.2004 14.8ZM10.0004 4.00002C9.68213 4.00002 9.37691 4.12645 9.15186 4.3515C8.92682 4.57654 8.80039 4.88176 8.80039 5.20002V10C8.80039 10.3183 8.92682 10.6235 9.15186 10.8486C9.37691 11.0736 9.68213 11.2 10.0004 11.2C10.3187 11.2 10.6239 11.0736 10.8489 10.8486C11.074 10.6235 11.2004 10.3183 11.2004 10V5.20002C11.2004 4.88176 11.074 4.57654 10.8489 4.3515C10.6239 4.12645 10.3187 4.00002 10.0004 4.00002Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AlertCircle;
