import { FC, SVGProps } from "react";

const QrCodeRefresh: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="#000"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11,11H7V7h4Zm2,6h4V13H13Z"
        fill="currentColor"
        strokeWidth="2"
      ></path>
      <path
        d="M11,11H7V7h4Zm6-4H15V9h2ZM13,17h4V13H13ZM7,17H9V15H7Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      ></path>
      <path
        d="M8,3H4A1,1,0,0,0,3,4V8"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      ></path>
      <path
        d="M21,8V4a1,1,0,0,0-1-1H16"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      ></path>
      <path
        d="M3,16v4a1,1,0,0,0,1,1H8"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      ></path>
      <path
        d="M16,21h4a1,1,0,0,0,1-1V16"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      ></path>
    </svg>
  );
};

export default QrCodeRefresh;
