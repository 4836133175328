import { forwardRef, useEffect } from "react";
import qrcode from "qrcode";

export type QrCodeProps = {
  value: string;
};
export type Ref = HTMLCanvasElement;

const QrCode = forwardRef<Ref, QrCodeProps>(function QrCode(props, ref) {
  useEffect(() => {
    if (ref && typeof ref !== "function" && ref.current) {
      qrcode.toCanvas(ref.current, props.value);
    }
  }, [ref]);

  return (
    <div id="qr-code-canvas-container" data-testid="qr-code-wrapper">
      <canvas ref={ref} data-testid="qr-code-canvas" />
    </div>
  );
});

export default QrCode;
