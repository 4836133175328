import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { Country } from "country-state-city";

import Dropdown, { DropdownOption } from "../Dropdown";
import Input from "../Input";
import FormField from "../FormField";

import { CreditCardFormValues } from "../../types/credit-card";

type CountryOption = {
  label: string;
  value: string;
};

export type CreditCardBillingAddressDetailsProps =
  FormikProps<CreditCardFormValues>;

const CreditCardBillingAddressDetails = (
  props: CreditCardBillingAddressDetailsProps
) => {
  const { t } = useTranslation("forms");

  return (
    <div className="flex flex-col space-y-4 pb-3">
      <FormField
        label={t("Country")}
        name="country"
        helpText={props.errors.country}
        state={props.errors.country ? "error" : "default"}
      >
        <Dropdown
          block
          disabled={props.isSubmitting}
          name="country"
          data-testid="country"
          onChange={(selection) => {
            props.setFieldValue("country", (selection as CountryOption).value);
          }}
          value={props.values.country}
          options={Country.getAllCountries().map<CountryOption>((country) => ({
            label: `${country.flag} ${country.name}`,
            value: country.isoCode
          }))}
          keyExtractor={(country) => (country as CountryOption).value}
          renderOption={(country, helpers) => (
            <DropdownOption {...helpers}>
              {(country as CountryOption).label}
            </DropdownOption>
          )}
          renderSelection={(countryIsoCode) => {
            const country = Country.getCountryByCode(countryIsoCode as string);
            if (!country) {
              return t("Country");
            }
            return `${country.flag} ${country.name}`;
          }}
        />
      </FormField>
      <FormField
        label={t("Province/State")}
        name="provinceState"
        helpText={t(props.errors.provinceState as string, {
          field: t("Province/State")
        })}
        state={props.errors.provinceState ? "error" : "default"}
      >
        <Input
          block
          disabled={props.isSubmitting}
          autoComplete="province-state"
          name="provinceState"
          value={props.values.provinceState}
          onChange={props.handleChange}
          hasError={!!props.errors.provinceState}
          placeholder="Province/State"
          data-testid="province-state"
        />
      </FormField>
      <FormField
        label={t("City/District")}
        name="city"
        helpText={t(props.errors.city as string, {
          field: t("City/District")
        })}
        state={props.errors.city ? "error" : "default"}
      >
        <Input
          block
          disabled={props.isSubmitting}
          autoComplete="city"
          name="city"
          value={props.values.city}
          onChange={props.handleChange}
          hasError={!!props.errors.city}
          placeholder="City/District"
          data-testid="city"
        />
      </FormField>
      <FormField
        label={t("Street Line 1")}
        name="streetLine1"
        helpText={t(props.errors.streetLine1 as string, {
          field: t("Street Line 1")
        })}
        state={props.errors.streetLine1 ? "error" : "default"}
      >
        <Input
          block
          disabled={props.isSubmitting}
          autoComplete="street-address"
          name="streetLine1"
          value={props.values.streetLine1}
          onChange={props.handleChange}
          hasError={!!props.errors.streetLine1}
          placeholder="Address"
          data-testid="address-1"
        />
      </FormField>
      <FormField
        label={t("Postal Code")}
        name="postalCode"
        helpText={t(props.errors.postalCode as string, {
          field: t("Postal Code")
        })}
        state={props.errors.postalCode ? "error" : "default"}
      >
        <Input
          block
          disabled={props.isSubmitting}
          type="text"
          autoComplete="postal-code"
          name="postalCode"
          value={props.values.postalCode}
          onChange={props.handleChange}
          hasError={!!props.errors.postalCode}
          placeholder="Postal Code"
          data-testid="zipcode"
        />
      </FormField>
    </div>
  );
};

export default CreditCardBillingAddressDetails;
