import { FormikErrors, FormikTouched } from "formik";
import FormField from "../../../../../components/FormField";
import Input from "../../../../../components/Input";

/**
 * @template Names Union of strings of the channel properties set of keys
 */
export interface ExtraChannelPropertyInput<Names> {
  label: string;
  name: Names;
  placeholder?: string;
  // Use withMask here
  ref?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: any
  ) =>
    | HTMLInputElement
    | HTMLTextAreaElement
    | HTMLElement
    | HTMLInputElement
    | null;
  required?: boolean;
  // Extend as necessary, this is in relation to tailwind css width
  width: "full" | "auto" | "1/2" | "1/3" | "2/3";
  inputType?: "text" | "number" | "email" | "password" | "tel";
}

/**
 * @description Generates a React Input field for a specific channel property
 */
export const generateChannelPropertyInputs = function <
  Properties extends { [key: string]: string | number }
>(
  channelPropertyInputs: ExtraChannelPropertyInput<keyof Properties>[],
  // formik fields
  touched: FormikTouched<Properties>,
  errors: FormikErrors<Properties>,
  values: Properties,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
): JSX.Element[] {
  return channelPropertyInputs.map((inputParams) => {
    const name = String(inputParams.name);
    return (
      <div
        key={`input_${name}`}
        className={`md:w-${inputParams.width} flex-grow px-2 pb-4`}
      >
        <FormField
          label={inputParams.label}
          name={name}
          helpText={
            touched[inputParams.name]
              ? errors[inputParams.name]?.toString()
              : ""
          }
          state={
            touched[inputParams.name] && errors[inputParams.name]
              ? "error"
              : "default"
          }
          required={inputParams.required}
        >
          <Input
            block
            type={inputParams.inputType || "text"}
            aria-label={inputParams.label}
            name={name}
            value={values[inputParams.name]}
            onChange={handleChange}
            onBlur={handleBlur}
            hasError={touched[inputParams.name] && !!errors[inputParams.name]}
            placeholder={inputParams.placeholder ?? ""}
            ref={inputParams.ref}
          />
        </FormField>
      </div>
    );
  });
};
