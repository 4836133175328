import { FC, useEffect, useState } from "react";
import { formatDisplayAmountWithCurrencyCode } from "@xendit/checkout-utilities/dist/src/amount-formatter";
import { useTranslation } from "react-i18next";

import { CreditCardPromotion } from "../../types/credit-card";
import { formatDate } from "../../helpers/date-strings";

type Props = {
  amount: number;
  currency?: string;
  exactValue?: {
    amount: number;
    currency?: string;
  } | null;
  expiryDate: string;
  expiryTimeFormat?: string;
  promotion?: CreditCardPromotion | null;
  showExpiry?: boolean;
  totalAmount: number;
};

const AmountDue: FC<Props> = (props) => {
  const [expiryDisplay, setExpiryDisplay] = useState("");
  const { i18n, t } = useTranslation("common");

  useEffect(() => {
    if (props.showExpiry) {
      setExpiryDisplay(
        formatDate(
          new Date(props.expiryDate),
          i18n.language,
          props.expiryTimeFormat
        )
      );
    }
  }, [
    props.expiryDate,
    props.expiryTimeFormat,
    props.showExpiry,
    i18n.language
  ]);

  return (
    <section className="py-8">
      <p
        className="px-4 lg:px-0 text-center text-lg font-semibold uppercase"
        data-testid="expiry-date"
      >
        {props.showExpiry
          ? t("Pay Before {{expiry_date}}", { expiry_date: expiryDisplay })
          : t("Total Amount Due")}
      </p>

      <div className="my-3 flex justify-center text-primary">
        <div className="relative">
          {props.exactValue ? (
            <span className="text-sm font-medium absolute right-full mr-1 mt-0.5">
              EST.
            </span>
          ) : null}
          <p className="text-5xl font-light leading-10">
            {props.exactValue
              ? formatDisplayAmountWithCurrencyCode(
                  props.amount,
                  props.currency
                )
              : formatDisplayAmountWithCurrencyCode(
                  props.promotion
                    ? props.promotion.final_amount
                    : props.totalAmount,
                  props.currency
                )}
          </p>
          {props.promotion ? (
            <p className="mt-2 text-xen-gray-700 font-sans line-through text-center">
              {formatDisplayAmountWithCurrencyCode(
                props.promotion.original_amount,
                props.currency
              )}
            </p>
          ) : null}
        </div>
      </div>
      {props.exactValue ? (
        <p className="text-center" data-testid="display-currency">
          {t("exactly {{amount}}", {
            amount: formatDisplayAmountWithCurrencyCode(
              props.exactValue.amount,
              props.exactValue.currency
            )
          })}
        </p>
      ) : null}
    </section>
  );
};

export default AmountDue;
