import { FC, SyntheticEvent, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import useQrCode from "../../hooks/use-qr-code.hook";

import { AlertCircle, QrCodeRefresh } from "../../assets/icons";
import Button from "../Button";
import MobileFooter from "../MobileFooter";
import QrCode from "../QrCode";
import RollingLoader from "../RollingLoader";
import FraudWarningNotes from "../FraudWarningNotes";
import { getNMIDFromQrString } from "../../utils/qr";
import { usePaymentLink } from "../../contexts/PaymentLinkContext";

type QrPaymentChannel = {
  displayName: string;
  label: string;
  logoUrl: string;
};

type QrPaymentProps = {
  channel: QrPaymentChannel;
  downloadFilename: string;
  error?: string | null;
  initializing: boolean;
  qrString?: string;
  hasExpired: boolean;
  willRefresh: boolean;
};

type QrAcceptedInProps = {
  channelCode: "QRIS" | "PROMPTPAY" | "QRPH";
};

const QrAcceptedIn = ({ channelCode }: QrAcceptedInProps) => {
  const { t } = useTranslation("common");

  const view = {
    QRIS: (
      <p className="text-center font-semibold">
        {t("We accept QR payments via all major banks and e-wallets")}
      </p>
    ),
    PROMPTPAY: (
      <p className="text-center font-semibold">
        {t("QR code accepted by all banks on PromptPay network")}
      </p>
    ),
    QRPH: (
      <p className="text-center font-semibold">
        {t("QR code accepted by all banks and e-wallets on QRPh network")}
      </p>
    )
  };

  return <>{view[channelCode]}</>;
};

const QrPayment: FC<QrPaymentProps> = (props) => {
  const qrRef = useRef<HTMLCanvasElement>(null);
  const { downloadQRCode } = useQrCode(qrRef, {
    downloadFilename: props.downloadFilename
  });
  const { t } = useTranslation("common");

  const handleDownloadClick = useCallback(
    (e: SyntheticEvent) => {
      if (props.qrString) {
        downloadQRCode(e);
      }
    },
    [props.qrString]
  );

  /**
   * NMID, or National Merchant ID, will only be available for QRIS.
   *
   * This is a temporary solution, since checkout has not yet integrated with PSS.
   * Ideally we will fetch the NMID from PSS, but for now we will get the NMID
   * from the QR payload
   */
  const nmid = () => {
    if (props.qrString) {
      return getNMIDFromQrString(props.qrString as string);
    }
  };

  const {
    paymentLink: { invoice }
  } = usePaymentLink();

  const isCurrencyIDR = invoice.currency === "IDR";
  const isQris = isCurrencyIDR && nmid();

  const qrClassName = classNames(
    "bg-white rounded-lg p-4 w-64 border border-xen-gray-200 shadow-lg flex flex-col items-center justify-center",
    {
      /**
       * This is because in the case of QRIS, we need to show the NMID
       * as part of the requirement from Bank of Indonesia, so we
       * need to make the container larger.
       *
       * Although, we still want to keep the same aspect ratio for non
       * QRIS QR codes.
       */
      "h-90": isQris,
      "h-80": !isQris
    }
  );

  return (
    <div className="px-4 pt-6 pb-12 flex flex-col items-center space-y-4">
      {isCurrencyIDR ? <FraudWarningNotes /> : null}
      <div>
        <QrAcceptedIn
          channelCode={
            props.channel
              .displayName as unknown as QrAcceptedInProps["channelCode"]
          }
        />
      </div>
      <div className={qrClassName}>
        {!props.initializing ? (
          <img
            src={props.channel.logoUrl}
            alt={props.channel.label}
            className="w-24 h-10"
          />
        ) : null}
        {props.initializing ? (
          <RollingLoader className="text-primary w-24 h-24" />
        ) : null}
        {props.error ? (
          <div className="flex flex-col items-center space-y-1">
            <AlertCircle className="text-xen-yellow-500 h-8 w-8" />
            <p className="text-center text-xen-gray-700">{props.error}</p>
          </div>
        ) : null}
        {props.hasExpired && !props.error && !props.initializing ? (
          <div className="flex flex-col items-center space-y-1">
            <QrCodeRefresh className="text-xen-yellow-500 h-16 w-16 mt-6 mb-6" />
            <p className="text-center text-xen-gray-900 font-semibold">
              {props.willRefresh
                ? t("Please wait while we refresh the QR code")
                : t("The QR code has expired")}
            </p>
          </div>
        ) : null}
        {props.qrString && !props.hasExpired ? (
          <QrCode value={props.qrString} ref={qrRef} />
        ) : null}
        {isQris && !props.hasExpired ? (
          <p className="text-base font-semibold">NMID: {nmid()}</p>
        ) : null}
      </div>

      <MobileFooter>
        <div className="flex space-x-4">
          <Button
            type="button"
            variant="brand-secondary"
            className="flex-1"
            disabled={!props.qrString || props.hasExpired}
            onClick={handleDownloadClick}
          >
            {t("Download QR Code")}
          </Button>
        </div>
      </MobileFooter>
    </div>
  );
};

export default QrPayment;
