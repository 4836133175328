export const OTP_ERROR: { [errorCode: string]: ErrorMessage } = {
  INVALID_OTP_ERROR: {
    title: "Invalid OTP Code",
    body: "The OTP code provided is invalid"
  },
  SERVER_ERROR: {
    title: "OTP Authentication Error",
    body: "We have encountered some issue. Please try again"
  }
};

export const getOTPErrors = (errorCode: string) => {
  return OTP_ERROR[errorCode] || OTP_ERROR.SERVER_ERROR;
};
