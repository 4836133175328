import { FC, SVGProps } from "react";

const QrCode: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width={24} height={24} viewBox="0 0 32 32" fill="none" {...props}>
      <path d="M10.5 5H5" stroke="#5D7077" strokeLinecap="round" />
      <path d="M5 10.5L5 5" stroke="#5D7077" strokeLinecap="round" />
      <path d="M21.5 5H27" stroke="#5D7077" strokeLinecap="round" />
      <path d="M27 10.5L27 5" stroke="#5D7077" strokeLinecap="round" />
      <path d="M10.5 27H5" stroke="#5D7077" strokeLinecap="round" />
      <path d="M5 21.5L5 27" stroke="#5D7077" strokeLinecap="round" />
      <path d="M21.5 27H27" stroke="#5D7077" strokeLinecap="round" />
      <path d="M27 21.5L27 27" stroke="#5D7077" strokeLinecap="round" />
      <rect
        x="8.25"
        y="8.25"
        width="6.33333"
        height="6.33333"
        stroke="#5D7077"
      />
      <rect
        x="8.25"
        y="17.4167"
        width="6.33333"
        height="6.33333"
        stroke="#5D7077"
      />
      <rect
        x="18.3333"
        y="8.25"
        width="6.33333"
        height="6.33333"
        stroke="#5D7077"
      />
      <rect
        x="18.3333"
        y="17.4167"
        width="6.33333"
        height="6.33333"
        stroke="#5D7077"
      />
    </svg>
  );
};

export default QrCode;
