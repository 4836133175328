import { FC } from "react";
import cx from "classnames";

export type CalloutProps = {
  title?: string;
  description?: React.ReactNode;
  variant?: "low" | "medium" | "high" | "critical";
};

const CalloutTitle: FC<Pick<CalloutProps, "title">> = ({ title }) => {
  if (!title) {
    return null;
  }
  return <p className="font-bold">{title}</p>;
};

const CalloutDescription: FC<Pick<CalloutProps, "description">> = ({
  description
}) => {
  if (!description) {
    return null;
  }

  return <p className="mt-2">{description}</p>;
};

const Callout: FC<CalloutProps> = ({
  title,
  description,
  variant = "medium"
}) => {
  const classNames = cx([
    "my-4 border border-l-4 p-4 rounded-md",
    { "bg-xen-gray-100 border-xen-gray-500": variant === "low" },
    { "bg-xen-blue-100 border-xen-blue-500": variant === "medium" },
    { "bg-xen-yellow-100 border-xen-yellow-500": variant === "high" },
    { "bg-xen-red-100 border-xen-red-500": variant === "critical" }
  ]);

  return (
    <div className={classNames}>
      <CalloutTitle title={title} />
      <CalloutDescription description={description} />
    </div>
  );
};

export default Callout;
