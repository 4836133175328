import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import { APP_ENV_MODE } from "../utils/constants";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

interface DateAndTimeAvailability {
  timezone: string;
  start_time?: string;
  end_time?: string;
  unavailable_days_of_week?: string[];
}

export const validateTime = (props: DateAndTimeAvailability) => {
  const { timezone, start_time, end_time } = props;

  // No time limitation for test mode
  if (APP_ENV_MODE !== "production-live") {
    return;
  }

  // Convert user's current time to channel's local timezone
  const localizedCurrentTime = dayjs().tz(timezone);

  if (start_time && end_time) {
    const localizedStartTime = dayjs()
      .tz(timezone)
      .set("hour", Number(start_time.split(":")[0]))
      .set("minute", Number(start_time.split(":")[1]))
      .set("second", 0);

    const localizedEndTime = dayjs()
      .tz(timezone)
      .set("hour", Number(end_time.split(":")[0]))
      .set("minute", Number(end_time.split(":")[1]))
      .set("second", 0);

    // Channel is available if the current time is between the channel's start and end local time
    const isAvailable = dayjs(localizedCurrentTime).isBetween(
      localizedStartTime,
      localizedEndTime
    );

    // Return user's local time and channel's operation hours in their local time
    return {
      localizedStartTime: localizedStartTime.local().format("HH:mm"),
      localizedEndTime: localizedEndTime.local().format("HH:mm"),
      localizedCurrentTime: localizedCurrentTime.local().format("HH:mm"),
      isAvailable
    };
  }
};

// Following the day format from day.js (https://day.js.org/docs/en/get-set/day)
// 0 (Sunday) to 6 (Saturday)
const dayMapping: any = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
};

export const validateDay = (props: DateAndTimeAvailability) => {
  const { timezone, unavailable_days_of_week } = props;

  // No day limitation for test mode
  if (APP_ENV_MODE !== "production-live") {
    return;
  }

  // Convert user's current day to channel's local timezone
  const localizedCurrentDate = dayjs().tz(timezone);
  const localizedDay = localizedCurrentDate.day();

  if (unavailable_days_of_week && unavailable_days_of_week.length > 0) {
    const daysAvailability = unavailable_days_of_week.map((day: string) => {
      // Channel is available if the current day is not the unavailable day
      const isAvailable = localizedDay !== dayMapping[day];

      return isAvailable;
    });

    // Channel is available if the current day is not in the list of unavailable_days
    const isAvailable = !daysAvailability.includes(false);

    return {
      localizedCurrentDay: dayjs().format("dddd"),
      isAvailable
    };
  }
};
