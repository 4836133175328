import { FC, SVGProps } from "react";

const Error: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width={244} height={192} viewBox="0 0 244 192" fill="none" {...props}>
      <path
        opacity={0.18}
        d="M216.166 185.44c12.675-4.79 24.134-15.034 26.619-28.361 2.048-10.992-2.173-22.152-7.3-32.1s-11.346-19.554-13.741-30.475c-4.46-20.308 4.742-42.838-4-61.7a46.378 46.378 0 00-11.384-14.711C186.07-.036 155.601-4.095 129.611 3.921c-25.99 8.016-47.683 26.729-63.645 48.754C59.38 61.75 53.66 71.445 46.478 80.05c-9.567 11.459-21.985 21.518-26.14 35.833-3.024 10.346-1.2 21.554 2.395 31.732 5.526 15.6 29.337 43.353 40.671 43.3 25.26-.126 73.726 1.114 97.142.216 17.542-.692 37.257 1.242 55.62-5.691z"
        fill="currentColor"
      />
      <path
        d="M107.888 164.42c-.6 1.706-1.126 3.664-.257 5.251a6.695 6.695 0 002.281 2.143l8.262 5.616a11.104 11.104 0 005.173 2.329c1.9.144 4.065-1.072 4.227-2.994.192-2.2-2.113-3.7-3.191-5.628-1.413-2.527-.635-5.658.1-8.46a154.87 154.87 0 004.059-21.47c-1.694-.694-6.628-3.8-8.382-3.287-1.976.6-3.76 6.125-4.64 7.927a143.574 143.574 0 00-7.634 18.572l.002.001z"
        fill="#F1AF8C"
      />
      <path
        d="M123.737 117.031c-2.143 6.089-4.419 12.2-7.855 17.668a.867.867 0 00-.186.557c.055.19.173.355.335.467 3.455 3.024 7.185 6.179 11.729 6.831.296.069.606.041.886-.078.262-.186.456-.453.551-.76a51.99 51.99 0 003.826-21.332 87.944 87.944 0 00-.862-10.849c-.12-.874-.186-4.754-.946-5.209-1.156-.683-2.425 1.7-2.928 2.449a44.431 44.431 0 00-4.55 10.256z"
        fill="#fff"
      />
      <path
        d="M133.131 105.428s-2.7 1.012-4.9 8.616c-2.2 7.604-7.442 26.032-7.442 26.032s8.113 4.053 10.142 2.533 2.2-37.181 2.2-37.181z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M156.145 97.597c4.42-.247 8.852.183 13.142 1.275a21.776 21.776 0 0111.082 6.891c3.089 3.8 4.442 8.759 4.754 13.639.312 4.88-.335 9.771-.826 14.645a232.386 232.386 0 00-.91 34.336c.132 2.742 1.7 17.057-.227 19.015-1.2 1.233 1.2 2.467-.509 2.874-13.95 3.353-39.012 1.706-52.22-3.886-3.359-1.425-6.73-3.407-8.382-6.652-1.8-3.514 3.053-17.447 3.634-21.356a441.737 441.737 0 003.808-35.983c.341-5.335-.054-11.082 1.042-16.321.8-3.8 3.245-4.838 6.777-5.772a85.274 85.274 0 0118.836-2.706h-.001z"
        fill="#fff"
      />
      <path
        d="M167.622 160.013c-.461 2.425-1.066 4.957-2.724 6.789a11.709 11.709 0 01-4.682 2.862c-8.322 3.137-17.812 2.646-25.787 6.586a5.584 5.584 0 00-1.533 1c-1.539 1.521-1.108 4.191.114 5.987 2.712 3.969 7.987 5.083 12.747 5.819 11.411 1.8 23.063 3.263 34.468 1.425a3.158 3.158 0 001.706-.653 3.096 3.096 0 00.724-1.617c2.586-11.483.551-23.44.5-35.21 0-6.789.6-13.585.138-20.356a41.291 41.291 0 00-2.6-12.316c-1.06-2.682-4.161-8.6-6.023-3.107-2.958 8.735-3.808 18.153-4.874 27.259-.575 5.192-1.198 10.389-2.174 15.532z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        d="M171.191 181.064H74.426a4.915 4.915 0 00-4.915 4.915v.001c0 2.714 2.2 4.915 4.915 4.915h96.765a4.915 4.915 0 004.915-4.915v-.001c0-2.715-2.2-4.915-4.915-4.915z"
        fill="currentColor"
      />
      <path
        d="M171.191 181.064H74.426a4.915 4.915 0 00-4.915 4.915v.001c0 2.714 2.2 4.915 4.915 4.915h96.765a4.915 4.915 0 004.915-4.915v-.001c0-2.715-2.2-4.915-4.915-4.915z"
        fill="url(#prefix__paint2_linear)"
      />
      <path
        d="M145.47 106.9c-1.982-.317-3.1-2.311-2.209-7.113.1-.533.222-1.048.365-1.563.015-.099.041-.195.078-.287.216-.742.461-1.485.7-2.221a16.207 16.207 0 001.042-5.526 9.897 9.897 0 01.347-4.077 6.667 6.667 0 012.221-2.688c2.137-1.646 8.382-4.742 9.579-.653.533 1.748.144 3.784.12 5.574a16.758 16.758 0 001.377 7.478c1.306 2.622-8.753 11.855-13.62 11.076z"
        fill="#F1AF8C"
      />
      <path
        d="M144.063 97.148s11.04-4.19 13.172-10.819l-11.765 3.85s-.281 6.065-1.407 6.97z"
        fill="url(#prefix__paint3_linear)"
      />
      <path
        d="M173.993 111.48a74.17 74.17 0 006.035 36.635c.15.424.403.805.736 1.108a3 3 0 001.8.443 22.037 22.037 0 0013.687-4.395c1.2-.9 2.323-2.06 2.425-3.467a5.634 5.634 0 00-.437-2.2 243.302 243.302 0 00-10.687-26.05c-1.509-3.137-3.532-6.813-7.185-8.2-4.398-1.651-6.069 2.845-6.374 6.126z"
        fill="#fff"
      />
      <path
        d="M152.6 118.642c3.275-3.455 5.676-7.628 8.047-11.753 1.443-2.515 2.922-5.143 3.125-8.04a1.605 1.605 0 00-.15-.965 1.724 1.724 0 00-.551-.509l-4.495-3.047c-.078 2.76-2.06 5.131-4.323 6.718-2.395 1.7-9.25 3.562-7.783 7.089.366.893 5.747 10.908 6.13 10.507zM145.47 106.901a78.974 78.974 0 01-4.5 7.729 5.836 5.836 0 01-2.395 2.395.656.656 0 01-.437.06c-.263-.09-.323-.437-.341-.712-.221-4.6-.437-9.286.689-13.77a12.702 12.702 0 011.94-4.436 12.293 12.293 0 014-2.485c-.239.736-.485 1.48-.7 2.221a.725.725 0 00-.072.222v.066c-.144.515-.269 1.03-.365 1.563-.897 4.836.217 6.83 2.181 7.147z"
        fill="currentColor"
      />
      <path
        d="M152.6 118.642c3.275-3.455 5.676-7.628 8.047-11.753 1.443-2.515 2.922-5.143 3.125-8.04a1.605 1.605 0 00-.15-.965 1.724 1.724 0 00-.551-.509l-4.495-3.047c-.078 2.76-2.06 5.131-4.323 6.718-2.395 1.7-9.25 3.562-7.783 7.089.366.893 5.747 10.908 6.13 10.507z"
        fill="url(#prefix__paint4_linear)"
      />
      <path
        d="M145.47 106.901a78.974 78.974 0 01-4.5 7.729 5.836 5.836 0 01-2.395 2.395.656.656 0 01-.437.06c-.263-.09-.323-.437-.341-.712-.221-4.6-.437-9.286.689-13.77a12.702 12.702 0 011.94-4.436 12.293 12.293 0 014-2.485c-.239.736-.485 1.48-.7 2.221a.725.725 0 00-.072.222v.066c-.144.515-.269 1.03-.365 1.563-.897 4.836.217 6.83 2.181 7.147z"
        fill="url(#prefix__paint5_linear)"
      />
      <path
        d="M178.184 183.315l26.224-5.011a9.444 9.444 0 004.395-1.616 6.58 6.58 0 002.03-5.873 19.297 19.297 0 00-2.143-6.095c-3.724-7.711-11.28-21.554-11.328-20.745-4.586.97-8.382 1.018-12.322 3.538-.678.366-1.251.9-1.664 1.551-1.575 2.994 2.73 7.364 4.191 9.639.56.727.934 1.58 1.09 2.485a4.11 4.11 0 01-.347 1.862c-1.419 3.485-6.472 5.514-9.579 7.3a32.26 32.26 0 01-13.339 4.478c-3.664.311-7.406-.162-10.986.659a15.732 15.732 0 00-10.316 8.047c2.131 1.353 4.9.569 7.358-.048a43.055 43.055 0 0113.92-1.12c3.029.26 11.379 3.769 12.816.949z"
        fill="#F1AF8C"
      />
      <path
        d="M137.639 190.9H63.398l-19.584-49.693a4.364 4.364 0 014.06-5.987h58.279a14.056 14.056 0 0113.082 8.9l18.404 46.78z"
        fill="currentColor"
      />
      <path
        d="M137.639 190.9H63.398l-19.584-49.693a4.364 4.364 0 014.06-5.987h58.279a14.056 14.056 0 0113.082 8.9l18.404 46.78z"
        fill="url(#prefix__paint6_linear)"
      />
      <path
        d="M133.867 190.955h-70.54L44.718 143.77a4.15 4.15 0 013.856-5.67h55.357a13.379 13.379 0 0112.429 8.46l17.507 44.395z"
        fill="url(#prefix__paint7_linear)"
      />
      <path
        d="M143.075 26.966a18.302 18.302 0 015.622-2.521c2.03-.4 4.79-.156 6.161 1.377a12.821 12.821 0 00-9.154 4.832.892.892 0 01-.461.4.867.867 0 01-.485-.09c-.826-.311-7.017 1.1-7.376.353.017-.005 4.932-3.89 5.693-4.351z"
        fill="currentColor"
      />
      <path
        d="M143.075 26.966a18.302 18.302 0 015.622-2.521c2.03-.4 4.79-.156 6.161 1.377a12.821 12.821 0 00-9.154 4.832.892.892 0 01-.461.4.867.867 0 01-.485-.09c-.826-.311-7.017 1.1-7.376.353.017-.005 4.932-3.89 5.693-4.351z"
        fill="url(#prefix__paint8_linear)"
      />
      <path
        d="M129.693 72.625c4.014 0 7.268-4.01 7.268-8.957 0-4.947-3.254-8.957-7.268-8.957-4.014 0-7.268 4.01-7.268 8.957 0 4.947 3.254 8.957 7.268 8.957z"
        fill="currentColor"
      />
      <path
        d="M131.185 72.96c3.714 0 6.724-3.71 6.724-8.287 0-4.576-3.01-8.286-6.724-8.286-3.714 0-6.724 3.71-6.724 8.286s3.01 8.286 6.724 8.286z"
        fill="currentColor"
      />
      <path
        d="M131.185 72.96c3.714 0 6.724-3.71 6.724-8.287 0-4.576-3.01-8.286-6.724-8.286-3.714 0-6.724 3.71-6.724 8.286s3.01 8.286 6.724 8.286z"
        fill="url(#prefix__paint9_linear)"
      />
      <path
        d="M128.82 47.981l1.682 30.78s.748 11.615 12.262 11.729c13.83.138 18.039-7.9 20.141-14.022 2.102-6.122 4-24.092 0-29.062-4-4.97-26.505-17.2-34.085.575z"
        fill="#F1AF8C"
      />
      <path
        d="M137.567 29.499c-5.424-.1-11.621 2.437-14.094 7.556a8.867 8.867 0 00-.168 7.622 3.195 3.195 0 01.467 2.419 6.342 6.342 0 002.569 5.388 14.836 14.836 0 012.868 2.012l-.293-5.73a1.198 1.198 0 01.91-1.425l3.646-1.7s1.126.82 1.263.886a4.585 4.585 0 001.916.365 26.93 26.93 0 004.275-.24 40.391 40.391 0 008.843-2.67 4.022 4.022 0 01-1.09 2.36l9.3 1.8a25.252 25.252 0 013.263 6.095 21.23 21.23 0 011.257 5.275 19.056 19.056 0 01-.677 7.3 7.65 7.65 0 001.64-1.712 9.42 9.42 0 002.754 1.31 1.2 1.2 0 00.641.055c.455-.126.551-.665.6-1.1.371-3.862 3.245-7.125 4.562-10.813a16.264 16.264 0 00-1.652-13.627 10.99 10.99 0 00-2.892-3.377c-4.281-3.137-10.364-1.7-15.249-3.341-2.323-.778-4.191-2.257-6.412-3.233a23.043 23.043 0 00-8.247-1.475z"
        fill="#3F3D56"
      />
      <path
        d="M137.567 29.499c-5.424-.1-11.621 2.437-14.094 7.556a8.867 8.867 0 00-.168 7.622 3.195 3.195 0 01.467 2.419 6.342 6.342 0 002.569 5.388 14.836 14.836 0 012.868 2.012l-.293-5.73a1.198 1.198 0 01.91-1.425l3.646-1.7s1.126.82 1.263.886a4.585 4.585 0 001.916.365 26.93 26.93 0 004.275-.24 40.391 40.391 0 008.843-2.67 4.022 4.022 0 01-1.09 2.36l9.3 1.8a25.252 25.252 0 013.263 6.095 21.23 21.23 0 011.257 5.275 19.056 19.056 0 01-.677 7.3 7.65 7.65 0 001.64-1.712 9.42 9.42 0 002.754 1.31 1.2 1.2 0 00.641.055c.455-.126.551-.665.6-1.1.371-3.862 3.245-7.125 4.562-10.813a16.264 16.264 0 00-1.652-13.627 10.99 10.99 0 00-2.892-3.377c-4.281-3.137-10.364-1.7-15.249-3.341-2.323-.778-4.191-2.257-6.412-3.233a23.043 23.043 0 00-8.247-1.475z"
        fill="url(#prefix__paint10_linear)"
      />
      <path
        d="M169.73 73.636c3.972 0 7.191-3.97 7.191-8.867s-3.219-8.867-7.191-8.867c-3.971 0-7.191 3.97-7.191 8.867s3.22 8.867 7.191 8.867z"
        fill="currentColor"
      />
      <path
        d="M169.73 73.636c3.972 0 7.191-3.97 7.191-8.867s-3.219-8.867-7.191-8.867c-3.971 0-7.191 3.97-7.191 8.867s3.22 8.867 7.191 8.867z"
        fill="url(#prefix__paint11_linear)"
      />
      <path
        d="M172.484 73.636c3.971 0 7.191-3.97 7.191-8.867s-3.22-8.867-7.191-8.867c-3.972 0-7.191 3.97-7.191 8.867s3.219 8.867 7.191 8.867z"
        fill="currentColor"
      />
      <path
        d="M172.537 56.608l-3.592.383-.856.09-3.592-17.68c-1.868-6.993-6.436-12.369-12.166-14.453a6.466 6.466 0 00-2.766-.329l-2.449.222 1.2-.317 2.395-.635a7.889 7.889 0 012.3-.28c6.394.233 12.459 5.387 14.657 12.776l4.869 20.223z"
        fill="currentColor"
      />
      <path
        d="M172.537 56.608l-3.592.383-.856.09-3.592-17.68c-1.868-6.993-6.436-12.369-12.166-14.453a6.466 6.466 0 00-2.766-.329l-2.449.222 1.2-.317 2.395-.635a7.889 7.889 0 012.3-.28c6.394.233 12.459 5.387 14.657 12.776l4.869 20.223z"
        fill="url(#prefix__paint12_linear)"
      />
      <path
        d="M172.538 56.608l-3.592.383-3.592-17.722c-1.9-7.065-6.508-12.483-12.292-14.585a6.317 6.317 0 00-2.8-.335l-1.952.174 2.395-.635a7.889 7.889 0 012.3-.28c6.394.233 12.459 5.387 14.657 12.776l4.876 20.224z"
        fill="currentColor"
      />
      <path
        opacity={0.45}
        d="M90.877 3.089H15.64C7.002 3.089 0 10.09 0 18.729v90.535c0 8.638 7.002 15.64 15.64 15.64h75.237c8.638 0 15.64-7.002 15.64-15.64V18.729c0-8.638-7.002-15.64-15.64-15.64z"
        fill="currentColor"
      />
      <path
        d="M32.121 113.253c8.802 0 15.938-7.136 15.938-15.938s-7.136-15.938-15.938-15.938-15.938 7.136-15.938 15.938 7.136 15.938 15.938 15.938z"
        fill="url(#prefix__paint13_linear)"
      />
      <path d="M77.57 14.64H9.49v3.831h68.08V14.64z" fill="currentColor" />
      <path
        opacity={0.51}
        d="M79.623 65.027h-68.08v3.832h68.08v-3.832z"
        fill="currentColor"
      />
      <path
        d="M59.937 58.471H11.543v3.832h48.394v-3.832zM98.955 58.471H66.56v3.832h32.396v-3.832zM98.848 34.217H49.035v3.407h49.813v-3.407z"
        fill="currentColor"
      />
      <path
        opacity={0.51}
        d="M91.891 40.258H38.6v3.407h53.292v-3.407z"
        fill="currentColor"
      />
      <path
        d="M45.233 34.217H12.471v3.407h32.762v-3.407zM98.847 46.125H26.84v3.407h72.007v-3.407z"
        fill="currentColor"
      />
      <path
        opacity={0.51}
        d="M23.044 46.125H12.471v3.407h10.573v-3.407zM98.842 52.172H51.316v3.407h47.526v-3.407z"
        fill="currentColor"
      />
      <path
        d="M45.233 52.172H12.471v3.407h32.762v-3.407zM33.54 40.114H12.471v3.407h21.07v-3.407zM35.653 86.838a5.561 5.561 0 00-.916 4.19c.055.265.055.537 0 .8a1.8 1.8 0 01-.359.6 61.597 61.597 0 01-8.484 8.646.88.88 0 01-.305.192.904.904 0 01-.6-.084 4.776 4.776 0 00-2.8-.509 2.81 2.81 0 00-1.85 1.766 4.79 4.79 0 00-.138 2.628 39.742 39.742 0 013.191-2.395l1.575 1.563a.698.698 0 01.275.527.681.681 0 01-.186.323l-2.323 2.742a4.497 4.497 0 004.6-.766 3.182 3.182 0 00.2-4.407l8.915-9.993a3.412 3.412 0 004.137-.06 4.49 4.49 0 001.557-3.987 16.714 16.714 0 00-3.065 2.359l-1.8-2.24c.635-.6 3.1-2.25 1.5-2.9a3 3 0 00-3.124 1.005z"
        fill="currentColor"
      />
      <path
        d="M87.256 102.991l.856-2.724a31.403 31.403 0 00-3.485-2.5l-.138-1.97 3.1-3.293-1.425-2.473c-1.415.2-2.814.491-4.19.874a19.81 19.81 0 00-2.6-2.066l-.133-4.49-2.754-.748a31.944 31.944 0 00-2.353 3.592s-1.94.407-2.868.7l-3.137-2.67-2.395 1.545s.712 3.335.994 4.035c-.67.736-1.509 1.57-1.509 1.57l-4.466.717-.383 2.826s3.143 1.7 3.754 1.874a9.79 9.79 0 00.42 2.7l-2.438 3.748 1.838 2.179s2.437-.856 3.5-1.323c.988.9 2.395 1.964 2.395 1.964l1.126 4.383 2.85.12s1.371-3.191 1.515-3.892a14.142 14.142 0 012.76-.036l4.005 2.1 2.006-2.036a40.939 40.939 0 00-1.934-3.784c.255-.309.485-.637.69-.982l4.4.06zm-13.29.1a5.663 5.663 0 11-.012-11.33 5.663 5.663 0 01.011 11.332v-.002z"
        fill="url(#prefix__paint14_linear)"
      />
      <path
        d="M124.952 46.432a7.61 7.61 0 015.676-3.718 22.599 22.599 0 017 .323c-2.245 1.8-8.514 4.472-8.633 4.951a14.49 14.49 0 00-.03 2.551c.084 1.233.126 2.527.12 3.76a9.566 9.566 0 00-1.928-1.46 4.78 4.78 0 01-1.617-1.037 4.97 4.97 0 01-.593-5.37h.005z"
        fill="url(#prefix__paint15_linear)"
      />
      <path
        d="M155.444 86.263a27.9 27.9 0 01-7.963-1.33 1.2 1.2 0 01.689-2.3c6.167 1.857 10.807 1.612 13.77-.73 4.035-3.166 3.772-9.2 3.772-9.261a1.188 1.188 0 01.289-.896 1.194 1.194 0 01.846-.411 1.202 1.202 0 011.26 1.187c0 .293.317 7.346-4.688 11.274a12.528 12.528 0 01-7.975 2.467z"
        fill="currentColor"
      />
      <path
        d="M155.444 86.263a27.9 27.9 0 01-7.963-1.33 1.2 1.2 0 01.689-2.3c6.167 1.857 10.807 1.612 13.77-.73 4.035-3.166 3.772-9.2 3.772-9.261a1.188 1.188 0 01.289-.896 1.194 1.194 0 01.846-.411 1.202 1.202 0 011.26 1.187c0 .293.317 7.346-4.688 11.274a12.528 12.528 0 01-7.975 2.467z"
        fill="url(#prefix__paint16_linear)"
      />
      <path
        d="M148.104 80.61a3.175 3.175 0 011.515 1.64c.263 1.018-.527 1.994-1.341 2.658a5.015 5.015 0 01-2.485 1.2 5.765 5.765 0 01-3.772-1.341 4.997 4.997 0 01-1.569-1.515c-1.042-1.832.305-4.191 2.072-4.969 1.958-.846 4.042 1.316 5.58 2.327z"
        fill="currentColor"
      />
      <path
        d="M148.104 80.61a3.175 3.175 0 011.515 1.64c.263 1.018-.527 1.994-1.341 2.658a5.015 5.015 0 01-2.485 1.2 5.765 5.765 0 01-3.772-1.341 4.997 4.997 0 01-1.569-1.515c-1.042-1.832.305-4.191 2.072-4.969 1.958-.846 4.042 1.316 5.58 2.327z"
        fill="url(#prefix__paint17_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={159.691}
          y1={122.951}
          x2={118.659}
          y2={123.146}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={261.996}
          y1={247.992}
          x2={104.963}
          y2={180.576}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={122.596}
          y1={182.145}
          x2={122.604}
          y2={199.212}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={152.796}
          y1={74.969}
          x2={150.424}
          y2={102.45}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear"
          x1={149.508}
          y1={100.142}
          x2={164.462}
          y2={107.488}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.58} stopColor="#fff" stopOpacity={0.388} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear"
          x1={147.511}
          y1={105.984}
          x2={135.242}
          y2={106.099}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.58} stopColor="#fff" stopOpacity={0.388} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear"
          x1={144.605}
          y1={69.184}
          x2={121.841}
          y2={184.231}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear"
          x1={106.141}
          y1={214.211}
          x2={92.771}
          y2={107.78}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.09} stopOpacity={0.82} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear"
          x1={145.456}
          y1={21.492}
          x2={145.67}
          y2={34.482}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint9_linear"
          x1={134.466}
          y1={51.813}
          x2={125.393}
          y2={75.231}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint10_linear"
          x1={160.095}
          y1={39.124}
          x2={153.037}
          y2={49.39}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint11_linear"
          x1={170.981}
          y1={64.521}
          x2={162.319}
          y2={65.662}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint12_linear"
          x1={172.181}
          y1={35.389}
          x2={151.894}
          y2={40.464}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint13_linear"
          x1={34.99}
          y1={40.225}
          x2={31.388}
          y2={111.691}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.58} stopColor="#fff" stopOpacity={0.388} />
          <stop offset={0.68} stopColor="#fff" stopOpacity={0.678} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint14_linear"
          x1={89.139}
          y1={61.792}
          x2={74.773}
          y2={95.683}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.58} stopColor="#fff" stopOpacity={0.388} />
          <stop offset={0.68} stopColor="#fff" stopOpacity={0.678} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint15_linear"
          x1={130.298}
          y1={56.233}
          x2={131.277}
          y2={38.567}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint16_linear"
          x1={154.467}
          y1={72.155}
          x2={157.614}
          y2={85.591}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.58} stopColor="#fff" stopOpacity={0.388} />
          <stop offset={0.68} stopColor="#fff" stopOpacity={0.678} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint17_linear"
          x1={147.898}
          y1={76.022}
          x2={142.451}
          y2={91.66}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Error;
