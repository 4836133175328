import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from "react";
import classnames from "classnames";

export type ButtonProps = {
  block?: boolean;
  variant?: "brand" | "brand-secondary" | "primary" | "secondary";
  outline?: boolean;
};

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  ButtonProps;

const Button: FC<Props> = ({
  block,
  variant = "brand",
  outline = false,
  ...props
}) => {
  const className = classnames([
    "flex items-center justify-center text-base font-semibold h-10 rounded px-4 border transition outline-none",
    {
      "!text-white": !outline,
      "border-2 hover:text-white focus:ring-2 !bg-transparent": outline,

      "bg-primary text-primary border-primary hover:brightness-110 hover:!bg-primary focus:brightness-110 focus:ring-2 ring-primary":
        !props.disabled && variant === "brand",
      "bg-secondary text-secondary border-secondary hover:brightness-110 hover:!bg-secondary focus:brightness-110 focus:ring-2 ring-secondary":
        !props.disabled && variant === "brand-secondary",
      "bg-xen-blue-500 text-xen-blue-600 border-xen-blue-500 hover:!bg-xen-blue-600 hover:border-xen-blue-600 focus:ring-2 ring-xen-blue-600":
        !props.disabled && variant === "primary",
      "bg-xen-gray-700 text-xen-gray-800 border-xen-gray-700 hover:!bg-xen-gray-800 hover:border-xen-gray-800 focus:ring-2 ring-xen-gray-800":
        !props.disabled && variant === "secondary",

      "bg-xen-gray-600": props.disabled && !outline,
      "border-xen-gray-400 text-xen-gray-600 hover:text-xen-gray-600":
        props.disabled && outline,

      "w-full flex-1": block
    },
    props.className
  ]);

  return <button {...props} className={className} />;
};

export default Button;
