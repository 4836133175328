import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import clx from "classnames";
import {
  formatDisplayAmountWithCurrencyCode,
  PaymentMethodsEnum
} from "@xendit/checkout-utilities";

import FraudWarningNotes from "../FraudWarningNotes";
import Tooltip from "../Tooltip";

import Copy from "../../assets/icons/Copy";

import useHover from "../../hooks/use-hover";
import useCopyToClipboard from "../../hooks/use-copy-to-clipboard";

export const ChannelCodeInformationsRoot = ({
  children
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  return (
    <div className="lg:mt-6">
      <div
        className={clx([
          "flex flex-col-reverse sm:flex-row sm:items-center",
          "space-y-6 space-y-reverse",
          "p-6 sm:p-8",
          "border-.5 border-xen-gray-400 rounded-sm"
        ])}
      >
        {children}
      </div>
    </div>
  );
};

export const ChannelCodeInformationsGroup = ({
  children
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  return <div className="space-y-1">{children}</div>;
};

export const ChannelCodeInformationsLabel = ({
  children
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  return (
    <div className="font-semibold text-xen-gray-700 text-base leading-tight">
      {children}
    </div>
  );
};

export interface ChannelCodeInformationsValueProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  dataTestId: string;
}
export const ChannelCodeInformationsValue = ({
  children,
  dataTestId
}: ChannelCodeInformationsValueProps) => {
  return (
    <div className="text-3xl" data-testid={dataTestId}>
      {children}
    </div>
  );
};

export interface ChannelCodeInformationsProps {
  paymentMethodType: PaymentMethodsEnum;
  paymentCode: string;
  merchantName: string;
  amount: number;
  currency: string;
  visual: React.ReactNode;
}
const ChannelCodeInformations = ({
  paymentMethodType,
  paymentCode,
  merchantName,
  amount,
  currency,
  visual
}: ChannelCodeInformationsProps) => {
  const { t } = useTranslation("common");

  const clipboardRef = useRef<HTMLSpanElement>(null);

  const isClipboardHovered = useHover(clipboardRef);
  const [copyAction, isCopied] = useCopyToClipboard(paymentCode);

  const handleCopyToClipboard = () => {
    copyAction();
  };

  return (
    <>
      <ChannelCodeInformationsRoot>
        <div className="basis-1/2 space-y-6">
          <ChannelCodeInformationsGroup>
            <ChannelCodeInformationsLabel>
              {paymentMethodType === PaymentMethodsEnum.BANK_TRANSFER
                ? t("Virtual Account Number")
                : t("Payment Code")}
            </ChannelCodeInformationsLabel>
            <ChannelCodeInformationsValue dataTestId="payment-code-container">
              <span
                className="flex items-center"
                data-testid={
                  paymentMethodType === PaymentMethodsEnum.BANK_TRANSFER
                    ? "va-number"
                    : "payment-code"
                }
              >
                {paymentCode === undefined ? (
                  <div className="animate-pulse">
                    <div className="flex-1 py-1">
                      <div
                        className="bg-xen-gray-200 lg:rounded w-48 h-8 text-base py-1 px-3"
                        data-testid="payment-code-loader"
                      >
                        {t("Generating...")}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {paymentCode}
                    <span ref={clipboardRef} className="pl-2">
                      <Tooltip
                        open={isClipboardHovered}
                        contentChildren={t(
                          isCopied
                            ? "Copied"
                            : paymentMethodType ===
                              PaymentMethodsEnum.BANK_TRANSFER
                            ? "Copy Account Number"
                            : "Copy Payment Code"
                        )}
                      >
                        <Copy
                          className="cursor-pointer text-xen-gray-700"
                          onClick={handleCopyToClipboard}
                        />
                      </Tooltip>
                    </span>
                  </>
                )}
              </span>
            </ChannelCodeInformationsValue>
          </ChannelCodeInformationsGroup>
          <ChannelCodeInformationsGroup>
            <ChannelCodeInformationsLabel>
              {paymentMethodType === PaymentMethodsEnum.BANK_TRANSFER
                ? t("Virtual Account Name")
                : t("Pay to Merchant")}
            </ChannelCodeInformationsLabel>
            <ChannelCodeInformationsValue
              dataTestId={
                paymentMethodType === PaymentMethodsEnum.BANK_TRANSFER
                  ? "va-account-name"
                  : "merchant-name"
              }
            >
              {merchantName}
            </ChannelCodeInformationsValue>
          </ChannelCodeInformationsGroup>
          <ChannelCodeInformationsGroup>
            <ChannelCodeInformationsLabel>
              {t("Amount to Pay")}
            </ChannelCodeInformationsLabel>
            <ChannelCodeInformationsValue dataTestId="total-amount">
              {formatDisplayAmountWithCurrencyCode(amount, currency)}
            </ChannelCodeInformationsValue>
          </ChannelCodeInformationsGroup>
        </div>
        <div className="basis-1/2 self-center flex flex-col items-center">
          {visual}
        </div>
      </ChannelCodeInformationsRoot>
      {paymentMethodType === PaymentMethodsEnum.BANK_TRANSFER &&
      currency === "IDR" ? (
        <FraudWarningNotes />
      ) : null}
    </>
  );
};

export default ChannelCodeInformations;
