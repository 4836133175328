import { createStore } from "zustand/vanilla";

type StoreState = {
  experimentId: string;
  variantId: string;
};

export const shareGrowthBookItemsStore = createStore<StoreState>(() => ({
  experimentId: "",
  variantId: ""
}));
