import { CountriesEnum } from "@xendit/checkout-utilities";
import { MOBILE_PHONE_COUNTRY_CODE } from "./constants";

export const constantCase = (name: string) => {
  return name.toLocaleUpperCase().replace(/-/g, "_");
};

export const titleCase = (name: string) => {
  return name
    .split(" ")
    .map((word) => `${word[0].toLocaleUpperCase()}${word.slice(1)}`)
    .join(" ");
};

export const prependCountryCode = (
  phoneNumber: string,
  countryOfOperation: CountriesEnum
) => {
  const sanitizedNumber = phoneNumber.replace(/\s/g, "");
  if (!sanitizedNumber.startsWith("0")) {
    return sanitizedNumber;
  }

  const countryCode =
    MOBILE_PHONE_COUNTRY_CODE[countryOfOperation.toUpperCase()] ??
    MOBILE_PHONE_COUNTRY_CODE.Indonesia;

  return `${countryCode}${sanitizedNumber.slice(1)}`;
};

export const trimSpecialCharactersAndSpaces = (mobileNumber: string) => {
  return mobileNumber.trim().replace(/[^a-zA-Z0-9+]/g, "");
};
