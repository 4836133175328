import { Suspense, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { CheckoutInitialData } from "../../types/checkout";

import { applyCustomColors } from "../../helpers/apply-custom-colors";
import {
  InternalAnalyticsEvent,
  logInternalAnalyticsEvent
} from "../../utils/analytics";

import CheckoutProcessing from "../../views/CheckoutProcessing";
import NotFound from "../../views/NotFound";

import ErrorBoundary from "../../components/ErrorBoundary";
import GenericError from "../../components/GenericError";
import Layout, { MainView } from "../../components/Layout";

import PaymentLinkProvider from "../../contexts/PaymentLinkContext";

import useFetch from "../../hooks/use-fetch";
import { CHECKOUT_UI_GATEWAY_URL } from "../../utils/constants";

type Props = {
  invoiceId: string;
};

const ProcessingView = ({ invoiceId }: Props) => {
  const { data, error } = useFetch<CheckoutInitialData>(
    CHECKOUT_UI_GATEWAY_URL + `/api/invoices/${invoiceId}/init`
  );

  useEffect(() => {
    if (data) {
      document.querySelector(".loading-container")?.remove();

      if (data.invoice_settings) {
        applyCustomColors(data.invoice_settings);
      }
      if (data.invoice) {
        logInternalAnalyticsEvent({
          event: InternalAnalyticsEvent.PAYMENT_PROCESSING
        });
      }
    }
  }, [data]);

  if (error) {
    if ((error as AxiosError).response?.status === 404) {
      return (
        <Layout>
          <MainView>
            <NotFound />
          </MainView>
        </Layout>
      );
    } else {
      throw error;
    }
  }

  if (!data) {
    return null;
  }

  return (
    <PaymentLinkProvider paymentLink={data}>
      <MainView>
        <CheckoutProcessing />
      </MainView>
    </PaymentLinkProvider>
  );
};

const Processing = () => {
  const { id } = useParams();

  return (
    <Layout>
      <ErrorBoundary
        fallback={
          <MainView>
            <GenericError initial />
          </MainView>
        }
      >
        <Suspense>
          <ProcessingView invoiceId={id as string} />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  );
};

export default Processing;
