import { FC, useEffect } from "react";
import { formatDisplayAmountWithCurrencyCode } from "@xendit/checkout-utilities";
import { useTranslation } from "react-i18next";
import type { PaymentChannelsPropertiesType } from "@xendit/checkout-utilities/dist/src/types/payment-channels";

import { usePaymentLink } from "../../contexts/PaymentLinkContext";
import { usePaymentMethod } from "../../contexts/PaymentMethodContext";
import { usePaymentRequest } from "../../contexts/PaymentRequestContext";

import { useQrCodeRefresh } from "../../hooks/use-qr-code-refresh";

import { ENABLE_SIMULATION } from "../../utils/constants";

import QrPayment from "../../components/QrPayment";
import SimulationProvider from "../../contexts/SimulationContext/SimulationContext";
import SimulationBar from "../../components/SimulationBar/SimulationBar";

type Props = {
  channel: PaymentChannelsPropertiesType;
};

const PaymentQrCodeAccordion: FC<Props> = (props) => {
  const {
    paymentLink: {
      invoice: { id, currency, expiry_date: expiryDate }
    }
  } = usePaymentLink();
  const { t } = useTranslation("common");
  const { amountRange, selectPaymentChannel, paymentChannel } =
    usePaymentMethod();
  const {
    error: paymentRequestError,
    paymentRequest,
    initializing,
    onInitialize
  } = usePaymentRequest();

  const { qr_string: qrString, expires_at: expiresAt } =
    paymentRequest?.payment_method.qr_code?.channel_properties || {};

  const { hasExpired, willRefresh } = useQrCodeRefresh(
    onInitialize,
    expiryDate,
    expiresAt
  );

  useEffect(() => {
    selectPaymentChannel(props.channel.channel);
  }, [selectPaymentChannel, props.channel.channel]);

  const error = (() => {
    if (amountRange && !amountRange.isWithinAmountRange) {
      return t(
        "The accepted amount range is {{minimumAmount}} — {{maximumAmount}}",
        {
          minimumAmount: formatDisplayAmountWithCurrencyCode(
            amountRange?.minimumAmount,
            currency
          ),
          maximumAmount: formatDisplayAmountWithCurrencyCode(
            amountRange?.maximumAmount,
            currency
          )
        }
      );
    }
    if (paymentRequestError) {
      return t("{{channel}} is not available.", {
        channel: props.channel.display_name
      });
    }

    return null;
  })();

  const QrCodeAccordionContent = (
    <QrPayment
      channel={{
        displayName: props.channel.channel,
        label: props.channel.label,
        logoUrl: props.channel.logo_url
      }}
      downloadFilename={`checkout-${id}-qr-code`}
      initializing={initializing}
      qrString={qrString}
      hasExpired={hasExpired}
      willRefresh={willRefresh}
      error={error}
    />
  );

  if (ENABLE_SIMULATION && paymentChannel?.channel === props.channel.channel) {
    return (
      <SimulationProvider>
        <SimulationBar />
        {QrCodeAccordionContent}
      </SimulationProvider>
    );
  }

  return QrCodeAccordionContent;
};

export default PaymentQrCodeAccordion;
