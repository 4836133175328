import { FC, SVGProps } from "react";

const PayWithCrypto: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      height={24}
      width={24}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.31 14.4625C22.069 13.7317 22.6021 12.7983 22.8459 11.7732C23.0897 10.7482 23.034 9.67472 22.6853 8.68045C22.3366 7.68619 21.7097 6.813 20.8791 6.16474C20.0485 5.51649 19.0492 5.12046 18 5.02375V3C18 2.73478 17.8946 2.48043 17.7071 2.29289C17.5196 2.10536 17.2652 2 17 2C16.7348 2 16.4804 2.10536 16.2929 2.29289C16.1054 2.48043 16 2.73478 16 3V5H14V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2C12.7348 2 12.4804 2.10536 12.2929 2.29289C12.1054 2.48043 12 2.73478 12 3V5H8C7.73478 5 7.48043 5.10536 7.29289 5.29289C7.10536 5.48043 7 5.73478 7 6C7 6.26522 7.10536 6.51957 7.29289 6.70711C7.48043 6.89464 7.73478 7 8 7H9V24H8C7.73478 24 7.48043 24.1054 7.29289 24.2929C7.10536 24.4804 7 24.7348 7 25C7 25.2652 7.10536 25.5196 7.29289 25.7071C7.48043 25.8946 7.73478 26 8 26H12V28C12 28.2652 12.1054 28.5196 12.2929 28.7071C12.4804 28.8946 12.7348 29 13 29C13.2652 29 13.5196 28.8946 13.7071 28.7071C13.8946 28.5196 14 28.2652 14 28V26H16V28C16 28.2652 16.1054 28.5196 16.2929 28.7071C16.4804 28.8946 16.7348 29 17 29C17.2652 29 17.5196 28.8946 17.7071 28.7071C17.8946 28.5196 18 28.2652 18 28V26H19C20.3868 26 21.7309 25.5196 22.8035 24.6404C23.876 23.7613 24.611 22.5378 24.8832 21.1779C25.1555 19.8181 24.9483 18.4059 24.2968 17.1816C23.6454 15.9573 22.5899 14.9964 21.31 14.4625ZM21 10.5C21 11.4283 20.6313 12.3185 19.9749 12.9749C19.3185 13.6313 18.4283 14 17.5 14H11V7H17.5C18.4283 7 19.3185 7.36875 19.9749 8.02513C20.6313 8.6815 21 9.57174 21 10.5ZM19 24H11V16H19C20.0609 16 21.0783 16.4214 21.8284 17.1716C22.5786 17.9217 23 18.9391 23 20C23 21.0609 22.5786 22.0783 21.8284 22.8284C21.0783 23.5786 20.0609 24 19 24Z"
        fill="#777"
      />
    </svg>
  );
};

export default PayWithCrypto;
