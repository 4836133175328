import { Flow, FlowStep } from "../../Flow";
import { THDirectDebitFormBValues } from "./formik";
import THDirectDebitFormB from "./THDirectDebitFormB";

export class THDirectDebitBFlow extends Flow {
  buildFlow() {
    this.clearFlow();
    this.appendStep({
      overlay: false,
      form: (
        <THDirectDebitFormB
          key="th-dd-form-b-step-1"
          paymentMethod={this.getSelectedPaymentMethod()}
          onSubmit={this.onFormSubmission}
          onCancel={this.resetSelection}
        />
      )
    });
    return this;
  }

  onSelectFlow(): FlowStep[] {
    return this.flow;
  }

  private onFormSubmission = async (values: THDirectDebitFormBValues) => {
    const createdTokenEither = await this.postPaymentTokenAction(values);
    if (createdTokenEither.isRight()) {
      const createdToken = createdTokenEither.getRight();
      this.exitFlow(createdToken);
    }
  };
}
