import { useState, useEffect } from "react";

export const useRedirect = (shouldRedirect: boolean, redirectUrl?: string) => {
  const timer = 3;
  const [secondsLeft, setSecondsLeft] = useState<number>(timer);

  useEffect(() => {
    if (shouldRedirect && redirectUrl) {
      if (secondsLeft === 0) {
        const rootWindow = window.top || window;
        rootWindow.location.href = redirectUrl;
      } else {
        const timer = setTimeout(() => {
          setSecondsLeft(secondsLeft - 1);
        }, 1000);
        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [shouldRedirect, redirectUrl, secondsLeft]);

  return secondsLeft;
};
