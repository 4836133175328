import { FC, useMemo } from "react";
import { formatDisplayAmountWithCurrencyCode } from "@xendit/checkout-utilities/dist/src/amount-formatter";
import upperFirst from "lodash/upperFirst";
import { useTranslation } from "react-i18next";
import { CreditCardPromotion } from "../../types/credit-card";

export type Fee = {
  name: string;
  value: number;
};

export type Item = {
  name: string;
  price: number;
  quantity: number;
};

type Props = {
  amount: number;
  currency?: string;
  fees?: Fee[];
  items?: Item[];
  promotion?: CreditCardPromotion | null;
  totalLabel?: string;
};

const PriceSummary: FC<Props> = (props) => {
  const { t } = useTranslation("common");
  const { hasFees, hasItems, feesTotal, subtotal, shouldShowSubtotal } =
    useMemo(() => {
      const hasItems = props.items && props.items.length > 0;
      const hasFees = props.fees && props.fees.length > 0;
      const itemsTotal =
        props.items?.reduce(
          (agg, item) => agg + item.price * item.quantity,
          0
        ) || 0;
      const feesTotal =
        props.fees?.reduce((agg, fee) => agg + fee.value, 0) || 0;

      return {
        hasFees,
        hasItems,
        feesTotal,
        subtotal: hasItems ? itemsTotal : props.amount - feesTotal,
        shouldShowSubtotal: hasFees || hasItems
      };
    }, [props.items, props.fees, props.amount]);

  return (
    <table className="w-full">
      <tbody>
        {hasItems ? (
          <>
            {props.items?.map((item, index) => {
              const amount = item.quantity * item.price;
              return (
                <tr key={item.name} data-testid={`order-item-summary-${index}`}>
                  <td className="py-3 pr-4 w-full" valign="top">
                    <div className="font-semibold mb-1">{item.name}</div>
                    <div>
                      {Intl.NumberFormat("id", {
                        style: "decimal"
                      }).format(item.quantity)}{" "}
                      &times;{" "}
                      {formatDisplayAmountWithCurrencyCode(
                        item.price,
                        props.currency
                      )}
                    </div>
                  </td>
                  <td
                    className="py-3 text-right font-semibold whitespace-nowrap"
                    valign="top"
                  >
                    {formatDisplayAmountWithCurrencyCode(
                      amount,
                      props.currency
                    )}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={2} className="py-4">
                <hr className="border-xen-gray-500 opacity-20" />
              </td>
            </tr>
          </>
        ) : null}
        {shouldShowSubtotal ? (
          <>
            <tr data-testid="subtotal">
              <td className="lg:text-right font-semibold py-3 pr-4 w-full text-xen-gray-700 lg:text-xen-gray-900">
                {t("Subtotal")}
              </td>
              <td className="text-right font-semibold py-3 whitespace-nowrap">
                {formatDisplayAmountWithCurrencyCode(subtotal, props.currency)}
              </td>
            </tr>
            {props.promotion ? (
              <tr data-testid="promo-amount">
                <td className="lg:text-right py-3 pr-4 w-full text-xen-gray-700 lg:text-xen-gray-900">
                  {"Promo Code"}
                </td>
                <td className="text-right py-3 whitespace-nowrap">
                  -{" "}
                  {formatDisplayAmountWithCurrencyCode(
                    props.promotion.discount_amount,
                    props.currency
                  )}
                </td>
              </tr>
            ) : null}
            <tr className="hidden lg:table-row">
              <td colSpan={2} className="py-3">
                <hr className="border-xen-gray-500 opacity-20" />
              </td>
            </tr>
          </>
        ) : null}
        {hasFees ? (
          <>
            {props.fees?.map((fee, index) => {
              return (
                <tr key={fee.name} data-testid={`order-fee-${index}`}>
                  <td className="lg:text-right py-2 pr-4 w-full font-semibold lg:font-normal text-xen-gray-700 lg:text-xen-gray-900">
                    {fee.name
                      .toLocaleLowerCase()
                      .split(" ")
                      .map(upperFirst)
                      .join(" ")}
                  </td>
                  <td className="text-right py-2 font-semibold lg:font-normal whitespace-nowrap">
                    {formatDisplayAmountWithCurrencyCode(
                      fee.value,
                      props.currency
                    )}
                  </td>
                </tr>
              );
            })}
            <tr className="hidden lg:table-row" data-testid="total-fees">
              <td className="text-right font-semibold py-2 pr-4 w-full">
                {t("Total Fees")}
              </td>
              <td className="text-right font-semibold py-2 whitespace-nowrap">
                {formatDisplayAmountWithCurrencyCode(feesTotal, props.currency)}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="py-3">
                <hr className="border-xen-gray-500 opacity-20" />
              </td>
            </tr>
          </>
        ) : null}
        <tr data-testid="total-due">
          <td className="py-3" valign="middle" colSpan={2}>
            <div className="flex items-center justify-between">
              <div className="font-semibold hidden lg:block">
                {props.totalLabel || t("Total Amount Due")}
              </div>
              <div className="font-semibold text-xl lg:hidden">Total</div>
              <div className="text-right text-xl lg:text-2xl font-semibold">
                {formatDisplayAmountWithCurrencyCode(
                  props.promotion ? props.promotion.final_amount : props.amount,
                  props.currency
                )}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PriceSummary;
