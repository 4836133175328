import { FC, useState } from "react";

import { PaymentChannelsPropertiesType } from "@xendit/checkout-utilities/dist/src/types/payment-channels";
import { useTranslation } from "react-i18next";
import kebabCase from "lodash/kebabCase";

import { usePaymentMethod } from "../../contexts/PaymentMethodContext";

import Dropdown, { DropdownOption } from "../../components/Dropdown";
import FormField from "../../components/FormField";
import Tooltip from "../../components/Tooltip";

import FpxLogo from "../../assets/icons/Fpx";
import Button from "../../components/Button/Button";

type Props = {
  channels: PaymentChannelsPropertiesType[];
};

type FpxMode = "INDIVIDUAL" | "CORPORATE";

const FPXAccordionContent: FC<Props> = (props) => {
  type BankOption = (typeof channels)[0];

  const { selectPaymentChannel } = usePaymentMethod();

  const { t } = useTranslation(["fpx-dd", "common"]);

  const [selectedBank, setSelectedBank] = useState<BankOption | null>(null);
  const [fpxMode, setFpxMode] = useState<FpxMode>("INDIVIDUAL");

  const channels = props.channels.map((channel: any) => {
    return {
      channel,
      disabled: channel?.disabled,
      tooltipMessage: channel?.tooltipMessage
    };
  });

  const getChannels = (
    bankChannels: typeof channels,
    mode: FpxMode
  ): typeof channels => {
    const fpxChannels = bankChannels.filter((channel: any) => {
      const isCorporate = (channel?.channel?.name as string)?.includes(
        "FPX_BUSINESS"
      );
      if (mode === "CORPORATE") {
        return isCorporate;
      }
      return !isCorporate;
    });

    return fpxChannels;
  };

  const switchMode = (mode: FpxMode) => {
    if (fpxMode !== mode) {
      setSelectedBank(null);
      setFpxMode(mode);
    }
  };

  return (
    <>
      {channels && channels.length > 0 ? (
        <div className="py-6 flex justify-center">
          <div className="w-full max-w-md px-4 flex flex-col space-y-6">
            <div className="w-full flex flex-row justify-center">
              <div className="flex items-center justify-center">
                <label className="text-center mx-auto text-xl font-bold">
                  {t("Pay with")}
                </label>
              </div>
              <FpxLogo />
            </div>

            <div className="grid grid-cols-2">
              <Button
                outline={fpxMode !== "INDIVIDUAL"}
                onClick={() => switchMode("INDIVIDUAL")}
                className="rounded-r-none"
                variant={fpxMode === "INDIVIDUAL" ? "primary" : "secondary"}
                data-testid="fpx-individual"
              >
                {t("Individual")}
              </Button>
              <Button
                outline={fpxMode !== "CORPORATE"}
                onClick={() => switchMode("CORPORATE")}
                className="rounded-l-none"
                variant={fpxMode === "CORPORATE" ? "primary" : "secondary"}
                data-testid="fpx-corporate"
              >
                {t("Corporate")}
              </Button>
            </div>

            <FormField>
              <Dropdown
                data-testid="select-fpx-bank"
                options={getChannels(channels, fpxMode)}
                keyExtractor={(option) =>
                  (option as BankOption).channel.channel
                }
                value={selectedBank?.channel}
                onChange={(channel: any) => {
                  return setSelectedBank(channel);
                }}
                block
                placeholder={t("Select Bank")}
                renderOption={(option, helpers) => {
                  const { tooltipMessage, channel } = option as BankOption;

                  return (
                    <Tooltip
                      contentChildren={tooltipMessage}
                      delayDuration={0}
                      side="bottom"
                      asChild
                    >
                      <div className="w-full">
                        <DropdownOption
                          {...helpers}
                          className="flex items-center space-x-2"
                          data-testid={kebabCase(channel.label.toLowerCase())}
                        >
                          {channel.logo_url ? (
                            <div className="w-8 h-6 bg-white rounded flex items-center justify-center">
                              <img
                                src={channel.logo_url}
                                alt={channel.label}
                                className="w-7 h-5"
                              />
                            </div>
                          ) : null}
                          <span>{channel.display_name}</span>
                        </DropdownOption>
                      </div>
                    </Tooltip>
                  );
                }}
                renderSelection={(selection) => {
                  const { display_name, logo_url, label } =
                    selection as PaymentChannelsPropertiesType;

                  return (
                    <div className="flex items-center space-x-2">
                      {logo_url ? (
                        <div className="w-8 h-6 bg-white rounded flex items-center justify-center">
                          <img src={logo_url} alt={label} className="w-7 h-5" />
                        </div>
                      ) : null}
                      <span>{display_name}</span>
                    </div>
                  );
                }}
                optionDisabled={(option) => (option as BankOption).disabled}
              />
            </FormField>
            {selectedBank ? (
              <>
                <Button
                  block
                  variant="brand-secondary"
                  className="flex-1 md:flex-initial"
                  data-testid="continue"
                  onClick={() =>
                    selectPaymentChannel(selectedBank?.channel.channel)
                  }
                >
                  {t("Continue")}
                </Button>

                <label className="text-center mx-auto text-sm font-bold">
                  *{t("By clicking Continue, you hereby agree with")}
                  <span className="mr-1"> </span>
                  <a
                    href="https://www.paynet.my/img/content/fpx/FPX%20Entry%20Page%20Terms%20and%20Conditions%20for%20Customer_final%20(180315).pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <label className="text-blue-500 underline cursor-pointer">
                      {t("FPX Terms and Conditions")}
                    </label>
                  </a>
                </label>
              </>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FPXAccordionContent;
