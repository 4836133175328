export type Country = {
  name: string;
  iso2: string;
  dial_code: string;
  priority: number;
  area_codes: string[] | null;
  flag: string;
};
export const countries: Country[] = [
  {
    name: "Afghanistan",
    iso2: "AF",
    dial_code: "93",
    priority: 0,
    area_codes: null,
    flag: "🇦🇫"
  },
  {
    name: "Åland Islands",
    iso2: "AX",
    dial_code: "358",
    priority: 1,
    area_codes: ["18"],
    flag: "🇦🇽"
  },
  {
    name: "Albania",
    iso2: "AL",
    dial_code: "355",
    priority: 0,
    area_codes: null,
    flag: "🇦🇱"
  },
  {
    name: "Algeria",
    iso2: "DZ",
    dial_code: "213",
    priority: 0,
    area_codes: null,
    flag: "🇩🇿"
  },
  {
    name: "American Samoa",
    iso2: "AS",
    dial_code: "1",
    priority: 5,
    area_codes: ["684"],
    flag: "🇦🇸"
  },
  {
    name: "Andorra",
    iso2: "AD",
    dial_code: "376",
    priority: 0,
    area_codes: null,
    flag: "🇦🇩"
  },
  {
    name: "Angola",
    iso2: "AO",
    dial_code: "244",
    priority: 0,
    area_codes: null,
    flag: "🇦🇴"
  },
  {
    name: "Anguilla",
    iso2: "AI",
    dial_code: "1",
    priority: 6,
    area_codes: ["264"],
    flag: "🇦🇮"
  },
  {
    name: "Antigua & Barbuda",
    iso2: "AG",
    dial_code: "1",
    priority: 7,
    area_codes: ["268"],
    flag: "🇦🇬"
  },
  {
    name: "Argentina",
    iso2: "AR",
    dial_code: "54",
    priority: 0,
    area_codes: null,
    flag: "🇦🇷"
  },
  {
    name: "Armenia",
    iso2: "AM",
    dial_code: "374",
    priority: 0,
    area_codes: null,
    flag: "🇦🇲"
  },
  {
    name: "Aruba",
    iso2: "AW",
    dial_code: "297",
    priority: 0,
    area_codes: null,
    flag: "🇦🇼"
  },
  {
    name: "Ascension Island",
    iso2: "AC",
    dial_code: "247",
    priority: 0,
    area_codes: null,
    flag: "🇦🇨"
  },
  {
    name: "Australia",
    iso2: "AU",
    dial_code: "61",
    priority: 0,
    area_codes: null,
    flag: "🇦🇺"
  },
  {
    name: "Austria",
    iso2: "AT",
    dial_code: "43",
    priority: 0,
    area_codes: null,
    flag: "🇦🇹"
  },
  {
    name: "Azerbaijan",
    iso2: "AZ",
    dial_code: "994",
    priority: 0,
    area_codes: null,
    flag: "🇦🇿"
  },
  {
    name: "Bahamas",
    iso2: "BS",
    dial_code: "1",
    priority: 8,
    area_codes: ["242"],
    flag: "🇧🇸"
  },
  {
    name: "Bahrain",
    iso2: "BH",
    dial_code: "973",
    priority: 0,
    area_codes: null,
    flag: "🇧🇭"
  },
  {
    name: "Bangladesh",
    iso2: "BD",
    dial_code: "880",
    priority: 0,
    area_codes: null,
    flag: "🇧🇩"
  },
  {
    name: "Barbados",
    iso2: "BB",
    dial_code: "1",
    priority: 9,
    area_codes: ["246"],
    flag: "🇧🇧"
  },
  {
    name: "Belarus",
    iso2: "BY",
    dial_code: "375",
    priority: 0,
    area_codes: null,
    flag: "🇧🇾"
  },
  {
    name: "Belgium",
    iso2: "BE",
    dial_code: "32",
    priority: 0,
    area_codes: null,
    flag: "🇧🇪"
  },
  {
    name: "Belize",
    iso2: "BZ",
    dial_code: "501",
    priority: 0,
    area_codes: null,
    flag: "🇧🇿"
  },
  {
    name: "Benin",
    iso2: "BJ",
    dial_code: "229",
    priority: 0,
    area_codes: null,
    flag: "🇧🇯"
  },
  {
    name: "Bermuda",
    iso2: "BM",
    dial_code: "1",
    priority: 10,
    area_codes: ["441"],
    flag: "🇧🇲"
  },
  {
    name: "Bhutan",
    iso2: "BT",
    dial_code: "975",
    priority: 0,
    area_codes: null,
    flag: "🇧🇹"
  },
  {
    name: "Bolivia",
    iso2: "BO",
    dial_code: "591",
    priority: 0,
    area_codes: null,
    flag: "🇧🇴"
  },
  {
    name: "Bosnia & Herzegovina",
    iso2: "BA",
    dial_code: "387",
    priority: 0,
    area_codes: null,
    flag: "🇧🇦"
  },
  {
    name: "Botswana",
    iso2: "BW",
    dial_code: "267",
    priority: 0,
    area_codes: null,
    flag: "🇧🇼"
  },
  {
    name: "Brazil",
    iso2: "BR",
    dial_code: "55",
    priority: 0,
    area_codes: null,
    flag: "🇧🇷"
  },
  {
    name: "British Indian Ocean Territory",
    iso2: "IO",
    dial_code: "246",
    priority: 0,
    area_codes: null,
    flag: "🇮🇴"
  },
  {
    name: "British Virgin Islands",
    iso2: "VG",
    dial_code: "1",
    priority: 11,
    area_codes: ["284"],
    flag: "🇻🇬"
  },
  {
    name: "Brunei",
    iso2: "BN",
    dial_code: "673",
    priority: 0,
    area_codes: null,
    flag: "🇧🇳"
  },
  {
    name: "Bulgaria",
    iso2: "BG",
    dial_code: "359",
    priority: 0,
    area_codes: null,
    flag: "🇧🇬"
  },
  {
    name: "Burkina Faso",
    iso2: "BF",
    dial_code: "226",
    priority: 0,
    area_codes: null,
    flag: "🇧🇫"
  },
  {
    name: "Burundi",
    iso2: "BI",
    dial_code: "257",
    priority: 0,
    area_codes: null,
    flag: "🇧🇮"
  },
  {
    name: "Cambodia",
    iso2: "KH",
    dial_code: "855",
    priority: 0,
    area_codes: null,
    flag: "🇰🇭"
  },
  {
    name: "Cameroon",
    iso2: "CM",
    dial_code: "237",
    priority: 0,
    area_codes: null,
    flag: "🇨🇲"
  },
  {
    name: "Canada",
    iso2: "CA",
    dial_code: "1",
    priority: 1,
    area_codes: [
      "204",
      "226",
      "236",
      "249",
      "250",
      "263",
      "289",
      "306",
      "343",
      "354",
      "365",
      "367",
      "368",
      "382",
      "387",
      "403",
      "416",
      "418",
      "428",
      "431",
      "437",
      "438",
      "450",
      "584",
      "468",
      "474",
      "506",
      "514",
      "519",
      "548",
      "579",
      "581",
      "584",
      "587",
      "604",
      "613",
      "639",
      "647",
      "672",
      "683",
      "705",
      "709",
      "742",
      "753",
      "778",
      "780",
      "782",
      "807",
      "819",
      "825",
      "867",
      "873",
      "879",
      "902",
      "905"
    ],
    flag: "🇨🇦"
  },
  {
    name: "Cape Verde",
    iso2: "CV",
    dial_code: "238",
    priority: 0,
    area_codes: null,
    flag: "🇨🇻"
  },
  {
    name: "Caribbean Netherlands",
    iso2: "BQ",
    dial_code: "599",
    priority: 1,
    area_codes: ["3", "4", "7"],
    flag: "🇧🇶"
  },
  {
    name: "Cayman Islands",
    iso2: "KY",
    dial_code: "1",
    priority: 12,
    area_codes: ["345"],
    flag: "🇰🇾"
  },
  {
    name: "Central African Republic",
    iso2: "CF",
    dial_code: "236",
    priority: 0,
    area_codes: null,
    flag: "🇨🇫"
  },
  {
    name: "Chad",
    iso2: "TD",
    dial_code: "235",
    priority: 0,
    area_codes: null,
    flag: "🇹🇩"
  },
  {
    name: "Chile",
    iso2: "CL",
    dial_code: "56",
    priority: 0,
    area_codes: null,
    flag: "🇨🇱"
  },
  {
    name: "China",
    iso2: "CN",
    dial_code: "86",
    priority: 0,
    area_codes: null,
    flag: "🇨🇳"
  },
  {
    name: "Christmas Island",
    iso2: "CX",
    dial_code: "61",
    priority: 2,
    area_codes: ["89164"],
    flag: "🇨🇽"
  },
  {
    name: "Cocos (Keeling) Islands",
    iso2: "CC",
    dial_code: "61",
    priority: 1,
    area_codes: ["89162"],
    flag: "🇨🇨"
  },
  {
    name: "Colombia",
    iso2: "CO",
    dial_code: "57",
    priority: 0,
    area_codes: null,
    flag: "🇨🇴"
  },
  {
    name: "Comoros",
    iso2: "KM",
    dial_code: "269",
    priority: 0,
    area_codes: null,
    flag: "🇰🇲"
  },
  {
    name: "Congo - Brazzaville",
    iso2: "CG",
    dial_code: "242",
    priority: 0,
    area_codes: null,
    flag: "🇨🇬"
  },
  {
    name: "Congo - Kinshasa",
    iso2: "CD",
    dial_code: "243",
    priority: 0,
    area_codes: null,
    flag: "🇨🇩"
  },
  {
    name: "Cook Islands",
    iso2: "CK",
    dial_code: "682",
    priority: 0,
    area_codes: null,
    flag: "🇨🇰"
  },
  {
    name: "Costa Rica",
    iso2: "CR",
    dial_code: "506",
    priority: 0,
    area_codes: null,
    flag: "🇨🇷"
  },
  {
    name: "Côte d’Ivoire",
    iso2: "CI",
    dial_code: "225",
    priority: 0,
    area_codes: null,
    flag: "🇨🇮"
  },
  {
    name: "Croatia",
    iso2: "HR",
    dial_code: "385",
    priority: 0,
    area_codes: null,
    flag: "🇭🇷"
  },
  {
    name: "Cuba",
    iso2: "CU",
    dial_code: "53",
    priority: 0,
    area_codes: null,
    flag: "🇨🇺"
  },
  {
    name: "Curaçao",
    iso2: "CW",
    dial_code: "599",
    priority: 0,
    area_codes: null,
    flag: "🇨🇼"
  },
  {
    name: "Cyprus",
    iso2: "CY",
    dial_code: "357",
    priority: 0,
    area_codes: null,
    flag: "🇨🇾"
  },
  {
    name: "Czechia",
    iso2: "CZ",
    dial_code: "420",
    priority: 0,
    area_codes: null,
    flag: "🇨🇿"
  },
  {
    name: "Denmark",
    iso2: "DK",
    dial_code: "45",
    priority: 0,
    area_codes: null,
    flag: "🇩🇰"
  },
  {
    name: "Djibouti",
    iso2: "DJ",
    dial_code: "253",
    priority: 0,
    area_codes: null,
    flag: "🇩🇯"
  },
  {
    name: "Dominica",
    iso2: "DM",
    dial_code: "1",
    priority: 13,
    area_codes: ["767"],
    flag: "🇩🇲"
  },
  {
    name: "Dominican Republic",
    iso2: "DO",
    dial_code: "1",
    priority: 2,
    area_codes: ["809", "829", "849"],
    flag: "🇩🇴"
  },
  {
    name: "Ecuador",
    iso2: "EC",
    dial_code: "593",
    priority: 0,
    area_codes: null,
    flag: "🇪🇨"
  },
  {
    name: "Egypt",
    iso2: "EG",
    dial_code: "20",
    priority: 0,
    area_codes: null,
    flag: "🇪🇬"
  },
  {
    name: "El Salvador",
    iso2: "SV",
    dial_code: "503",
    priority: 0,
    area_codes: null,
    flag: "🇸🇻"
  },
  {
    name: "Equatorial Guinea",
    iso2: "GQ",
    dial_code: "240",
    priority: 0,
    area_codes: null,
    flag: "🇬🇶"
  },
  {
    name: "Eritrea",
    iso2: "ER",
    dial_code: "291",
    priority: 0,
    area_codes: null,
    flag: "🇪🇷"
  },
  {
    name: "Estonia",
    iso2: "EE",
    dial_code: "372",
    priority: 0,
    area_codes: null,
    flag: "🇪🇪"
  },
  {
    name: "Eswatini",
    iso2: "SZ",
    dial_code: "268",
    priority: 0,
    area_codes: null,
    flag: "🇸🇿"
  },
  {
    name: "Ethiopia",
    iso2: "ET",
    dial_code: "251",
    priority: 0,
    area_codes: null,
    flag: "🇪🇹"
  },
  {
    name: "Falkland Islands",
    iso2: "FK",
    dial_code: "500",
    priority: 0,
    area_codes: null,
    flag: "🇫🇰"
  },
  {
    name: "Faroe Islands",
    iso2: "FO",
    dial_code: "298",
    priority: 0,
    area_codes: null,
    flag: "🇫🇴"
  },
  {
    name: "Fiji",
    iso2: "FJ",
    dial_code: "679",
    priority: 0,
    area_codes: null,
    flag: "🇫🇯"
  },
  {
    name: "Finland",
    iso2: "FI",
    dial_code: "358",
    priority: 0,
    area_codes: null,
    flag: "🇫🇮"
  },
  {
    name: "France",
    iso2: "FR",
    dial_code: "33",
    priority: 0,
    area_codes: null,
    flag: "🇫🇷"
  },
  {
    name: "French Guiana",
    iso2: "GF",
    dial_code: "594",
    priority: 0,
    area_codes: null,
    flag: "🇬🇫"
  },
  {
    name: "French Polynesia",
    iso2: "PF",
    dial_code: "689",
    priority: 0,
    area_codes: null,
    flag: "🇵🇫"
  },
  {
    name: "Gabon",
    iso2: "GA",
    dial_code: "241",
    priority: 0,
    area_codes: null,
    flag: "🇬🇦"
  },
  {
    name: "Gambia",
    iso2: "GM",
    dial_code: "220",
    priority: 0,
    area_codes: null,
    flag: "🇬🇲"
  },
  {
    name: "Georgia",
    iso2: "GE",
    dial_code: "995",
    priority: 0,
    area_codes: null,
    flag: "🇬🇪"
  },
  {
    name: "Germany",
    iso2: "DE",
    dial_code: "49",
    priority: 0,
    area_codes: null,
    flag: "🇩🇪"
  },
  {
    name: "Ghana",
    iso2: "GH",
    dial_code: "233",
    priority: 0,
    area_codes: null,
    flag: "🇬🇭"
  },
  {
    name: "Gibraltar",
    iso2: "GI",
    dial_code: "350",
    priority: 0,
    area_codes: null,
    flag: "🇬🇮"
  },
  {
    name: "Greece",
    iso2: "GR",
    dial_code: "30",
    priority: 0,
    area_codes: null,
    flag: "🇬🇷"
  },
  {
    name: "Greenland",
    iso2: "GL",
    dial_code: "299",
    priority: 0,
    area_codes: null,
    flag: "🇬🇱"
  },
  {
    name: "Grenada",
    iso2: "GD",
    dial_code: "1",
    priority: 14,
    area_codes: ["473"],
    flag: "🇬🇩"
  },
  {
    name: "Guadeloupe",
    iso2: "GP",
    dial_code: "590",
    priority: 0,
    area_codes: null,
    flag: "🇬🇵"
  },
  {
    name: "Guam",
    iso2: "GU",
    dial_code: "1",
    priority: 15,
    area_codes: ["671"],
    flag: "🇬🇺"
  },
  {
    name: "Guatemala",
    iso2: "GT",
    dial_code: "502",
    priority: 0,
    area_codes: null,
    flag: "🇬🇹"
  },
  {
    name: "Guernsey",
    iso2: "GG",
    dial_code: "44",
    priority: 1,
    area_codes: ["1481", "7781", "7839", "7911"],
    flag: "🇬🇬"
  },
  {
    name: "Guinea",
    iso2: "GN",
    dial_code: "224",
    priority: 0,
    area_codes: null,
    flag: "🇬🇳"
  },
  {
    name: "Guinea-Bissau",
    iso2: "GW",
    dial_code: "245",
    priority: 0,
    area_codes: null,
    flag: "🇬🇼"
  },
  {
    name: "Guyana",
    iso2: "GY",
    dial_code: "592",
    priority: 0,
    area_codes: null,
    flag: "🇬🇾"
  },
  {
    name: "Haiti",
    iso2: "HT",
    dial_code: "509",
    priority: 0,
    area_codes: null,
    flag: "🇭🇹"
  },
  {
    name: "Honduras",
    iso2: "HN",
    dial_code: "504",
    priority: 0,
    area_codes: null,
    flag: "🇭🇳"
  },
  {
    name: "Hong Kong SAR China",
    iso2: "HK",
    dial_code: "852",
    priority: 0,
    area_codes: null,
    flag: "🇭🇰"
  },
  {
    name: "Hungary",
    iso2: "HU",
    dial_code: "36",
    priority: 0,
    area_codes: null,
    flag: "🇭🇺"
  },
  {
    name: "Iceland",
    iso2: "IS",
    dial_code: "354",
    priority: 0,
    area_codes: null,
    flag: "🇮🇸"
  },
  {
    name: "India",
    iso2: "IN",
    dial_code: "91",
    priority: 0,
    area_codes: null,
    flag: "🇮🇳"
  },
  {
    name: "Indonesia",
    iso2: "ID",
    dial_code: "62",
    priority: 0,
    area_codes: null,
    flag: "🇮🇩"
  },
  {
    name: "Iran",
    iso2: "IR",
    dial_code: "98",
    priority: 0,
    area_codes: null,
    flag: "🇮🇷"
  },
  {
    name: "Iraq",
    iso2: "IQ",
    dial_code: "964",
    priority: 0,
    area_codes: null,
    flag: "🇮🇶"
  },
  {
    name: "Ireland",
    iso2: "IE",
    dial_code: "353",
    priority: 0,
    area_codes: null,
    flag: "🇮🇪"
  },
  {
    name: "Isle of Man",
    iso2: "IM",
    dial_code: "44",
    priority: 2,
    area_codes: ["1624", "74576", "7524", "7924", "7624"],
    flag: "🇮🇲"
  },
  {
    name: "Israel",
    iso2: "IL",
    dial_code: "972",
    priority: 0,
    area_codes: null,
    flag: "🇮🇱"
  },
  {
    name: "Italy",
    iso2: "IT",
    dial_code: "39",
    priority: 0,
    area_codes: null,
    flag: "🇮🇹"
  },
  {
    name: "Jamaica",
    iso2: "JM",
    dial_code: "1",
    priority: 4,
    area_codes: ["876", "658"],
    flag: "🇯🇲"
  },
  {
    name: "Japan",
    iso2: "JP",
    dial_code: "81",
    priority: 0,
    area_codes: null,
    flag: "🇯🇵"
  },
  {
    name: "Jersey",
    iso2: "JE",
    dial_code: "44",
    priority: 3,
    area_codes: ["1534", "7509", "7700", "7797", "7829", "7937"],
    flag: "🇯🇪"
  },
  {
    name: "Jordan",
    iso2: "JO",
    dial_code: "962",
    priority: 0,
    area_codes: null,
    flag: "🇯🇴"
  },
  {
    name: "Kazakhstan",
    iso2: "KZ",
    dial_code: "7",
    priority: 1,
    area_codes: ["33", "7"],
    flag: "🇰🇿"
  },
  {
    name: "Kenya",
    iso2: "KE",
    dial_code: "254",
    priority: 0,
    area_codes: null,
    flag: "🇰🇪"
  },
  {
    name: "Kiribati",
    iso2: "KI",
    dial_code: "686",
    priority: 0,
    area_codes: null,
    flag: "🇰🇮"
  },
  {
    name: "Kosovo",
    iso2: "XK",
    dial_code: "383",
    priority: 0,
    area_codes: null,
    flag: "🇽🇰"
  },
  {
    name: "Kuwait",
    iso2: "KW",
    dial_code: "965",
    priority: 0,
    area_codes: null,
    flag: "🇰🇼"
  },
  {
    name: "Kyrgyzstan",
    iso2: "KG",
    dial_code: "996",
    priority: 0,
    area_codes: null,
    flag: "🇰🇬"
  },
  {
    name: "Laos",
    iso2: "LA",
    dial_code: "856",
    priority: 0,
    area_codes: null,
    flag: "🇱🇦"
  },
  {
    name: "Latvia",
    iso2: "LV",
    dial_code: "371",
    priority: 0,
    area_codes: null,
    flag: "🇱🇻"
  },
  {
    name: "Lebanon",
    iso2: "LB",
    dial_code: "961",
    priority: 0,
    area_codes: null,
    flag: "🇱🇧"
  },
  {
    name: "Lesotho",
    iso2: "LS",
    dial_code: "266",
    priority: 0,
    area_codes: null,
    flag: "🇱🇸"
  },
  {
    name: "Liberia",
    iso2: "LR",
    dial_code: "231",
    priority: 0,
    area_codes: null,
    flag: "🇱🇷"
  },
  {
    name: "Libya",
    iso2: "LY",
    dial_code: "218",
    priority: 0,
    area_codes: null,
    flag: "🇱🇾"
  },
  {
    name: "Liechtenstein",
    iso2: "LI",
    dial_code: "423",
    priority: 0,
    area_codes: null,
    flag: "🇱🇮"
  },
  {
    name: "Lithuania",
    iso2: "LT",
    dial_code: "370",
    priority: 0,
    area_codes: null,
    flag: "🇱🇹"
  },
  {
    name: "Luxembourg",
    iso2: "LU",
    dial_code: "352",
    priority: 0,
    area_codes: null,
    flag: "🇱🇺"
  },
  {
    name: "Macao SAR China",
    iso2: "MO",
    dial_code: "853",
    priority: 0,
    area_codes: null,
    flag: "🇲🇴"
  },
  {
    name: "Madagascar",
    iso2: "MG",
    dial_code: "261",
    priority: 0,
    area_codes: null,
    flag: "🇲🇬"
  },
  {
    name: "Malawi",
    iso2: "MW",
    dial_code: "265",
    priority: 0,
    area_codes: null,
    flag: "🇲🇼"
  },
  {
    name: "Malaysia",
    iso2: "MY",
    dial_code: "60",
    priority: 0,
    area_codes: null,
    flag: "🇲🇾"
  },
  {
    name: "Maldives",
    iso2: "MV",
    dial_code: "960",
    priority: 0,
    area_codes: null,
    flag: "🇲🇻"
  },
  {
    name: "Mali",
    iso2: "ML",
    dial_code: "223",
    priority: 0,
    area_codes: null,
    flag: "🇲🇱"
  },
  {
    name: "Malta",
    iso2: "MT",
    dial_code: "356",
    priority: 0,
    area_codes: null,
    flag: "🇲🇹"
  },
  {
    name: "Marshall Islands",
    iso2: "MH",
    dial_code: "692",
    priority: 0,
    area_codes: null,
    flag: "🇲🇭"
  },
  {
    name: "Martinique",
    iso2: "MQ",
    dial_code: "596",
    priority: 0,
    area_codes: null,
    flag: "🇲🇶"
  },
  {
    name: "Mauritania",
    iso2: "MR",
    dial_code: "222",
    priority: 0,
    area_codes: null,
    flag: "🇲🇷"
  },
  {
    name: "Mauritius",
    iso2: "MU",
    dial_code: "230",
    priority: 0,
    area_codes: null,
    flag: "🇲🇺"
  },
  {
    name: "Mayotte",
    iso2: "YT",
    dial_code: "262",
    priority: 1,
    area_codes: ["269", "639"],
    flag: "🇾🇹"
  },
  {
    name: "Mexico",
    iso2: "MX",
    dial_code: "52",
    priority: 0,
    area_codes: null,
    flag: "🇲🇽"
  },
  {
    name: "Micronesia",
    iso2: "FM",
    dial_code: "691",
    priority: 0,
    area_codes: null,
    flag: "🇫🇲"
  },
  {
    name: "Moldova",
    iso2: "MD",
    dial_code: "373",
    priority: 0,
    area_codes: null,
    flag: "🇲🇩"
  },
  {
    name: "Monaco",
    iso2: "MC",
    dial_code: "377",
    priority: 0,
    area_codes: null,
    flag: "🇲🇨"
  },
  {
    name: "Mongolia",
    iso2: "MN",
    dial_code: "976",
    priority: 0,
    area_codes: null,
    flag: "🇲🇳"
  },
  {
    name: "Montenegro",
    iso2: "ME",
    dial_code: "382",
    priority: 0,
    area_codes: null,
    flag: "🇲🇪"
  },
  {
    name: "Montserrat",
    iso2: "MS",
    dial_code: "1",
    priority: 16,
    area_codes: ["664"],
    flag: "🇲🇸"
  },
  {
    name: "Morocco",
    iso2: "MA",
    dial_code: "212",
    priority: 0,
    area_codes: null,
    flag: "🇲🇦"
  },
  {
    name: "Mozambique",
    iso2: "MZ",
    dial_code: "258",
    priority: 0,
    area_codes: null,
    flag: "🇲🇿"
  },
  {
    name: "Myanmar (Burma)",
    iso2: "MM",
    dial_code: "95",
    priority: 0,
    area_codes: null,
    flag: "🇲🇲"
  },
  {
    name: "Namibia",
    iso2: "NA",
    dial_code: "264",
    priority: 0,
    area_codes: null,
    flag: "🇳🇦"
  },
  {
    name: "Nauru",
    iso2: "NR",
    dial_code: "674",
    priority: 0,
    area_codes: null,
    flag: "🇳🇷"
  },
  {
    name: "Nepal",
    iso2: "NP",
    dial_code: "977",
    priority: 0,
    area_codes: null,
    flag: "🇳🇵"
  },
  {
    name: "Netherlands",
    iso2: "NL",
    dial_code: "31",
    priority: 0,
    area_codes: null,
    flag: "🇳🇱"
  },
  {
    name: "New Caledonia",
    iso2: "NC",
    dial_code: "687",
    priority: 0,
    area_codes: null,
    flag: "🇳🇨"
  },
  {
    name: "New Zealand",
    iso2: "NZ",
    dial_code: "64",
    priority: 0,
    area_codes: null,
    flag: "🇳🇿"
  },
  {
    name: "Nicaragua",
    iso2: "NI",
    dial_code: "505",
    priority: 0,
    area_codes: null,
    flag: "🇳🇮"
  },
  {
    name: "Niger",
    iso2: "NE",
    dial_code: "227",
    priority: 0,
    area_codes: null,
    flag: "🇳🇪"
  },
  {
    name: "Nigeria",
    iso2: "NG",
    dial_code: "234",
    priority: 0,
    area_codes: null,
    flag: "🇳🇬"
  },
  {
    name: "Niue",
    iso2: "NU",
    dial_code: "683",
    priority: 0,
    area_codes: null,
    flag: "🇳🇺"
  },
  {
    name: "Norfolk Island",
    iso2: "NF",
    dial_code: "672",
    priority: 0,
    area_codes: null,
    flag: "🇳🇫"
  },
  {
    name: "North Korea",
    iso2: "KP",
    dial_code: "850",
    priority: 0,
    area_codes: null,
    flag: "🇰🇵"
  },
  {
    name: "North Macedonia",
    iso2: "MK",
    dial_code: "389",
    priority: 0,
    area_codes: null,
    flag: "🇲🇰"
  },
  {
    name: "Northern Mariana Islands",
    iso2: "MP",
    dial_code: "1",
    priority: 17,
    area_codes: ["670"],
    flag: "🇲🇵"
  },
  {
    name: "Norway",
    iso2: "NO",
    dial_code: "47",
    priority: 0,
    area_codes: null,
    flag: "🇳🇴"
  },
  {
    name: "Oman",
    iso2: "OM",
    dial_code: "968",
    priority: 0,
    area_codes: null,
    flag: "🇴🇲"
  },
  {
    name: "Pakistan",
    iso2: "PK",
    dial_code: "92",
    priority: 0,
    area_codes: null,
    flag: "🇵🇰"
  },
  {
    name: "Palau",
    iso2: "PW",
    dial_code: "680",
    priority: 0,
    area_codes: null,
    flag: "🇵🇼"
  },
  {
    name: "Palestinian Territories",
    iso2: "PS",
    dial_code: "970",
    priority: 0,
    area_codes: null,
    flag: "🇵🇸"
  },
  {
    name: "Panama",
    iso2: "PA",
    dial_code: "507",
    priority: 0,
    area_codes: null,
    flag: "🇵🇦"
  },
  {
    name: "Papua New Guinea",
    iso2: "PG",
    dial_code: "675",
    priority: 0,
    area_codes: null,
    flag: "🇵🇬"
  },
  {
    name: "Paraguay",
    iso2: "PY",
    dial_code: "595",
    priority: 0,
    area_codes: null,
    flag: "🇵🇾"
  },
  {
    name: "Peru",
    iso2: "PE",
    dial_code: "51",
    priority: 0,
    area_codes: null,
    flag: "🇵🇪"
  },
  {
    name: "Philippines",
    iso2: "PH",
    dial_code: "63",
    priority: 0,
    area_codes: null,
    flag: "🇵🇭"
  },
  {
    name: "Poland",
    iso2: "PL",
    dial_code: "48",
    priority: 0,
    area_codes: null,
    flag: "🇵🇱"
  },
  {
    name: "Portugal",
    iso2: "PT",
    dial_code: "351",
    priority: 0,
    area_codes: null,
    flag: "🇵🇹"
  },
  {
    name: "Puerto Rico",
    iso2: "PR",
    dial_code: "1",
    priority: 3,
    area_codes: ["787", "939"],
    flag: "🇵🇷"
  },
  {
    name: "Qatar",
    iso2: "QA",
    dial_code: "974",
    priority: 0,
    area_codes: null,
    flag: "🇶🇦"
  },
  {
    name: "Réunion",
    iso2: "RE",
    dial_code: "262",
    priority: 0,
    area_codes: null,
    flag: "🇷🇪"
  },
  {
    name: "Romania",
    iso2: "RO",
    dial_code: "40",
    priority: 0,
    area_codes: null,
    flag: "🇷🇴"
  },
  {
    name: "Russia",
    iso2: "RU",
    dial_code: "7",
    priority: 0,
    area_codes: null,
    flag: "🇷🇺"
  },
  {
    name: "Rwanda",
    iso2: "RW",
    dial_code: "250",
    priority: 0,
    area_codes: null,
    flag: "🇷🇼"
  },
  {
    name: "Samoa",
    iso2: "WS",
    dial_code: "685",
    priority: 0,
    area_codes: null,
    flag: "🇼🇸"
  },
  {
    name: "San Marino",
    iso2: "SM",
    dial_code: "378",
    priority: 0,
    area_codes: null,
    flag: "🇸🇲"
  },
  {
    name: "São Tomé & Príncipe",
    iso2: "ST",
    dial_code: "239",
    priority: 0,
    area_codes: null,
    flag: "🇸🇹"
  },
  {
    name: "Saudi Arabia",
    iso2: "SA",
    dial_code: "966",
    priority: 0,
    area_codes: null,
    flag: "🇸🇦"
  },
  {
    name: "Senegal",
    iso2: "SN",
    dial_code: "221",
    priority: 0,
    area_codes: null,
    flag: "🇸🇳"
  },
  {
    name: "Serbia",
    iso2: "RS",
    dial_code: "381",
    priority: 0,
    area_codes: null,
    flag: "🇷🇸"
  },
  {
    name: "Seychelles",
    iso2: "SC",
    dial_code: "248",
    priority: 0,
    area_codes: null,
    flag: "🇸🇨"
  },
  {
    name: "Sierra Leone",
    iso2: "SL",
    dial_code: "232",
    priority: 0,
    area_codes: null,
    flag: "🇸🇱"
  },
  {
    name: "Singapore",
    iso2: "SG",
    dial_code: "65",
    priority: 0,
    area_codes: null,
    flag: "🇸🇬"
  },
  {
    name: "Sint Maarten",
    iso2: "SX",
    dial_code: "1",
    priority: 21,
    area_codes: ["721"],
    flag: "🇸🇽"
  },
  {
    name: "Slovakia",
    iso2: "SK",
    dial_code: "421",
    priority: 0,
    area_codes: null,
    flag: "🇸🇰"
  },
  {
    name: "Slovenia",
    iso2: "SI",
    dial_code: "386",
    priority: 0,
    area_codes: null,
    flag: "🇸🇮"
  },
  {
    name: "Solomon Islands",
    iso2: "SB",
    dial_code: "677",
    priority: 0,
    area_codes: null,
    flag: "🇸🇧"
  },
  {
    name: "Somalia",
    iso2: "SO",
    dial_code: "252",
    priority: 0,
    area_codes: null,
    flag: "🇸🇴"
  },
  {
    name: "South Africa",
    iso2: "ZA",
    dial_code: "27",
    priority: 0,
    area_codes: null,
    flag: "🇿🇦"
  },
  {
    name: "South Korea",
    iso2: "KR",
    dial_code: "82",
    priority: 0,
    area_codes: null,
    flag: "🇰🇷"
  },
  {
    name: "South Sudan",
    iso2: "SS",
    dial_code: "211",
    priority: 0,
    area_codes: null,
    flag: "🇸🇸"
  },
  {
    name: "Spain",
    iso2: "ES",
    dial_code: "34",
    priority: 0,
    area_codes: null,
    flag: "🇪🇸"
  },
  {
    name: "Sri Lanka",
    iso2: "LK",
    dial_code: "94",
    priority: 0,
    area_codes: null,
    flag: "🇱🇰"
  },
  {
    name: "St. Barthélemy",
    iso2: "BL",
    dial_code: "590",
    priority: 1,
    area_codes: null,
    flag: "🇧🇱"
  },
  {
    name: "St. Helena",
    iso2: "SH",
    dial_code: "290",
    priority: 0,
    area_codes: null,
    flag: "🇸🇭"
  },
  {
    name: "St. Kitts & Nevis",
    iso2: "KN",
    dial_code: "1",
    priority: 18,
    area_codes: ["869"],
    flag: "🇰🇳"
  },
  {
    name: "St. Lucia",
    iso2: "LC",
    dial_code: "1",
    priority: 19,
    area_codes: ["758"],
    flag: "🇱🇨"
  },
  {
    name: "St. Martin",
    iso2: "MF",
    dial_code: "590",
    priority: 2,
    area_codes: null,
    flag: "🇲🇫"
  },
  {
    name: "St. Pierre & Miquelon",
    iso2: "PM",
    dial_code: "508",
    priority: 0,
    area_codes: null,
    flag: "🇵🇲"
  },
  {
    name: "St. Vincent & Grenadines",
    iso2: "VC",
    dial_code: "1",
    priority: 20,
    area_codes: ["784"],
    flag: "🇻🇨"
  },
  {
    name: "Sudan",
    iso2: "SD",
    dial_code: "249",
    priority: 0,
    area_codes: null,
    flag: "🇸🇩"
  },
  {
    name: "Suriname",
    iso2: "SR",
    dial_code: "597",
    priority: 0,
    area_codes: null,
    flag: "🇸🇷"
  },
  {
    name: "Svalbard & Jan Mayen",
    iso2: "SJ",
    dial_code: "47",
    priority: 1,
    area_codes: ["79"],
    flag: "🇸🇯"
  },
  {
    name: "Sweden",
    iso2: "SE",
    dial_code: "46",
    priority: 0,
    area_codes: null,
    flag: "🇸🇪"
  },
  {
    name: "Switzerland",
    iso2: "CH",
    dial_code: "41",
    priority: 0,
    area_codes: null,
    flag: "🇨🇭"
  },
  {
    name: "Syria",
    iso2: "SY",
    dial_code: "963",
    priority: 0,
    area_codes: null,
    flag: "🇸🇾"
  },
  {
    name: "Taiwan",
    iso2: "TW",
    dial_code: "886",
    priority: 0,
    area_codes: null,
    flag: "🇹🇼"
  },
  {
    name: "Tajikistan",
    iso2: "TJ",
    dial_code: "992",
    priority: 0,
    area_codes: null,
    flag: "🇹🇯"
  },
  {
    name: "Tanzania",
    iso2: "TZ",
    dial_code: "255",
    priority: 0,
    area_codes: null,
    flag: "🇹🇿"
  },
  {
    name: "Thailand",
    iso2: "TH",
    dial_code: "66",
    priority: 0,
    area_codes: null,
    flag: "🇹🇭"
  },
  {
    name: "Timor-Leste",
    iso2: "TL",
    dial_code: "670",
    priority: 0,
    area_codes: null,
    flag: "🇹🇱"
  },
  {
    name: "Togo",
    iso2: "TG",
    dial_code: "228",
    priority: 0,
    area_codes: null,
    flag: "🇹🇬"
  },
  {
    name: "Tokelau",
    iso2: "TK",
    dial_code: "690",
    priority: 0,
    area_codes: null,
    flag: "🇹🇰"
  },
  {
    name: "Tonga",
    iso2: "TO",
    dial_code: "676",
    priority: 0,
    area_codes: null,
    flag: "🇹🇴"
  },
  {
    name: "Trinidad & Tobago",
    iso2: "TT",
    dial_code: "1",
    priority: 22,
    area_codes: ["868"],
    flag: "🇹🇹"
  },
  {
    name: "Tunisia",
    iso2: "TN",
    dial_code: "216",
    priority: 0,
    area_codes: null,
    flag: "🇹🇳"
  },
  {
    name: "Turkey",
    iso2: "TR",
    dial_code: "90",
    priority: 0,
    area_codes: null,
    flag: "🇹🇷"
  },
  {
    name: "Turkmenistan",
    iso2: "TM",
    dial_code: "993",
    priority: 0,
    area_codes: null,
    flag: "🇹🇲"
  },
  {
    name: "Turks & Caicos Islands",
    iso2: "TC",
    dial_code: "1",
    priority: 23,
    area_codes: ["649"],
    flag: "🇹🇨"
  },
  {
    name: "Tuvalu",
    iso2: "TV",
    dial_code: "688",
    priority: 0,
    area_codes: null,
    flag: "🇹🇻"
  },
  {
    name: "Uganda",
    iso2: "UG",
    dial_code: "256",
    priority: 0,
    area_codes: null,
    flag: "🇺🇬"
  },
  {
    name: "Ukraine",
    iso2: "UA",
    dial_code: "380",
    priority: 0,
    area_codes: null,
    flag: "🇺🇦"
  },
  {
    name: "United Arab Emirates",
    iso2: "AE",
    dial_code: "971",
    priority: 0,
    area_codes: null,
    flag: "🇦🇪"
  },
  {
    name: "United Kingdom",
    iso2: "GB",
    dial_code: "44",
    priority: 0,
    area_codes: null,
    flag: "🇬🇧"
  },
  {
    name: "United States",
    iso2: "US",
    dial_code: "1",
    priority: 0,
    area_codes: null,
    flag: "🇺🇸"
  },
  {
    name: "Uruguay",
    iso2: "UY",
    dial_code: "598",
    priority: 0,
    area_codes: null,
    flag: "🇺🇾"
  },
  {
    name: "U.S. Virgin Islands",
    iso2: "VI",
    dial_code: "1",
    priority: 24,
    area_codes: ["340"],
    flag: "🇻🇮"
  },
  {
    name: "Uzbekistan",
    iso2: "UZ",
    dial_code: "998",
    priority: 0,
    area_codes: null,
    flag: "🇺🇿"
  },
  {
    name: "Vanuatu",
    iso2: "VU",
    dial_code: "678",
    priority: 0,
    area_codes: null,
    flag: "🇻🇺"
  },
  {
    name: "Vatican City",
    iso2: "VA",
    dial_code: "39",
    priority: 1,
    area_codes: ["06698"],
    flag: "🇻🇦"
  },
  {
    name: "Venezuela",
    iso2: "VE",
    dial_code: "58",
    priority: 0,
    area_codes: null,
    flag: "🇻🇪"
  },
  {
    name: "Vietnam",
    iso2: "VN",
    dial_code: "84",
    priority: 0,
    area_codes: null,
    flag: "🇻🇳"
  },
  {
    name: "Wallis & Futuna",
    iso2: "WF",
    dial_code: "681",
    priority: 0,
    area_codes: null,
    flag: "🇼🇫"
  },
  {
    name: "Western Sahara",
    iso2: "EH",
    dial_code: "212",
    priority: 1,
    area_codes: ["5288", "5289"],
    flag: "🇪🇭"
  },
  {
    name: "Yemen",
    iso2: "YE",
    dial_code: "967",
    priority: 0,
    area_codes: null,
    flag: "🇾🇪"
  },
  {
    name: "Zambia",
    iso2: "ZM",
    dial_code: "260",
    priority: 0,
    area_codes: null,
    flag: "🇿🇲"
  },
  {
    name: "Zimbabwe",
    iso2: "ZW",
    dial_code: "263",
    priority: 0,
    area_codes: null,
    flag: "🇿🇼"
  }
];
