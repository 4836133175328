import React from "react";
import {
  Business,
  Customer,
  ErrorContent,
  PaymentMethod,
  Session,
  Setting,
  SucceededChannel
} from "../types";

export enum SessionActionTypes {
  SET_INITIAL_STATE,
  SET_SESSION,
  SET_SUCCEEDED_CHANNEL,
  SET_ERROR_CONTENT,
  TRANSITION_VIEW,
  REDIRECT_TO_POLLING,
  SET_TOKEN_REQUEST_ID,
  SET_LOADING
}

export interface SessionState {
  session?: Session;
  customer?: Customer;
  business?: Business;
  settings?: Setting;
  paymentMethods?: PaymentMethod[];
  succeededChannel?: SucceededChannel;
  // UI States
  errorContent?: ErrorContent;
  viewState?: ViewState;
  tokenRequestId?: string;
  isLoading?: boolean;
}
export interface SessionContextInterface {
  state: SessionState;
  dispatch: React.Dispatch<{
    type: SessionActionTypes;
    payload: SessionState;
  }>;
}

export abstract class ViewState {
  abstract transitionView(context: SessionContextInterface): void;
  abstract renderView(context: SessionContextInterface): JSX.Element;
}
