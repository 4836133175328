import { BankTransferChannelsEnum } from "@xendit/checkout-utilities";

export const getCompanyCode = (
  channelCode: string,
  fullPaymentCode: string
) => {
  switch (channelCode) {
    case BankTransferChannelsEnum.MANDIRI: {
      return fullPaymentCode?.substring(0, 5);
    }
    default:
      return "";
  }
};

export const getPaymentCodeWithoutPrefix = (
  channelCode: string,
  fullPaymentCode: string
) => {
  switch (channelCode) {
    case BankTransferChannelsEnum.BSI:
      return fullPaymentCode?.slice(4);
    default:
      return "";
  }
};

export const getIbankingSource = (channelCode: string) => {
  switch (channelCode) {
    case BankTransferChannelsEnum.BCA:
      return "https://ibank.klikbca.com";
    case BankTransferChannelsEnum.BJB:
      return "https://ib.bankbjb.co.id/bjb.net";
    case BankTransferChannelsEnum.BNI:
      return "https://ibank.bni.co.id";
    case BankTransferChannelsEnum.BSI:
      return "https://bsinet.bankbsi.co.id";
    case BankTransferChannelsEnum.CIMB:
      return "https://www.octoclicks.co.id/login";
    case BankTransferChannelsEnum.MANDIRI:
      return "https://ibank.bankmandiri.co.id";
    case BankTransferChannelsEnum.PERMATA:
      return "https://www.permatanet.com";
    case BankTransferChannelsEnum.SAHABAT_SAMPOERNA:
      return "https://ibank.banksampoerna.co.id";
    default:
      return "";
  }
};
