import { PaymentMethodsEnum } from "@xendit/checkout-utilities/dist/src/enums/payment-methods";

const paymentMethodPropertyKeys: Partial<{
  [paymentMethodType in PaymentMethodsEnum | "CRYPTOCURRENCY"]: string;
}> = {
  BANK_TRANSFER: "virtual_account",
  CREDIT_CARD: "card",
  DIRECT_DEBIT: "direct_debit",
  EWALLET: "ewallet",
  QR_CODE: "qr_code",
  RETAIL_OUTLET: "over_the_counter",
  CRYPTOCURRENCY: "cryptocurrency"
};

export const getPaymentMethodPropertyKey = (
  paymentMethodType: PaymentMethodsEnum
) => {
  return paymentMethodPropertyKeys[paymentMethodType];
};
