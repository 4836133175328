import { FC, useEffect } from "react";
import { PaymentMethodsEnum } from "@xendit/checkout-utilities";
import { useTranslation } from "react-i18next";
import clx from "classnames";
import kebabCase from "lodash/kebabCase";

import { Instructions } from "../../components/InstructionsTabs";
import Barcode from "../../components/Barcode";
import Button from "../../components/Button";
import ChannelCodeInformations from "../../components/ChannelCodeInformations";
import InstructionsSteps from "../../components/InstructionsSteps";
import PaymentInstructionsStepsSkeleton from "../../components/PaymentInstructionsStepsSkeleton";
import PaymentView from "../../components/PaymentView";

import { useLegacyAsyncPayment } from "../../contexts/LegacyAsyncPaymentContext";
import { usePaymentLink } from "../../contexts/PaymentLinkContext";
import { usePaymentMethod } from "../../contexts/PaymentMethodContext";

const PaymentInstructionsSteps: FC = () => {
  const {
    paymentLink: { invoice }
  } = usePaymentLink();
  const { clearPaymentChannel, paymentChannel, paymentMethodType } =
    usePaymentMethod();

  const { isCreating, paymentInstrument } = useLegacyAsyncPayment();

  const { t } = useTranslation(["common", kebabCase(paymentChannel?.channel)]);

  const instructions: Instructions[] = t("instructions", {
    ns: kebabCase(paymentChannel?.channel),
    returnObjects: true
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!paymentChannel) {
    return null;
  }

  if (!paymentInstrument.payment_destination || isCreating) {
    return <PaymentInstructionsStepsSkeleton />;
  }

  const shouldHideBarcode = ["CEBUANA"].includes(paymentChannel.channel);

  return (
    <div>
      <PaymentView
        paymentChannelName={paymentChannel.display_name}
        footer={
          <div className="flex space-x-4">
            <Button
              type="button"
              variant="brand-secondary"
              className="flex-1"
              onClick={clearPaymentChannel}
            >
              {t("Back")}
            </Button>
          </div>
        }
      >
        <ChannelCodeInformations
          paymentMethodType={paymentMethodType as PaymentMethodsEnum}
          paymentCode={paymentInstrument.payment_destination as string}
          merchantName={paymentInstrument.merchant_name}
          amount={invoice.amount}
          currency={invoice.currency as string}
          visual={
            shouldHideBarcode ? (
              <img
                src={paymentChannel?.logo_url}
                alt={paymentChannel?.channel}
                className="w-20 sm:w-40 md:w-80"
                data-testid="otc-logo"
              />
            ) : (
              <Barcode
                value={paymentInstrument.payment_destination as string}
              />
            )
          }
        />

        <div
          className={clx([
            "prose prose-sm max-w-full",
            "prose-a:font-semibold prose-a:text-primary",
            "prose-h3:font-semibold prose-h3:text-lg prose-h3:text-gray-500 prose-h3:mb-4",
            "prose-ol:space-y-2 prose-ul:list-disc",
            "prose-em:font-bold prose-em:text-primary prose-em:not-italic"
          ])}
          data-testid="instruction"
        >
          {instructions.map((instructions: Instructions, index: number) => {
            return (
              <InstructionsSteps
                key={`${paymentChannel?.channel}-instructions-${instructions.title}-${index}`}
                channelCode={paymentChannel?.channel as string}
                displayName={paymentChannel?.display_name as string}
                paymentCode={paymentInstrument.payment_destination as string}
                merchantName={paymentInstrument.merchant_name as string}
                instructions={instructions}
                i18nKeyPrefix="instructions"
                stepIndex={index}
              />
            );
          })}
        </div>

        <div className="hidden md:flex justify-center lg:justify-start mt-10">
          <Button
            type="button"
            variant="brand-secondary"
            className="w-36"
            onClick={clearPaymentChannel}
            data-testid="back"
          >
            {t("Back")}
          </Button>
        </div>
      </PaymentView>
    </div>
  );
};

export default PaymentInstructionsSteps;
