import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ImgHTMLAttributes,
  ReactNode
} from "react";
import clx from "classnames";

const Header = ({ children }: { children: ReactNode }) => {
  return (
    <header>
      <div className="relative flex items-center justify-between h-12 md:h-16 lg:h-auto lg:max-w-screen-sm mx-auto bg-primary lg:bg-transparent px-4 lg:px-0">
        {children}
      </div>
    </header>
  );
};

const HeaderContent = ({ children }: { children: ReactNode }) => {
  return <div className="flex-1 flex items-center">{children}</div>;
};

const HeaderImage = (
  props: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
) => {
  return (
    <div className="w-8 md:w-12 lg:w-16 h-8 md:h-12 lg:h-16 rounded-lg overflow-hidden flex-shrink-0">
      <img
        className="h-full w-full object-contain"
        {...props}
        alt={props.alt || ""}
        data-testid="merchant-logo"
      />
    </div>
  );
};

const HeaderTitle = ({ children }: { children: ReactNode }) => {
  return (
    <h3 className="text-xl md:text-2xl lg:text-3xl text-white lg:text-primary line-clamp-1 ml-2 lg:ml-4">
      {children}
    </h3>
  );
};

const HeaderAddon = ({ children }: { children: ReactNode }) => {
  return <div className="flex-shrink-0">{children}</div>;
};

const HeaderBackButton = (
  props: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
) => {
  const className = clx(
    "text-xl text-white lg:text-primary lg:opacity-50 hover:opacity-100 focus:opacity-100 transition-opacity lg:absolute right-full pr-2 lg:px-2 h-8 rounded-full",
    props.className
  );
  return (
    <button {...props} className={className}>
      &larr;
    </button>
  );
};

export {
  Header,
  HeaderAddon,
  HeaderBackButton,
  HeaderContent,
  HeaderImage,
  HeaderTitle
};

export default Header;
