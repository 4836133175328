const PoweredByXendit = () => {
  return (
    <div className="relative flex items-center h-8" data-testid="branding-logo">
      <div className="absolute inset-0 border border-primary text-primary rounded opacity-50" />
      <div className="flex items-baseline px-2 space-x-1 text-primary">
        <span className="text-[8px] uppercase font-semibold">Powered by</span>
        <svg viewBox="0 0 72 68" fill="none" className="h-4 self-center">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.9465 0.283203L16.0111 6.95172H16.022L3.95491 27.4031L3.94404 27.3925L0.0195312 34.0504L19.9465 67.8176L22.2092 63.9947L23.8819 61.1703L23.8807 61.1682L36.0048 40.6656L39.9402 34.0078L51.9803 13.6198L64.0432 34.061L51.9524 54.5496L46.1737 44.7749L42.2383 51.4222L48.0022 61.1597H36.6042L32.6797 67.8176H51.987L55.9224 61.1597L67.9785 40.7189L71.9139 34.0716L67.9894 27.3713L55.9115 6.95172L51.9978 0.283203L48.0979 6.88385L48.0936 6.88782L27.6448 41.4939L19.9477 54.5055L7.87943 34.061L19.9585 13.5983L25.6569 23.2193L29.5814 16.5827L23.8829 6.95172H35.3293L39.2646 0.283203H19.9465Z"
            fill="currentColor"
          />
        </svg>
        <span className="text-[11px]">xendit</span>
      </div>
    </div>
  );
};

export default PoweredByXendit;
