import { FC } from "react";
import times from "lodash/times";

type Props = {
  count?: number;
};

export const PaymentMethodListLoadingSkeleton: FC<Props> = (props) => {
  return (
    <div className="flex flex-col space-y-2">
      {times(props.count || 4, (index) => {
        return <div key={index} className="bg-xen-gray-200 rounded h-14"></div>;
      })}
    </div>
  );
};
