import { FC } from "react";

import { BrandingBadgeLogos } from "../../types/checkout";

export type CustomBadgesProps = {
  badgeLogos: BrandingBadgeLogos[];
};

const CustomBadges: FC<CustomBadgesProps> = ({ badgeLogos }) => {
  return (
    <>
      {badgeLogos.map(({ url, website }, index: number) => {
        const imgEl = (
          <img
            key={`${url}-${index}`}
            src={url}
            className="h-8 mx-2"
            alt={`badge-logo-${index}`}
          />
        );

        if (website) {
          return (
            <a
              key={`${url}-${index}`}
              target="_blank"
              rel="noopener noreferrer"
              href={website}
            >
              {imgEl}
            </a>
          );
        }
        return imgEl;
      })}
    </>
  );
};

export default CustomBadges;
