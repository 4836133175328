declare global {
  interface Window {
    dataLayer: object[];
    fbq?: (action: string, event_name: string, params?: object) => unknown;
    gtag?: (action: string, event_name: string, params?: object) => unknown;
    snowplow?: (...args: any[]) => void;
  }
}

export enum InternalAnalyticsEvent {
  CHECKOUT_WITH_EC_OVO = "checkout-with-ec-ovo",
  CLOSE_OTP_MODAL = "close-otp-modal",
  CUSTOMER_INFO_FIELD = "customer-info-field",
  CUSTOMER_PORTAL_REDIRECTION = "customer-portal-redirection",
  INVOICE_CONTEXT = "invoice-context",
  OVO_EC_MOBILE_NUMBER_CHANGED = "ovo-ec-mobile-number-changed",
  OVO_EC_SCREEN_LOADED = "ovo-ec-screen-loaded",
  PAYMENT_FAILED = "payment-failed",
  PAYMENT_PROCESSING = "payment-processing",
  PAYMENT_SIMULATED = "payment-simulated",
  PAYMENT_SUCCESSFUL = "payment-successful",
  PENDING_PAYMENT_APPROVAL = "pending-payment-approval",
  SELECT_PAYMENT_CHANNEL = "select-payment-channel",
  SELECTED_LOCALE = "selected-locale",
  SHOPEEPAY_RETRY = "shopeepay-retry",
  SUBMIT_PAYMENT = "submit-payment",
  SUBMIT_PAYMENT_OVO_BALANCE_SCREEN = "submit-payment-ovo-balance-screen",
  SUBMIT_SAVED_PAYMENT = "submit-saved-payment",
  USE_DIFFERENT_PAYMENT_METHOD = "use-different-payment-method",
  WALLET_REFRESH_BALANCE = "wallet-refresh-balance"
}

export enum ExternalAnalyticsEvent {
  ADD_PAYMENT_INFO = "xendit_add_payment_info",
  INITIATE_CHECKOUT = "xendit_begin_checkout",
  PURCHASE = "xendit_purchase",
  VIEW_CONTENT = "xendit_view_item",
  SUBMIT = "xendit_checkout_progress"
}

export enum ExternalAnalyticsProvider {
  FACEBOOK = "facebook",
  GOOGLE = "google"
}

const DEFAULT_INTERNAL_RETRY_TIMES = 3;
const DEFAULT_INTERNAL_RETRY_DELAY_MS = (retry: number) => retry * 300;

/**
 * A utility function to send analytics data for internal tracking using
 * Google tag manager.
 *
 * @param params tracking parameters to send
 * @param ignoreSnowplow whether to ignore waiting for Snowplow to load before sending event
 * @param retry the current retry iteration
 */
export const logInternalAnalyticsEvent = (
  params: object,
  ignoreSnowplow = false,
  retry = DEFAULT_INTERNAL_RETRY_TIMES
) => {
  // need to make sure Snowplow is loaded on the page before sending events
  if (ignoreSnowplow || typeof window.snowplow === "function") {
    (window.dataLayer || []).push({
      ...params
    });
  } else {
    retry--;
    if (retry > 0) {
      setTimeout(
        () => logInternalAnalyticsEvent(params, ignoreSnowplow, retry),
        DEFAULT_INTERNAL_RETRY_DELAY_MS(retry)
      );
    } else {
      return;
    }
  }
};

const DEFAULT_EXTERNAL_RETRY_TIMES = 3;
const DEFAULT_EXTERNAL_RETRY_DELAY_MS = 2000;

/**
 * A utility function to send analytics data for external tracking using
 * Facebook Pixel and/or Google Analytics.
 *
 * @param event_name event name to show on provider dashboard
 * @param target analytics provider(s) to send to
 * @param params tracking parameters to send
 */
export const logExternalAnalyticsEvent = (data: {
  event_name: string;
  target: ExternalAnalyticsProvider[];
  params?: object;
}) => {
  const { event_name, target = [], params } = data;
  if (target.includes(ExternalAnalyticsProvider.FACEBOOK)) {
    logFacebookPixelExternalAnalyticsEvent("trackCustom", event_name, params);
  }
  if (target.includes(ExternalAnalyticsProvider.GOOGLE)) {
    logGoogleAnalyticsExternalAnalyticsEvent("event", event_name, params);
  }
};

const logFacebookPixelExternalAnalyticsEvent = (
  action: string,
  event_name: string,
  params?: object,
  retry = DEFAULT_EXTERNAL_RETRY_TIMES
) => {
  // need to make sure FB Pixel is loaded on the page before sending events
  if (typeof window.fbq === "function") {
    window.fbq(action, event_name, params);
  } else {
    retry--;
    if (retry > 0) {
      setTimeout(
        () =>
          logFacebookPixelExternalAnalyticsEvent(action, event_name, params),
        DEFAULT_EXTERNAL_RETRY_DELAY_MS
      );
    } else {
      return;
    }
  }
};

const logGoogleAnalyticsExternalAnalyticsEvent = (
  action: string,
  event_name: string,
  params?: object,
  retry = DEFAULT_EXTERNAL_RETRY_TIMES
) => {
  // need to make sure Google Analytics is loaded on the page before sending events
  if (typeof window.gtag === "function") {
    window.gtag(action, event_name, params);
  } else {
    retry--;
    if (retry > 0) {
      setTimeout(
        () =>
          logGoogleAnalyticsExternalAnalyticsEvent(action, event_name, params),
        DEFAULT_EXTERNAL_RETRY_DELAY_MS
      );
    } else {
      return;
    }
  }
};
