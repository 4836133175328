/* eslint-disable import/no-named-as-default-member */
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { LOG_I18N_WARNINGS } from "./utils/constants";

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    // enable debug on dev mode
    debug: LOG_I18N_WARNINGS,

    // fallback languages
    fallbackLng: ["en", "id", "th", "vi"],

    // disable escaping since React takes care of it
    interpolation: {
      escapeValue: false
    },

    // default namespace if not specified
    defaultNS: "common",

    // do not load any namespace unless required
    ns: [],

    // do not allow `null` as valid translation
    returnNull: false
  });

/**
 * reload resources upon change of locale files without
 * a full page reload
 */
if (import.meta.hot) {
  import.meta.hot.on("locales-update", () => {
    i18n.reloadResources().then(() => {
      i18n.changeLanguage(i18n.language);
    });
  });
}

export default i18n;
