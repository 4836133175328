import { InvoiceSettings } from "../types/checkout";

export const applyCustomColors = (settings?: InvoiceSettings) => {
  document.documentElement.style.setProperty(
    "--primary-color",
    settings?.primary_color || "#4573FF"
  );
  document.documentElement.style.setProperty(
    "--secondary-color",
    settings?.secondary_color || "#00CF99"
  );
};
