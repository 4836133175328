import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer
} from "react";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import {
  API,
  CHECKOUT_UI_GATEWAY_URL,
  ENABLE_RUM
} from "../../utils/constants";
import { getBrowserLocale } from "../../utils/locale";
import {
  AsyncChannelObject,
  CheckoutInitialData,
  Customer,
  Invoice,
  InvoiceStatus
} from "../../types/checkout";
import { fetchInvoice } from "../../utils/fetch-resource";
import {
  ExternalAnalyticsEvent,
  ExternalAnalyticsProvider,
  InternalAnalyticsEvent,
  logExternalAnalyticsEvent,
  logInternalAnalyticsEvent
} from "../../utils/analytics";
import { RealtimeUpdateMessage } from "../../types/realtime";
import PaymentLinkReducer from "./PaymentLinkReducer";
import { CreditCardPromotion } from "../../types/credit-card";
import { nexPaymentSuccessfulEvent } from "../../utils/nex-events";

type PaymentLinkContextValues = {
  customer: Customer | null;
  promotion: CreditCardPromotion | null;
  paymentLink: CheckoutInitialData;
  bindingFixedVaChannel: string | null;
  onSetCustomer: (customer: Customer) => void;
  onSetPromotion: (promotion: CreditCardPromotion | null) => void;
  onSetBindingFixedVaChannel: (bindingFixedVaChannel: string | null) => void;
  onSetPollingInterval: (pollingInterval: number) => void;
  onMutateAsyncChannelObject: (
    key: "banks" | "retail_outlets",
    value: AsyncChannelObject
  ) => void;
};

const PaymentLinkContext = createContext<PaymentLinkContextValues>(
  {} as PaymentLinkContextValues
);

const INVOICE_POLLING_INTERVAL_MS = 10 * 1000; // 10 seconds

type PaymentLinkContextProviderProps = {
  children?: ReactNode;
  paymentLink: CheckoutInitialData;
};

const PaymentLinkProvider: FC<PaymentLinkContextProviderProps> = (props) => {
  const { i18n } = useTranslation();
  const [state, dispatch] = useReducer(PaymentLinkReducer, {
    connectedToRealtime: false,
    promotion: null,
    customer: props.paymentLink.invoice.customer || null,
    paymentLink: props.paymentLink,
    shouldEnableInvoiceUpdates: true,
    bindingFixedVaChannel: null,
    pollingInterval: INVOICE_POLLING_INTERVAL_MS
  });

  const handleSetCustomer = useCallback(
    (customer: Customer) => {
      dispatch({ type: "set_customer", payload: { customer } });
    },
    [dispatch]
  );

  const handleSetPromotion = useCallback(
    (promotion: CreditCardPromotion | null) => {
      dispatch({ type: "set_promotion", payload: { promotion: promotion } });
    },
    [dispatch]
  );

  const handleSetBindingFixedVaChannel = useCallback(
    (bindingFixedVaChannel: string | null) => {
      dispatch({
        type: "set_binding_fixed_va_channel",
        payload: { bindingFixedVaChannel }
      });
    },
    [dispatch]
  );

  const handleSetPollingInterval = useCallback(
    (pollingInterval: number) => {
      dispatch({
        type: "set_polling_interval",
        payload: { pollingInterval }
      });
    },
    [dispatch]
  );

  useEffect(() => {
    // send internal invoice context analytics event on invoice loaded
    const isExpressCheckout =
      get(
        state.paymentLink.invoice_settings,
        "express_checkout_enabled",
        false
      ) &&
      get(state.paymentLink.invoice_settings, "express_checkout_channels", [])
        .length > 0;
    const isCustomerFilled = !!get(state.paymentLink.invoice, "customer");

    // add datadog RUM context from fetched payment link
    if (ENABLE_RUM) {
      import("@datadog/browser-rum").then((datadog) => {
        datadog.datadogRum.addRumGlobalContext(
          "invoice-id",
          state.paymentLink.invoice.id
        );
        datadog.datadogRum.addRumGlobalContext(
          "business-id",
          state.paymentLink.invoice.user_id
        );
      });
    }

    logInternalAnalyticsEvent(
      {
        event: InternalAnalyticsEvent.INVOICE_CONTEXT,
        "business-id": state.paymentLink.invoice.user_id,
        "invoice-id": state.paymentLink.invoice.id,
        "device-type": window
          ? window.innerWidth > 640
            ? "desktop"
            : "mobile"
          : "unknown",
        "user-agent": window?.navigator?.userAgent,
        amount: state.paymentLink.invoice.amount,
        default_payment_method:
          state.paymentLink.invoice_settings.primary_payment_method,
        is_customer_info_filled: isCustomerFilled.toString(),
        is_express_checkout: isExpressCheckout.toString()
      },
      true
    );

    // send external initiate checkout analytics event on invoice is pending
    if (state.paymentLink.invoice.status === InvoiceStatus.Pending) {
      logExternalAnalyticsEvent({
        event_name: ExternalAnalyticsEvent.INITIATE_CHECKOUT,
        target: [
          ExternalAnalyticsProvider.FACEBOOK,
          ExternalAnalyticsProvider.GOOGLE
        ]
      });
    }

    if (
      ([InvoiceStatus.Paid, InvoiceStatus.Settled] as InvoiceStatus[]).includes(
        state.paymentLink.invoice.status
      )
    ) {
      // send internal analytics event if invoice is paid
      logInternalAnalyticsEvent({
        event: InternalAnalyticsEvent.PAYMENT_SUCCESSFUL,
        "payment-channel": state.paymentLink.invoice.payment_channel
      });

      // send external analytics event if invoice is paid
      logExternalAnalyticsEvent({
        event_name: ExternalAnalyticsEvent.PURCHASE,
        target: [
          ExternalAnalyticsProvider.FACEBOOK,
          ExternalAnalyticsProvider.GOOGLE
        ],
        params: {
          currency: state.paymentLink.invoice.currency,
          value: state.paymentLink.invoice.paid_amount
        }
      });

      nexPaymentSuccessfulEvent(
        state.paymentLink.invoice.id,
        state.paymentLink.invoice.payment_channel ?? ""
      );
    }
  }, [state.paymentLink]);

  const handleMutateAsyncChannelObject = useCallback(
    (key: "banks" | "retail_outlets", newValue: AsyncChannelObject) => {
      const asyncPaymentInstruments = state.paymentLink.invoice[key];
      const newAsyncPaymentInstruments = asyncPaymentInstruments?.map(
        (paymentInstrument) => {
          if (paymentInstrument.name === newValue.name) {
            return {
              ...paymentInstrument,
              payment_destination: newValue.payment_destination,
              merchant_name: newValue.merchant_name,
              alternative_displays: newValue.alternative_displays
            };
          }
          return paymentInstrument;
        }
      );

      dispatch({
        type: "set_invoice",
        payload: {
          invoice: {
            [key]: newAsyncPaymentInstruments
          }
        }
      });
    },
    [dispatch, state.paymentLink]
  );

  useEffect(() => {
    const browserLanguage = state.paymentLink.invoice_settings
      .detect_browser_language
      ? getBrowserLocale()
      : null;

    const defaultLanguage =
      browserLanguage ||
      state.paymentLink.invoice.locale ||
      state.paymentLink.invoice_settings.invoice_primary_language;

    if (defaultLanguage) {
      i18n.changeLanguage(defaultLanguage);
    }
  }, [
    state.paymentLink.invoice.locale,
    state.paymentLink.invoice_settings.invoice_primary_language
  ]);

  /**
   * handler to initialize event source by first fetching latest data.
   * also handles event source connection failures and cleanup.
   */
  useEffect(() => {
    if (
      state.shouldEnableInvoiceUpdates &&
      state.paymentLink.feature_flags?.checkout_ui_realtime_updates_enabled
    ) {
      let eventSource: EventSource;
      const fetchLatestDataAbortController = new AbortController();

      const fetchLatestDataAndEstablishEventSource = async () => {
        try {
          const latestData = await fetchInvoice<Invoice>(
            state.paymentLink.invoice.id,
            { abortSignal: fetchLatestDataAbortController.signal }
          );
          dispatch({
            type: "set_invoice",
            payload: {
              invoice: {
                ...state.paymentLink.invoice,
                id: latestData.id,
                user_id: latestData.user_id,
                status: latestData.status,
                payment_method: latestData.payment_method,
                payment_channel: latestData.payment_channel,
                installment: latestData.installment,
                paid_at: latestData.paid_at,
                paid_amount: latestData.paid_amount
              }
            }
          });
        } catch (error) {
          dispatch({
            type: "set_connected_to_realtime",
            payload: { connectedToRealtime: false }
          });
        }

        eventSource = new EventSource(
          CHECKOUT_UI_GATEWAY_URL +
            API.establishRealtimeUpdates(state.paymentLink.invoice.id)
        );
        eventSource.onmessage = (e) => {
          const realtimeUpdateMessage = JSON.parse(
            e.data
          ) as RealtimeUpdateMessage;
          if (
            realtimeUpdateMessage.type === "invoice_status_update" &&
            realtimeUpdateMessage.data?.status
          ) {
            dispatch({
              type: "set_invoice",
              payload: { invoice: realtimeUpdateMessage.data }
            });
          }
        };
        eventSource.onopen = () => {
          dispatch({
            type: "set_connected_to_realtime",
            payload: { connectedToRealtime: true }
          });
        };
        eventSource.onerror = (e) => {
          dispatch({
            type: "set_connected_to_realtime",
            payload: { connectedToRealtime: false }
          });
          console.error("Failed to establish realtime connection", e);
        };
      };
      fetchLatestDataAndEstablishEventSource();

      return () => {
        fetchLatestDataAbortController.abort();
        eventSource?.close();
        dispatch({
          type: "set_connected_to_realtime",
          payload: { connectedToRealtime: false }
        });
      };
    }
  }, [
    dispatch,
    state.shouldEnableInvoiceUpdates,
    state.paymentLink.invoice.id,
    state.paymentLink.feature_flags?.checkout_ui_realtime_updates_enabled
  ]);

  /**
   * invoice updates should only be attempted when invoice is still pending
   * and browser/tab is currently visible.
   */
  useEffect(() => {
    if (
      state.paymentLink.invoice.status === InvoiceStatus.Pending ||
      state.paymentLink.invoice.status === InvoiceStatus.PendingPaymentApproval
    ) {
      const handleVisibilityChange = async () => {
        dispatch({
          type: "set_should_enable_invoice_updates",
          payload: { shouldEnableInvoiceUpdates: !document.hidden }
        });
      };
      document.addEventListener("visibilitychange", handleVisibilityChange);
      handleVisibilityChange();

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
    dispatch({
      type: "set_should_enable_invoice_updates",
      payload: { shouldEnableInvoiceUpdates: false }
    });
  }, [dispatch, state.paymentLink.invoice.status]);

  /**
   * handle realtime connection error by specifying invoice polling intervals.
   */
  useEffect(() => {
    if (state.shouldEnableInvoiceUpdates && !state.connectedToRealtime) {
      const pollInvoice = async () => {
        const invoice = await fetchInvoice<Invoice>(
          state.paymentLink.invoice.id
        );

        if (state.paymentLink.invoice.status !== invoice.status) {
          dispatch({
            type: "set_invoice",
            payload: {
              invoice: {
                ...state.paymentLink.invoice,
                id: invoice.id,
                user_id: invoice.user_id,
                status: invoice.status,
                payment_method: invoice.payment_method,
                payment_channel: invoice.payment_channel,
                installment: invoice.installment,
                paid_at: invoice.paid_at,
                paid_amount: invoice.paid_amount
              }
            }
          });
        }

        // update invoice upon successful VA binding
        const isFixedVaBindingSuccessful =
          state.bindingFixedVaChannel &&
          invoice.callback_virtual_account_collection_id &&
          invoice.callback_virtual_account_collection_id !==
            state.paymentLink.invoice.callback_virtual_account_collection_id;

        if (isFixedVaBindingSuccessful) {
          dispatch({
            type: "set_invoice",
            payload: {
              invoice: {
                ...state.paymentLink.invoice,
                callback_virtual_account: invoice.callback_virtual_account,
                callback_virtual_account_collection_id:
                  invoice.callback_virtual_account_collection_id,
                callback_virtual_account_collection:
                  invoice.callback_virtual_account_collection
              }
            }
          });
        }
      };

      const pollingIntervalId = setInterval(pollInvoice, state.pollingInterval);

      return () => {
        clearInterval(pollingIntervalId);
      };
    }
  }, [
    dispatch,
    state.paymentLink.invoice.id,
    state.paymentLink.invoice.status,
    state.shouldEnableInvoiceUpdates,
    state.connectedToRealtime,
    state.pollingInterval
  ]);

  useEffect(() => {
    // add datadog RUM context from selected language
    if (i18n.language && ENABLE_RUM) {
      import("@datadog/browser-rum").then((datadog) => {
        datadog.datadogRum.addRumGlobalContext("locale", i18n.language);
      });
    }
  }, [i18n.language]);

  return (
    <PaymentLinkContext.Provider
      value={{
        customer: state.customer,
        promotion: state.promotion,
        paymentLink: state.paymentLink,
        bindingFixedVaChannel: state.bindingFixedVaChannel,
        onSetCustomer: handleSetCustomer,
        onSetPromotion: handleSetPromotion,
        onMutateAsyncChannelObject: handleMutateAsyncChannelObject,
        onSetBindingFixedVaChannel: handleSetBindingFixedVaChannel,
        onSetPollingInterval: handleSetPollingInterval
      }}
    >
      {props.children}
    </PaymentLinkContext.Provider>
  );
};

export default PaymentLinkProvider;

export const usePaymentLink = () => useContext(PaymentLinkContext);
