import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MAX_POLLING_RETRIES } from "../../../utils/constants";
import { delay } from "../../../utils/delay";
import {
  FullSessionResponse,
  makeDefaultErrorContent,
  pollSession
} from "../../api/api";
import { SessionContext } from "../../context/context";
import { SessionActionTypes } from "../../context/SessionContextInterface";
import { isPTStatusFailed } from "../../lib/payment-token";
import { ErrorContent } from "../../types";
import SessionPolling from "./Polling";
import PollingFailed from "./PollingFailed";

const POLLING_INITIAL_MS = 1000;
const POLLING_MAX_MS = 5000;

// Poll for session status perpetually
const SessionPollingRouter: React.FC = () => {
  const context = useContext(SessionContext);
  const { state, dispatch } = context;
  const { session, viewState, tokenRequestId } = state;
  const { id: sessionId } = useParams();

  if (!sessionId || !tokenRequestId) {
    throw new Error("sessionId or tokenRequestId is not defined", {
      cause: { sessionId, tokenRequestId }
    });
  }

  const [showPollingError, setShowPollingError] = useState(false);
  const [pollingErrorContent, setPollingErrorContent] = useState<
    ErrorContent | undefined
  >(undefined);

  const handlePollingError = (
    errorContent: ErrorContent,
    retryCount: number,
    delayMs: number,
    lastDelay: number
  ) => {
    // Stop polling after max retries
    if (retryCount > MAX_POLLING_RETRIES) {
      setPollingErrorContent(errorContent);
      setShowPollingError(true);
      return;
    }

    // Continue polling
    delay(delayMs).then(() =>
      startPollingWithBackoff(lastDelay, delayMs, retryCount + 1)
    );
  };

  const handlePollingSucceeded = (
    fullSessionResponse: FullSessionResponse,
    retryCount: number,
    delayMs: number,
    lastDelay: number
  ) => {
    // Set the session context
    dispatch({
      type: SessionActionTypes.SET_SESSION,
      payload: fullSessionResponse
    });
    // Set the succeeded channel context
    dispatch({
      type: SessionActionTypes.SET_SUCCEEDED_CHANNEL,
      payload: fullSessionResponse
    });

    // Show the failure page if token fails
    if (
      fullSessionResponse.paymentToken &&
      isPTStatusFailed(fullSessionResponse.paymentToken)
    ) {
      setPollingErrorContent(fullSessionResponse.errorContent);
      setShowPollingError(true);
      return;
    }

    // Continue polling if session is still active
    if (fullSessionResponse.session?.status === "ACTIVE") {
      delay(delayMs).then(() =>
        startPollingWithBackoff(lastDelay, delayMs, retryCount + 1)
      );
    }
  };

  // poll with fibonacci backoff
  const startPollingWithBackoff = (
    secondToTheLastDelay = 0,
    lastDelay = POLLING_INITIAL_MS,
    retryCount = 0
  ) => {
    let delayMs = lastDelay;
    if (lastDelay < POLLING_MAX_MS) {
      delayMs = secondToTheLastDelay + lastDelay;
    }
    pollSession(sessionId, tokenRequestId, retryCount).then((response) => {
      if (response.isLeft()) {
        handlePollingError(response.getLeft(), retryCount, delayMs, lastDelay);
      } else {
        handlePollingSucceeded(
          response.getRight(),
          retryCount,
          delayMs,
          lastDelay
        );
      }
    });
  };

  useEffect(() => {
    startPollingWithBackoff();
  }, []);

  // Transition when the session has transitioned to a different status
  useEffect(() => {
    if (session && session.status !== "ACTIVE") {
      viewState?.transitionView(context);
    }
  }, [session]);

  if (showPollingError) {
    const errorContent = pollingErrorContent ?? makeDefaultErrorContent();
    return <PollingFailed errorContent={errorContent} />;
  }

  return <SessionPolling />;
};

export default SessionPollingRouter;
