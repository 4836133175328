import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import MobileFooter from "../../components/MobileFooter";

type Props = {
  amount?: string;
  centeredHeader?: boolean;
  children?: ReactNode;
  footer?: ReactNode;
  header?: boolean;
  paymentChannelLogoUrl?: string;
  paymentChannelName: string;
};

const PaymentView: FC<Props> = (props) => {
  const { t } = useTranslation("common");
  const headerClassName = classnames([
    "text-lg font-semibold uppercase",
    { "text-center": props.centeredHeader }
  ]);

  return (
    <>
      <section className="pt-8 pb-12 px-4 lg:px-0 max-w-screen-sm mx-auto">
        {props.header ? (
          <p
            className={headerClassName}
            data-testid={`pay-with-${props.paymentChannelName.toLowerCase()}`}
          >
            {t("Pay {{amount}} with", { amount: props.amount })}
            {props.paymentChannelLogoUrl ? (
              <img
                src={props.paymentChannelLogoUrl}
                alt={props.paymentChannelName}
                className="inline-block h-12 object-contain px-2"
              />
            ) : (
              props.paymentChannelName
            )}
          </p>
        ) : null}
        {props.children}
      </section>
      {props.footer ? <MobileFooter>{props.footer}</MobileFooter> : null}
    </>
  );
};

export default PaymentView;
