export const SupportedPaymentRequestReturnStatus = {
  CANCELLED: "CANCELLED",
  FAILED: "FAILED"
};
export type SupportedPaymentRequestReturnStatus =
  keyof typeof SupportedPaymentRequestReturnStatus;

export const SupportedPaymentRequestReturnStatusHash: {
  [key in SupportedPaymentRequestReturnStatus]: string;
} = {
  CANCELLED: "cancelled",
  FAILED: "failed"
};
