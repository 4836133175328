import React from "react";

const PriceTag: React.FC<React.SVGProps<SVGSVGElement>> = (svgProps) => {
  const { ...rest } = svgProps;

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...rest}>
      <path
        d="M13.2733 6.71967L7.27334 0.719674C7.03334 0.479674 6.70001 0.333008 6.33334 0.333008H1.66668C0.933344 0.333008 0.333344 0.933008 0.333344 1.66634V6.33301C0.333344 6.69967 0.48001 7.03301 0.726677 7.27967L6.72668 13.2797C6.96668 13.5197 7.30001 13.6663 7.66668 13.6663C8.03334 13.6663 8.36668 13.5197 8.60668 13.273L13.2733 8.60634C13.52 8.36634 13.6667 8.03301 13.6667 7.66634C13.6667 7.29967 13.5133 6.95967 13.2733 6.71967ZM2.66668 3.66634C2.11334 3.66634 1.66668 3.21967 1.66668 2.66634C1.66668 2.11301 2.11334 1.66634 2.66668 1.66634C3.22001 1.66634 3.66668 2.11301 3.66668 2.66634C3.66668 3.21967 3.22001 3.66634 2.66668 3.66634Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PriceTag;
