import { FC, SVGProps } from "react";

const Fpx: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      height={48}
      width={100}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1121_414)">
        <mask
          id="mask0_1121_414"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M56 16H8v16.02h48V16z"></path>
        </mask>
        <g mask="url(#mask0_1121_414)">
          <path
            fill="#242B57"
            d="M25.626 18.005h7.729v2.342h-5.19v2.343h4.799v2.342h-4.8v4.491h-2.54l.002-11.518zM35.177 18.005h4.279c.569 0 1.136.056 1.693.17a3.83 3.83 0 011.39.56c.402.271.727.64.945 1.073.249.53.368 1.11.35 1.694a3.923 3.923 0 01-.326 1.693c-.2.434-.507.81-.894 1.09-.407.284-.868.48-1.355.578a7.929 7.929 0 01-1.693.17h-1.85v4.49h-2.541l.002-11.518zm2.54 4.88h1.693c.222 0 .442-.021.659-.066.201-.038.394-.112.569-.218.17-.104.31-.25.408-.424.11-.208.163-.442.154-.677.01-.252-.063-.5-.208-.706a1.37 1.37 0 00-.522-.415 2.255 2.255 0 00-.708-.186 7.198 7.198 0 00-.748-.04h-1.3l.003 2.732zM48.29 23.503l-3.806-5.498h3.188l2.464 4.034 2.36-4.034h3.059l-3.726 5.45 4.166 6.068h-3.182l-2.816-4.636-2.74 4.636h-3.002l4.035-6.02z"
          ></path>
          <path
            fill="#0987C9"
            d="M14.511 27.362l-1.607-2.176c-.237-.323-.65-.748-.65-1.175 0-.427.41-.846.65-1.185l1.607-2.178c.064-.088.138-.168.222-.237v-2.496c0-2.013-.847-2.521-1.863-1.128L9.4 21.477C8.89 22.173 8 23.091 8 24.016c0 .924.886 1.833 1.399 2.54l3.463 4.675c1.03 1.393 1.862.89 1.862-1.126v-2.507a1.217 1.217 0 01-.213-.237z"
          ></path>
          <path
            fill="#242B57"
            d="M21.317 21.476l-3.465-4.695c-1.03-1.394-1.863-.886-1.863 1.127v2.512c.084.068.158.148.22.237l1.61 2.177c.24.324.65.75.65 1.186 0 .435-.41.846-.65 1.175L16.2 27.362c-.063.09-.137.17-.22.24v2.504c0 2.017.847 2.518 1.862 1.126l3.474-4.684c.508-.703 1.397-1.622 1.397-2.54 0-.918-.883-1.834-1.397-2.532z"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1121_414">
          <path
            fill="#fff"
            d="M0 0H48V17H0z"
            transform="translate(8 15)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Fpx;
