import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";

type Props = {
  children?: ReactNode;
};

const MobileFooter: FC<Props> = (props) => {
  const portal = document.getElementById("mobile-footer-portal");

  if (!portal) {
    return null;
  }

  return createPortal(
    <footer className="bg-white border-t border-xen-gray-200 p-4">
      {props.children}
    </footer>,
    portal
  );
};

export default MobileFooter;
