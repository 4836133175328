import React from "react";
import ProcessingGraphic from "../../../assets/graphics/Processing";
import Layout, { MainView } from "../../../components/Layout";
import PoweredByXendit from "../../../components/PoweredByXendit";
import RollingLoader from "../../../components/RollingLoader";
import { useTranslation } from "react-i18next";

// Poll for session status perpetually
const SessionPolling: React.FC = () => {
  const { t } = useTranslation("session");
  return (
    <Layout>
      <MainView>
        <div className="flex-1 px-4 lg:px-0">
          <div className="pt-8 pb-12 space-y-8">
            <div className="flex flex-col items-center">
              <ProcessingGraphic />
              <RollingLoader className="text-primary w-24 h-24 mt-4" />
              <p className="text-lg text-center mt-4 mb-4">
                {t("Please wait while your session is being processed")}
              </p>
              <PoweredByXendit />
            </div>
          </div>
        </div>
      </MainView>
    </Layout>
  );
};

export default SessionPolling;
