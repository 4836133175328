import { FormikErrors, FormikTouched } from "formik";
import { useTranslation } from "react-i18next";
import { ArrowLeft } from "../../../../../assets/icons";
import Button from "../../../../../components/Button";
import Header, {
  HeaderContent,
  HeaderImage,
  HeaderTitle
} from "../../../../../components/Header";
import { MainView } from "../../../../../components/Layout";
import MobileFooter from "../../../../../components/MobileFooter";
import { DEFAULT_XENDIT_LOGO } from "../../../../../utils/constants";
import { useSessionContext } from "../../../../context/context";
import { PaymentMethod } from "../../../../types";
import {
  ExtraChannelPropertyInput,
  generateChannelPropertyInputs
} from "./GenerateChannelPropertiesInputs";

interface ChannelPropertiesFormProps<P> {
  paymentMethod?: PaymentMethod;
  channelPropertyInputs: ExtraChannelPropertyInput<keyof P>[];
  onCancel: () => void;
  // formik fields
  touched: FormikTouched<P>;
  errors: FormikErrors<P>;
  values: P;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  isValid: boolean;
  dirty: boolean;
}

const ChannelPropertiesForm = function <
  P extends { [key: string]: string | number }
>(props: ChannelPropertiesFormProps<P>) {
  const {
    onCancel,
    paymentMethod,
    channelPropertyInputs,
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty
  } = props;

  const { t } = useTranslation(["session", "common"]);

  // Context
  const { state } = useSessionContext();
  const { business, session } = state;

  if (!paymentMethod || !business || !session) {
    throw new Error("There was a problem loading the state", {
      cause: { state, paymentMethod }
    });
  }

  return (
    <MainView>
      <Header>
        <HeaderContent>
          <HeaderImage
            src={business.merchant_profile_picture_url ?? DEFAULT_XENDIT_LOGO}
            alt={business.name ?? ""}
          />
          <HeaderTitle>{business.name}</HeaderTitle>
        </HeaderContent>
      </Header>
      <section className="pt-8 pb-12 px-4 lg:px-0 max-w-screen-sm mx-auto">
        <p className="text-lg font-semibold text-center">
          {t("Save your payment information with")}
          {paymentMethod.logo_url ? (
            <img
              src={paymentMethod.logo_url}
              alt={paymentMethod.channel_code}
              className="inline-block h-12 object-contain px-2"
            />
          ) : (
            paymentMethod.channel_code
          )}
        </p>
        <div className="pt-6">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-4 pb-6">
              <div className="flex flex-wrap -mx-2">
                {generateChannelPropertyInputs(
                  channelPropertyInputs,
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur
                )}
              </div>
            </div>
            <div className="flex justify-center space-x-2">
              <Button
                variant="brand-secondary"
                outline
                type="button"
                onClick={onCancel}
                className="md:w-36"
              >
                <span className="hidden md:inline">
                  {t("Back", { ns: "common" })}
                </span>
                <span className="md:hidden">
                  <ArrowLeft />
                </span>
              </Button>
              <Button
                id="pay-now-button"
                variant="brand-secondary"
                type="submit"
                className="flex-1 md:flex-initial md:w-36"
                disabled={!isValid || !dirty}
              >
                {t("Continue", { ns: "common" })}
              </Button>
            </div>
            <MobileFooter>
              <div className="flex space-x-4">
                <Button
                  variant="brand-secondary"
                  outline
                  type="button"
                  onClick={onCancel}
                  className="md:w-36"
                >
                  <span className="hidden md:inline">
                    {t("Back", { ns: "common" })}
                  </span>
                  <span className="md:hidden">
                    <ArrowLeft />
                  </span>
                </Button>
                <Button
                  id="pay-now-button"
                  variant="brand-secondary"
                  type="submit"
                  className="flex-1 md:flex-initial md:w-36"
                  disabled={!isValid || !dirty}
                >
                  {t("Continue", { ns: "common" })}
                </Button>
              </div>
            </MobileFooter>
          </form>
        </div>
      </section>
    </MainView>
  );
};

export default ChannelPropertiesForm;
