import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Graphic from "../../assets/graphics/Error";

type Props = {
  initial?: boolean;
};

const GenericError: FC<Props> = (props) => {
  const { t } = useTranslation("common");

  useEffect(() => {
    if (props.initial) {
      document.querySelector(".loading-container")?.remove();
    }
  }, [props.initial]);

  return (
    <div className="flex flex-col items-center px-4 lg:px-0 pt-12 lg:pt-4">
      <Graphic className="text-primary mb-4" />
      <p className="font-semibold text-lg text-center mb-4">
        {t("Sorry, this wasn't supposed to happen")}
      </p>
      <p className="text-center">
        {t(
          "Our engineers have been notified. Please wait a few minutes, refresh and try again."
        )}
      </p>
    </div>
  );
};

export default GenericError;
