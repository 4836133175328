/* eslint-disable @typescript-eslint/no-explicit-any */

import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { PropertiesFormFieldsType } from "@xendit/checkout-utilities/dist/src/types/payment-channels";

export const getYupValidationSchema = (
  schema: any,
  config: PropertiesFormFieldsType
) => {
  const { t } = useTranslation(["forms"]);

  const { label, name, validation_type, validations = [] as any } = config;

  if (!validation_type || !(yup as any)[validation_type]) {
    return schema;
  }

  let validator: any = (yup as any)[validation_type]();

  validations.forEach((validation: any) => {
    const { type, params, error } = validation;

    if (type === "matches") {
      validator = validator.matches(
        new RegExp(params[0]),
        t(error, {
          field: t(label, { ns: "forms" }),
          ns: "forms"
        })
      );
    } else {
      if (!validator[type]) {
        return;
      }

      validator = validator[type](
        t(params[0], {
          field: t(label, { ns: "forms" }),
          ns: "forms"
        })
      );
    }
  });

  schema[name] = validator;

  return schema;
};
