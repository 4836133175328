import { Suspense, lazy } from "react";
import { useRoutes } from "react-router-dom";

import Checkout from "./pages/Checkout";
import NotFound from "./pages/NotFound";
import Processing from "./pages/Processing";
import Session from "./pages/Session/Session";

const OnDemand = lazy(() => import("./pages/OnDemand"));
const Recurring = lazy(() => import("./pages/Recurring"));

import "./i18n";
import "./index.css";

function App() {
  const element = useRoutes([
    {
      path: "/web/:id/processing",
      element: <Processing />
    },
    {
      path: "/web/:id",
      element: <Checkout />
    },
    {
      path: "/session/:id",
      element: <Session />
    },
    {
      path: "/od/:on_demand_link",
      element: (
        <Suspense fallback={null}>
          <OnDemand />
        </Suspense>
      )
    },
    {
      path: "/donation/:on_demand_link",
      element: (
        <Suspense fallback={null}>
          <OnDemand />
        </Suspense>
      )
    },
    {
      path: "/r/:recurring_id",
      element: (
        <Suspense fallback={null}>
          <Recurring />
        </Suspense>
      )
    },
    { path: "*", element: <NotFound /> }
  ]);

  return element;
}

export default App;
