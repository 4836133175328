/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import type { PaymentMethodsEnum } from "@xendit/checkout-utilities/dist/src/enums/payment-methods";
import { formatDisplayAmountWithCurrencyCode } from "@xendit/checkout-utilities/dist/src/amount-formatter";
import type {
  PaymentChannelsInterface,
  PaymentChannelsPropertiesType
} from "@xendit/checkout-utilities/dist/src/types/payment-channels";
import { PaymentChannelsEnum } from "@xendit/checkout-utilities";
import isEmpty from "lodash/isEmpty";

import {
  AmountRangeEvaluation,
  evaluateAmountRange
} from "../../helpers/amount-validation";
import { constantCase } from "../../utils/strings";
import {
  ExternalAnalyticsEvent,
  ExternalAnalyticsProvider,
  InternalAnalyticsEvent,
  logExternalAnalyticsEvent,
  logInternalAnalyticsEvent
} from "../../utils/analytics";
import { getCheckoutPaymentChannel, paymentMethodAvailable } from ".";
import { InvoicePaymentMethod } from "../../types/checkout";
import { usePaymentLink } from "../PaymentLinkContext";
import {
  SupportedPaymentRequestReturnStatus,
  SupportedPaymentRequestReturnStatusHash
} from "../../helpers/payment-request";
import { useTranslation } from "react-i18next";
import Dialog from "../../components/Dialog";

type PaymentMethodContextValues = {
  amountRange: AmountRangeEvaluation | null;
  checkoutPaymentChannels: PaymentChannelsInterface;
  clearPaymentChannel: () => void;
  clearPaymentMethodType: () => void;
  error: ErrorMessage | null;
  paymentChannel: null | PaymentChannelsPropertiesType;
  paymentMethodType: null | PaymentMethodsEnum;
  ready: boolean;
  selectPaymentMethodType: (paymentMethodType: string) => void;
  selectPaymentChannel: (channelCode: string) => void;
};

const PaymentMethodContext = createContext<PaymentMethodContextValues>({
  amountRange: null,
  checkoutPaymentChannels: {},
  clearPaymentChannel: () => {},
  clearPaymentMethodType: () => {},
  error: null,
  paymentChannel: null,
  paymentMethodType: null,
  ready: false,
  selectPaymentChannel: () => {},
  selectPaymentMethodType: () => {}
});

type PaymentMethodReducerState = {
  checkoutPaymentChannels: PaymentChannelsInterface;
  error: ErrorMessage | null;
  paymentChannel: null | PaymentChannelsPropertiesType;
  paymentMethodType: null | PaymentMethodsEnum;
  ready: boolean;
  onCloseDialog?: (() => void) | null;
};
type PaymentMethodReducerAction =
  | { type: "clear_channel" }
  | { type: "clear_method_type" }
  | {
      type: "select_channel";
      payload: SelectChannelPayload;
    }
  | {
      type: "select_method_type";
      payload: SelectMethodTypePayload;
    }
  | {
      type: "set_checkout_channels";
      payload: SetCheckoutChannelsPayload;
    }
  | {
      type: "set_error";
      payload: SetErrorPayload;
    }
  | {
      type: "dismiss_error";
    };
type SelectChannelPayload = {
  paymentChannel: PaymentChannelsPropertiesType;
};
type SelectMethodTypePayload = {
  paymentMethodType: PaymentMethodsEnum;
};
type SetCheckoutChannelsPayload = {
  checkoutPaymentChannels: PaymentChannelsInterface;
  paymentChannel?: PaymentChannelsPropertiesType;
  paymentMethodType?: PaymentMethodsEnum;
};
type SetErrorPayload = {
  error: ErrorMessage;
  onCloseDialog?: (() => void) | null;
};

const PaymentMethodReducer = (
  state: PaymentMethodReducerState,
  action: PaymentMethodReducerAction
): PaymentMethodReducerState => {
  switch (action.type) {
    case "clear_channel":
      return { ...state, paymentChannel: null };
    case "clear_method_type":
      return { ...state, paymentMethodType: null, paymentChannel: null };
    case "select_method_type":
      return {
        ...state,
        paymentMethodType: action.payload.paymentMethodType,
        paymentChannel: null
      };
    case "select_channel":
      return {
        ...state,
        paymentChannel: action.payload.paymentChannel,
        paymentMethodType: action.payload.paymentChannel.payment_method
      };
    case "set_checkout_channels":
      return {
        ...state,
        checkoutPaymentChannels: action.payload.checkoutPaymentChannels,
        paymentMethodType: action.payload.paymentMethodType || null,
        paymentChannel: action.payload.paymentChannel || null,
        ready: true
      };
    case "set_error":
      return {
        ...state,
        error: action.payload.error,
        onCloseDialog: action.payload.onCloseDialog
      };
    case "dismiss_error":
      return {
        ...state,
        error: null,
        onCloseDialog: null
      };
    default:
      return state;
  }
};

type Props = {
  children?: ReactNode;
};

const PaymentMethodProvider: FC<Props> = (props) => {
  const {
    paymentLink: {
      invoice: {
        amount,
        currency,
        payment_channels: invoicePaymentChannels,
        payment_methods: invoicePaymentMethods
      },
      invoice_settings: { primary_payment_method },
      available_payment_methods: checkoutPaymentChannelsJSON
    }
  } = usePaymentLink();
  const { t } = useTranslation("common");

  const [searchParams] = useSearchParams();
  const { hash } = useLocation();
  const channelOrPaymentMethodTypeSelectionByHash = (() => {
    const selection = hash.slice(1);
    return isEmpty(selection) ? null : selection;
  })();
  const [channelCodeSelection, paymentMethodTypeSelection] = [
    constantCase(
      searchParams.get("channelCode") ||
        channelOrPaymentMethodTypeSelectionByHash ||
        ""
    ),
    constantCase(
      searchParams.get("paymentMethodType") ||
        channelOrPaymentMethodTypeSelectionByHash ||
        (primary_payment_method === InvoicePaymentMethod.RetailOutlets
          ? "RETAIL_OUTLET"
          : primary_payment_method) ||
        ""
    )
  ];

  const isSingleChannel = invoicePaymentMethods?.length === 1;

  const [state, dispatch] = useReducer(PaymentMethodReducer, {
    checkoutPaymentChannels: {},
    error: null,
    paymentChannel: null,
    paymentMethodType: null,
    ready: false
  });

  const handleClearPaymentChannel = useCallback(() => {
    dispatch({ type: "clear_channel" });
  }, [dispatch]);

  const handleClearPaymentMethodType = useCallback(() => {
    dispatch({ type: "clear_method_type" });
  }, [dispatch]);

  const handleSelectPaymentChannel = useCallback(
    (paymentChannel: string) => {
      if (!state.ready) {
        return;
      }

      const checkoutPaymentChannel = getCheckoutPaymentChannel(
        state.checkoutPaymentChannels,
        invoicePaymentChannels || [],
        paymentChannel
      );
      if (!checkoutPaymentChannel) {
        return;
      }
      dispatch({
        type: "select_channel",
        payload: {
          paymentChannel: checkoutPaymentChannel
        }
      });
    },
    [
      dispatch,
      invoicePaymentChannels,
      state.checkoutPaymentChannels,
      state.ready
    ]
  );

  const handleSelectPaymentMethodType = useCallback(
    (paymentMethodType: string) => {
      if (!state.ready) {
        return;
      }

      if (
        paymentMethodAvailable(
          state.checkoutPaymentChannels,
          invoicePaymentMethods || [],
          paymentMethodType
        )
      ) {
        dispatch({
          type: "select_method_type",
          payload: {
            paymentMethodType: paymentMethodType as PaymentMethodsEnum
          }
        });
      }
    },
    [
      dispatch,
      invoicePaymentMethods,
      state.checkoutPaymentChannels,
      state.ready
    ]
  );

  useEffect(() => {
    if (state.ready || !checkoutPaymentChannelsJSON) {
      return;
    }

    const payload: SetCheckoutChannelsPayload = {
      checkoutPaymentChannels: checkoutPaymentChannelsJSON
    };

    const initialChannelSelection = (() => {
      if (
        channelCodeSelection ===
          SupportedPaymentRequestReturnStatus.CANCELLED ||
        channelCodeSelection === SupportedPaymentRequestReturnStatus.FAILED
      ) {
        return "";
      }

      return invoicePaymentChannels?.length === 1
        ? invoicePaymentChannels[0] || ""
        : channelCodeSelection;
    })();

    // Check and stop auto-initialization if the payment was cancelled or failed
    if (
      channelCodeSelection === SupportedPaymentRequestReturnStatus.CANCELLED
    ) {
      dispatch({
        type: "set_error",
        payload: {
          error: {
            title: t("Payment cancelled"),
            body: t("You have cancelled the payment.")
          }
        }
      });
    }
    if (channelCodeSelection === SupportedPaymentRequestReturnStatus.FAILED) {
      dispatch({
        type: "set_error",
        payload: {
          error: {
            title: t("Payment failed"),
            body: t("There was an error processing your payment.")
          }
        }
      });
    }

    const checkoutPaymentChannel = getCheckoutPaymentChannel(
      payload.checkoutPaymentChannels,
      invoicePaymentChannels || [],
      initialChannelSelection
    );
    if (checkoutPaymentChannel) {
      payload.paymentChannel = checkoutPaymentChannel;
      payload.paymentMethodType = checkoutPaymentChannel.payment_method;
      dispatch({ type: "set_checkout_channels", payload });
      return;
    }

    if (
      paymentMethodAvailable(
        payload.checkoutPaymentChannels,
        invoicePaymentMethods || [],
        paymentMethodTypeSelection
      )
    ) {
      payload.paymentMethodType =
        paymentMethodTypeSelection as PaymentMethodsEnum;
    }

    dispatch({ type: "set_checkout_channels", payload });
  }, [
    checkoutPaymentChannelsJSON,
    channelCodeSelection,
    dispatch,
    currency,
    invoicePaymentChannels,
    invoicePaymentMethods,
    paymentMethodTypeSelection,
    state.ready
  ]);

  useEffect(() => {
    if (state.paymentChannel) {
      // send internal analytics event on payment channel change
      logInternalAnalyticsEvent({
        event: InternalAnalyticsEvent.SELECT_PAYMENT_CHANNEL,
        "payment-channel": state.paymentChannel.channel
      });

      // send external analytics event on payment channel change
      logExternalAnalyticsEvent({
        event_name: ExternalAnalyticsEvent.ADD_PAYMENT_INFO,
        target: [
          ExternalAnalyticsProvider.FACEBOOK,
          ExternalAnalyticsProvider.GOOGLE
        ],
        params: { channel: state.paymentChannel.channel }
      });

      // Check if amount is within supported range by the selected channel
      const { minimumAmount, maximumAmount, isWithinAmountRange } =
        evaluateAmountRange(amount, currency, state.paymentChannel);

      const amountRangeErrDescription = t(
        "The accepted amount range is {{minimumAmount}} — {{maximumAmount}}",
        {
          minimumAmount: formatDisplayAmountWithCurrencyCode(
            minimumAmount,
            currency
          ),
          maximumAmount: formatDisplayAmountWithCurrencyCode(
            maximumAmount,
            currency
          )
        }
      );

      // NOTE: For single channel invoice or automatically selected by hash invoice, check if the amount is within the supported range
      const shouldShowAmountErrModal =
        (isSingleChannel || channelOrPaymentMethodTypeSelectionByHash) &&
        !isWithinAmountRange;

      // Exception: Cards have their own amount range limit set on cards settings, so checkout will just let cards do the checking instead
      const isNotCardsPayment =
        state.paymentChannel.channel !== PaymentChannelsEnum.CREDIT_CARD;

      if (shouldShowAmountErrModal && isNotCardsPayment) {
        dispatch({
          type: "set_error",
          payload: {
            error: {
              title: t("Amount is not within the supported range"),
              body: amountRangeErrDescription
            },
            onCloseDialog: handleClearPaymentChannel
          }
        });
      }
    }
  }, [state.paymentChannel]);

  return (
    <PaymentMethodContext.Provider
      value={{
        ...state,
        amountRange: state.paymentChannel
          ? evaluateAmountRange(amount, currency, state.paymentChannel)
          : null,
        clearPaymentChannel: handleClearPaymentChannel,
        clearPaymentMethodType: handleClearPaymentMethodType,
        selectPaymentChannel: handleSelectPaymentChannel,
        selectPaymentMethodType: handleSelectPaymentMethodType
      }}
    >
      {props.children}

      <Dialog
        open={!!state.error}
        title={state.error?.title}
        description={state.error?.body}
        buttons={[
          {
            text: "OK, Got it!",
            variant: "brand-secondary",
            onClick: () => {
              dispatch({ type: "dismiss_error" });

              // Use custom onClose function if any
              if (state.onCloseDialog) {
                state.onCloseDialog();
                window.location.hash = "";
              }

              const returnHash = hash.slice(1);
              if (
                returnHash ===
                  SupportedPaymentRequestReturnStatusHash.CANCELLED ||
                returnHash === SupportedPaymentRequestReturnStatusHash.FAILED
              ) {
                window.location.hash = "";
              }
            }
          }
        ]}
      />
    </PaymentMethodContext.Provider>
  );
};

export default PaymentMethodProvider;

export const usePaymentMethod = () => useContext(PaymentMethodContext);
