import { memo } from "react";

export function SingleOtpInput(
  props: React.InputHTMLAttributes<HTMLInputElement>
) {
  const { ...rest } = props;

  return <input autoComplete="one-time-code" {...rest} />;
}

const SingleOTPInput = memo(SingleOtpInput);

export default SingleOTPInput;
