import { FC, SVGProps } from "react";

const Paylater: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="a" fill="#FFF">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.565 2v10.658c0 .429.348.777.777.777h10.659C21.517 18.797 17.01 23 11.52 23 5.712 23 1 18.29 1 12.478 1 6.99 5.202 2.483 10.565 2Z"
        />
      </mask>
      <path
        d="M10.565 2h1l-1.09-.997.09.996Zm11.436 11.435.996.09-.996-1.09v1ZM9.565 1.999v10.659h2V1.999h-2Zm0 10.659c0 .981.796 1.777 1.777 1.777v-2c.123 0 .223.1.223.223h-2Zm1.777 1.777h10.659v-2H11.342v2Zm9.663-1.09C20.567 18.197 16.488 22 11.522 22v2c6.01 0 10.945-4.602 11.475-10.476l-1.992-.179ZM11.522 22A9.522 9.522 0 0 1 2 12.478H0C0 18.842 5.158 24 11.522 24v-2ZM2 12.478c0-4.966 3.803-9.045 8.655-9.483l-.18-1.992C4.603 1.533 0 6.468 0 12.478h2Z"
        fill="#5D7077"
        mask="url(#a)"
      />
      <path
        d="m12.239 1-.001.5c5.66.006 10.247 4.593 10.253 10.253 0 .12-.097.216-.217.216H12.24a.217.217 0 0 1-.217-.217V1.717c0-.12.097-.217.217-.217V1Zm0 0c5.935.006 10.746 4.817 10.752 10.752 0 .396-.321.717-.717.717H12.24a.717.717 0 0 1-.717-.717V1.717c0-.396.32-.717.717-.717Z"
        stroke="#5D7077"
      />
    </svg>
  );
};

export default Paylater;
