import { useTranslation } from "react-i18next";

import Dropdown, { DropdownOption } from "../Dropdown";
import FormField from "../FormField";

export type InstallmentOption = {
  label: string;
  value: number;
  count: number;
  interval: string;
};

export type CreditCardInstallmentOptionsProps = {
  allowFullPayment?: boolean;
  disabled: boolean;
  error?: string;
  handleChange: (option: InstallmentOption) => void;
  installment: InstallmentOption;
  installmentOptions: InstallmentOption[];
};

const CreditCardInstallmentOptions = ({
  allowFullPayment = true,
  disabled,
  error,
  handleChange,
  installment,
  installmentOptions
}: CreditCardInstallmentOptionsProps) => {
  const { t } = useTranslation("forms");
  const placeholder = allowFullPayment
    ? t("Pay in full")
    : t("Select an installment option");

  return (
    <div className="w-full">
      <FormField
        label={t("Installment Plan")}
        state={error ? "error" : "default"}
        helpText={error ? t(error) : undefined}
      >
        <Dropdown
          disabled={
            (installmentOptions.length === 1 && allowFullPayment) || disabled
          }
          alignment="left"
          block
          options={installmentOptions}
          keyExtractor={(option) =>
            `installment-${(option as InstallmentOption).value}`
          }
          value={installment}
          onChange={(installmentOption) =>
            handleChange(installmentOption as InstallmentOption)
          }
          placeholder={placeholder}
          renderOption={(option, helpers) => {
            return (
              <DropdownOption
                {...helpers}
                className="flex items-center space-x-2"
              >
                <span
                  data-testid={`installment-${
                    (option as InstallmentOption).count
                  }-${(option as InstallmentOption).interval}`}
                >
                  {(option as InstallmentOption).label}
                </span>
              </DropdownOption>
            );
          }}
          renderSelection={(selection) => {
            return (
              <div className="flex items-center space-x-1">
                <span>{(selection as InstallmentOption).label}</span>
              </div>
            );
          }}
          data-testid="installment"
        />
      </FormField>
    </div>
  );
};

export default CreditCardInstallmentOptions;
