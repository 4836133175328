import SessionLoading from "../states/Loading/Loading";
import SessionPollingRouter from "../states/Polling/PollingRouter";
import Wizard from "../states/SelectingPaymentMethod/wizard/Wizard";
import SessionCanceled from "../states/Terminal/Canceled";
import SessionCompleted from "../states/Terminal/Completed";
import SessionExpired from "../states/Terminal/Expired";
import SessionInvalid from "../states/Terminal/Invalid";
import {
  SessionActionTypes,
  SessionContextInterface,
  ViewState
} from "./SessionContextInterface";

export class TerminalSessionState extends ViewState {
  transitionView() {
    // no-op
    return;
  }

  renderView(context: SessionContextInterface) {
    const session = context.state.session;

    if (!session) {
      throw new Error("Session is not defined");
    }

    switch (session.status) {
      case "COMPLETED":
        return <SessionCompleted />;
      case "EXPIRED":
        return <SessionExpired />;
      case "CANCELED":
        return <SessionCanceled />;
      default:
        return <SessionInvalid />;
    }
  }
}

export class LoadingSessionState extends ViewState {
  transitionView(context: SessionContextInterface) {
    const { session, tokenRequestId } = context.state;

    if (tokenRequestId) {
      context.dispatch({
        type: SessionActionTypes.TRANSITION_VIEW,
        payload: {
          viewState: new PollingSessionState()
        }
      });

      return;
    }

    if (!session) {
      throw new Error("Session is not defined");
    }

    if (session.status === "ACTIVE") {
      context.dispatch({
        type: SessionActionTypes.TRANSITION_VIEW,
        payload: {
          viewState: new SelectingPaymentMethodState()
        }
      });
    } else {
      context.dispatch({
        type: SessionActionTypes.TRANSITION_VIEW,
        payload: {
          viewState: new TerminalSessionState()
        }
      });
    }
  }

  renderView() {
    return <SessionLoading />;
  }
}

export class PollingSessionState extends ViewState {
  transitionView(context: SessionContextInterface) {
    const { session, tokenRequestId } = context.state;

    if (!session) {
      throw new Error("Session is not defined");
    }

    if (!tokenRequestId) {
      throw new Error("Token Request ID is not defined");
    }

    if (session.status === "ACTIVE") {
      context.dispatch({
        type: SessionActionTypes.TRANSITION_VIEW,
        payload: {
          viewState: new SelectingPaymentMethodState()
        }
      });
    } else {
      context.dispatch({
        type: SessionActionTypes.TRANSITION_VIEW,
        payload: {
          viewState: new TerminalSessionState()
        }
      });
    }
  }

  renderView() {
    return <SessionPollingRouter />;
  }
}

export class SelectingPaymentMethodState extends ViewState {
  transitionView(context: SessionContextInterface) {
    const { session } = context.state;

    if (!session) {
      throw new Error("Session is not defined");
    }

    if (session.status === "ACTIVE") {
      context.dispatch({
        type: SessionActionTypes.TRANSITION_VIEW,
        payload: {
          viewState: new PollingSessionState()
        }
      });
    } else {
      context.dispatch({
        type: SessionActionTypes.TRANSITION_VIEW,
        payload: {
          viewState: new TerminalSessionState()
        }
      });
    }
  }

  renderView() {
    return <Wizard />;
  }
}
