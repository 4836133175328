export const getCardFullMonthYear = (value: string) => {
  const cardMonth = value.slice(0, value.length - 2);
  const cardYear = "20" + value.slice(-2);

  return { cardMonth, cardYear };
};

export const validateCardExpirationDate = (value: string) => {
  const { cardMonth, cardYear } = getCardFullMonthYear(value);

  const intCardMonth = parseInt(cardMonth);
  const intCardYear = parseInt(cardYear);

  const todayMonth = new Date().getMonth() + 1; // because getMonth is zero indexed
  const todayYear = new Date().getFullYear();

  const isValid =
    todayYear < intCardYear ||
    (todayYear === intCardYear && todayMonth <= intCardMonth);

  return isValid;
};
