import { FC } from "react";
import { useFormik, FormikErrors } from "formik";
import { useTranslation } from "react-i18next";
import { withMask } from "use-mask-input";
import get from "lodash/get";
import * as yup from "yup";

import { ArrowLeft } from "../../assets/icons";
import Button from "../Button";
import FormField from "../FormField";
import Input from "../Input";

import validateCardExpirationDate from "../../helpers/validate-card-expiry";

import { usePaymentRequest } from "../../contexts/PaymentRequestContext";

import MobileFooter from "../../components/MobileFooter";

export type InitialCustomerDataValues = {
  given_names: string;
  surname: string;
  mobile_number: string;
  email: string;
};

export type DirectDebitFormValues = {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  cardLastFour: string;
  cardExpiry: string;
};

type Props = {
  countryCode?: string | number;
  initialCustomerData?: InitialCustomerDataValues | null;
  disableSubmit: boolean;
  onCancel: () => void;
  onSubmit: (values: DirectDebitFormValues) => object;
};

const DirectDebitForm: FC<Props> = (props) => {
  const { t } = useTranslation("forms");
  const { initializing } = usePaymentRequest();
  const {
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
    values,
    touched
  } = useFormik<DirectDebitFormValues>({
    initialValues: (() => {
      const mobileNumber = get(props.initialCustomerData, "mobile_number", "");
      return {
        firstName: get(props.initialCustomerData, "given_names", ""),
        lastName: get(props.initialCustomerData, "surname", ""),
        mobileNumber: !mobileNumber.startsWith("+")
          ? `${props.countryCode}${mobileNumber}`
          : mobileNumber,
        email: get(props.initialCustomerData, "email", ""),
        cardLastFour: "",
        cardExpiry: ""
      };
    })(),
    onSubmit: async (values) => {
      await props.onSubmit(values);
    },
    validate: (values) => {
      const errors: FormikErrors<DirectDebitFormValues> = {};
      const sanitizedCardExpiry = values.cardExpiry.replace(/\//g, "");

      if (
        values.cardExpiry &&
        !values.cardExpiry.match(/^(0[1-9]|1[0-2])\/([0-9]{2})$/)
      ) {
        errors.cardExpiry = "Card expiry should be in MM/YY format";
      }

      if (
        sanitizedCardExpiry.length === 4 &&
        !validateCardExpirationDate(sanitizedCardExpiry)
      ) {
        errors.cardExpiry = "Card has past expiry date";
      }

      return errors;
    },
    validationSchema: yup.object({
      firstName: yup
        .string()
        .required(t("{{field}} is required", { field: t("First Name") })),
      lastName: yup
        .string()
        .required(t("{{field}} is required", { field: t("Last Name") })),
      mobileNumber: yup
        .string()
        .required(t("{{field}} is required", { field: t("Mobile Number") })),
      email: yup
        .string()
        .email("Email address is not valid")
        .required(t("{{field}} is required", { field: t("Email Address") })),
      cardLastFour: yup
        .string()
        .length(4, "Incomplete last four digits card number")
        .required(t("{{field}} is required", { field: t("Card Number") })),
      cardExpiry: yup
        .string()
        .required(t("{{field}} is required", { field: t("Valid Thru") }))
    })
  });

  return (
    <div className="pt-6">
      <h3 className="font-semibold uppercase mb-4">
        {t("Customer Information", { ns: "common" })}
      </h3>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col space-y-4 pb-6">
          <div className="flex flex-col md:flex-row -mx-2">
            <div className="md:w-1/2 px-2 pb-4 md:pb-0">
              <FormField
                label={t("First Name")}
                name="firstName"
                helpText={touched.firstName ? errors.firstName : ""}
                state={
                  touched.firstName && errors.firstName ? "error" : "default"
                }
                required
              >
                <Input
                  block
                  autoComplete="given-name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.firstName && !!errors.firstName}
                  placeholder="John"
                  disabled={initializing || props.disableSubmit}
                  data-testid="first-name"
                />
              </FormField>
            </div>
            <div className="md:w-1/2 px-2">
              <FormField
                label={t("Last Name")}
                name="lastName"
                helpText={touched.lastName ? errors.lastName : ""}
                state={
                  touched.lastName && errors.lastName ? "error" : "default"
                }
                required
              >
                <Input
                  block
                  autoComplete="family-name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.lastName && !!errors.lastName}
                  placeholder="Doe"
                  disabled={initializing || props.disableSubmit}
                  data-testid="last-name"
                />
              </FormField>
            </div>
          </div>
          <div className="flex flex-col md:flex-row -mx-2">
            <div className="md:w-1/2 px-2 pb-4 md:pb-0">
              <FormField
                label={t("Mobile Number")}
                name="mobileNumber"
                helpText={touched.mobileNumber ? errors.mobileNumber : ""}
                state={
                  touched.mobileNumber && errors.mobileNumber
                    ? "error"
                    : "default"
                }
                required
              >
                <Input
                  block
                  type="tel"
                  autoComplete="tel"
                  name="mobileNumber"
                  value={values.mobileNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.mobileNumber && !!errors.mobileNumber}
                  placeholder={`${props.countryCode} 812 345 6789`}
                  data-testid="mobile-number"
                  ref={withMask(`${props.countryCode} 999 999 99999`, {
                    placeholder: "",
                    onBeforePaste: (value: string) =>
                      !value.startsWith("+") ? `+${value}` : value
                  })}
                  disabled={initializing || props.disableSubmit}
                />
              </FormField>
            </div>
            <div className="md:w-1/2 px-2">
              <FormField
                label={t("Email Address")}
                name="email"
                helpText={touched.email ? errors.email : ""}
                state={touched.email && errors.email ? "error" : "default"}
                required
              >
                <Input
                  block
                  type="email"
                  autoComplete="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.email && !!errors.email}
                  placeholder="abc@gmail.com"
                  disabled={initializing || props.disableSubmit}
                  data-testid="email"
                />
              </FormField>
            </div>
          </div>
          <h3 className="font-semibold uppercase pt-6 mb-4">
            {t("Card Information", { ns: "common" })}
          </h3>
          <div className="flex flex-col md:flex-row -mx-2">
            <div className="md:w-1/2 px-2 pb-4 md:pb-0">
              <FormField
                label={t("Card Number Last 4 Digits")}
                name="cardLastFour"
                helpText={touched.cardLastFour ? errors.cardLastFour : ""}
                state={
                  touched.cardLastFour && errors.cardLastFour
                    ? "error"
                    : "default"
                }
                required
              >
                <Input
                  block
                  type="text"
                  inputMode="numeric"
                  aria-label="Card number"
                  aria-invalid={touched.cardLastFour && !!errors.cardLastFour}
                  autoComplete="card-last-four"
                  autoCorrect="off"
                  name="cardLastFour"
                  value={values.cardLastFour}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.cardLastFour && !!errors.cardLastFour}
                  placeholder="1234"
                  data-testid="card-last-four"
                  ref={withMask("9999", {
                    placeholder: "",
                    showMaskOnHover: false,
                    autoUnmask: true
                  })}
                  disabled={initializing || props.disableSubmit}
                />
              </FormField>
            </div>
            <div className="md:w-1/2 px-2">
              <FormField
                label={t("Valid Thru")}
                name="cardExpiry"
                helpText={touched.cardExpiry ? errors.cardExpiry : ""}
                state={
                  touched.cardExpiry && errors.cardExpiry ? "error" : "default"
                }
                required
              >
                <Input
                  block
                  type="text"
                  inputMode="numeric"
                  aria-label="Valid Thru"
                  aria-invalid={!!errors.cardExpiry}
                  autoComplete="valid-thru"
                  autoCorrect="off"
                  name="cardExpiry"
                  value={values.cardExpiry}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.cardExpiry && !!errors.cardExpiry}
                  placeholder="MM/YY"
                  data-testid="card-expiry"
                  ref={withMask("99/99", {
                    placeholder: "",
                    showMaskOnHover: false
                  })}
                  disabled={initializing || props.disableSubmit}
                />
              </FormField>
            </div>
          </div>
        </div>
        <div className="hidden md:flex justify-center space-x-2">
          <Button
            variant="brand-secondary"
            outline
            type="button"
            onClick={props.onCancel}
            className="md:w-36"
            disabled={initializing || props.disableSubmit}
            data-testid="back"
          >
            <span className="hidden md:inline">
              {t("Back", { ns: "common" })}
            </span>
            <span className="md:hidden">
              <ArrowLeft />
            </span>
          </Button>
          <Button
            id="pay-now-button"
            variant="brand-secondary"
            type="submit"
            disabled={initializing || props.disableSubmit}
            className="flex-1 md:flex-initial md:w-36"
            data-testid="pay-now"
          >
            {initializing || props.disableSubmit
              ? t("Processing...", { ns: "common" })
              : t("Pay Now", { ns: "common" })}
          </Button>
        </div>
        <MobileFooter>
          <div className="flex space-x-4">
            <Button
              variant="brand-secondary"
              outline
              type="button"
              onClick={props.onCancel}
              className="md:w-36"
              disabled={initializing || props.disableSubmit}
            >
              <span className="hidden md:inline">
                {t("Back", { ns: "common" })}
              </span>
              <span className="md:hidden">
                <ArrowLeft />
              </span>
            </Button>
            <Button
              id="pay-now-button"
              variant="brand-secondary"
              type="button"
              disabled={initializing || props.disableSubmit}
              className="flex-1 md:flex-initial md:w-36"
              onClick={submitForm}
            >
              {initializing || props.disableSubmit
                ? t("Processing...", { ns: "common" })
                : t("Pay Now", { ns: "common" })}
            </Button>
          </div>
        </MobileFooter>
      </form>
    </div>
  );
};

export default DirectDebitForm;
