import axios, { AxiosError, isAxiosError } from "axios";
import { MockSessionResponse } from "../../../mocks/base-response";
import { MockPaymentTokenResponse } from "../../../mocks/payment-token";
import {
  ALLOW_SESSION_MOCKS,
  CHECKOUT_UI_GATEWAY_URL
} from "../../utils/constants";
import { delay } from "../../utils/delay";
import { mapFullSessionResponseToSessionState } from "./client-mapper";
import { Either } from "../lib/either";
import {
  Business,
  Customer,
  ErrorContent,
  ExtendedPaymentToken,
  PaymentMethod,
  PaymentToken,
  Session,
  Setting,
  SucceededChannel
} from "../types";

const SESSION_ENDPOINTS = {
  GET_SESSION: (id: string) => `/api/sessions/${id}`,
  POLL: (id: string) => `/api/sessions/${id}/poll`,
  PAYMENT_TOKENS: "/api/sessions/payment_tokens",
  PAYMENT_TOKENS_AUTH: "/api/sessions/payment_tokens/auth"
};

export interface FullSessionResponse {
  session?: Session;
  customer?: Customer;
  business?: Business;
  settings?: Setting;
  paymentMethods?: PaymentMethod[];
  succeededChannel?: SucceededChannel;
  paymentToken?: PaymentToken;
  errorContent?: ErrorContent;
}

interface ErrorResponse {
  error_code: string;
  message: string;
  error_content?: ErrorContent;
}

export function makeDefaultErrorContent(): ErrorContent {
  return {
    title: "Error",
    message_1: "There was a problem with the request.",
    message_2: "Please try again later."
  };
}

function handleUIGWError(
  error: Error | AxiosError<ErrorResponse>
): Either<ErrorContent, never> {
  if (
    isAxiosError<ErrorResponse>(error) &&
    error.response?.data?.error_content
  ) {
    return Either.makeLeft(error.response.data.error_content);
  }
  return Either.makeLeft(makeDefaultErrorContent());
}

export async function getSession(
  id: string
): Promise<Either<ErrorContent, FullSessionResponse>> {
  if (ALLOW_SESSION_MOCKS) {
    await delay(1000);
    const fullResponse = new MockSessionResponse()
      .withActiveSession()
      .getFullResponse();
    return Either.makeRight(mapFullSessionResponseToSessionState(fullResponse));
  }

  return axios
    .get(CHECKOUT_UI_GATEWAY_URL + SESSION_ENDPOINTS.GET_SESSION(id))
    .then((response) => {
      return Either.makeRight(
        mapFullSessionResponseToSessionState(response.data)
      );
    })
    .catch((error: Error | AxiosError) => {
      return handleUIGWError(error);
    });
}

/**
 *
 * @param id Session ID
 * @param mockRetryCount For testing. Can remove this when API is ready
 * @returns Session object
 */
export async function pollSession(
  id: string,
  tokenRequestId: string,
  mockRetryCount?: number
): Promise<Either<ErrorContent, FullSessionResponse>> {
  if (ALLOW_SESSION_MOCKS) {
    const paymentToken = new MockPaymentTokenResponse()
      .withStatusActive()
      .getValue();
    if (mockRetryCount && mockRetryCount > 2) {
      const response = new MockSessionResponse()
        .withCompletedSession()
        .withSucceededChannel("GCASH");
      return Either.makeRight({
        session: response.getSession(),
        succeededChannel: response.getSucceededChannel(),
        paymentToken: paymentToken
      });
    }
    return Either.makeRight({
      session: new MockSessionResponse().withActiveSession().getSession(),
      succeededChannel: undefined,
      paymentToken: paymentToken
    });
  }
  return axios
    .get<{
      session: Session;
      succeeded_channel: SucceededChannel;
      payment_token: PaymentToken;
      error_content?: ErrorContent;
    }>(
      `${CHECKOUT_UI_GATEWAY_URL}${SESSION_ENDPOINTS.POLL(
        id
      )}?token_request_id=${tokenRequestId}`
    )
    .then((response) => {
      if (!response.data.session) {
        return Either.makeLeft(
          response.data.error_content || makeDefaultErrorContent()
        );
      }
      return Either.makeRight(
        mapFullSessionResponseToSessionState(response.data)
      );
    })
    .catch((error: Error | AxiosError) => {
      return handleUIGWError(error);
    });
}

export async function postPaymentToken(
  data: CreatePaymentTokenProps
): Promise<Either<ErrorContent, ExtendedPaymentToken>> {
  if (ALLOW_SESSION_MOCKS) {
    await delay(1000);
    return Either.makeRight(
      new MockPaymentTokenResponse()
        .withRedirectAction()
        .withPostOnServerAction()
        .withSessionTokenRequestId()
        .getValue()
    );
  }
  return axios
    .post<ExtendedPaymentToken>(
      CHECKOUT_UI_GATEWAY_URL + SESSION_ENDPOINTS.PAYMENT_TOKENS,
      data
    )
    .then((response) => {
      return Either.makeRight(response.data);
    })
    .catch((error: Error | AxiosError) => {
      return handleUIGWError(error);
    });
}

export async function postPaymentTokenAuth(
  data: CreatePaymentTokenAuthProps
): Promise<Either<ErrorContent, PaymentToken>> {
  if (ALLOW_SESSION_MOCKS) {
    return Either.makeRight(
      new MockPaymentTokenResponse()
        .withRedirectAction()
        .withSessionTokenRequestId()
        .getValue()
    );
  }
  return axios
    .post<PaymentToken>(
      CHECKOUT_UI_GATEWAY_URL + SESSION_ENDPOINTS.PAYMENT_TOKENS_AUTH,
      data
    )
    .then((response) => {
      return Either.makeRight(response.data);
    })
    .catch((error: Error | AxiosError) => {
      return handleUIGWError(error);
    });
}

interface CreatePaymentTokenProps {
  session_id: string;
  channel_code?: string;
  channel_properties: Record<string, unknown>;
}

interface CreatePaymentTokenAuthProps {
  session_id: string;
  payment_token_id: string;
  auth_request: {
    auth_code: string;
  };
}
