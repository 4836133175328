export const formatDate = (
  date: Date,
  language: string,
  timeFormat?: string | null,
  dateTimeFormatOpts?: Intl.DateTimeFormatOptions
): string => {
  const expiryDateStringParts = Intl.DateTimeFormat(language, {
    dateStyle: "long",
    timeStyle: "short",
    hour12: timeFormat === "12h",
    ...dateTimeFormatOpts
  }).formatToParts(new Date(date));
  return expiryDateStringParts
    .map(({ value }, index) => {
      if (expiryDateStringParts[index - 1]?.type === "hour" && value !== ":") {
        return ":";
      }
      return value;
    })
    .join("");
};
