import { Helmet } from "react-helmet";
import { Suspense, useEffect, ReactNode, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import isEmpty from "lodash/isEmpty";

import CheckoutExpired from "../../views/CheckoutExpired";
import CheckoutPaid from "../../views/CheckoutPaid";
import CheckoutPending from "../../views/CheckoutPending";
import CheckoutPendingPaymentApproval from "../../views/CheckoutPendingPaymentApproval";
import NotFound from "../../views/NotFound";

import ErrorBoundary from "../../components/ErrorBoundary";
import GenericError from "../../components/GenericError";
import Layout, { MainView } from "../../components/Layout";

import PaymentLinkProvider, {
  usePaymentLink
} from "../../contexts/PaymentLinkContext";
import PaymentMethodProvider from "../../contexts/PaymentMethodContext";

import { applyCustomColors } from "../../helpers/apply-custom-colors";

import { CheckoutInitialData, InvoiceStatus } from "../../types/checkout";
import {
  CHECKOUT_UI_GATEWAY_URL,
  ENABLE_SIMULATION
} from "../../utils/constants";
import useFetch from "../../hooks/use-fetch";
import {
  GrowthBookProvider,
  useGrowthBook
} from "@growthbook/growthbook-react";
import { growthbookClient } from "../../utils/growthbook-client";
import {
  experimentExposureEvent,
  nexInvoiceLoadedEvent
} from "../../utils/nex-events";
import { isWebview } from "../../helpers/is-webview";
import { shareGrowthBookItemsStore } from "../../utils/share-growthbook-items";

const CheckoutStatusView = () => {
  const {
    paymentLink: {
      invoice: { status }
    }
  } = usePaymentLink();

  const checkoutView: Record<InvoiceStatus, ReactNode> = {
    PENDING: <CheckoutPending />,
    PENDING_PAYMENT_APPROVAL: <CheckoutPendingPaymentApproval />,
    PAID: <CheckoutPaid />,
    SETTLED: <CheckoutPaid />,
    EXPIRED: <CheckoutExpired />
  };

  return <Layout>{checkoutView[status]}</Layout>;
};

type CheckoutViewProps = {
  invoiceId: string;
};
const CheckoutView = ({ invoiceId }: CheckoutViewProps) => {
  const [isGrowthBookLoaded, setIsGrowthBookLoaded] = useState(false);
  const { data, error } = useFetch<CheckoutInitialData>(
    CHECKOUT_UI_GATEWAY_URL + `/api/invoices/${invoiceId}/init`,
    { method: "GET" }
  );

  useEffect(() => {
    if (data) {
      document.querySelector(".loading-container")?.remove();

      if (data.invoice_settings) {
        applyCustomColors(data.invoice_settings);
      }
    }
  }, [data]);

  const growthbookHook = useGrowthBook();

  const setGrowthBookAttributes = useCallback(() => {
    if (!growthbookHook || !data) return;

    growthbookHook.setAttributes({
      businessId: data.invoice.user_id,
      invoiceId: data.invoice.id,
      currency: data.invoice.currency,
      successRedirectUrl: data.invoice.success_redirect_url || "",
      failedRedirectUrl: data.invoice.failure_redirect_url || "",
      userAgent: navigator.userAgent,
      isWebview: isWebview()
    });

    const result = growthbookHook.evalFeature("nex_card_banner_v3");

    if (result) {
      const { setState } = shareGrowthBookItemsStore;
      setState({
        experimentId: "nex_card_banner_v3",
        variantId: String(result?.experimentResult?.variationId)
      });
      experimentExposureEvent(
        "nex_card_banner_v3",
        String(result?.experimentResult?.variationId)
      );
    }

    setIsGrowthBookLoaded(true);
  }, [growthbookHook, data]);

  useEffect(() => {
    setGrowthBookAttributes();
  }, [setGrowthBookAttributes]);

  useEffect(() => {
    if (
      isGrowthBookLoaded &&
      data &&
      data.invoice.status === InvoiceStatus.Pending
    ) {
      nexInvoiceLoadedEvent(data.invoice.id);
    }
  }, [isGrowthBookLoaded, data]);

  if (error) {
    if ((error as AxiosError).response?.status === 404) {
      return (
        <Layout>
          <MainView>
            <NotFound />
          </MainView>
        </Layout>
      );
    } else {
      throw error;
    }
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>
          {data.invoice_settings.branding?.brand_name || "Checkout"} - #
          {data.invoice.external_id}
        </title>
        {/* external analytics */}
        {!isEmpty(data.invoice_settings.analytics?.facebook) ? (
          <script>{`!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${data.invoice_settings.analytics?.facebook}');`}</script>
        ) : null}
        {!isEmpty(data.invoice_settings.analytics?.google) ? (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${data.invoice_settings.analytics?.google}&l=dataLayerExternal`}
          />
        ) : null}
        {!isEmpty(data.invoice_settings.analytics?.google) ? (
          <script>{`window.dataLayerExternal = window.dataLayerExternal || [];function gtag(){dataLayerExternal.push(arguments);}gtag('js', new Date());gtag('config', '${data.invoice_settings.analytics?.google}');`}</script>
        ) : null}
      </Helmet>
      <PaymentLinkProvider paymentLink={data}>
        <PaymentMethodProvider>
          {ENABLE_SIMULATION || import.meta.env.DEV ? (
            <div
              id="simulation-bar-portal"
              className="sticky top-0 inset-x-0 z-10"
            ></div>
          ) : null}
          <CheckoutStatusView />
        </PaymentMethodProvider>
      </PaymentLinkProvider>
    </>
  );
};

const Checkout = () => {
  const { id } = useParams();

  useEffect(() => {
    growthbookClient.loadFeatures({ autoRefresh: true }).catch((err: Error) => {
      console.error("GrowthBook failed to load", err);
    });
  }, []);

  return (
    <ErrorBoundary
      fallback={
        <Layout>
          <MainView>
            <GenericError initial />
          </MainView>
        </Layout>
      }
    >
      <Suspense>
        <GrowthBookProvider growthbook={growthbookClient}>
          <CheckoutView invoiceId={id as string} />
        </GrowthBookProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Checkout;
