import { FC, useMemo } from "react";
import classNames from "classnames";
import kebabCase from "lodash/kebabCase";

import { OtherBanks } from "../../assets/icons";
import { PaymentMethodListItemChannel } from "../PaymentMethodList";
import Tooltip from "../Tooltip";
import { MOBILE_BANKING_CHANNEL_CODE_REGEX } from "../../utils/constants";

type Props = {
  channels: PaymentMethodListItemChannel[];
  onSelectChannel: (channel: string) => void;
};

const ChannelsGrid: FC<Props> = (props) => {
  return (
    <ul className="w-full flex flex-wrap sm:-mx-2">
      {props.channels.map((channel) => (
        <ChannelsGridItem
          key={channel.name}
          channel={channel}
          onClick={() => props.onSelectChannel(channel.name)}
        />
      ))}
    </ul>
  );
};

export default ChannelsGrid;

type ChannelsGridItemProps = {
  channel: PaymentMethodListItemChannel;
  onClick: () => void;
};

const ChannelsGridItem: FC<ChannelsGridItemProps> = (props) => {
  const shouldDisplayFullWidthLogo = useMemo(() => {
    return MOBILE_BANKING_CHANNEL_CODE_REGEX.test(props.channel.name);
  }, [props.channel.name]);

  const contentClassName = classNames([
    "flex items-center justify-center border-.5 border-xen-gray-300 sm:rounded ring-primary transition px-3 h-10 sm:px-10 sm:h-24 w-20 sm:w-full space-y-1.5",
    {
      "bg-white group-hover:border-primary sm:group-focus:ring-2":
        !props.channel.disabled
    }
  ]);

  const buttonClassName = classNames([
    "w-full group p-1.5 sm:p-0 flex items-center space-x-3 text-left focus:bg-xen-gray-300 transition-colors outline-none",
    {
      "cursor-not-allowed": props.channel.disabled
    }
  ]);

  const imageClassName = classNames([
    "object-contain",
    {
      "h-5 sm:h-12": !shouldDisplayFullWidthLogo
    },
    {
      "h-full sm:h-full": shouldDisplayFullWidthLogo
    },
    {
      "opacity-50 grayscale": props.channel.disabled
    }
  ]);

  const otherBankClassName = classNames([
    "text-primary h-5 w-5",
    {
      "opacity-50 grayscale": props.channel.disabled
    }
  ]);

  return (
    <li className="sm:px-2 w-full sm:w-1/3 sm:pb-4">
      <Tooltip
        contentChildren={props.channel.tooltipMessage}
        delayDuration={0}
        side="bottom"
        asChild
      >
        <button
          type="button"
          onClick={props.onClick}
          disabled={props.channel.disabled}
          className={buttonClassName}
          data-testid={`payment-channel-${kebabCase(props.channel.label)}`}
        >
          <div className={contentClassName}>
            {props.channel.isAdditionalChannel ? (
              <div className="flex flex-col items-center space-y-1.5">
                <OtherBanks className={otherBankClassName} />
                <div className="hidden sm:block text-center whitespace-nowrap">
                  {props.channel.displayName}
                </div>
              </div>
            ) : (
              <img
                src={props.channel.logoUrl}
                alt={props.channel.label || props.channel.name}
                className={imageClassName}
              />
            )}
          </div>
          <span className="sm:hidden text-base text-xen-gray-800 flex-1">
            {props.channel.displayName || props.channel.name}
          </span>
        </button>
      </Tooltip>
    </li>
  );
};
