import React from "react";
import { MainView } from "../../../components/Layout";
import Graphic from "../../../assets/graphics/UnderConstruction";
import { useTranslation } from "react-i18next";
import PoweredByXendit from "../../../components/PoweredByXendit";

const SessionExpired: React.FC = () => {
  const { t } = useTranslation("session");

  return (
    <MainView>
      <div className="flex-1 px-4 lg:px-0">
        <div className="pt-8 pb-12 space-y-8">
          <div className="flex flex-col items-center">
            <Graphic className="text-primary h-32 mb-4" />
            <p className="font-semibold text-lg text-center mb-4">
              {t("Your session has expired")}
            </p>
          </div>
        </div>

        <div className="flex justify-center pb-12">
          <PoweredByXendit />
        </div>
      </div>
    </MainView>
  );
};

export default SessionExpired;
