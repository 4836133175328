import type { PaymentChannelsInterface } from "@xendit/checkout-utilities/dist/src/types/payment-channels";
import { InvoicePaymentMethod } from "../../types/checkout";

export const paymentMethodAvailable = (
  checkoutPaymentChannels: PaymentChannelsInterface,
  invoicePaymentMethods: InvoicePaymentMethod[],
  paymentMethod: string
) => {
  // for dragonpay case
  // force payment method to DIRECT_DEBIT when Online Banking is selected
  if (paymentMethod === "ONLINE_BANKING") {
    paymentMethod = "DIRECT_DEBIT";
  }

  const checkoutPaymentChannel = Object.values(checkoutPaymentChannels).find(
    (value) => {
      return paymentMethod.includes(value.payment_method);
    }
  );
  if (!checkoutPaymentChannel) return false;
  return invoicePaymentMethods.includes(
    checkoutPaymentChannel.payment_method as InvoicePaymentMethod
  );
};
