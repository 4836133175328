import { shareGrowthBookItemsStore } from "./share-growthbook-items";
import { APP_ENV_MODE } from "./constants";

type EventBannerPlacement = "pre-payment-page" | "post-payment-page";
const schema_urls = {
  EXPERIMENT_EXPOSURE_EVENT:
    "iglu:com.xendit/experiment_exposure_1/jsonschema/1-0-0",
  EXPERIMENTATION_CONTEXT:
    "iglu:com.xendit/experimentation_context_1/jsonschema/1-0-0",
  INVOICE_CONTEXT: "iglu:com.xendit/invoice_context/jsonschema/1-0-0",
  ENVIRONMENT_CONTEXT: "iglu:com.xendit/environment_context/jsonschema/2-0-0"
};

const getEnvironmentContext = () => {
  return {
    schema: schema_urls.ENVIRONMENT_CONTEXT,
    data: {
      /**@description Xendit dashboard environment (live / test) */
      environment_type: (APP_ENV_MODE as string).includes("live")
        ? "live"
        : "test",
      /**@description Xendit infrastructure environment (staging / prod / dev / etc.) */
      application_type: (APP_ENV_MODE as string).includes("prod")
        ? "prod"
        : "staging"
    }
  };
};

export const nexBannerClickEvent = (
  placement: EventBannerPlacement,
  invoiceId: string
) => {
  const { experimentId, variantId } = shareGrowthBookItemsStore.getState();
  let SNOWPLOW_RETRY_ATTEMPTS = 3;

  if (typeof window.snowplow === "function" && experimentId && variantId) {
    const data = {
      category: "nex-events",
      action: "nex-card_banner_clicked",
      label: placement,
      context: [
        {
          schema: schema_urls.EXPERIMENTATION_CONTEXT,
          data: {
            experimentId,
            variationId: variantId
          }
        },
        {
          schema: schema_urls.INVOICE_CONTEXT,
          data: {
            invoice_id: invoiceId
          }
        },
        getEnvironmentContext()
      ]
    };

    window.snowplow!("trackStructEvent", data);
  } else {
    SNOWPLOW_RETRY_ATTEMPTS--;
    if (SNOWPLOW_RETRY_ATTEMPTS > 0) {
      setTimeout(() => nexBannerClickEvent(placement, invoiceId), 1000);
    } else {
      return;
    }
  }
};

export const experimentExposureEvent = (
  experimentId: string,
  variantId: string
) => {
  let SNOWPLOW_RETRY_ATTEMPTS = 3;

  if (typeof window.snowplow === "function" && experimentId && variantId) {
    const data = {
      event: {
        schema: "iglu:com.xendit/experiment_exposure_1/jsonschema/1-0-0",
        data: {
          experimentId,
          variationId: variantId,
          timestamp: new Date().toISOString(),
          user_id: null
        }
      },
      context: [getEnvironmentContext()]
    };

    window.snowplow!("trackSelfDescribingEvent", data);
  } else {
    SNOWPLOW_RETRY_ATTEMPTS--;
    if (SNOWPLOW_RETRY_ATTEMPTS > 0) {
      setTimeout(() => experimentExposureEvent(experimentId, variantId), 1000);
    } else {
      return;
    }
  }
};

export const nexPaymentSuccessfulEvent = (
  invoiceId: string,
  paymentChannel: string
) => {
  const { experimentId, variantId } = shareGrowthBookItemsStore.getState();
  let SNOWPLOW_RETRY_ATTEMPTS = 3;

  if (typeof window.snowplow === "function" && experimentId && variantId) {
    const data = {
      category: "nex-events",
      action: "nex-payment_successful",
      label: paymentChannel,
      context: [
        {
          schema: schema_urls.EXPERIMENTATION_CONTEXT,
          data: {
            experimentId,
            variationId: variantId
          }
        },
        {
          schema: schema_urls.INVOICE_CONTEXT,
          data: {
            invoice_id: invoiceId
          }
        },
        getEnvironmentContext()
      ]
    };

    window.snowplow!("trackStructEvent", data);
  } else {
    SNOWPLOW_RETRY_ATTEMPTS--;
    if (SNOWPLOW_RETRY_ATTEMPTS > 0) {
      setTimeout(
        () => nexPaymentSuccessfulEvent(invoiceId, paymentChannel),
        1000
      );
    } else {
      return;
    }
  }
};

export const nexInvoiceLoadedEvent = (invoiceId: string) => {
  const { experimentId, variantId } = shareGrowthBookItemsStore.getState();
  let SNOWPLOW_RETRY_ATTEMPTS = 3;

  if (
    typeof (window as any).snowplow === "function" &&
    experimentId &&
    variantId
  ) {
    const data = {
      category: "nex-events",
      action: "nex-invoice_loaded",
      context: [
        {
          schema: schema_urls.EXPERIMENTATION_CONTEXT,
          data: {
            experimentId,
            variationId: variantId
          }
        },
        {
          schema: schema_urls.INVOICE_CONTEXT,
          data: {
            invoice_id: invoiceId
          }
        },
        getEnvironmentContext()
      ]
    };

    (window as any).snowplow("trackStructEvent", data);
  } else {
    SNOWPLOW_RETRY_ATTEMPTS--;
    if (SNOWPLOW_RETRY_ATTEMPTS > 0) {
      setTimeout(() => nexInvoiceLoadedEvent(invoiceId), 1000);
    } else {
      return;
    }
  }
};
