import { FC, SVGProps } from "react";

const UnderConstruction: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 107.5V65.44L41.755 12.5H158.245L177.5 65.44V107.5H22.5Z"
        fill="#DFF0FE"
      />
      <path
        d="M156.5 15L175 65.88V105H25V65.88L43.5 15H156.5ZM160 10H40L20 65V110H180V65L160 10Z"
        fill="#4788C7"
      />
      <path
        d="M35 95H165V67.645L149.495 25H50.505L35 67.645V95Z"
        fill="#98CCFD"
      />
      <path
        d="M22.5 187.5V67.5H72.61C73.875 81.5 85.675 92.5 100 92.5C114.325 92.5 126.125 81.5 127.39 67.5H177.5V187.5H22.5Z"
        fill="#DFF0FE"
      />
      <path
        d="M175 70V185H25V70H70.415C72.8 84.17 85.16 95 100 95C114.84 95 127.195 84.17 129.585 70H175ZM180 65H125C125 78.805 113.805 90 100 90C86.195 90 75 78.805 75 65H20V190H180V65Z"
        fill="#4788C7"
      />
      <path
        d="M100 172.5C117.949 172.5 132.5 157.949 132.5 140C132.5 122.051 117.949 107.5 100 107.5C82.0507 107.5 67.5 122.051 67.5 140C67.5 157.949 82.0507 172.5 100 172.5Z"
        fill="white"
      />
      <path
        d="M100 110C116.54 110 130 123.46 130 140C130 156.54 116.54 170 100 170C83.46 170 70 156.54 70 140C70 123.46 83.46 110 100 110ZM100 105C80.67 105 65 120.67 65 140C65 159.33 80.67 175 100 175C119.33 175 135 159.33 135 140C135 120.67 119.33 105 100 105Z"
        fill="#4788C7"
      />
      <path
        d="M111.79 122.58L100 140L112.085 152.085"
        stroke="#4788C7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M100 145C102.761 145 105 142.761 105 140C105 137.239 102.761 135 100 135C97.2386 135 95 137.239 95 140C95 142.761 97.2386 145 100 145Z"
        fill="#4788C7"
      />
    </svg>
  );
};

export default UnderConstruction;
