import { FC, useEffect, useState } from "react";
import { useSessionContext } from "../../../context/context";
import {
  isOTPChannel,
  isPMTypeCard,
  isRedirectChannel,
  isTHDDFormAChannel,
  isTHDDFormBChannel
} from "../channels";
import ActiveSaveOnlySelectPaymentMethod from "../components/SelectPaymentMethod";
import { BRIAndBCAFlow } from "./channels/BRIAndBCAForm/BRIAndBCAFlow";
import { CardFlow } from "./channels/CardForm/CardFlow";
import { RedirectFlow } from "./channels/RedirectFlow";
import { THDirectDebitAFlow } from "./channels/THDirectDebitFormA/THDirectDebitAFlow";
import { THDirectDebitBFlow } from "./channels/THDirectDebitFormB/THDirectDebitBFlow";
import { FlowStep } from "./Flow";
import { useWizard } from "./use-wizard";

const Wizard: FC = () => {
  const { state } = useSessionContext();
  const { business, paymentMethods } = state;

  if (!business || !paymentMethods) {
    throw new Error("Wizard was initialized with an invalid state", {
      cause: state
    });
  }

  // Caches
  const [selectedChannelCode, setSelectedChannelCode] = useState<string>();
  const [selectedPMType, setSelectedPMType] = useState<string>();
  const [selectedFlow, setSelectedFlow] = useState<FlowStep[]>([]);

  const resetSelection = () => {
    setSelectedChannelCode(undefined);
    setSelectedPMType(undefined);
  };

  const { nextStep, previousStep, form } = useWizard(selectedFlow);

  // flows
  const flowParams = {
    channelCode: selectedChannelCode,
    pmType: selectedPMType,
    nextStep: nextStep,
    previousStep: previousStep,
    resetSelection: resetSelection
  };

  // The code is cleaner, but perhaps initializing several states for each flow at once is not the best approach
  const cardFlow = new CardFlow(flowParams).buildFlow();
  const ddOtpChannelFlow = new BRIAndBCAFlow(flowParams).buildFlow();
  const thDDFormA = new THDirectDebitAFlow(flowParams).buildFlow();
  const thDDFormB = new THDirectDebitBFlow(flowParams).buildFlow();
  const redirectFlow = new RedirectFlow(flowParams).buildFlow();

  // Channel Selection effect
  useEffect(() => {
    // Cards selection
    if (isPMTypeCard(selectedPMType)) {
      // Use "CARDS" channel code for card payment methods
      setSelectedChannelCode(selectedPMType);
      setSelectedFlow(cardFlow.onSelectFlow());
    }
    // DD with OTP selection
    else if (isOTPChannel(selectedChannelCode)) {
      setSelectedFlow(ddOtpChannelFlow.onSelectFlow());
    }
    // TH DD Form A selection
    else if (isTHDDFormAChannel(selectedChannelCode)) {
      setSelectedFlow(thDDFormA.onSelectFlow());
    }
    // TH DD Form B selection
    else if (isTHDDFormBChannel(selectedChannelCode)) {
      setSelectedFlow(thDDFormB.onSelectFlow());
    }
    // Redirect channel selection
    else if (isRedirectChannel(selectedChannelCode)) {
      setSelectedFlow(redirectFlow.onSelectFlow());
    }
    // No op
    else {
      setSelectedFlow([]);
    }
  }, [selectedChannelCode, selectedPMType]);

  return selectedFlow.length ? (
    form
  ) : (
    <ActiveSaveOnlySelectPaymentMethod
      business={business}
      paymentMethods={paymentMethods}
      onSelectChannelCode={setSelectedChannelCode}
      onSelectPMType={setSelectedPMType}
    />
  );
};

export default Wizard;
