import { FC, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  ExternalAnalyticsEvent,
  ExternalAnalyticsProvider,
  InternalAnalyticsEvent,
  logExternalAnalyticsEvent,
  logInternalAnalyticsEvent
} from "../../utils/analytics";
import {
  Header,
  HeaderAddon,
  HeaderBackButton,
  HeaderContent,
  HeaderImage,
  HeaderTitle
} from "../../components/Header";
import { MainView } from "../../components/Layout";
import LanguageNavigation from "../../components/LanguageNavigation";
import OrderSummary from "../../components/OrderSummary";
import PoweredByXendit from "../../components/PoweredByXendit";
import SimulationBar from "../../components/SimulationBar";

import Graphic from "../../assets/graphics/UnderConstruction";

import { ENABLE_SIMULATION } from "../../utils/constants";

import { usePaymentLink } from "../../contexts/PaymentLinkContext";

import { useRedirect } from "../../hooks/use-redirect";

import type { TranslationInterpolation } from "../../types/i18next";
import CustomBadges from "../../components/CustomBadges";

import { formatDate } from "../../helpers/date-strings";

const CheckoutExpired: FC = () => {
  const { i18n, t } = useTranslation("checkout-expired");
  const {
    paymentLink: { invoice, invoice_settings: invoiceSettings }
  } = usePaymentLink();

  const { expiry_date: expiryDate } = invoice;
  const { payment_due_time_format: paymentDueTimeFormat } = invoiceSettings;

  const expiryDateFormatted = formatDate(
    new Date(expiryDate),
    i18n.language,
    paymentDueTimeFormat
  );

  const handleLocaleChange = useCallback((locale: string) => {
    // send analytics event on language change
    logInternalAnalyticsEvent({
      event: InternalAnalyticsEvent.SELECTED_LOCALE,
      locale
    });
  }, []);

  const handleOrderSummaryOpenChange = useCallback((open: boolean) => {
    if (open) {
      // send external view content analytics event on order summary expand
      logExternalAnalyticsEvent({
        event_name: ExternalAnalyticsEvent.VIEW_CONTENT,
        target: [
          ExternalAnalyticsProvider.FACEBOOK,
          ExternalAnalyticsProvider.GOOGLE
        ]
      });
    }
  }, []);

  const secondsLeft = useRedirect(true, invoice.failure_redirect_url);

  return (
    <MainView>
      {ENABLE_SIMULATION ? <SimulationBar /> : null}
      <Header>
        <HeaderContent>
          {invoice.cancel_redirect_url ? (
            <HeaderBackButton
              title={`Back to ${invoice.merchant_name}`}
              onClick={() => {
                if (invoice.cancel_redirect_url) {
                  window.location.href = invoice.cancel_redirect_url;
                }
              }}
            />
          ) : null}
          <HeaderImage
            src={invoice.merchant_profile_picture_url}
            alt={invoice.merchant_name}
          />
          <HeaderTitle>{invoice.merchant_name}</HeaderTitle>
        </HeaderContent>
        <HeaderAddon>
          <LanguageNavigation onChange={handleLocaleChange} />
        </HeaderAddon>
      </Header>
      <div className="lg:hidden">
        <OrderSummary
          collapsible
          currency={invoice.currency}
          description={invoice.description}
          expiryDate={invoice.expiry_date}
          expiryTimeFormat={invoiceSettings.payment_due_time_format}
          externalId={invoice.external_id}
          fees={invoice.fees?.map((fee) => ({ ...fee, name: fee.type }))}
          // hideExpiryDate={invoice_settings.should_show_expiry_date_time}
          items={invoice.items}
          onOpenChange={handleOrderSummaryOpenChange}
          totalAmount={invoice.amount}
        />
      </div>
      <div className="flex-1 px-4 lg:px-0">
        <div className="pt-8 pb-12 space-y-8">
          <div className="flex flex-col items-center">
            <Graphic className="text-primary h-32 mb-4" />
            <p className="font-semibold text-lg text-center mb-4">
              {t("Your invoice has expired on", {
                expiryDate: expiryDateFormatted
              })}
            </p>
            <p className="text-center">
              <Trans t={t}>
                Sorry, please contact{" "}
                <strong>
                  {
                    {
                      merchant: invoice.merchant_name
                    } as TranslationInterpolation
                  }
                </strong>{" "}
                to create a new order.
              </Trans>
            </p>
          </div>

          {invoice.failure_redirect_url ? (
            <div className="text-center text-xen-gray-700 italic">
              {secondsLeft > 0
                ? t("Redirecting back in {{seconds}}s", {
                    seconds: secondsLeft
                  })
                : t("Redirecting now")}
            </div>
          ) : null}
        </div>

        <div className="flex justify-center pb-12">
          {invoiceSettings.branding?.badge_logos ? (
            <CustomBadges badgeLogos={invoiceSettings.branding?.badge_logos} />
          ) : (
            <PoweredByXendit />
          )}
        </div>
      </div>
    </MainView>
  );
};

export default CheckoutExpired;
