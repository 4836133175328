import { FC, ReactNode, useEffect, useState } from "react";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import kebabCase from "lodash/kebabCase";

import ChannelsGrid from "../ChannelsGrid";
import ChannelsPreview from "../ChannelsPreview";
import ChevronDown from "../../assets/icons/ChevronDown";

export type PaymentMethodListItem = {
  channels: PaymentMethodListItemChannel[];
  icon?: FC;
  displayName: string;
  showNewBadge?: boolean;
  renderContent?: () => ReactNode;
  value: string;
};
export type PaymentMethodListItemChannel = {
  disabled?: boolean;
  displayName: string;
  label: string;
  isAdditionalChannel?: boolean;
  logoUrl: string;
  name: string;
  tooltipMessage?: ReactNode;
};

type Props = {
  items: PaymentMethodListItem[];
  onSelectChannel: (channel: string) => void;
  onSelectPaymentMethodType: (paymentMethodType: string) => void;
  value: string;
};

const COLLAPSE_EXPAND_ANIMATION_DURATION_MS = 200;

const PaymentMethodList: FC<Props> = (props) => {
  const itemsWithChannels = props.items.filter(
    (item) => !isEmpty(item.channels)
  );

  return (
    <Accordion.Root
      className="w-full border-.5 border-xen-gray-300 rounded-md divide-y-.5 divide-xen-gray-300"
      collapsible
      onValueChange={props.onSelectPaymentMethodType}
      type="single"
      value={props.value}
    >
      {itemsWithChannels.map((item) => {
        return (
          <PaymentMethodListItem
            {...item}
            key={item.value}
            collapsed={item.value !== props.value}
            onSelectChannel={props.onSelectChannel}
          />
        );
      })}
    </Accordion.Root>
  );
};

export default PaymentMethodList;

type PaymentMethodListItemProps = PaymentMethodListItem & {
  collapsed: boolean;
  onSelectChannel: (channel: string) => void;
};

const PaymentMethodListItem: FC<PaymentMethodListItemProps> = (props) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(false);
    if (!props.collapsed) {
      const readyTimeout = setTimeout(() => {
        setReady(true);
      }, COLLAPSE_EXPAND_ANIMATION_DURATION_MS);
      return () => {
        clearTimeout(readyTimeout);
      };
    }
  }, [props.collapsed]);

  const accordionItemContentClassName = classNames([
    "transition-all data-[state=open]:animate-accordion-down data-[state=closed]:animate-accordion-up",
    {
      "overflow-hidden": !ready
    }
  ]);

  return (
    <Accordion.Item
      key={props.value}
      className="first:rounded-t-md first:mt-0 last:rounded-b-md data-[state=open]:bg-xen-gray-100"
      value={props.value}
    >
      <Accordion.Header className="flex">
        <Accordion.Trigger
          className="p-4 flex flex-1 items-center justify-between [&[data-state=open]>span>svg>path]:first:stroke-primary [&[data-state=open]>span>svg>rect]:first:stroke-primary transition-all [&[data-state=open]>svg]:rotate-180 [&[data-state=open]>span>[data-role=preview]]:hidden"
          data-testid={`payment-channel-list-${kebabCase(
            props.value.toLowerCase()
          )}`}
        >
          {props.icon ? (
            <span className="pr-1.5">
              <props.icon />
            </span>
          ) : null}
          <span className="pr-2 w-full flex items-center justify-between">
            <span className="font-semibold text-base text-xen-black-400 text-left">
              {props.displayName}
              {props.showNewBadge ? (
                <span
                  className="text-xs text-xen-blue-500 bg-xen-blue-100 px-1 py-0.5 rounded ml-1 sm:ml-3 align-middle"
                  style={{ width: "fit-content" }}
                >
                  New
                </span>
              ) : null}
            </span>
            <ChannelsPreview channels={props.channels} />
          </span>
          <ChevronDown className="transition-transform duration-200 w-6 h-6" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content className={accordionItemContentClassName}>
        {props.renderContent?.() || (
          <div className="px-10 pt-0 pb-3 sm:px-2 sm:py-3 flex justify-center">
            <ChannelsGrid
              channels={props.channels}
              onSelectChannel={props.onSelectChannel}
            />
          </div>
        )}
      </Accordion.Content>
    </Accordion.Item>
  );
};
