import { CountriesEnum } from "@xendit/checkout-utilities";

export const APP_ENV_MODE = import.meta.env.VITE_APP_ENV_MODE;

export const APP_VERSION = import.meta.env.VITE_CHECKOUT_APP_VERSION;

export const CHECKOUT_UI_GATEWAY_URL = import.meta.env
  .VITE_CHECKOUT_UI_GATEWAY_URL;

export const ENABLE_RUM = import.meta.env.VITE_ENABLE_RUM === "true";

export const ENABLE_SIMULATION =
  import.meta.env.VITE_ENABLE_SIMULATION === "true";
export const BASE_ASSETS_URL = import.meta.env.BASE_URL || "/";

export const CHECKOUT_UI_GATEWAY_REQUEST_ORIGIN = "CHECKOUT_UI";

export const GROWTHBOOK_API_HOST = "https://cdn.growthbook.io";

export const GROWTHBOOK_CLIENT_KEY = import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY;

export const GROWTHBOOK_ENABLE_DEV_MODE = import.meta.env
  .VITE_GROWTHBOOK_ENABLE_DEV_MODE;

export const ALLOW_SESSION_MOCKS =
  import.meta.env.VITE_ALLOW_SESSION_MOCKS === "true";

export const LOG_I18N_WARNINGS =
  import.meta.env.VITE_LOG_I18N_WARNINGS === "true";

export const MAX_POLLING_RETRIES =
  import.meta.env.VITE_MAX_POLLING_RETRIES || 7;

export const MOBILE_BANKING_CHANNEL_CODE_REGEX = new RegExp(/\b\w+_MB\b/);

export const MOBILE_PHONE_COUNTRY_CODE: Record<string, string> = {
  [CountriesEnum.Indonesia.toUpperCase()]: "+62",
  [CountriesEnum.Thailand.toUpperCase()]: "+66"
  // Add more countries here
};

export const API = {
  getInitialData: (id: string) => `/api/invoices/${id}/init`,
  getInvoice: (id: string) => `/api/invoices/${id}`,
  establishRealtimeUpdates: (id: string) => `/api/realtime/invoices/${id}`,

  // unified payment api
  createPaymentRequest: "/api/payment/payment-requests",
  simulatePaymentMethod: (paymentMethodId: string) =>
    `/api/payment/payment-methods/${paymentMethodId}/simulate`,

  // paylater
  initiatePlans: "/api/paylater/plans",
  createPaylaterCharge: "/api/paylater/charges",
  getPaylaterCustomer: "/api/paylater/customers",

  // virtual account
  createPoolVirtualAccount: (channelCode: string) =>
    `/api/virtual-accounts/pool/${channelCode}`,
  createFixedVirtualAccount: (channelCode: string) =>
    `/api/virtual-accounts/fixed/${channelCode}`,
  simulateVirtualAccountPayment: (channelCode: string) =>
    `/api/virtual-accounts/simulate/${channelCode}`,

  // over the counter
  createOtcPaymentCode: (channelCode: string) =>
    `/api/retail-outlets/${channelCode}`,
  simulateOtcPayment: (channelCode: string) =>
    `/api/retail-outlets/${channelCode}/simulate`,

  // credit card cybs charge
  createCardCybsCharge: "/api/payments/credit-card",
  getCreditCardChargeOptions: "/api/credit-card/charge-options?",

  // credit card signature
  createSignature: "/api/credit-card/signature",

  // direct debit auth
  authorizePaymentMethod: (id: string) =>
    `/api/payment/payment-methods/${id}/auth`,

  // on demand
  getOnDemandInitialData: (onDemandLink: string) =>
    `/api/on-demand/${onDemandLink}`,
  createInvoiceFromOnDemand: (onDemandLink: string) =>
    `/api/on-demand/${onDemandLink}/invoice`,

  // recurring
  getRecurringInitialData: (recurringId: string) =>
    `/api/recurring/${recurringId}`,
  getRecurringInvoices: (recurringId: string) =>
    `/api/recurring/${recurringId}/invoices?`
};

export const DEFAULT_XENDIT_LOGO =
  "https://du8nwjtfkinx.cloudfront.net/xendit.png";
