const PaymentInstructionsTabsLoadingSkeleton = () => {
  return (
    <div className="pt-2 lg:pt-8 pb-12 px-4 lg:px-0 max-w-screen-sm mx-auto">
      <div className="animate-pulse">
        <div className="mt-6">
          <div className="flex flex-col-reverse sm:flex-row sm:items-center space-y-4 sm:space-x-4 space-y-reverse p-6 sm:p-8 border-[0.5px] border-xen-gray-300 rounded-sm">
            <div className="basis-1/2">
              <div className="space-y-6 flex flex-col sm:pr-12">
                <div className="space-y-2">
                  <div className="h-4 w-40 bg-xen-gray-200 rounded"></div>
                  <div className="h-8 bg-xen-gray-200 rounded"></div>
                </div>
                <div className="space-y-2">
                  <div className="h-4 w-40 bg-xen-gray-200 rounded"></div>
                  <div className="h-8 bg-xen-gray-200 rounded"></div>
                </div>
                <div className="space-y-2">
                  <div className="h-4 w-40 bg-xen-gray-200 rounded"></div>
                  <div className="h-8 bg-xen-gray-200 rounded"></div>
                </div>
              </div>
            </div>
            <div className="basis-1/2 self-center sm:w-1/2">
              <div className="h-8 sm:h-24 w-24 sm:w-auto bg-xen-gray-200 rounded"></div>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <div>
            <div className="flex space-x-4">
              <div className="h-7 sm:h-8 w-32 bg-xen-gray-200 rounded"></div>
              <div className="h-7 sm:h-8 w-32 bg-xen-gray-200 rounded"></div>
            </div>
          </div>
          <div className="space-y-6 mt-10">
            <div>
              <div className="space-y-3">
                <div className="h-4 bg-xen-gray-200 rounded"></div>
                <div className="h-4 bg-xen-gray-200 rounded"></div>
                <div className="h-4 bg-xen-gray-200 rounded"></div>
              </div>
            </div>
            <div>
              <div className="space-y-3">
                <div className="h-4 bg-xen-gray-200 rounded"></div>
                <div className="h-4 bg-xen-gray-200 rounded"></div>
                <div className="h-4 bg-xen-gray-200 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInstructionsTabsLoadingSkeleton;
