import { Country } from "country-state-city";
import { FC } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { withMask } from "use-mask-input";
import classNames from "classnames";
import * as yup from "yup";

import { ArrowLeft } from "../../assets/icons";
import Button from "../Button";
import Dropdown, { DropdownOption } from "../Dropdown";
import FormField from "../FormField";
import Input from "../Input";
import MobileFooter from "../MobileFooter";

export type CustomerFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
};

type CountryOption = {
  label: string;
  value: string;
};

type Props = {
  countryCode?: string | number | null;
  defaultCountry?: string;
  initialCustomerData?: CustomerFormValues | null;
  onCancel: () => void;
  onSubmit: (values: CustomerFormValues) => Promise<void>;
  shouldRenderMobileFooter?: boolean;
};

const CustomerForm: FC<Props> = (props) => {
  const { t } = useTranslation(["forms", "common"]);
  const {
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    submitForm,
    values
  } = useFormik<CustomerFormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      city: "",
      postalCode: "",
      mobileNumber: "",
      ...props.initialCustomerData,
      country: props.defaultCountry || "ID"
    },
    onSubmit: async (values) => {
      await props.onSubmit(values);
    },
    validationSchema: yup.object({
      firstName: yup
        .string()
        .required(t("{{field}} is required", { field: t("First Name") })),
      lastName: yup
        .string()
        .required(t("{{field}} is required", { field: t("Last Name") })),
      email: yup
        .string()
        .email(t("{{field}} is not valid", { field: t("Email Address") }))
        .matches(
          /^.*@.+\..+$/,
          t("{{field}} is not valid", { field: t("Email Address") })
        )
        .required(t("{{field}} is required", { field: t("Email Address") })),
      mobileNumber: yup
        .string()
        .required(t("{{field}} is required", { field: t("Mobile Number") })),
      address: yup
        .string()
        .required(t("{{field}} is required", { field: t("Address") })),
      city: yup
        .string()
        .required(t("{{field}} is required", { field: t("City/District") })),
      postalCode: yup
        .string()
        .required(t("{{field}} is required", { field: t("Postal Code") })),
      country: yup
        .string()
        .length(2, t("{{field}} is not valid", { field: t("Country") }))
        .required(t("{{field}} is required", { field: t("Country") }))
    })
  });

  const buttonsContainerClassName = classNames([
    "justify-center space-x-2",
    {
      flex: !props.shouldRenderMobileFooter,
      "hidden md:flex": props.shouldRenderMobileFooter
    }
  ]);

  return (
    <div className="pt-6">
      <h3 className="font-semibold uppercase mb-4">
        {t("Customer Information", { ns: "common" })}
      </h3>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col space-y-4 pb-6">
          <div className="flex -mx-2">
            <div className="w-1/2 px-2">
              <FormField
                label={t("First Name")}
                name="firstName"
                helpText={errors.firstName}
                state={errors.firstName ? "error" : "default"}
                required
              >
                <Input
                  block
                  autoComplete="given-name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  hasError={!!errors.firstName}
                  placeholder="John"
                  data-testid="first-name"
                  disabled={isSubmitting}
                />
              </FormField>
            </div>
            <div className="w-1/2 px-2">
              <FormField
                label={t("Last Name")}
                name="lastName"
                helpText={errors.lastName}
                state={errors.lastName ? "error" : "default"}
                required
              >
                <Input
                  block
                  autoComplete="family-name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  hasError={!!errors.lastName}
                  placeholder="Doe"
                  data-testid="last-name"
                  disabled={isSubmitting}
                />
              </FormField>
            </div>
          </div>
          <div className="flex -mx-2">
            <div className="w-1/2 px-2">
              <FormField
                label={t("Email Address")}
                name="email"
                helpText={errors.email}
                state={errors.email ? "error" : "default"}
                required
              >
                <Input
                  block
                  type="email"
                  autoComplete="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  hasError={!!errors.email}
                  placeholder="abc@gmail.com"
                  data-testid="email"
                  disabled={isSubmitting}
                />
              </FormField>
            </div>
            <div className="w-1/2 px-2">
              <FormField
                label={t("Mobile Number")}
                name="mobileNumber"
                helpText={errors.mobileNumber}
                state={errors.mobileNumber ? "error" : "default"}
                required
              >
                <Input
                  block
                  type="tel"
                  autoComplete="tel"
                  name="mobileNumber"
                  value={values.mobileNumber}
                  onChange={handleChange}
                  hasError={!!errors.mobileNumber}
                  placeholder={`${props.countryCode} 812 345 6789`}
                  data-testid="mobile-number"
                  ref={withMask(`${props.countryCode} 999 999 99999`, {
                    placeholder: "",
                    onBeforePaste: (value: string) =>
                      !value.startsWith("+") ? `+${value}` : value
                  })}
                  disabled={isSubmitting}
                />
              </FormField>
            </div>
          </div>
          <FormField
            label={t("Address")}
            name="address"
            helpText={errors.address}
            state={errors.address ? "error" : "default"}
            required
          >
            <Input
              block
              autoComplete="street-address"
              name="address"
              value={values.address}
              onChange={handleChange}
              hasError={!!errors.address}
              placeholder={t("Address")}
              data-testid="address-1"
              disabled={isSubmitting}
            />
          </FormField>
          <div className="flex -mx-2">
            <div className="w-1/2 px-2">
              <FormField
                label={t("City/District")}
                name="city"
                helpText={errors.city}
                state={errors.city ? "error" : "default"}
                required
              >
                <Input
                  block
                  autoComplete="address-level2"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  hasError={!!errors.city}
                  placeholder={t("City/District")}
                  data-testid="city"
                  disabled={isSubmitting}
                />
              </FormField>
            </div>
            <div className="w-1/2 px-2">
              <FormField
                label={t("Postal Code")}
                name="postalCode"
                helpText={errors.postalCode}
                state={errors.postalCode ? "error" : "default"}
                required
              >
                <Input
                  block
                  type="text"
                  autoComplete="postal-code"
                  name="postalCode"
                  value={values.postalCode}
                  onChange={handleChange}
                  hasError={!!errors.postalCode}
                  placeholder={t("Postal Code")}
                  data-testid="zipcode"
                  disabled={isSubmitting}
                />
              </FormField>
            </div>
          </div>
          <FormField
            label={t("Country")}
            name="country"
            helpText={errors.country}
            state={errors.country ? "error" : "default"}
            required
          >
            <Dropdown
              block
              name="country"
              data-testid="country"
              disabled
              onChange={(selection) => {
                setFieldValue("country", (selection as CountryOption).value);
              }}
              value={values.country}
              options={Country.getAllCountries().map<CountryOption>(
                (country) => ({
                  label: `${country.flag} ${country.name}`,
                  value: country.isoCode
                })
              )}
              keyExtractor={(country) => (country as CountryOption).value}
              renderOption={(country, helpers) => (
                <DropdownOption {...helpers}>
                  {(country as CountryOption).label}
                </DropdownOption>
              )}
              renderSelection={(countryIsoCode) => {
                const country = Country.getCountryByCode(
                  countryIsoCode as string
                );
                if (!country) {
                  return t("Country");
                }
                return `${country.flag} ${country.name}`;
              }}
            />
          </FormField>
        </div>

        <div className={buttonsContainerClassName}>
          <Button
            variant="brand-secondary"
            outline
            type="button"
            onClick={props.onCancel}
            className="md:w-36"
            data-testid="back"
          >
            <span className="hidden md:inline">
              {t("Back", { ns: "common" })}
            </span>
            <span className="md:hidden">
              <ArrowLeft />
            </span>
          </Button>
          <Button
            variant="brand-secondary"
            type="submit"
            disabled={isSubmitting}
            className="flex-1 md:flex-initial md:w-36"
            data-testid="continue"
          >
            {t("Continue", { ns: "common" })}
          </Button>
        </div>
        {props.shouldRenderMobileFooter ? (
          <MobileFooter>
            <div className="flex space-x-4">
              <Button
                type="button"
                onClick={props.onCancel}
                variant="brand-secondary"
                outline
                className="flex-shrink-0"
              >
                <ArrowLeft />
              </Button>
              <Button
                id="pay-now-button"
                type="button"
                onClick={submitForm}
                variant="brand-secondary"
                className="flex-1"
                disabled={isSubmitting}
              >
                {t("Continue", { ns: "common" })}
              </Button>
            </div>
          </MobileFooter>
        ) : null}
      </form>
    </div>
  );
};

export default CustomerForm;
