import { FC, useEffect, useRef, useReducer } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import useHover from "../../hooks/use-hover";
import ChevronDown from "../../assets/icons/ChevronDown";
import useEventListener from "../../hooks/use-event-listener";

const LANGUAGE_LABELS: { [language: string]: string } = {
  en: "English",
  id: "Bahasa Indonesia",
  th: "ภาษาไทย",
  vi: "Tiếng Việt"
};

const initialState = {
  expanded: false
};

type LanguageNavigationAction = { type: "set_expanded"; payload: boolean };

const reducer = (
  state: typeof initialState,
  action: LanguageNavigationAction
) => {
  switch (action.type) {
    case "set_expanded":
      return {
        ...state,
        expanded: action.payload
      };
    default:
      throw Error("<LanguageNavigation /> Unknown action: " + action);
  }
};

type Props = {
  onChange?: (locale: string) => unknown;
};

const LanguageNavigation: FC<Props> = (props) => {
  const { i18n } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const overlay = state.expanded ? "open" : "closed";

  const overlayRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const isTriggerHovered = useHover(triggerRef);
  const isOverlayHovered = useHover(overlayRef);

  const handleSelectLocale = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = (e.target as HTMLButtonElement).dataset;
    const selectedLocale = i18n.languages.find(
      (language) => language === value
    );

    if (selectedLocale) {
      i18n.changeLanguage(selectedLocale);
      dispatch({ type: "set_expanded", payload: false });
      props.onChange?.(selectedLocale);
      return;
    }

    throw Error("Unmatched locale value: " + value);
  };

  // handle keyboard and touch interactions
  const handleEventClick = () => {
    dispatch({ type: "set_expanded", payload: !state.expanded });
  };
  useEventListener("click", handleEventClick, triggerRef);

  // default interactions
  useEffect(() => {
    if (isTriggerHovered !== state.expanded) {
      dispatch({ type: "set_expanded", payload: true });
    }

    if (!isOverlayHovered) {
      dispatch({ type: "set_expanded", payload: false });
    }
  }, [isTriggerHovered, isOverlayHovered]);

  return (
    <div
      ref={overlayRef}
      className="relative inline-flex justify-center"
      data-testid="language-navigation"
    >
      <div className="relative">
        <div
          ref={triggerRef}
          className={`flex justify-center rounded-md relative ${
            state.expanded ? "bg-xen-gray-200" : "bg-xen-gray-100"
          }`}
        >
          <button
            className="p-2 flex items-center gap-1 text-sm"
            data-state={overlay}
            type="button"
            aria-expanded={state.expanded}
            aria-label="Switch language"
            data-testid="select-language"
          >
            {LANGUAGE_LABELS[i18n.language] || i18n.language}
            <ChevronDown height={12} width={12} />
          </button>
          {state.expanded ? (
            <div className="absolute h-3 flex items-end justify-center top-full">
              <div className="relative w-3.5 h-3.5 top-3/4 bg-xen-gray-100 rotate-45 drop-shadow-lg"></div>
            </div>
          ) : null}
        </div>
        <div className="absolute flex justify-center top-full right-0">
          {state.expanded ? (
            <div className="relative w-36 mt-3 bg-xen-gray-100 rounded-md shadow-lg z-10">
              <ul className="py-1 grid grid-flow-row grid-rows-2">
                {Object.entries(LANGUAGE_LABELS).map(([language, label]) => {
                  return (
                    <li key={language}>
                      <button
                        className={classnames([
                          "w-full cursor-pointer text-left text-sm h-10 px-3 hover:text-primary",
                          {
                            "text-primary": language === i18n.language
                          }
                        ])}
                        onClick={handleSelectLocale}
                        type="button"
                        data-value={language}
                      >
                        {label}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LanguageNavigation;
