import { CurrenciesEnum } from "@xendit/checkout-utilities/dist/src/enums/currencies";
import { FC } from "react";
import { formatDisplayAmountWithCurrencyCode } from "@xendit/checkout-utilities/dist/src/amount-formatter";
import { getCountryCodeByCurrency } from "@xendit/checkout-utilities/dist/src/country-of-operations";
import { PaylaterChannelsEnum } from "@xendit/checkout-utilities/dist/src/enums/payment-channels";
import { PaymentChannelsPropertiesType } from "@xendit/checkout-utilities/dist/src/types/payment-channels";
import { useTranslation } from "react-i18next";
import times from "lodash/times";
import kebabCase from "lodash/kebabCase";

import { ENABLE_SIMULATION } from "../../utils/constants";

import { usePaymentLink } from "../../contexts/PaymentLinkContext";
import { usePaymentMethod } from "../../contexts/PaymentMethodContext";

import { evaluateAmountRange } from "../../helpers/amount-validation";
import {
  mapCustomerDataToFormValues,
  paylaterInformationLinks
} from "../../helpers/paylater";

import Button from "../../components/Button";
import CustomerForm from "../../components/CustomerForm";
import Dialog from "../../components/Dialog";
import Dropdown, { DropdownOption } from "../../components/Dropdown";
import FormField from "../../components/FormField";
import InstallmentOptions from "../../components/InstallmentOptions";
import PaymentView from "../../components/PaymentView";
import SimulationBar from "../../components/SimulationBar";
import Tooltip from "../../components/Tooltip";
import RollingLoader from "../../components/RollingLoader";
import FraudWarningNotes from "../../components/FraudWarningNotes";

import usePaylater from "../../hooks/use-paylater";

type Props = {
  channels: PaymentChannelsPropertiesType[];
};

const PaymentPaylaterAccordion: FC<Props> = (props) => {
  const {
    creatingCharge,
    error,
    fetchingCustomer,
    initiatingPlans,
    onCreateCharge,
    onDismissError,
    onInitiatePlans,
    onPaymentChannelSelected,
    paylaterCountryCode,
    paylaterPlans,
    shouldShowPaylaterInstallmentOptions,
    showCustomerForm,
    showError
  } = usePaylater();
  const {
    customer,
    paymentLink: {
      invoice: { amount, currency }
    }
  } = usePaymentLink();
  const { clearPaymentChannel, paymentChannel } = usePaymentMethod();
  const { t } = useTranslation(["paylater", "common"]);

  const channels = props.channels.map((channel) => {
    const amountRange = evaluateAmountRange(amount, currency, channel);

    return {
      channel,
      disabled: !amountRange.isWithinAmountRange,
      tooltipMessage: !amountRange.isWithinAmountRange
        ? t(
            "The accepted amount range is {{minimumAmount}} — {{maximumAmount}}",
            {
              minimumAmount: formatDisplayAmountWithCurrencyCode(
                amountRange.minimumAmount,
                currency
              ),
              maximumAmount: formatDisplayAmountWithCurrencyCode(
                amountRange.maximumAmount,
                currency
              ),
              ns: "common"
            }
          )
        : null
    };
  });

  type PaylaterOption = (typeof channels)[0];

  const shouldRenderSimulationBar = ENABLE_SIMULATION && !!paymentChannel;

  return (
    <>
      {shouldRenderSimulationBar ? <SimulationBar /> : null}
      {!!paymentChannel && showCustomerForm ? (
        <div className="lg:px-4">
          <PaymentView
            header
            paymentChannelLogoUrl={paymentChannel.logo_url}
            paymentChannelName={paymentChannel.display_name}
          >
            <CustomerForm
              countryCode={getCountryCodeByCurrency(currency as CurrenciesEnum)}
              defaultCountry={paylaterCountryCode || "ID"}
              initialCustomerData={mapCustomerDataToFormValues(customer)}
              onCancel={clearPaymentChannel}
              onSubmit={(values) => onInitiatePlans(paymentChannel, values)}
            />
          </PaymentView>
        </div>
      ) : (
        <div className="py-6 flex justify-center">
          <div className="w-full max-w-md px-4 flex flex-col space-y-6">
            <FormField label={t("Partner")}>
              <Dropdown
                data-testid="select-paylater-partner"
                options={channels}
                keyExtractor={(option) =>
                  (option as PaylaterOption).channel.channel
                }
                value={paymentChannel}
                onChange={(channel) =>
                  onPaymentChannelSelected((channel as PaylaterOption).channel)
                }
                block
                placeholder={t("Select Partner")}
                renderOption={(option, helpers) => {
                  const { tooltipMessage, channel } = option as PaylaterOption;

                  return (
                    <Tooltip
                      contentChildren={tooltipMessage}
                      delayDuration={0}
                      side="bottom"
                      asChild
                    >
                      <div className="w-full">
                        <DropdownOption
                          {...helpers}
                          className="flex items-center space-x-2"
                          data-testid={kebabCase(channel.label.toLowerCase())}
                        >
                          {channel.logo_url ? (
                            <div className="w-8 h-6 bg-white rounded flex items-center justify-center">
                              <img
                                src={channel.logo_url}
                                alt={channel.label}
                                className="w-7 h-5"
                              />
                            </div>
                          ) : null}
                          <span>{channel.display_name}</span>
                        </DropdownOption>
                      </div>
                    </Tooltip>
                  );
                }}
                renderSelection={(selection) => {
                  const { logo_url, display_name, label } =
                    selection as PaymentChannelsPropertiesType;

                  return (
                    <div className="flex items-center space-x-2">
                      {logo_url ? (
                        <div className="w-8 h-6 bg-white rounded flex items-center justify-center">
                          <img src={logo_url} alt={label} className="w-7 h-5" />
                        </div>
                      ) : null}
                      <span>{display_name}</span>
                    </div>
                  );
                }}
                optionDisabled={(option) => (option as PaylaterOption).disabled}
              />
            </FormField>

            {shouldShowPaylaterInstallmentOptions &&
            paylaterPlans?.options &&
            paylaterPlans.options.length > 0 ? (
              <InstallmentOptions
                options={paylaterPlans.options.map((option) => ({
                  installmentAmount: option.installment_amount,
                  interval: option.interval,
                  intervalCount: option.interval_count,
                  totalAmount: option.total_amount,
                  totalRecurrence: option.total_recurrence
                }))}
                currency={currency}
                informationLink={
                  paylaterInformationLinks[
                    paymentChannel?.channel as PaylaterChannelsEnum
                  ]
                }
              />
            ) : null}

            {paylaterPlans ? (
              <div className="space-y-4">
                {!shouldShowPaylaterInstallmentOptions ? (
                  <p>
                    Please click &ldquo;<strong>Continue</strong>&rdquo; to see
                    the installment options on the{" "}
                    {paymentChannel?.display_name} page.
                  </p>
                ) : null}
                <div>
                  <Button
                    block
                    variant="brand-secondary"
                    disabled={creatingCharge}
                    onClick={onCreateCharge}
                    data-testid="continue"
                  >
                    {t(creatingCharge ? "Please wait" : "Continue", {
                      ns: "common"
                    })}
                  </Button>
                </div>
                <p className="text-sm leading-6 text-xen-gray-800">
                  {t(
                    "By clicking Continue, you will be re-directed to the chosen Partner's website for you to avail of the Partner's PayLater service."
                  )}
                </p>
              </div>
            ) : null}

            {fetchingCustomer ? (
              <RollingLoader className="h-12 w-12 self-center text-primary" />
            ) : null}

            {/* loading skeleton */}
            {initiatingPlans ? (
              <div className="flex flex-col space-y-6">
                <div className="h-8 bg-xen-gray-200 rounded"></div>
                <div className="flex flex-col space-y-2">
                  {times(4, (index) => {
                    return (
                      <div key={index} className="flex -mx-1">
                        <div className="px-1 w-1/5">
                          <div className="h-8 bg-xen-gray-200 rounded"></div>
                        </div>
                        <div className="px-1 w-2/5">
                          <div className="h-8 bg-xen-gray-200 rounded"></div>
                        </div>
                        <div className="px-1 w-2/5">
                          <div className="h-8 bg-xen-gray-200 rounded"></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="h-10 bg-xen-gray-200 rounded"></div>
              </div>
            ) : null}
          </div>
        </div>
      )}

      <Dialog
        open={!!showError}
        title={error?.title}
        description={error?.body}
        buttons={[
          {
            text: "OK, Got it!",
            variant: "brand-secondary",
            onClick: () => {
              clearPaymentChannel();
              onDismissError();
            }
          }
        ]}
      />
    </>
  );
};

export default PaymentPaylaterAccordion;
