import { FC, SVGProps } from "react";

const OtherBanks: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M5.25 6.375H3.417v6.417H5.25V6.375z"
        fill="currentColor"
        fillOpacity={0.2}
      />
      <path
        d="M4.917 6.708H3.75v5.75h1.167v-5.75z"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path
        d="M8.917 6.375H7.083v6.417h1.834V6.375z"
        fill="currentColor"
        fillOpacity={0.2}
      />
      <path
        d="M8.583 6.708H7.417v5.75h1.166v-5.75z"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path
        d="M12.583 6.375H10.75v6.417h1.833V6.375z"
        fill="currentColor"
        fillOpacity={0.2}
      />
      <path
        d="M12.25 6.708h-1.167v5.75h1.167v-5.75z"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path
        d="M13.959 12.333H2.042v1.833h11.917v-1.833z"
        fill="currentColor"
        fillOpacity={0.2}
      />
      <path
        d="M13.625 12.666H2.375v1.167h11.25v-1.167z"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path
        d="M13.959 5H2.042v1.833h11.917V5z"
        fill="currentColor"
        fillOpacity={0.2}
      />
      <path
        d="M13.625 5.333H2.375v1.166h11.25V5.333z"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path
        d="M13.252 5.125H2.748a.32.32 0 0 1-.324-.255.32.32 0 0 1 .17-.375l5.253-2.708a.335.335 0 0 1 .306 0l5.252 2.708a.32.32 0 0 1 .171.375.32.32 0 0 1-.324.255z"
        fill="currentColor"
        fillOpacity={0.2}
      />
      <path
        d="M8 2.083 2.748 4.791h10.504L8 2.083zm0-.667c.105 0 .21.025.306.075l5.252 2.708c.621.32.393 1.259-.306 1.259H2.748c-.7 0-.927-.939-.306-1.26l5.252-2.707A.662.662 0 0 1 8 1.416z"
        fill="currentColor"
      />
    </svg>
  );
};

export default OtherBanks;
