import { createPortal } from "react-dom";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useSimulation } from "../../contexts/SimulationContext";

const SimulationBar: FC = () => {
  const { t } = useTranslation("common");

  const {
    onSimulatePayment,
    paymentChannelDisplayName,
    shouldShowCompletedMessage,
    shouldShowSimulateButton,
    shouldShowSimulatingMessage
  } = useSimulation();

  const portal = document.getElementById("simulation-bar-portal");

  if (!portal) {
    return null;
  }

  return createPortal(
    <div className="bg-xen-red-500 text-center py-1.5 px-4">
      <p className="text-white font-semibold text-base">
        {t(
          "You are in Test Mode and any transactions made are simulated and not real."
        )}
      </p>
      {shouldShowSimulatingMessage ? (
        <p className="text-white font-semibold text-base mt-0.5 pb-px">
          {t("Please wait until the process is done.")}
        </p>
      ) : null}
      {shouldShowCompletedMessage ? (
        <p className="text-white font-semibold text-base mt-0.5 pb-px">
          {t(
            "Payment simulation completed, the page will be updated automatically."
          )}
        </p>
      ) : null}
      {shouldShowSimulateButton ? (
        <button
          type="button"
          className="p-0 text-white font-semibold text-base underline focus:outline-none"
          onClick={() => onSimulatePayment()}
          data-testid="simulate-button"
        >
          {t("Click here to simulate your payment with {{channel}}", {
            channel: paymentChannelDisplayName
          })}
        </button>
      ) : null}
    </div>,
    portal
  );
};

export default SimulationBar;
