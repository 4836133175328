export const makePollingLocalUrl = (
  sessionId: string,
  token_request_id: string
) => {
  return `/session/${sessionId}?token_request_id=${token_request_id}`;
};

export const makeActiveSessionLocalUrl = (sessionId: string) => {
  return `/session/${sessionId}`;
};
