/**
 * This function will handle cases like
 * +08571293891 → +628571293891
 * 08571293891 → +628571293891
 * +628571293891 → +628571293891 (already correct, no change)
 * @param mobileNumber
 * @param countryCode
 * @returns {string}
 */
export const formatToE164 = (
  mobileNumber: string,
  countryCode = "62"
): string => {
  // Remove all non-digit characters
  const digits = mobileNumber.replace(/\D/g, "");

  // Remove leading zeros
  const trimmed = digits.replace(/^0+/, "");

  // If the number starts with the country code, return it as is
  if (trimmed.startsWith(countryCode)) {
    return `+${trimmed}`;
  }

  // Otherwise, add the country code
  return `+${countryCode}${trimmed}`;
};
