import { PropertiesFormFieldsType } from "@xendit/checkout-utilities/dist/src/types/payment-channels";

import { getYupValidationSchema } from "../utils/yup-validations";
import { prependCountryCode } from "../utils/strings";
import { CountriesEnum } from "@xendit/checkout-utilities";
import { InitialCustomerDataValues } from "../components/DirectDebitForm";

export const getValidationSchema = (
  fields: Array<PropertiesFormFieldsType>
) => {
  return fields ? fields.reduce(getYupValidationSchema, {}) : {};
};

export const getFormFieldsInitialValues = (
  fields: Array<PropertiesFormFieldsType>,
  customer?: InitialCustomerDataValues | null
): Record<string, string> => {
  const initialValues: Record<string, string> = {};

  fields.forEach((data: PropertiesFormFieldsType) => {
    switch (data.name) {
      case "mobileNumber":
        initialValues[data.name] = customer?.mobile_number || data.value || "";
        break;
      default:
        initialValues[data.name] = data.value || "";
        break;
    }
  });

  return initialValues;
};

export const getPaymentMethodChannelProperties = (
  fields: Array<PropertiesFormFieldsType>,
  countryOfOperation: CountriesEnum,
  values: Record<string, string>
): { payment_method_channel_properties: Record<string, string> } => {
  const paymentMethodChannelProperties: Record<string, string> = {};

  fields.forEach((data: PropertiesFormFieldsType) => {
    const { key, name } = data;
    paymentMethodChannelProperties[key] =
      name === "mobileNumber"
        ? prependCountryCode(values[name], countryOfOperation)
        : values[name];
  });

  return {
    payment_method_channel_properties: paymentMethodChannelProperties
  };
};
