import type { PaymentChannelsPropertiesType } from "@xendit/checkout-utilities/dist/src/types/payment-channels";

export type AmountRangeEvaluation = {
  minimumAmount: number;
  maximumAmount: number;
  isWithinAmountRange: boolean;
};

const evaluateAmountRange = (
  amount: number,
  currency: string,
  channel: PaymentChannelsPropertiesType
): AmountRangeEvaluation => {
  const minAmountByCurrency =
    channel?.amount_by_currency &&
    channel?.amount_by_currency[currency]?.min_amount;
  const minimumAmountSetting = minAmountByCurrency || channel.min_amount;
  const minimumAmount = minimumAmountSetting?.validate_on_checkout
    ? minimumAmountSetting?.amount || 0
    : 0;

  const maxAmountByCurrency =
    channel?.amount_by_currency &&
    channel?.amount_by_currency[currency]?.max_amount;
  const maximumAmountSetting = maxAmountByCurrency || channel.max_amount;
  const maximumAmount = maximumAmountSetting?.validate_on_checkout
    ? maximumAmountSetting?.amount || 0
    : 0;

  return {
    minimumAmount,
    maximumAmount,
    isWithinAmountRange:
      amount >= minimumAmount &&
      (maximumAmount ? amount <= maximumAmount : true)
  };
};

export { evaluateAmountRange };
