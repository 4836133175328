import { FC, SVGProps } from "react";

const RollingLoader: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" {...props}>
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="currentColor"
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
        opacity="0.5"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="0.66s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
};

export default RollingLoader;
