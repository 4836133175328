interface QRComponent {
  qrDataId: string;
  qrValueLength: string;
  qrValue: string | QRComponent[];
}

function parseQrComponent(inputString: string): QRComponent[] {
  const qrComponents: QRComponent[] = [];

  let i = 0;

  while (i < inputString.length) {
    // Get the current component substring
    const qrDataId: string = inputString.slice(i, i + 2);

    // Move the index to the next 2 characters (qrValueLength)
    i += 2;
    const qrValueLength: string = inputString.slice(i, i + 2);

    // Move the index to the next length specified by qrValueLength
    i += 2;
    const qrValue: string = inputString.slice(
      i,
      i + parseInt(qrValueLength, 10)
    );

    // Move the index to the next component
    i += parseInt(qrValueLength, 10);

    // Push the parsed component to the array
    qrComponents.push({
      qrDataId: qrDataId,
      qrValueLength: qrValueLength,
      qrValue: qrValue
    });
  }

  return qrComponents;
}

// To understand more about QR tags for QRIS can be found here:
// https://xendit.atlassian.net/wiki/spaces/D/pages/3090056015/QR+String+Definitions#%F0%9F%87%AE%F0%9F%87%A9-P2M-QRIS
const merchantAccountInformationQrTag = "51";
const nmidQrSubtag = "02";

export function getNMIDFromQrString(qrString: string): string | undefined {
  const initial = parseQrComponent(qrString);

  const merchantInformationComponent: QRComponent | undefined = initial.find(
    (qrComponent) => {
      return qrComponent.qrDataId === merchantAccountInformationQrTag;
    }
  );

  if (merchantInformationComponent) {
    const merchantInformation = parseQrComponent(
      merchantInformationComponent.qrValue as string
    );

    const merchantAccountInformation: QRComponent | undefined =
      merchantInformation.find((qrComponent) => {
        return qrComponent.qrDataId === nmidQrSubtag;
      });

    if (merchantAccountInformation) {
      return merchantAccountInformation.qrValue as string;
    }
  }
}
