import React from "react";
import clx from "classnames";
import { useTranslation } from "react-i18next";
import { withMask } from "use-mask-input";
import { FormikProps } from "formik";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from "../Command";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import FormField from "../FormField";
import Input from "../Input";

import { countries, Country } from "../../helpers/countries";

export type MobileNumberInputProps = {
  defaultCountryCode: string;
  dialCodeFieldName: string;
  name?: string;
  noFormField?: boolean;
} & FormikProps<{ dialCode: string; mobileNumber: string }>;

const MobileNumberInput = (props: MobileNumberInputProps) => {
  const [open, setOpen] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState<
    Country | undefined
  >(() => countries.find((c) => c.iso2 === props.defaultCountryCode));

  const { t } = useTranslation("forms");

  const handleDialCodeChange = (country: Country) => {
    props.setFieldValue(props.dialCodeFieldName, country.dial_code);
    setSelectedCountry(country);
    setOpen(false);
  };

  const btnClass = clx([
    "w-max flex items-center text-lg h-10 rounded px-4 border border-xen-gray-400 transition outline-none border-1 rounded-r-none hover:text-white hover:border-primary hover:brightness-110 hover:!bg-primary focus:brightness-110 focus:ring-1 ring-primary",
    { "!bg-white text-xen-gray-900": !props.isSubmitting },
    { "bg-xen-gray-300 text-xen-gray-700": props.isSubmitting }
  ]);

  const renderInput = () => (
    <div className="flex w-full">
      <div>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <button
              // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
              role="combobox"
              aria-expanded={open}
              disabled={props.isSubmitting}
              data-testid="dial-code"
              className={btnClass}
            >
              {selectedCountry ? (
                <>
                  <span className="mr-2">{selectedCountry.flag}</span>
                  <span>+{selectedCountry.dial_code}</span>
                </>
              ) : (
                t("Select country")
              )}
            </button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0">
            <Command>
              <CommandInput
                placeholder={t("Search country")}
                data-testid="search-country"
              />
              <CommandList>
                <CommandEmpty>{t("No country found")}</CommandEmpty>
                <CommandGroup>
                  {countries.map((country) => (
                    <CommandItem
                      key={country.iso2}
                      data-testid={country.name}
                      onSelect={() => {
                        handleDialCodeChange(country);
                      }}
                    >
                      {country.flag} {country.name} +{country.dial_code}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
      <div className="w-full">
        <Input
          block
          type="tel"
          autoComplete="tel"
          name="mobileNumber"
          value={props.values.mobileNumber}
          onChange={(e) => {
            props.handleChange(e);

            // https://github.com/eduardoborges/use-mask-input/issues/62#issuecomment-1822592882
            const caret = e.target.selectionStart;
            const element = e.target;
            window.requestAnimationFrame(() => {
              element.selectionStart = caret;
              element.selectionEnd = caret;
            });
          }}
          onBlur={props.handleBlur}
          hasError={props.touched.mobileNumber && !!props.errors.mobileNumber}
          placeholder={t("Enter mobile number")}
          data-testid="mobile-number"
          ref={withMask("9999999999999999", {
            placeholder: "",
            autoUnmask: true
          })}
          disabled={props.isSubmitting}
          className="flex-1 rounded-l-none -ml-px hover:z-0 focus:z-0"
        />
      </div>
    </div>
  );

  if (props.noFormField) {
    return renderInput();
  }

  return (
    <div>
      <FormField
        label={t("Mobile Number")}
        name={props.name || "mobileNumber"}
        helpText={
          props.touched.mobileNumber && props.errors.mobileNumber
            ? t(props.errors.mobileNumber as string, {
                field: t("Mobile Number")
              })
            : undefined
        }
        state={
          props.touched.mobileNumber && props.errors.mobileNumber
            ? "error"
            : "default"
        }
      >
        {renderInput()}
      </FormField>
    </div>
  );
};

export default MobileNumberInput;
