import React from "react";
import { ENABLE_RUM } from "../../utils/constants";

export interface ErrorBoundaryProps {
  fallback: React.ReactNode;
  children: React.ReactNode;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public state: ErrorBoundaryState = {
    hasError: false,
    error: null
  };

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    if (ENABLE_RUM) {
      import("@datadog/browser-rum").then((datadog) => {
        const renderingError = new Error(error.message);
        renderingError.name = `ReactRenderingError`;
        renderingError.stack = info.componentStack;
        renderingError.cause = error;

        datadog.datadogRum.addError(renderingError);
      });
    }
    console.error("Unhandled Error:", error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
