import { FC } from "react";
import { useTranslation } from "react-i18next";
import { withMask } from "use-mask-input";
import { ArrowLeft } from "../../../../../../assets/icons";
import Button from "../../../../../../components/Button";
import FormField from "../../../../../../components/FormField";
import Header, {
  HeaderContent,
  HeaderImage,
  HeaderTitle
} from "../../../../../../components/Header";
import Input from "../../../../../../components/Input";
import { MainView } from "../../../../../../components/Layout";
import MobileFooter from "../../../../../../components/MobileFooter";
import PoweredByXendit from "../../../../../../components/PoweredByXendit";
import { DEFAULT_XENDIT_LOGO } from "../../../../../../utils/constants";
import { useSessionContext } from "../../../../../context/context";
import { CardChannelProperties, useCardFormik } from "./formik";

/**
 * Needs to be wrapped in React.Suspense in order to load the translations asynchronously.
 * Otherwise, an error will be thrown
 */
const CardForm: FC<{
  onSubmit: (values: CardChannelProperties) => Promise<void>;
  onCancel: () => void;
}> = (props) => {
  const { onCancel, onSubmit } = props;
  const { t } = useTranslation(["session", "common"]);

  // Context
  const { state } = useSessionContext();
  const { business, session, paymentMethods } = state;

  if (!business || !session || !paymentMethods) {
    throw new Error("There was a problem loading the state", {
      cause: { state }
    });
  }

  const {
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
    values,
    touched,
    isValid,
    dirty
  } = useCardFormik({
    onSubmit,
    optionalValidation: {
      allowedBins: session.channel_properties?.cards?.allowed_bins
    }
  });

  const renderBrandLogos = () => {
    const cardPaymentMethod = paymentMethods.find(
      (pm) => pm.pm_type === "CARDS"
    );

    const logoImages = cardPaymentMethod?.card?.brands.map((brand) => (
      <img
        className="w-9 h-6"
        key={brand.name}
        src={brand.logo_url}
        alt={brand.name}
      />
    ));

    return (
      <div className="flex space-x-1 justify-center my-2">{logoImages}</div>
    );
  };

  return (
    <MainView>
      <Header>
        <HeaderContent>
          <HeaderImage
            src={business.merchant_profile_picture_url ?? DEFAULT_XENDIT_LOGO}
            alt={business.name ?? ""}
          />
          <HeaderTitle>{business.name}</HeaderTitle>
        </HeaderContent>
      </Header>
      <section className="pt-8 pb-12 px-4 lg:px-0 max-w-screen-sm md:mx-auto">
        <p className="text-lg font-semibold text-center">
          {t("Save your card information")}
        </p>
        {renderBrandLogos()}
        <div className="pt-6">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-4 pb-6">
              <div className="md:w-full px-2 pb-0">
                <FormField
                  label={t("Cardholder Name")}
                  name="cardHolderName"
                  helpText={touched.cardHolderName ? errors.cardHolderName : ""}
                  state={
                    touched.cardHolderName && errors.cardHolderName
                      ? "error"
                      : "default"
                  }
                  required
                >
                  <Input
                    block
                    type="text"
                    aria-label="Cardholder Name"
                    aria-invalid={
                      touched.cardHolderName && !!errors.cardHolderName
                    }
                    autoComplete="cc-name"
                    name="cardHolderName"
                    value={values.cardHolderName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.cardHolderName && !!errors.cardHolderName}
                    placeholder="John Doe"
                  />
                </FormField>
              </div>
              <div className="md:w-full px-2 pb-0">
                <FormField
                  label={t("Card Number")}
                  name="cardNumber"
                  helpText={touched.cardNumber ? errors.cardNumber : ""}
                  state={
                    touched.cardNumber && errors.cardNumber
                      ? "error"
                      : "default"
                  }
                  required
                >
                  <Input
                    block
                    type="text"
                    inputMode="numeric"
                    aria-label="Card number"
                    aria-invalid={touched.cardNumber && !!errors.cardNumber}
                    autoComplete="cc-number"
                    autoCorrect="off"
                    name="cardNumber"
                    value={values.cardNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.cardNumber && !!errors.cardNumber}
                    placeholder="4000 0000 0000 1091"
                    ref={withMask("9999 9999 9999 9999", {
                      placeholder: "",
                      showMaskOnHover: false,
                      autoUnmask: true
                    })}
                  />
                </FormField>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 px-2 pb-4">
                  <FormField
                    label={t("Valid Thru")}
                    name="cardExpiry"
                    helpText={touched.cardExpiry ? errors.cardExpiry : ""}
                    state={
                      touched.cardExpiry && errors.cardExpiry
                        ? "error"
                        : "default"
                    }
                    required
                  >
                    <Input
                      block
                      type="text"
                      inputMode="numeric"
                      aria-label="Valid Thru"
                      aria-invalid={!!errors.cardExpiry}
                      autoComplete="cc-exp"
                      autoCorrect="off"
                      name="cardExpiry"
                      value={values.cardExpiry}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={touched.cardExpiry && !!errors.cardExpiry}
                      placeholder="MM/YY"
                      ref={withMask("99/99", {
                        placeholder: "",
                        showMaskOnHover: false
                      })}
                    />
                  </FormField>
                </div>
                <div className="md:w-1/2 px-2 pb-4">
                  <FormField
                    label={t("CVN")}
                    name="cvn"
                    helpText={touched.cvn ? errors.cvn : ""}
                    state={touched.cvn && errors.cvn ? "error" : "default"}
                    required
                  >
                    <Input
                      block
                      type="password"
                      inputMode="numeric"
                      aria-label="CVN"
                      aria-invalid={!!errors.cvn}
                      autoComplete="cc-csc"
                      autoCorrect="off"
                      name="cvn"
                      maxLength={4}
                      value={values.cvn}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={touched.cvn && !!errors.cvn}
                      placeholder="CVN"
                      ref={withMask("999[9]", {
                        placeholder: "",
                        showMaskOnHover: false,
                        regex: "[0-9]*"
                      })}
                    />
                  </FormField>
                </div>
              </div>
            </div>
            <div className="flex justify-center space-x-2">
              <Button
                variant="brand-secondary"
                outline
                type="button"
                onClick={props.onCancel}
                className="md:w-36"
              >
                <span className="hidden md:inline">
                  {t("Back", { ns: "common" })}
                </span>
                <span className="md:hidden">
                  <ArrowLeft />
                </span>
              </Button>
              <Button
                id="pay-now-button"
                variant="brand-secondary"
                type="submit"
                className="flex-1 md:flex-initial md:w-36"
                disabled={!isValid || !dirty}
              >
                {t("Continue", { ns: "common" })}
              </Button>
            </div>
            <MobileFooter>
              <div className="flex space-x-4">
                <Button
                  variant="brand-secondary"
                  outline
                  type="button"
                  onClick={onCancel}
                  className="md:w-36"
                >
                  <span className="hidden md:inline">
                    {t("Back", { ns: "common" })}
                  </span>
                  <span className="md:hidden">
                    <ArrowLeft />
                  </span>
                </Button>
                <Button
                  id="pay-now-button"
                  variant="brand-secondary"
                  type="button"
                  className="flex-1 md:flex-initial md:w-36"
                  onClick={submitForm}
                  disabled={!isValid || !dirty}
                >
                  {t("Continue", { ns: "common" })}
                </Button>
              </div>
            </MobileFooter>
          </form>
        </div>
      </section>
      <div className="flex justify-center pb-12">
        <PoweredByXendit />
      </div>
    </MainView>
  );
};

export default CardForm;
