import { Flow } from "../Flow";

export class RedirectFlow extends Flow {
  buildFlow() {
    return this;
  }

  onSelectFlow() {
    this.postPaymentTokenAction({}).then((response) => {
      if (response.isRight()) {
        const createdToken = response.getRight();
        this.exitFlow(createdToken);
      }
    });
    return this.flow;
  }
}
