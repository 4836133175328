import { FC } from "react";
import { useTranslation } from "react-i18next";
import clx from "classnames";
import { formatDisplayAmountWithCurrencyCode } from "@xendit/checkout-utilities";

import Button from "../Button";
import Dialog, { DialogProps } from "../Dialog";
import FormField from "../FormField";
import Input from "../Input";

import { CreditCardPromotion } from "../../types/credit-card";

export type PromoCodeInputProps = {
  value: string;
  error?: boolean;
  onChange: (promo: string) => void;
  onPromoSubmit: () => void;
};
export const PromoCodeInput = ({
  value,
  onChange,
  onPromoSubmit,
  error
}: PromoCodeInputProps) => {
  const { t } = useTranslation("forms");

  return (
    <div className="flex gap-x-2">
      <div className="w-4/6">
        <FormField
          label={t("Promo Code")}
          name="promo-code"
          helpText={error ? t("Invalid promo code") : undefined}
          state={error ? "error" : "default"}
        >
          <Input
            block
            name="promo-code"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={t("Enter promo code")}
            type="text"
            role="textbox"
            aria-label={t("Enter promo code")}
          />
        </FormField>
      </div>
      <div
        className={clx("pb-[5px] w-2/6 self-end", {
          "pt-[8px] self-center": error
        })}
      >
        <Button block outline type="button" onClick={onPromoSubmit}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export type PromoItemProps = {
  promotion: CreditCardPromotion;
  onClickApplyPromo: (promotion: CreditCardPromotion) => void;
};

export const PromoItem = ({ promotion, onClickApplyPromo }: PromoItemProps) => {
  const promoOff = promotion.discount_amount
    ? formatDisplayAmountWithCurrencyCode(
        promotion.discount_amount,
        promotion.currency
      )
    : `${promotion.discount_percent}% off`;

  return (
    <div className="p-2.5 flex items-center gap-x-2 border border-xen-gray-300 rounded-md">
      <div className="inline-flex items-center h-full w-4/6 px-2 bg-primary text-white font-semibold rounded-md">
        {promoOff}
      </div>
      <div className="w-2/6">
        <Button
          block
          outline
          type="button"
          onClick={() => onClickApplyPromo(promotion)}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export type PromoListProps = {
  promotions: CreditCardPromotion[];
  onClickApplyPromo: (promotion: CreditCardPromotion) => void;
};

export const PromoList = ({
  promotions,
  onClickApplyPromo
}: PromoListProps) => {
  return (
    <div className="mt-6 md:my-5 grid grid-rows-2 gap-y-3">
      {promotions.map((promotion) => {
        return (
          <PromoItem
            key={`promo-${promotion.reference_id}`}
            promotion={promotion}
            onClickApplyPromo={onClickApplyPromo}
          />
        );
      })}
    </div>
  );
};

const PromotionsDialog: FC<DialogProps> = (props) => {
  return (
    <Dialog
      open={props.open}
      title={props.title}
      content={props.content}
      onClose={props.onClose}
    />
  );
};

export default PromotionsDialog;
