import { FC, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { CurrenciesEnum } from "@xendit/checkout-utilities/dist/src/enums/currencies";
import { getCountryCodeByCurrency } from "@xendit/checkout-utilities/dist/src/country-of-operations";
import { formatDisplayAmountWithCurrencyCode } from "@xendit/checkout-utilities/dist/src/amount-formatter";

import { usePaymentLink } from "../../contexts/PaymentLinkContext";
import { usePaymentMethod } from "../../contexts/PaymentMethodContext";
import { usePaymentRequest } from "../../contexts/PaymentRequestContext";
import { useSimulation } from "../../contexts/SimulationContext";

import PaymentView from "../../components/PaymentView";
import DirectDebitForm, {
  InitialCustomerDataValues,
  DirectDebitFormValues
} from "../../components/DirectDebitForm";
import OTPDialog from "../../components/OTPDialog";
import FraudWarningNotes from "../../components/FraudWarningNotes";

import { authorizePaymentMethod } from "../../utils/fetch-resource";
import { trimSpecialCharactersAndSpaces } from "../../utils/strings";
import type { TranslationInterpolation } from "../../types/i18next";

const PaymentDebitCard: FC = () => {
  const { t } = useTranslation("common");
  const {
    customer,
    paymentLink: {
      invoice: { id, amount, currency }
    }
  } = usePaymentLink();
  const { clearPaymentChannel, paymentChannel } = usePaymentMethod();
  const { paymentRequest, showError, onInitialize } = usePaymentRequest();
  const { onReset: onResetSimulation } = useSimulation();

  const [maskedMobileNumber, setMaskedMobileNumber] = useState<string>();
  const [optDescription, setOTPDescription] = useState<React.ReactNode>();
  const [showOTP, setShowOTP] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const onSubmit = (values: DirectDebitFormValues) => {
    const {
      firstName,
      lastName,
      email,
      mobileNumber,
      cardLastFour,
      cardExpiry
    } = values;
    setProcessing(true);

    setMaskedMobileNumber(mobileNumber);

    onInitialize({
      payment_method_channel_properties: {
        email,
        mobile_number: trimSpecialCharactersAndSpaces(mobileNumber),
        card_last_four: cardLastFour,
        card_expiry: cardExpiry
      },
      customer: {
        given_names: firstName,
        surname: lastName,
        email,
        mobile_number: trimSpecialCharactersAndSpaces(mobileNumber)
      }
    });

    return values;
  };

  const onVerifyOTP = async (otpCode: string) => {
    await authorizePaymentMethod(
      id,
      paymentRequest?.payment_method?.id || "",
      otpCode
    );

    setShowOTP(false);
  };

  const onCloseOTPDialog = () => {
    setShowOTP(false);
    setProcessing(false);
    onResetSimulation();
  };

  if (
    !paymentChannel ||
    (paymentChannel.properties as { type: string }).type !== "DEBIT_CARD"
  ) {
    return null;
  }

  useEffect(() => {
    if (paymentRequest) {
      setOTPDescription(
        <Trans t={t} ns="common">
          Enter the {{ number: 6 }}-digit OTP we sent to your{" "}
          <strong>
            {
              {
                channelCode: paymentChannel.display_name
              } as TranslationInterpolation
            }
          </strong>{" "}
          registered number
          <strong>
            {{ mobileNumber: maskedMobileNumber } as TranslationInterpolation}
          </strong>{" "}
          to continue with your purchase.
        </Trans>
      );
      setShowOTP(true);
    }
  }, [paymentRequest]);

  useEffect(() => {
    if (!showError) {
      setProcessing(false);
      onResetSimulation();
    }
  }, [showError]);

  return (
    <PaymentView
      header
      paymentChannelName={paymentChannel.display_name}
      paymentChannelLogoUrl={paymentChannel.logo_url}
      amount={formatDisplayAmountWithCurrencyCode(amount, currency)}
    >
      <DirectDebitForm
        countryCode={getCountryCodeByCurrency(currency as CurrenciesEnum) || ""}
        initialCustomerData={customer as InitialCustomerDataValues}
        disableSubmit={processing}
        onCancel={clearPaymentChannel}
        onSubmit={(values) => onSubmit(values)}
      />
      <OTPDialog
        open={showOTP}
        title={t("Mobile Verification")}
        description={optDescription}
        numOTPInput={6}
        onVerifyOTP={(otpCode) => onVerifyOTP(otpCode as string)}
        onClose={onCloseOTPDialog}
      />
    </PaymentView>
  );
};

export default PaymentDebitCard;
