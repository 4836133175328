import { FC, SVGProps } from "react";

const Success: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 149 112"
      {...props}
    >
      <path
        d="M57.4132 22.0791C57.4132 22.0791 56.3218 24.3975 56.1356 26.1335C56.118 26.3023 56.134 26.4728 56.1824 26.6353C56.2308 26.7979 56.3108 26.9492 56.4178 27.0807C56.5247 27.2121 56.6565 27.3212 56.8056 27.4014C56.9547 27.4817 57.1182 27.5317 57.2866 27.5485C58.162 27.6306 59.3726 27.485 60.1101 26.3127L61.6001 24.4759C61.8613 24.0776 61.9923 23.6077 61.9749 23.1314C61.9575 22.6551 61.7927 22.196 61.5033 21.8178C60.5087 20.3506 58.0092 20.6306 57.4132 22.0791Z"
        fill="#F4A28C"
      />
      <path
        d="M61.0378 24.905L61.6412 33.2864L57.0073 33.2901L58.393 27.3429L61.0378 24.905Z"
        fill="#F4A28C"
      />
      <path
        d="M57.9724 22.3999C58.7037 22.7032 59.4674 22.9211 60.2483 23.0495C59.9137 23.2967 59.6585 23.6366 59.5143 24.0273C59.37 24.418 59.3429 24.8425 59.4363 25.2485C59.52 25.6438 59.7381 25.9977 60.0534 26.2497C60.3687 26.5016 60.7615 26.636 61.1647 26.6298L62.2822 23.5237C62.4528 23.0837 62.5013 22.6056 62.4226 22.1402C62.3439 21.6748 62.1409 21.2395 61.8352 20.8805C61.5731 20.5684 61.292 20.2729 60.9933 19.9957C60.2483 19.3087 58.7174 19.4207 58.1437 18.5247C58.0852 18.4356 58.0047 18.3631 57.91 18.3142C57.8153 18.2654 57.7096 18.2419 57.6032 18.2461C57.4968 18.2502 57.3933 18.2818 57.3026 18.3378C57.212 18.3938 57.1373 18.4724 57.0858 18.5658C56.5978 19.4543 56.2216 20.9962 57.3019 21.9781C57.5004 22.1551 57.727 22.2977 57.9724 22.3999Z"
        fill="#24285B"
      />
      <path
        d="M59.2051 25.0805C59.2051 25.0805 59.5776 24.1621 60.2332 24.4907C60.8888 24.8192 60.3896 26.152 59.3988 25.928L59.2051 25.0805Z"
        fill="#F4A28C"
      />
      <path
        d="M56.8025 23.5759L55.7893 24.2666C55.7308 24.3036 55.6827 24.3549 55.6493 24.4156C55.6159 24.4763 55.5984 24.5445 55.5984 24.6138C55.5984 24.6831 55.6159 24.7513 55.6493 24.812C55.6827 24.8727 55.7308 24.924 55.7893 24.961L56.6423 25.4725L56.8025 23.5759Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.31"
        d="M58.3559 27.5109C58.9149 27.4108 59.4363 27.1605 59.8645 26.7866C59.8645 26.7866 60.1625 28.6048 57.9573 29.2133L58.3559 27.5109Z"
        fill="#CE8172"
      />
      <path
        d="M92.6482 33.3611C99.612 33.3611 105.257 27.7032 105.257 20.7238C105.257 13.7444 99.612 8.08643 92.6482 8.08643C85.6844 8.08643 80.0391 13.7444 80.0391 20.7238C80.0391 27.7032 85.6844 33.3611 92.6482 33.3611Z"
        fill="#4573FF"
      />
      <path
        d="M83.1979 23.7852L74.5149 30.0609L86.5727 27.8918L83.1979 23.7852Z"
        fill="#4573FF"
      />
      <path
        d="M31.562 34.5482C31.562 34.5482 21.0165 55.0032 29.327 59.1098C37.6375 63.2165 54.7092 43.12 54.7092 43.12L49.7251 33.0176C49.7251 33.0176 38.4384 39.5733 33.2867 50.5232L34.1546 33.6746L31.562 34.5482Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M31.562 34.5482C31.562 34.5482 21.0165 55.0032 29.327 59.1098C37.6375 63.2165 54.7092 43.12 54.7092 43.12L49.7251 33.0176C49.7251 33.0176 38.4384 39.5733 33.2867 50.5232L34.1546 33.6746L31.562 34.5482Z"
        fill="white"
      />
      <path
        opacity="0.45"
        d="M80.6499 106.019C110.758 106.019 135.165 102.048 135.165 97.1487C135.165 92.2497 110.758 88.2783 80.6499 88.2783C50.5419 88.2783 26.1345 92.2497 26.1345 97.1487C26.1345 102.048 50.5419 106.019 80.6499 106.019Z"
        fill="#E6E6E6"
      />
      <path
        d="M116.548 48.2944H70.5999C68.5941 48.2944 66.968 49.9241 66.968 51.9344V83.5894C66.968 85.5997 68.5941 87.2294 70.5999 87.2294H116.548C118.554 87.2294 120.18 85.5997 120.18 83.5894V51.9344C120.18 49.9241 118.554 48.2944 116.548 48.2944Z"
        fill="#C9C9C9"
      />
      <path
        d="M28.9916 32.7413C35.5934 32.7413 40.9451 27.3775 40.9451 20.761C40.9451 14.1445 35.5934 8.78076 28.9916 8.78076C22.3899 8.78076 17.0381 14.1445 17.0381 20.761C17.0381 27.3775 22.3899 32.7413 28.9916 32.7413Z"
        fill="#D36820"
      />
      <path
        d="M28.9916 32.7413C35.5934 32.7413 40.9451 27.3775 40.9451 20.761C40.9451 14.1445 35.5934 8.78076 28.9916 8.78076C22.3899 8.78076 17.0381 14.1445 17.0381 20.761C17.0381 27.3775 22.3899 32.7413 28.9916 32.7413Z"
        fill="#FFD200"
      />
      <path
        opacity="0.08"
        d="M28.9916 32.7413C35.5934 32.7413 40.9451 27.3775 40.9451 20.761C40.9451 14.1445 35.5934 8.78076 28.9916 8.78076C22.3899 8.78076 17.0381 14.1445 17.0381 20.761C17.0381 27.3775 22.3899 32.7413 28.9916 32.7413Z"
        fill="black"
      />
      <path
        d="M32.3925 31.4271C38.9942 31.4271 44.346 26.0633 44.346 19.4468C44.346 12.8303 38.9942 7.46655 32.3925 7.46655C25.7907 7.46655 20.439 12.8303 20.439 19.4468C20.439 26.0633 25.7907 31.4271 32.3925 31.4271Z"
        fill="#FFD200"
      />
      <path
        opacity="0.33"
        d="M32.3925 27.6304C36.902 27.6304 40.5577 23.9665 40.5577 19.4469C40.5577 14.9273 36.902 11.2634 32.3925 11.2634C27.883 11.2634 24.2273 14.9273 24.2273 19.4469C24.2273 23.9665 27.883 27.6304 32.3925 27.6304Z"
        fill="white"
      />
      <path
        d="M115.162 52.7258H71.9856C69.2145 52.7258 66.968 54.9773 66.968 57.7546V92.273C66.968 95.0504 69.2145 97.3018 71.9856 97.3018H115.162C117.933 97.3018 120.18 95.0504 120.18 92.273V57.7546C120.18 54.9773 117.933 52.7258 115.162 52.7258Z"
        fill="#E6E6E6"
      />
      <path
        opacity="0.08"
        d="M87.5896 48.2944H96.5296C96.5296 48.2944 101.45 71.2582 85.634 76.8134C69.8176 82.3686 79.674 97.3019 79.674 97.3019H71.0692L68.2941 93.8V83.2832L66.1597 72.8L71.058 53.2038L76.3103 48.8171L87.5896 48.2944Z"
        fill="black"
      />
      <path
        d="M119.081 68.7046H105.198C102.299 68.7046 99.9492 71.0597 99.9492 73.9649V76.3318C99.9492 79.237 102.299 81.5921 105.198 81.5921H119.081C121.979 81.5921 124.329 79.237 124.329 76.3318V73.9649C124.329 71.0597 121.979 68.7046 119.081 68.7046Z"
        fill="#A5A5A5"
      />
      <path
        d="M105.969 77.9258C107.528 77.9258 108.792 76.6589 108.792 75.096C108.792 73.5331 107.528 72.2661 105.969 72.2661C104.409 72.2661 103.145 73.5331 103.145 75.096C103.145 76.6589 104.409 77.9258 105.969 77.9258Z"
        fill="#E6E6E6"
      />
      <path
        d="M47.5831 34.7683C48.1347 34.0686 48.8256 33.4918 49.6119 33.0747C50.3982 32.6575 51.2626 32.4091 52.15 32.3454C55.9905 32.0729 66.2156 33.3758 72.224 37.0867C80.6425 42.2835 82.058 48.9363 68.2904 70.6681L53.7629 69.8131C53.7629 69.8131 37.5964 47.3907 47.5831 34.7683Z"
        fill="currentColor"
      />
      <path
        opacity="0.08"
        d="M65.1355 40.0811C65.1355 40.0811 62.0288 47.4133 66.1599 53.8944C70.2909 60.3755 58.9185 70.1269 58.9185 70.1269L67.7951 70.6682L69.5012 68.712C69.5012 68.712 76.0125 57.9861 77.3237 53.4053C78.6349 48.8245 70.8869 46.3605 70.8869 46.3605L65.1355 40.0811Z"
        fill="black"
      />
      <path
        d="M65.3959 44.9568C62.8629 40.4507 67.3925 35.2501 72.2164 37.0869C79.9905 40.0475 91.5566 46.6144 89.6829 59.1136C86.9003 77.6944 72.8422 71.9637 72.8422 71.9637L72.6932 68.2565C72.6932 68.2565 81.6593 68.4619 81.7487 59.2965C81.8157 52.5355 69.2141 51.7515 65.3959 44.9568Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M65.3959 44.9568C62.8629 40.4507 67.3925 35.2501 72.2164 37.0869C79.9905 40.0475 91.5566 46.6144 89.6829 59.1136C86.9003 77.6944 72.8422 71.9637 72.8422 71.9637L72.6932 68.2565C72.6932 68.2565 81.6593 68.4619 81.7487 59.2965C81.8157 52.5355 69.2141 51.7515 65.3959 44.9568Z"
        fill="white"
      />
      <path
        d="M53.7444 69.8132L56.2066 95.1999H59.8795L60.6245 80.2367C60.685 79.0187 61.0108 77.8288 61.579 76.7504C62.1472 75.672 62.9441 74.7314 63.9136 73.9946L68.298 70.6682L53.7444 69.8132Z"
        fill="#24285B"
      />
      <path
        d="M68.283 70.6682L71.4678 95.1999L67.5603 95.8981L61.9728 75.4581L57.9573 72.7962L68.283 70.6682Z"
        fill="#24285B"
      />
      <path
        d="M34.1547 33.6747C34.1547 33.6747 33.432 31.2368 29.3122 31.36C25.1923 31.4832 27.3268 36.8592 34.1547 33.6747Z"
        fill="#F4A28C"
      />
      <path
        d="M72.6935 68.2566C72.6935 68.2566 67.7802 68.0625 67.6237 70.8065C67.4673 73.5505 69.0467 74.4652 70.9092 74.3345C72.7717 74.2038 70.2908 72.6918 72.6935 71.2993V68.2566Z"
        fill="#F4A28C"
      />
      <path
        d="M57.3279 95.2C57.3279 95.2 56.9331 96.8538 55.5138 97.1338C54.0946 97.4138 53.8748 98.8586 55.2047 99.0826C56.5345 99.3066 59.6747 97.2757 59.6747 97.2757L59.3506 95.2149L57.3279 95.2Z"
        fill="currentColor"
      />
      <path
        d="M70.2609 95.4055C70.2609 95.4055 70.9761 96.9474 72.4214 96.9399C73.8667 96.9324 74.3733 98.3063 73.1143 98.7879C71.8552 99.2695 68.3761 97.9068 68.3761 97.9068L68.283 95.8237L70.2609 95.4055Z"
        fill="currentColor"
      />
      <path
        d="M87.3047 19.5789L90.6996 25.375L97.7813 14.875"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Success;
