import { Business } from "../types";

export const getCountryCodeFromBusiness = (business: Business) => {
  switch (business.country_of_operation?.toLowerCase()) {
    case "indonesia":
      return "+62";
    case "philippines":
      return "+63";
    case "thailand":
      return "+66";
    case "viet nam":
      return "+84";
    case "malaysia":
      return "+60";
    default:
      return "0";
  }
};
