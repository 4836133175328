import { FC, SVGProps } from "react";

const BankTransfer: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      height={24}
      width={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.763 9.375h-2.25v9.75h2.25v-9.75ZM11.763 9.375h-2.25v9.75h2.25v-9.75ZM17.763 9.375h-2.25v9.75h2.25v-9.75ZM2.388 19.125h16.5a1.125 1.125 0 0 1 1.125 1.125V21a.375.375 0 0 1-.375.375h-18A.375.375 0 0 1 1.263 21v-.75a1.125 1.125 0 0 1 1.125-1.125ZM20.013 7.125H1.263v2.25h18.75v-2.25Z"
        stroke="#777"
      />
      <path
        d="M10.638 1.61 1.501 6.75h18.274l-9.137-5.14Zm0-.75a.75.75 0 0 1 .368.097l9.137 5.14c.672.378.404 1.403-.368 1.403H1.501c-.772 0-1.04-1.025-.367-1.404L10.27.956a.75.75 0 0 1 .368-.095Z"
        fill="#777"
      />
      <circle cx={18} cy={18} r={5.5} fill="#fff" stroke="#777" />
      <path
        d="M20.625 19.125H15.64l.805-.805-.265-.265-1.125 1.125a.187.187 0 0 0 0 .265l1.125 1.125.265-.265-.805-.805h4.985v-.375ZM20.945 16.555 19.82 15.43l-.265.265.805.805h-4.985v.375h4.985l-.805.805.265.265 1.125-1.125a.187.187 0 0 0 0-.265Z"
        fill="#777"
        stroke="#777"
        strokeWidth={0.2}
      />
    </svg>
  );
};

export default BankTransfer;
