import { useTranslation } from "react-i18next";
import Callout from "../Callout";

const FraudWarningNotes = () => {
  const { t } = useTranslation("common");

  return (
    <Callout
      title={t("Protect Yourself Against Fraud")}
      description={
        <>
          {t(
            "Ensure the merchant’s name, payment amount and details are correct."
          )}{" "}
          <br />
          {t("Always check before proceeding with payment.")}
        </>
      }
      variant="medium"
    />
  );
};

export default FraudWarningNotes;
