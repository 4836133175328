import {
  createContext,
  DetailedHTMLProps,
  FC,
  FieldsetHTMLAttributes,
  useContext
} from "react";
import classnames from "classnames";
import kebabCase from "lodash/kebabCase";

type FormFieldState = "error" | "warning" | "default";

type Props = DetailedHTMLProps<
  FieldsetHTMLAttributes<HTMLFieldSetElement>,
  HTMLFieldSetElement
> & {
  helpText?: string;
  label?: string | null;
  name?: string;
  required?: boolean;
  state?: FormFieldState;
};

const FormFieldContext = createContext<{
  state: FormFieldState;
}>({ state: "default" });

const FormField: FC<Props> = ({
  children,
  helpText,
  label,
  name,
  required,
  state = "default",
  ...props
}) => {
  const labelClassName = classnames([
    "block font-medium text-base",
    {
      "text-xen-red-600": state === "error"
    }
  ]);
  const helpTextClassName = classnames([
    "text-sm",
    {
      "text-xen-red-600": state === "error",
      "text-xen-yellow-600": state === "warning",
      "text-xen-gray-700": state === "default"
    }
  ]);

  return (
    <fieldset {...props}>
      <FormFieldContext.Provider value={{ state }}>
        {label ? (
          <label htmlFor={name} className={labelClassName}>
            {label}
            {required ? <span className="text-xen-red-500">*</span> : null}
          </label>
        ) : null}
        <div className="py-1">{children}</div>
        {helpText ? (
          <p
            className={helpTextClassName}
            data-testid={`${kebabCase(name)}-help-text`}
          >
            {helpText}
          </p>
        ) : null}
      </FormFieldContext.Provider>
    </fieldset>
  );
};

export default FormField;

export const useFormField = () => useContext(FormFieldContext);
