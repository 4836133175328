import { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  channel: string;
};

const PaymentFormInfoBox: FC<Props> = (props) => {
  const { channel } = props;

  const { t } = useTranslation(["common"]);

  const info = useMemo(() => {
    switch (channel) {
      case "OVO":
        return (
          <Trans t={t} ns="common">
            <p>
              <strong>
                IF THE TRANSACTION FAILS, PLEASE TRY THE FOLLOWING:
              </strong>
            </p>
            <ol>
              <li>Input your phone number again and try paying again.</li>
              <li>Clear your OVO app cache on your phone.</li>
              <li>
                If the problem continues, please contact Xendit for support.
              </li>
            </ol>
            <p>
              You will <strong>NOT be charged twice for retrying</strong>.
            </p>
          </Trans>
        );
      case "JENIUSPAY":
        return (
          <Trans t={t} ns="common">
            <p>
              <strong>HOW TO PAY:</strong>
            </p>
            <ol>
              <li>
                Make sure that you have registered for a Jenius account and
                assigned a $cashtag to it.
              </li>
              <li>
                Upon entering a valid $cashtag, you should expect to receive a
                push notification from the Jenius app to finish your payment.
              </li>
              <li>
                The Jenius app can be downloaded in iOS App Store and Google
                Play.
              </li>
            </ol>
          </Trans>
        );
      default:
        return null;
    }
  }, [channel]);

  if (!info) {
    return null;
  }

  return (
    <div
      className="bg-xen-green-100 border border-xen-green-300 p-4 rounded-md prose-sm prose-ol:list-decimal"
      data-testid={`${channel.toLowerCase()}-notes`}
    >
      {info}
    </div>
  );
};

export default PaymentFormInfoBox;
