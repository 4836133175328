import { formatDisplayAmountWithCurrencyCode } from "@xendit/checkout-utilities/dist/src/amount-formatter";
import { useCallback, useEffect } from "react";
import { PaymentChannelsEnum } from "@xendit/checkout-utilities/dist/src/enums/payment-channels";
import { useTranslation, Trans } from "react-i18next";

import { usePaymentLink } from "../../contexts/PaymentLinkContext";
import { usePaymentMethod } from "../../contexts/PaymentMethodContext";

import { ENABLE_SIMULATION } from "../../utils/constants";
import {
  ExternalAnalyticsEvent,
  ExternalAnalyticsProvider,
  InternalAnalyticsEvent,
  logExternalAnalyticsEvent,
  logInternalAnalyticsEvent
} from "../../utils/analytics";
import { nexBannerClickEvent } from "../../utils/nex-events";
import { formatDate } from "../../helpers/date-strings";

import {
  Header,
  HeaderAddon,
  HeaderBackButton,
  HeaderContent,
  HeaderImage,
  HeaderTitle
} from "../../components/Header";
import { MainView } from "../../components/Layout";
import { useRedirect } from "../../hooks/use-redirect";
import LanguageNavigation from "../../components/LanguageNavigation";
import OrderSummary from "../../components/OrderSummary";
import PoweredByXendit from "../../components/PoweredByXendit";
import SimulationBar from "../../components/SimulationBar";
import CustomBadges from "../../components/CustomBadges";
import NexBanner from "../../components/NexBanner";

import Graphic from "../../assets/graphics/Success";

const CheckoutPaid = () => {
  const {
    paymentLink: { invoice, invoice_settings: invoiceSettings }
  } = usePaymentLink();
  const { checkoutPaymentChannels } = usePaymentMethod();
  const { i18n, t } = useTranslation("checkout-paid");

  const handleLocaleChange = useCallback((locale: string) => {
    // send analytics event on language change
    logInternalAnalyticsEvent({
      event: InternalAnalyticsEvent.SELECTED_LOCALE,
      locale
    });
  }, []);

  const handleOrderSummaryOpenChange = useCallback((open: boolean) => {
    if (open) {
      // send external view content analytics event on order summary expand
      logExternalAnalyticsEvent({
        event_name: ExternalAnalyticsEvent.VIEW_CONTENT,
        target: [
          ExternalAnalyticsProvider.FACEBOOK,
          ExternalAnalyticsProvider.GOOGLE
        ]
      });
    }
  }, []);

  const checkoutPaymentChannel =
    checkoutPaymentChannels[
      invoice.payment_channel === "CARD_INSTALLMENT"
        ? "CREDIT_CARD"
        : invoice.payment_method === "CRYPTOCURRENCY"
        ? ("COINBASE" as PaymentChannelsEnum)
        : (invoice.payment_channel as PaymentChannelsEnum)
    ];

  const secondsLeft = useRedirect(true, invoice.success_redirect_url);

  return (
    <MainView>
      {ENABLE_SIMULATION ? <SimulationBar /> : null}
      <Header>
        <HeaderContent>
          {invoice.cancel_redirect_url ? (
            <HeaderBackButton
              title={`Back to ${invoice.merchant_name}`}
              onClick={() => {
                if (invoice.cancel_redirect_url) {
                  window.location.href = invoice.cancel_redirect_url;
                }
              }}
            />
          ) : null}
          <HeaderImage
            src={invoice.merchant_profile_picture_url}
            alt={invoice.merchant_name}
          />
          <HeaderTitle>{invoice.merchant_name}</HeaderTitle>
        </HeaderContent>
        <HeaderAddon>
          <LanguageNavigation onChange={handleLocaleChange} />
        </HeaderAddon>
      </Header>
      <div className="lg:hidden">
        <OrderSummary
          collapsible
          currency={invoice.currency}
          description={invoice.description}
          expiryDate={invoice.expiry_date}
          expiryTimeFormat={invoiceSettings.payment_due_time_format}
          externalId={invoice.external_id}
          items={invoice.items}
          onOpenChange={handleOrderSummaryOpenChange}
          totalAmount={invoice.amount}
        />
      </div>
      <div className="flex-1 px-4 lg:px-0">
        <div className="pt-8 pb-12 space-y-8">
          <div className="flex flex-col items-center">
            <Graphic className="text-primary h-32 mb-4 hidden md:block" />
            <h1 className="text-3xl font-semibold mb-2 text-center">
              {t("Thank You!")}
            </h1>
            <p className="text-center" data-testid="success-description">
              {t("Your order #{{external_id}} has been paid for successfully", {
                external_id: invoice.external_id
              })}
            </p>
          </div>
          <ul className="flex flex-col max-w-md mx-auto">
            <li className="flex -mx-2">
              <div className="w-1/2 px-2 font-semibold py-2">
                {t("Amount Paid")}
              </div>
              <div className="w-1/2 px-2 py-2" data-testid="paid-amount">
                {formatDisplayAmountWithCurrencyCode(
                  invoice.paid_amount,
                  invoice.currency
                )}
              </div>
            </li>
            <li className="flex -mx-2">
              <div className="w-1/2 px-2 font-semibold py-2">
                {t("Date Paid")}
              </div>
              <div className="w-1/2 px-2 py-2" data-testid="paid-date">
                {formatDate(
                  new Date(invoice.paid_at as string),
                  i18n.language,
                  invoiceSettings.payment_due_time_format,
                  {
                    dateStyle: "medium"
                  }
                )}
              </div>
            </li>
            <li className="flex -mx-2">
              <div className="w-1/2 px-2 font-semibold py-2">
                {t("Payment")}
              </div>
              <div
                className="w-1/2 px-2 flex items-center space-x-2"
                data-testid="paid-channel"
              >
                {checkoutPaymentChannel?.channel !== "CREDIT_CARD" ? (
                  <div className="h-8 w-12 p-1 border border-xen-gray-400 rounded">
                    <img
                      src={checkoutPaymentChannel?.logo_url}
                      alt={checkoutPaymentChannel?.label}
                      className="h-full w-full object-contain"
                    />
                  </div>
                ) : null}
                <span>
                  {checkoutPaymentChannel?.display_name ||
                    checkoutPaymentChannel?.channel}
                </span>
              </div>
            </li>
            {invoice.installment ? (
              <li className="flex -mx-2">
                <div className="w-1/2 px-2 font-semibold py-2">
                  {t("Installment")}
                </div>
                <div className="w-1/2 px-2 py-2" data-testid="installment-term">
                  {`${invoice.installment.count} ${invoice.installment.interval}`}
                </div>
              </li>
            ) : null}
          </ul>
          {invoice.success_redirect_url ? (
            <div className="text-center text-xen-gray-700 italic">
              {secondsLeft > 0 ? (
                <Trans t={t} ns="checkout-paid">
                  Redirecting back in {{ seconds: secondsLeft }}s, or click{" "}
                  <a
                    href={invoice.success_redirect_url}
                    target="_top"
                    className="underline underline-offset-auto text-xen-blue-500"
                  >
                    here
                  </a>
                </Trans>
              ) : (
                t("Redirecting now")
              )}
            </div>
          ) : null}
        </div>

        <div className="flex justify-center">
          <NexBanner
            className="pb-12 max-w-md mx-auto"
            bannerPlacementKey="placement_post"
            onClick={() => nexBannerClickEvent("post-payment-page", invoice.id)}
          />
        </div>

        <div className="flex justify-center pb-12">
          {invoiceSettings.branding?.badge_logos ? (
            <CustomBadges badgeLogos={invoiceSettings.branding?.badge_logos} />
          ) : (
            <PoweredByXendit />
          )}
        </div>
      </div>
    </MainView>
  );
};

export default CheckoutPaid;
