import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState
} from "react";
import { ChevronDown } from "../../assets/icons";
import classnames from "classnames";
import { useFormField } from "../FormField";

type Props = Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  "onChange" | "value"
> & {
  alignment?: "left" | "right";
  block?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  keyExtractor?: (option: unknown) => string;
  onChange: (option: unknown) => unknown;
  optionDisabled?: (option: unknown) => boolean;
  optionSelected?: (option: unknown, selection: unknown) => boolean;
  options: unknown[];
  placeholder?: ReactNode;
  renderOption?: (
    option: unknown,
    helpers?: {
      selected?: boolean;
      disabled?: boolean;
      hovering?: boolean;
    }
  ) => ReactNode;
  renderSelection?: (selection: unknown) => ReactNode;
  value: unknown;
};

const Dropdown: FC<Props> = ({
  alignment = "left",
  block,
  className,
  disabled,
  hasError,
  keyExtractor,
  onBlur,
  onChange,
  onFocus,
  onMouseDown,
  optionDisabled,
  optionSelected,
  options,
  placeholder,
  renderOption,
  renderSelection,
  value,
  ...props
}) => {
  const [focusing, setFocusing] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const button = useRef<HTMLButtonElement>(null);
  const formField = useFormField();

  useEffect(() => {
    if (disabled) {
      setFocusing(false);
    }
  }, [disabled]);

  useEffect(() => {
    setHoverIndex(-1);
  }, [focusing]);

  const containerClassName = classnames([
    "relative",
    {
      "inline-flex": !block
    }
  ]);
  const buttonClassName = classnames([
    "px-2 h-10 text-base placeholder-xen-gray-500 bg-white text-xen-gray-900 disabled:text-xen-gray-700 disabled:bg-xen-gray-300 disabled:bg-opacity-25 border border-xen-gray-400 rounded outline-none focus:border-primary hover:border-xen-gray-600 disabled:hover:border-xen-gray-400 transition-colors group peer flex items-center space-x-2",
    {
      "w-full flex-1": block,
      "border-xen-red-500": hasError || formField.state === "error"
    },
    className
  ]);
  const chevronClassName = classnames([
    "text-xen-gray-600 transform transition-all",
    {
      "rotate-180": focusing,
      "group-hover:text-xen-gray-700": !disabled
    }
  ]);
  const menuClassName = classnames([
    "absolute bg-white top-full py-1 mt-1 shadow-lg border border-xen-gray-400 rounded max-h-44 overflow-y-auto transition origin-top-left z-10",
    {
      "w-44": !block,
      "left-0": alignment === "left" || block,
      "right-0": alignment === "right" || block,
      "opacity-0": !focusing,
      "scale-0": !block && !focusing,
      "scale-y-0": block && !focusing
    }
  ]);

  return (
    <div className={containerClassName}>
      <button
        type="button"
        ref={button}
        className={buttonClassName}
        disabled={disabled}
        onBlur={(e) => {
          setFocusing(false);
          onBlur?.(e);
        }}
        onFocus={(e) => {
          setFocusing(true);
          onFocus?.(e);
        }}
        onKeyDown={(e) => {
          switch (e.code) {
            case "ArrowUp":
              setHoverIndex(
                hoverIndex > 0 ? hoverIndex - 1 : options.length - 1
              );
              break;
            case "ArrowDown":
              if (!focusing) {
                setFocusing(true);
              }
              setHoverIndex(
                hoverIndex < options.length - 1 ? hoverIndex + 1 : 0
              );
              break;
            case "Enter":
              if (hoverIndex > -1 && !optionDisabled?.(options[hoverIndex])) {
                onChange(options[hoverIndex]);
                setFocusing(false);
              }
          }
        }}
        onMouseDown={(e) => {
          setFocusing(!focusing);
          onMouseDown?.(e);
        }}
        {...props}
      >
        <div className="flex-1 text-left text-lg">
          {value ? renderSelection?.(value) || String(value) : placeholder}
        </div>
        <ChevronDown className={chevronClassName} height="24" width="24" />
      </button>
      <div className={menuClassName}>
        <ul>
          {options.map((option, index) => {
            const disabled = optionDisabled?.(option) || false;
            const hovering = hoverIndex === index;
            const selected =
              optionSelected?.(option, value) || option === value;

            return (
              <li key={keyExtractor?.(option) || String(option)}>
                <button
                  type="button"
                  onClick={() => {
                    onChange(option);
                    setFocusing(false);
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  disabled={disabled}
                  className="flex w-full text-base text-left"
                  tabIndex={-1}
                >
                  {renderOption?.(option, {
                    selected,
                    disabled,
                    hovering
                  }) || (
                    <DropdownOption disabled={disabled} hovering={hovering}>
                      {String(option)}
                    </DropdownOption>
                  )}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;

export interface DropdownOptionProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  disabled?: boolean;
  hovering?: boolean;
  "data-testid"?: string;
}
export const DropdownOption: FC<DropdownOptionProps> = ({
  children,
  className,
  disabled,
  hovering,
  ...props
}) => {
  const optionClassName = classnames([
    "transition-opacity duration-100 flex-1 px-2 py-2 flex space-x-1",
    {
      "hover:bg-xen-gray-200": !disabled,
      "bg-xen-gray-200": hovering,
      "opacity-50": disabled
    },
    className
  ]);

  return (
    <div className={optionClassName} {...props}>
      {children}
    </div>
  );
};
