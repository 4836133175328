import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import Dialog from "../../components/Dialog";

interface ErrorDialogProps {
  open?: boolean;
  title?: string;
  description?: string;
  onClose?: () => void;
}

const ErrorDialog: FC<ErrorDialogProps> = ({
  open,
  title,
  description,
  onClose
}) => {
  const { t } = useTranslation("common");

  const [genericErrorDialogOpen, setGenericErrorDialogOpen] = useState(
    open || false
  );

  // This dialog will also automatically open if it detected `error_code` in the params
  const [searchParams] = useSearchParams();
  const genericErrorTitle = searchParams.get("error_code") || "";
  const genericErrorMessage = searchParams.get("error_message") || "";

  useEffect(() => {
    // Open dialog if it detected `error_code` in the params
    !isEmpty(genericErrorTitle) && setGenericErrorDialogOpen(true);
  }, [genericErrorTitle]);

  const closeDialog = () => {
    // Execute custom onClose action if exists
    if (onClose) {
      onClose();
    }

    setGenericErrorDialogOpen(false);
  };

  return (
    <Dialog
      open={genericErrorDialogOpen}
      title={title || (isEmpty(genericErrorTitle) ? "" : t(genericErrorTitle))}
      description={
        description ||
        (isEmpty(genericErrorMessage) ? "" : t(genericErrorMessage))
      }
      buttons={[
        {
          text: "OK, Got it!",
          variant: "brand-secondary",
          onClick: () => closeDialog()
        }
      ]}
    />
  );
};

export default ErrorDialog;
