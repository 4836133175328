import { useEffect } from "react";

import Layout, { MainView } from "../../components/Layout";
import NotFoundView from "../../views/NotFound";

const NotFound = () => {
  useEffect(() => {
    document.querySelector(".loading-container")?.remove();
  }, []);

  return (
    <Layout>
      <MainView>
        <NotFoundView />
      </MainView>
    </Layout>
  );
};

export default NotFound;
