import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import jsbarcode from "jsbarcode";

export type BarcodeProps = {
  value: string;
};

const Barcode = ({ value }: BarcodeProps) => {
  const rendererReference = useRef<HTMLImageElement>(null);
  const { t } = useTranslation("common");

  const barcodeOpts = {
    format: "CODE128",
    renderer: "svg",
    width: 2,
    height: 100,
    displayValue: true,
    fontOptions: "",
    font: "Roboto",
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 2,
    fontSize: 15,
    background: "#ffffff",
    lineColor: "#000000",
    margin: 10
  };

  const changeHandler = () => {
    const canvas = rendererReference.current;
    jsbarcode(canvas, value, { ...barcodeOpts });
  };

  useEffect(() => {
    changeHandler();
  }, [value]);

  return (
    <div className="text-center">
      <span className="text-sm text-primary uppercase">
        {t("Show to the Cashier")}
      </span>
      <img
        alt="barcode"
        ref={rendererReference}
        data-testid="payment-barcode"
      />
    </div>
  );
};

export default Barcode;
