/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer
} from "react";
import startCase from "lodash/startCase";
import { AxiosError } from "axios";

import { usePaymentLink } from "../PaymentLinkContext";
import { usePaymentMethod } from "../PaymentMethodContext";

import { createPaymentRequest } from "../../utils/fetch-resource";
import { logFetchUnexpectedResponse } from "../../utils/rum";
import { getPaymentRequestPayload, shouldAutomaticallyInitialize } from ".";
import { evaluateAmountRange } from "../../helpers/amount-validation";

import Dialog from "../../components/Dialog";
import { isMobileDevice } from "../../helpers/is-mobile-device";
import { CountriesEnum } from "@xendit/checkout-utilities";

type CustomerType = {
  given_names?: string;
  surname?: string;
  email?: string;
  mobile_number?: string;
};

export type userArgsType = {
  payment_method_channel_properties?: object;
  customer?: CustomerType;
};

type PaymentRequestContextValues = {
  error: ErrorMessage | null;
  initializing: boolean;
  onInitialize: (
    userArgs?: userArgsType,
    callback?: () => unknown
  ) => AbortController | void;
  paymentRequest: PaymentRequestResponse | null;
  ready: boolean;
  showError: boolean;
};

const PaymentRequestContext = createContext<PaymentRequestContextValues>({
  error: null,
  initializing: false,
  onInitialize: () => {},
  paymentRequest: null,
  ready: false,
  showError: false
});

type PaymentRequestReducerState = {
  error: ErrorMessage | null;
  initializing: boolean;
  paymentRequest: PaymentRequestResponse | null;
  ready: boolean;
  showError: boolean;
  showDragonpayInstructions?: boolean;
  dragonpayInstructionsUrl?: string;
};
type PaymentRequestReducerAction =
  | { type: "dismiss_error" }
  | { type: "set_error"; payload: SetErrorPayload }
  | { type: "set_initializing" }
  | {
      type: "set_payment_request";
      payload: SetPaymentRequestPayload;
    }
  | { type: "dismiss_dragonpay_instructions" };
type SetErrorPayload = {
  error: ErrorMessage;
};
type SetPaymentRequestPayload = {
  paymentRequest: PaymentRequestResponse;
};

const PaymentRequestReducer = (
  state: PaymentRequestReducerState,
  action: PaymentRequestReducerAction
): PaymentRequestReducerState => {
  switch (action.type) {
    case "dismiss_error":
      return { ...state, showError: false };
    case "set_error":
      return {
        ...state,
        error: action.payload.error,
        initializing: false,
        showError: true
      };
    case "set_initializing":
      return {
        ...state,
        error: null,
        initializing: true,
        paymentRequest: null,
        ready: false,
        showError: false
      };
    case "set_payment_request": {
      // NOTE: For Dragonpay channel, URL will be returned in the response
      // We check if it's a dragonpay URL, then redirect user to that URL
      const paymentUrl =
        action.payload.paymentRequest.actions &&
        action.payload.paymentRequest.actions.length > 0 &&
        action.payload.paymentRequest.actions[0].url;
      // Dragonpay baseUrl is https://dragonpay.ph/
      const isDragonpayUrl = paymentUrl && paymentUrl.includes("dragonpay");
      if (isDragonpayUrl) {
        // There are 2 types of URL for dragonpay channels: instructions and bank login
        // Open bank login in the same tab
        // Open instructions in a dialog
        if (!paymentUrl.includes("GetEmailInstruction")) {
          const windowTarget = "_self";
          window.open(paymentUrl, windowTarget);
        } else {
          return {
            ...state,
            error: null,
            initializing: false,
            paymentRequest: action.payload.paymentRequest,
            ready: true,
            showError: false,
            showDragonpayInstructions: true,
            dragonpayInstructionsUrl: paymentUrl
          };
        }
      }

      return {
        ...state,
        error: null,
        initializing: false,
        paymentRequest: action.payload.paymentRequest,
        ready: true,
        showError: false
      };
    }
    case "dismiss_dragonpay_instructions":
      return {
        ...state,
        showDragonpayInstructions: false,
        dragonpayInstructionsUrl: ""
      };
    default:
      return state;
  }
};

type Props = {
  children?: ReactNode;
  onErrorDismissed?: () => void;
};

const PaymentRequestProvider: FC<Props> = (props) => {
  const {
    customer,
    paymentLink: {
      invoice: { id: invoiceId, amount, currency }
    }
  } = usePaymentLink();
  const { paymentChannel } = usePaymentMethod();
  const [state, dispatch] = useReducer(PaymentRequestReducer, {
    error: null,
    initializing: false,
    paymentRequest: null,
    ready: false,
    showError: false,
    showDragonpayInstructions: false
  });

  const handleInitialize = useCallback(
    (userArgs?: object, callback?: () => unknown) => {
      if (!paymentChannel) {
        return;
      }
      dispatch({
        type: "set_initializing"
      });

      const abortController = new AbortController();
      const doCreatePaymentRequest = async () => {
        const paymentRequestPayload = getPaymentRequestPayload(
          invoiceId,
          paymentChannel,
          {
            userArgs
          },
          customer
        );
        try {
          const paymentRequest =
            await createPaymentRequest<PaymentRequestResponse>(
              invoiceId,
              paymentRequestPayload,
              { abortSignal: abortController?.signal }
            );
          dispatch({
            type: "set_payment_request",
            payload: {
              paymentRequest
            }
          });
        } catch (error) {
          let errorCode = "Error";
          let errorMessage = "There was an error.";

          if (error instanceof AxiosError) {
            const errorResponse = error.response?.data;
            errorCode = startCase(errorResponse.error_code.toLowerCase());
            errorMessage = errorResponse.message;
          } else {
            logFetchUnexpectedResponse(error);
          }
          dispatch({
            type: "set_error",
            payload: {
              error: {
                title: errorCode,
                body: errorMessage
              }
            }
          });
        }
      };
      doCreatePaymentRequest().then(callback);

      return abortController;
    },
    [dispatch, invoiceId, paymentChannel, customer]
  );

  useEffect(() => {
    if (
      paymentChannel &&
      shouldAutomaticallyInitialize(paymentChannel) &&
      evaluateAmountRange(amount, currency, paymentChannel).isWithinAmountRange
    ) {
      // For MY WeChatPay, we need to generate H5 payment link instead of
      // the usual QR code on mobile device. We also need the client IP
      // address, but we will defer the injection to the Gateway.
      let userArgs;
      if (
        isMobileDevice() &&
        paymentChannel.channel === "WECHATPAY" &&
        paymentChannel.country_of_operation ===
          CountriesEnum.Malaysia.toUpperCase()
      ) {
        userArgs = {
          payment_method_channel_properties: {
            device_type: "MOBILE"
          }
        };
      }
      const abortController = handleInitialize(userArgs);
      return () => {
        abortController?.abort();
      };
    }
  }, [amount, handleInitialize, invoiceId, paymentChannel]);

  return (
    <PaymentRequestContext.Provider
      value={{
        ...state,
        onInitialize: handleInitialize
      }}
    >
      {props.children}

      <Dialog
        open={!!state.showError}
        title={state.error?.title}
        description={state.error?.body}
        buttons={[
          {
            text: "OK, Got it!",
            variant: "brand-secondary",
            onClick: () => {
              dispatch({ type: "dismiss_error" });
              props.onErrorDismissed?.();
            }
          }
        ]}
      />

      <Dialog
        open={!!state.showDragonpayInstructions}
        content={
          <iframe
            name="dragonpay-instructions"
            title="dragonpay-instructions"
            src={state.dragonpayInstructionsUrl}
            className="w-full h-full"
          />
        }
        contentCustomClassName="h-full pb-12"
        buttonCustomClassName="mt-1"
        buttons={[
          {
            text: "Close",
            variant: "secondary",
            onClick: () => {
              dispatch({
                type: "dismiss_dragonpay_instructions"
              });
            }
          }
        ]}
      />
    </PaymentRequestContext.Provider>
  );
};

export default PaymentRequestProvider;

export const usePaymentRequest = () => useContext(PaymentRequestContext);
