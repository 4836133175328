import { useContext } from "react";
import { SessionContext } from "../../../context/context";
import { useTranslation } from "react-i18next";

const obfuscateName = (name: string) => {
  // return asterisks for all characters except the first 3
  return name.slice(0, 3) + new Array(name.length - 3).fill("*").join("");
};

const obfuscateEmail = (email: string) => {
  const [username, domain] = email.split("@");
  const obfuscatedUsername = obfuscateName(username);
  return `${obfuscatedUsername}@${domain}`;
};

const obfuscateMobile = (str: string) => {
  if (str.length <= 7) {
    return "*******";
  }

  const firstThree = str.slice(0, 3);
  const lastFour = str.slice(-4);
  const middle = new Array(str.length - 7).fill("*").join("");

  return `${firstThree}${middle}${lastFour}`;
};

export const CustomerInformation = () => {
  const { t } = useTranslation(["session"]);
  const { state } = useContext(SessionContext);
  const { customer } = state;

  if (!customer) {
    return null;
  }

  const renderName = () => {
    if (customer?.individual_detail?.given_names) {
      return (
        <div className="mb-2">
          <span className="font-semibold text-base">{t("Name")}</span>
          <p className="text-sm">
            {obfuscateName(
              `${customer?.individual_detail?.given_names} ${customer?.individual_detail?.surname}`
            )}
          </p>
        </div>
      );
    } else if (customer?.business_detail?.business_name) {
      return (
        <div className="mb-2">
          <span className="font-semibold text-base">{t("Name")}</span>
          <p className="text-sm">
            {obfuscateName(`${customer?.business_detail?.business_name}`)}
          </p>
        </div>
      );
    }
  };

  const renderEmail = () => {
    if (!customer.email) {
      return null;
    }
    return (
      <div className="mb-2">
        <span className="font-semibold text-base">{t("Email")}</span>
        <p className="text-sm">{obfuscateEmail(customer.email)}</p>
      </div>
    );
  };

  const renderMobile = () => {
    if (!customer.mobile_number) {
      return null;
    }
    return (
      <div className="mb-2">
        <span className="font-semibold text-base">{t("Mobile Number")}</span>
        <p className="text-sm">{obfuscateMobile(customer.mobile_number)}</p>
      </div>
    );
  };

  return (
    <div className="px-4">
      <h2 className="font-semibold uppercase mb-4">{t("Your Details")}</h2>

      {renderName()}
      {renderEmail()}
      {renderMobile()}
    </div>
  );
};
