import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import filter from "lodash/filter";
import find from "lodash/find";

import Dialog from "../../components/Dialog";

import Graphic from "../../assets/graphics/Processing";

import { usePaymentMethod } from "../../contexts/PaymentMethodContext";
import { usePaymentRequest } from "../../contexts/PaymentRequestContext";

import { isMobileDevice } from "../../helpers/is-mobile-device";

const PaymentRedirect: FC = () => {
  const [error, setError] = useState<ErrorMessage | null>(null);

  const { clearPaymentChannel, paymentChannel } = usePaymentMethod();
  const { paymentRequest } = usePaymentRequest();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (paymentRequest) {
      const authActions = filter(paymentRequest.actions, {
        action: "AUTH"
      }) as PaymentRequestAuthAction[];

      try {
        if (!authActions) {
          throw "No auth actions found";
        }
        const shouldRedirectToMobileUrl = isMobileDevice();
        const [mobileAuthAction, webAuthAction] = [
          find(authActions, { url_type: "MOBILE" })?.url,
          (find(authActions, { url_type: "WEB" }) as PaymentRequestAuthAction)
            ?.url
        ];
        const redirectUrl = shouldRedirectToMobileUrl
          ? mobileAuthAction || webAuthAction
          : webAuthAction;

        if (!redirectUrl) {
          throw "No redirect URL found";
        }
        window.location.href = redirectUrl;
      } catch (error) {
        setError({
          body: `There was an error while redirecting you to the ${
            paymentChannel?.display_name || "partner"
          } checkout page.`,
          title: "Error"
        });
      }
    }
  }, [paymentChannel, paymentRequest]);

  if (!paymentChannel) {
    return null;
  }

  return (
    <div className="flex flex-col items-center py-12 px-4 md:px-0">
      <Graphic className="text-primary mb-4" />
      <p
        className="font-semibold text-center max-w-sm"
        data-testid="redirect-to-partner-copy"
      >
        {t("Redirecting you to {{channel}} checkout page in a moment…", {
          channel: paymentChannel.display_name
        })}
      </p>

      <Dialog
        open={!!error}
        title={error?.title}
        description={error?.body}
        buttons={[
          {
            text: "OK, Got it!",
            variant: "brand-secondary",
            onClick: () => {
              clearPaymentChannel();
            }
          }
        ]}
      />
    </div>
  );
};

export default PaymentRedirect;
