import { FC, SVGProps } from "react";

const Processing: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width={236} height={194} viewBox="0 0 236 194" fill="none" {...props}>
      <path
        opacity={0.24}
        d="M235.193 99.96a86.806 86.806 0 01-.223 3.125 162.644 162.644 0 01-4.274 22.85c-3.58 14.963-7.669 30.552-17.412 41.726-11.742 13.441-29.642 18.022-46.579 18.651-16.937.629-33.937-2.046-50.811-.246-17.1 1.831-33.811 8.239-50.971 7.565a63.344 63.344 0 01-24.578-6.079 51.315 51.315 0 01-20.092-16.209c-8.1-11.28-10.391-25.433-11.388-39.015a31.507 31.507 0 00-.555-3.961 18.894 18.894 0 00-.273-1.237c-1.552-6.315-4.687-11.777-6.55-17.815a37.63 37.63 0 01-.867-3.288 28.023 28.023 0 01-.513-8.3c.7-7.636 3.366-14.91 6.1-22.053 1.212-3.178 2.461-6.368 3.816-9.506 3.273-7.565 7.194-14.787 12.873-20.65 11.387-11.74 28.185-16.211 44.277-18.36 16.092-2.147 32.7-2.516 47.984-8.128 15.818-5.818 29.928-17.13 46.7-18.82 12.255-1.222 24.552 2.972 35.086 9.423 23.269 14.212 36.105 48.619 38.119 77.327.311 4.326.355 8.668.131 13z"
        fill="currentColor"
      />
      <path
        opacity={0.26}
        d="M78.738 135.877c19.19 0 34.746-2.975 34.746-6.644 0-3.67-15.556-6.644-34.746-6.644-19.19 0-34.746 2.974-34.746 6.644 0 3.669 15.556 6.644 34.746 6.644z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        opacity={0.26}
        d="M179.71 135.877c23.846 0 43.177-3.808 43.177-8.505 0-4.697-19.331-8.505-43.177-8.505s-43.177 3.808-43.177 8.505c0 4.697 19.331 8.505 43.177 8.505z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        d="M67.418 103.145a54.31 54.31 0 01-6.132-.582 5.855 5.855 0 00-1.563-.1 6.599 6.599 0 00-1.861.637 19.079 19.079 0 01-11.3 1.219 2.13 2.13 0 01-.837-.133 2.13 2.13 0 01-1.2-1.146 2.117 2.117 0 01-.173-.829 18.09 18.09 0 01-.079-3.652 4.874 4.874 0 01.465-1.977 3.861 3.861 0 011.614-1.461c2.433-1.3 5.369-.931 8.128-.847 3.257.116 6.63-.163 9.771.73a9.48 9.48 0 015.625 4.052c1.436 2.391.365 4.201-2.458 4.089z"
        fill="#FDB386"
      />
      <path
        d="M67.418 103.145a54.31 54.31 0 01-6.132-.582 5.855 5.855 0 00-1.563-.1 6.599 6.599 0 00-1.861.637 19.079 19.079 0 01-11.3 1.219 2.13 2.13 0 01-.837-.133 2.13 2.13 0 01-1.2-1.146 2.117 2.117 0 01-.173-.829 18.09 18.09 0 01-.079-3.652 4.874 4.874 0 01.465-1.977 3.861 3.861 0 011.614-1.461c2.433-1.3 5.369-.931 8.128-.847 3.257.116 6.63-.163 9.771.73a9.48 9.48 0 015.625 4.052c1.436 2.391.365 4.201-2.458 4.089z"
        fill="url(#prefix__paint2_linear)"
      />
      <path
        d="M140.493 112.944l16.377 11.6 54.055.409-15.154-10.78-55.278-1.229z"
        fill="currentColor"
      />
      <path
        d="M146.634 113.391l12.6 8.928 41.585.316-11.66-8.3-42.525-.944z"
        fill="#fff"
      />
      <path
        d="M133.193 75.769s13.307-6.551 17.81-16.991l14.335 4.5s-15.354 20.472-21.3 21.086l-10.845-8.595zM146.909 87.824c.819-.512 12.9-4.5 17.917-14.023l12.181 6.449s-11.976 16.18-22.728 16.689l-7.37-9.115zM190.039 104.206l9.008 10.24-33.164 3.82-4.234-7.509 28.39-6.551z"
        fill="currentColor"
      />
      <path
        d="M166.124 109.822l21.5-4.15a4.66 4.66 0 003.066.931l3.8 4.406s-1.131 1.517-.489 2.447l-23.807 3.364a7.571 7.571 0 00-3.415-1.075l-2.792-4.062s2.523-.274 2.137-1.861z"
        fill="url(#prefix__paint3_linear)"
      />
      <path
        d="M113.768 91.249l-.879-3.438c-.554-1.982-2.075-.256-3.927.637l-52.11 25.124a3.3 3.3 0 00-1.782 3.694l.67 2.973a3.282 3.282 0 001.2 1.884l8.687 6.709a3.3 3.3 0 004.062-.028l42.949-34.095a3.29 3.29 0 001.13-3.46z"
        fill="currentColor"
      />
      <path
        d="M113.768 91.249l-.879-3.438c-.554-1.982-2.075-.256-3.927.637l-52.11 25.124a3.3 3.3 0 00-1.782 3.694l.67 2.973a3.282 3.282 0 001.2 1.884l8.687 6.709a3.3 3.3 0 004.062-.028l42.949-34.095a3.29 3.29 0 001.13-3.46z"
        fill="url(#prefix__paint4_linear)"
      />
      <path
        d="M56.215 113.311l38.51-31.582a1.717 1.717 0 011.614-.3l15.424 5a1.71 1.71 0 01.526 2.978l-43.665 34.076a1.712 1.712 0 01-2.061.033l-10.285-7.497a1.72 1.72 0 01-.065-2.708h.002z"
        fill="currentColor"
      />
      <path
        d="M56.215 113.311l38.51-31.582a1.717 1.717 0 011.614-.3l15.424 5a1.71 1.71 0 01.526 2.978l-43.665 34.076a1.712 1.712 0 01-2.061.033l-10.285-7.497a1.72 1.72 0 01-.065-2.708h.002z"
        fill="url(#prefix__paint5_linear)"
      />
      <path
        d="M109.818 89.8l-1.061.829-37.845 29.484-11.343-7.491-.7-.465 33.843-27.883 17.106 5.527z"
        fill="#fff"
      />
      <path
        opacity={0.13}
        d="M108.757 90.63l-37.845 29.484-11.343-7.491L92.612 85.41l16.145 5.22z"
        fill="currentColor"
      />
      <path
        d="M81.186 111.558l-12.288-6.374 3.769-3.313 12.288 6.369-3.769 3.318z"
        fill="currentColor"
      />
      <path
        d="M81.186 111.558l-12.288-6.374 3.769-3.313 12.288 6.369-3.769 3.318z"
        fill="url(#prefix__paint6_linear)"
      />
      <path
        d="M106.905 80.195l-1.34-.572c.417-.911.885-1.798 1.4-2.657l1.261.73a24.327 24.327 0 00-1.317 2.5l-.004-.001zM111.037 73.513l-1.145-.9a33.756 33.756 0 013.559-3.89l.991 1.065a32.112 32.112 0 00-3.406 3.722l.001.003zm7.379-6.788l-.772-1.233a23.442 23.442 0 014.788-2.326l.465 1.4a22.392 22.392 0 00-4.481 2.159zm14.34-3.131a22.447 22.447 0 00-4.988-.1l-.14-1.447a24.26 24.26 0 015.313.1l-.185 1.447zM140.349 65.701a33.51 33.51 0 00-2.671-.99l.465-1.4c.931.3 1.861.646 2.792 1.037l-.586 1.353zM140.902 117.583l-.409-4.639 16.377 11.6 54.055.409-1.061 2.9-54.562.307-14.4-10.577z"
        fill="currentColor"
      />
      <path
        d="M140.902 117.583l-.409-4.639 16.377 11.6 54.055.409-1.061 2.9-54.562.307-14.4-10.577z"
        fill="url(#prefix__paint7_linear)"
      />
      <path
        d="M50.529 87.14a25.178 25.178 0 019.44.466 21.671 21.671 0 016.453 3.052 36.97 36.97 0 0112.562 14.312c.423.865.73 2.094-.065 2.638-.684.465-1.591 0-2.271-.465-2.964-2.029-12.883-12.1-19.983-8.817-.2 1.526 1.777 4.546 2.015 6.048a3.133 3.133 0 01-2.173 3.141 8.884 8.884 0 01-4.08.219c-3.927-.409-7.84.028-11.79-.065-9.222-.209-14.958-6.849-14.423-10.31.489-2.863 7.733-8.167 24.315-10.218z"
        fill="#FDB386"
      />
      <path
        d="M156.051 105.165l25.39-8.328 8.189 10.236-28.865 6.965-4.714-8.873zM33.742 102.848a12.791 12.791 0 01-2.122 4.871 7.525 7.525 0 00-3.6.144 25.2 25.2 0 00-5.118 2.257 124.152 124.152 0 01-12.13 5.714c-.767.321-1.567.628-2.381.889a75.168 75.168 0 00-4.365-11.535 76.9 76.9 0 01-2.866-7.3c-.363-1.135-.7-2.284-.958-3.476 6.658-2.736 17.457-.712 23.11-1.894a43.774 43.774 0 004.714-1.344 35.5 35.5 0 015.034-1.4c.813 4.276 1.566 8.942.682 13.074z"
        fill="currentColor"
      />
      <path
        d="M33.742 102.848a12.79 12.79 0 01-2.122 4.871c-3.01-.67-5.993.931-8.7 2.4a124.13 124.13 0 01-12.13 5.714c-.767.321-1.567.628-2.381.889a75.169 75.169 0 00-4.365-11.534 20.412 20.412 0 008.035 1.107 83.948 83.948 0 0011.635-1.86c3.323-.656 6.683-1.122 10.028-1.587z"
        fill="url(#prefix__paint8_linear)"
      />
      <path
        d="M153.12 60.732l7.012 2.354s-.931 2.2.2 3.224c0 0-8.035 11.822-14.791 14.688 0 0-.972-1.075-4.043-.768l-4.815-3.634s1.8-.87 1.4-2.252c0 0 9.673-4.913 12.334-11.464-.009.002 2.703.41 2.703-2.148z"
        fill="url(#prefix__paint9_linear)"
      />
      <path
        d="M166.677 76.737l5.918 2.852s-1.056 1.86-.172 2.87c0 0-8.4 9.567-14.688 11.353 0 0-.744-1.056-3.49-1.121l-3.852-3.722s1.68-.572 1.47-1.861c0 0 9.087-3.257 12.162-8.77.019 0 2.368.66 2.652-1.601z"
        fill="url(#prefix__paint10_linear)"
      />
      <path
        d="M160.592 104.956l17.815-5.63a3.51 3.51 0 002.661.614l3.583 3.992s-.819 1.638-.2 2.457l-19.825 5.085a5.538 5.538 0 00-2.973-.717l-2.694-3.755s2.093-.525 1.633-2.046z"
        fill="url(#prefix__paint11_linear)"
      />
      <path
        d="M33.742 102.848a12.791 12.791 0 01-2.122 4.871 7.525 7.525 0 00-3.6.144v-16.69a35.5 35.5 0 015.034-1.4c.818 4.277 1.577 8.943.688 13.075z"
        fill="url(#prefix__paint12_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={78.391}
          y1={100.145}
          x2={78.436}
          y2={208.696}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={179.278}
          y1={90.137}
          x2={179.339}
          y2={229.075}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={61.966}
          y1={109.929}
          x2={59.891}
          y2={85.695}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={172.589}
          y1={107.177}
          x2={173.8}
          y2={156.709}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear"
          x1={90.269}
          y1={124.658}
          x2={83.44}
          y2={79.518}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear"
          x1={89.6}
          y1={105.187}
          x2={41.585}
          y2={62.23}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear"
          x1={79.255}
          y1={103.15}
          x2={76.698}
          y2={113.901}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear"
          x1={176.272}
          y1={116.017}
          x2={176.032}
          y2={143.069}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear"
          x1={18.655}
          y1={102.557}
          x2={20.048}
          y2={143.266}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="prefix__paint9_linear"
          x1={139.687}
          y1={58.908}
          x2={166.131}
          y2={104.089}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="prefix__paint10_linear"
          x1={153.99}
          y1={71.478}
          x2={169.472}
          y2={115.924}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="prefix__paint11_linear"
          x1={169.621}
          y1={93.543}
          x2={171.505}
          y2={139.691}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="prefix__paint12_linear"
          x1={32.892}
          y1={86.427}
          x2={11.099}
          y2={99.589}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Processing;
