import { FC, useMemo } from "react";
import { formatDisplayAmountWithCurrencyCode } from "@xendit/checkout-utilities/dist/src/amount-formatter";
import {
  PaymentChannelsPropertiesType,
  PropertiesFormFieldsType
} from "@xendit/checkout-utilities/dist/src/types/payment-channels";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import isEmpty from "lodash/isEmpty";

import { usePaymentLink } from "../../contexts/PaymentLinkContext";
import { usePaymentMethod } from "../../contexts/PaymentMethodContext";
import { usePaymentRequest } from "../../contexts/PaymentRequestContext";

import {
  getValidationSchema,
  getFormFieldsInitialValues,
  getPaymentMethodChannelProperties
} from "../../helpers/dynamic-form";
import {
  validateDay,
  validateTime
} from "../../helpers/channel-date-time-validation";
import {
  ExternalAnalyticsEvent,
  ExternalAnalyticsProvider,
  InternalAnalyticsEvent,
  logExternalAnalyticsEvent,
  logInternalAnalyticsEvent
} from "../../utils/analytics";

import ArrowLeft from "../../assets/icons/ArrowLeft";
import Button from "../../components/Button";
import PaymentView from "../../components/PaymentView";
import PaymentFormFields from "../PaymentForm/PaymentFormFields";
import PaymentFormInfoBox from "../PaymentForm/PaymentFormInfoBox";
import {
  CountriesEnum,
  CurrenciesEnum,
  DEFAULT_COUNTRY_OF_OPERATION,
  PaymentMethodsEnum,
  getCountryCodeByCurrency
} from "@xendit/checkout-utilities";
import PaymentRedirect from "../PaymentRedirect";
import { MOBILE_BANKING_CHANNEL_CODE_REGEX } from "../../utils/constants";
import { InitialCustomerDataValues } from "../../components/DirectDebitForm";
import CustomerForm from "../../components/CustomerForm";
import ErrorDialog from "../../components/ErrorDialog";
import FraudWarningNotes from "../../components/FraudWarningNotes";
import Graphic from "../../assets/graphics/Processing";

const DynamicFormAction: FC<{
  paymentChannel: PaymentChannelsPropertiesType;
}> = ({ paymentChannel }) => {
  const { t } = useTranslation(["common", "forms"]);

  /**
   * Determines whether to invoke a redirect based on the payment channel and country of operation.
   * - If the country of operation is Thailand and the payment channel matches mobile banking,
   *   the function returns true indicating that after the form submission (payment request initialization), it will redirect using PaymentRedirect Component.
   *   Otherwise, it returns false.
   * @returns {boolean} Whether to invoke a redirect.
   */
  const shouldInvokeRedirect = useMemo(() => {
    // PCAD's country of operation is uppercase, while non PCAD is Capitalized
    if (
      paymentChannel.country_of_operation.toUpperCase() ===
      CountriesEnum.Thailand.toUpperCase()
    ) {
      return MOBILE_BANKING_CHANNEL_CODE_REGEX.test(paymentChannel.channel);
    }

    return (
      // Crypto should redirect to Coinbase
      paymentChannel.payment_method === ("CRYPTOCURRENCY" as PaymentMethodsEnum)
    );
  }, [paymentChannel]);

  if (shouldInvokeRedirect) {
    return <PaymentRedirect />;
  }

  const isDragonpayChannel = paymentChannel.channel.includes("ONLINE_BANKING");
  const paymentPendingMessage = isDragonpayChannel
    ? t(
        "Awaiting payment. This page will be automatically updated once payment has been made.",
        {
          ns: "common"
        }
      )
    : t(
        "Please check your {{channel}} application under “Notifications” and confirm the payment within 55 seconds.",
        {
          channel: paymentChannel.display_name,
          ns: "common"
        }
      );

  return (
    <div className="flex flex-col items-center py-12 px-4 md:px-0">
      <Graphic className="text-primary mb-4" />
      <p className="font-semibold text-center max-w-sm">
        {paymentPendingMessage}
      </p>
    </div>
  );
};

const PaymentForm: FC = () => {
  const { t } = useTranslation(["common", "forms"]);
  const {
    customer,
    paymentLink: {
      invoice: { amount, currency }
    }
  } = usePaymentLink();
  const { clearPaymentChannel, paymentChannel } = usePaymentMethod();
  const { initializing, onInitialize, ready } = usePaymentRequest();

  // Check date and time availability if field exists
  const timeValidation =
    paymentChannel?.date_and_time_availability &&
    validateTime(paymentChannel.date_and_time_availability);
  const dayValidation =
    paymentChannel?.date_and_time_availability &&
    validateDay(paymentChannel.date_and_time_availability);

  // Time and day is automatically available if it's undefined (date_and_time_availability is not set)
  const timeIsCurrentlyAvailable =
    isEmpty(timeValidation) || timeValidation.isAvailable;
  const dayIsCurrentlyAvailable =
    isEmpty(dayValidation) || dayValidation.isAvailable;

  const { errors, handleChange, handleSubmit, isValid, submitForm, values } =
    useFormik({
      initialValues: getFormFieldsInitialValues(
        paymentChannel?.properties?.fields ||
          ([] as Array<PropertiesFormFieldsType>),
        customer
          ? ({
              given_names: customer.given_names ?? "",
              surname: customer.surname ?? "",
              mobile_number: customer.mobile_number ?? "",
              email: customer.email ?? ""
            } as InitialCustomerDataValues)
          : null
      ),
      onSubmit: async (values) => {
        if (initializing) {
          return;
        }

        // send external analytics event on form submission
        logInternalAnalyticsEvent({
          event: InternalAnalyticsEvent.SUBMIT_PAYMENT
        });

        // send external submit analytics event on form submission
        logExternalAnalyticsEvent({
          event_name: ExternalAnalyticsEvent.SUBMIT,
          target: [
            ExternalAnalyticsProvider.FACEBOOK,
            ExternalAnalyticsProvider.GOOGLE
          ]
        });
        onInitialize(
          getPaymentMethodChannelProperties(
            paymentChannel?.properties
              ?.fields as Array<PropertiesFormFieldsType>,
            paymentChannel?.country_of_operation
              ? (paymentChannel?.country_of_operation as CountriesEnum)
              : DEFAULT_COUNTRY_OF_OPERATION,
            values
          )
        );
      },
      validationSchema: yup
        .object()
        .shape(
          getValidationSchema(
            paymentChannel?.properties
              ?.fields as Array<PropertiesFormFieldsType>
          )
        )
    });

  if (
    !paymentChannel ||
    (paymentChannel.properties as { type: string }).type !== "FORM"
  ) {
    return null;
  }

  if (ready) {
    return <DynamicFormAction paymentChannel={paymentChannel} />;
  }

  const shouldHideMobileFooter =
    paymentChannel.payment_method === ("CRYPTOCURRENCY" as PaymentMethodsEnum);

  return (
    <PaymentView
      header
      paymentChannelName={paymentChannel.display_name}
      paymentChannelLogoUrl={paymentChannel.logo_url}
      amount={formatDisplayAmountWithCurrencyCode(amount, currency)}
      footer={
        shouldHideMobileFooter ? null : (
          <div className="flex space-x-4">
            <Button
              type="button"
              onClick={clearPaymentChannel}
              variant="brand-secondary"
              outline
              className="flex-shrink-0"
            >
              <ArrowLeft />
            </Button>
            <Button
              id="pay-now-button"
              type="button"
              onClick={submitForm}
              variant="brand-secondary"
              className="flex-1"
              disabled={initializing || !isValid}
            >
              {t("Pay Now", { ns: "common" })}
            </Button>
          </div>
        )
      }
    >
      {paymentChannel.properties?.fields ? (
        <form
          onSubmit={handleSubmit}
          className="py-6 md:pb-12 flex flex-col space-y-12"
        >
          <PaymentFormFields
            fields={
              paymentChannel.properties
                ?.fields as Array<PropertiesFormFieldsType>
            }
            values={values}
            errors={errors}
            initializing={initializing}
            handleChange={handleChange}
          />
          <div className="hidden md:flex justify-center space-x-2">
            <Button
              variant="brand-secondary"
              outline
              type="button"
              onClick={clearPaymentChannel}
              className="w-36"
              disabled={initializing}
              data-testid="back"
            >
              {t("Back", { ns: "common" })}
            </Button>
            <Button
              variant="brand-secondary"
              type="submit"
              className="w-36"
              disabled={initializing || !isValid}
              data-testid="pay-now"
            >
              {t("Pay Now", { ns: "common" })}
            </Button>
          </div>
        </form>
      ) : (
        <CustomerForm
          onCancel={clearPaymentChannel}
          onSubmit={(values) => {
            return new Promise((resolve) => {
              onInitialize({ customer: values }, () => {
                resolve();
              });
            });
          }}
          defaultCountry={paymentChannel.country_of_operations[0]}
          countryCode={getCountryCodeByCurrency(currency as CurrenciesEnum)}
          shouldRenderMobileFooter
        />
      )}
      <PaymentFormInfoBox channel={paymentChannel?.channel} />
      <ErrorDialog
        open={!timeIsCurrentlyAvailable || !dayIsCurrentlyAvailable}
        title={t("{{channel}} is not available.", {
          channel: paymentChannel.display_name
        })}
        description={t("Currently is outside of operating hours.")}
        onClose={clearPaymentChannel}
      />
    </PaymentView>
  );
};

export default PaymentForm;
