import { datadogRum } from "@datadog/browser-rum";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import ProcessingGraphic from "../../../assets/graphics/Processing";
import Layout, { MainView } from "../../../components/Layout";
import PoweredByXendit from "../../../components/PoweredByXendit";
import RollingLoader from "../../../components/RollingLoader";
import { getSession } from "../../api/api";
import { useSessionContext } from "../../context/context";
import { SessionActionTypes } from "../../context/SessionContextInterface";

export const TOKEN_REQUEST_ID_PARAM = "token_request_id";

const SessionLoading: React.FC = () => {
  const context = useSessionContext();
  const { t } = useTranslation("session");
  const { state, dispatch } = context;
  const { viewState } = state;
  const [finishedLoading, setFinishedLoading] = useState(false);

  if (!viewState) {
    throw new Error("viewState is not defined");
  }

  // Get the path params
  const { id: sessionId } = useParams();

  if (!sessionId) {
    throw new Error("sessionId is not defined");
  }

  // Get the query params
  const [searchParams] = useSearchParams();
  const tokenRequestId = searchParams.get(TOKEN_REQUEST_ID_PARAM) ?? undefined;

  const loadSession = async () => {
    const response = await getSession(sessionId);

    if (response.isLeft()) {
      dispatch({
        type: SessionActionTypes.SET_ERROR_CONTENT,
        payload: {
          errorContent: response.getLeft()
        }
      });
    } else {
      dispatch({
        type: SessionActionTypes.SET_INITIAL_STATE,
        payload: response.getRight()
      });
    }
  };

  useEffect(() => {
    document.querySelector(".loading-container")?.remove();
    if (tokenRequestId) {
      dispatch({
        type: SessionActionTypes.SET_TOKEN_REQUEST_ID,
        payload: { tokenRequestId }
      });
      setFinishedLoading(true);
    } else {
      loadSession().then(() => {
        // Remove the loading screen when data has loaded
        datadogRum.setGlobalContextProperty("sessionState", state);
        setFinishedLoading(true);
      });
    }
  }, []);

  // Transition to the next state once the required data has loaded
  useEffect(() => {
    if (finishedLoading) {
      viewState.transitionView(context);
    }
  }, [finishedLoading]);

  return (
    <Layout>
      <MainView>
        <div className="flex-1 px-4 lg:px-0">
          <div className="pt-8 pb-12 space-y-8">
            <div className="flex flex-col items-center">
              <ProcessingGraphic />
              <RollingLoader className="text-primary w-24 h-24 mt-4" />
              <p className="text-lg text-center mt-4 mb-4">
                {t("Please wait while your session is being loaded")}
              </p>
              <PoweredByXendit />
            </div>
          </div>
        </div>
      </MainView>
    </Layout>
  );
};

export default SessionLoading;
