import get from "lodash/get";
import {
  PaylaterChannelsEnum,
  PaymentChannelsEnum
} from "@xendit/checkout-utilities/dist/src/enums/payment-channels";
import {
  PaymentChannelsPropertiesType,
  getCountryOfOperationByCurrency
} from "@xendit/checkout-utilities";

import { Customer } from "../types/checkout";
import { CustomerFormValues } from "../components/CustomerForm";

/**
 * Error mapper for PayLater service
 * @see https://xendit.atlassian.net/wiki/spaces/D/pages/1483671460/BillEase+Failure+Messages
 */
export const paylaterErrors: { [errorCode: string]: ErrorMessage } = {
  UNSUPPORTED_CURRENCY: {
    title: "Unsupported Currency",
    body: "The currency is currently not supported by the PayLater provider"
  },
  TRANSACTION_BLOCKED: {
    title: "Transaction Blocked",
    body: "The PayLater provider detected suspicious activity. Please contact your provider."
  },
  ACCOUNT_ACCESS_BLOCKED: {
    title: "Account Access Blocked",
    body: "The account is blocked. Please contact your provider."
  },
  CHANNEL_NOT_ACTIVATED: {
    title: "PayLater Provider Not Activated",
    body: "The PayLater provider is not activated"
  },
  MAXIMUM_LIMIT_REACHED: {
    title: "Maximum Limit Reached",
    body: "Maximum transaction limit has been reached."
  },
  TRANSACTION_TIMEOUT: {
    title: "Transaction Timeout",
    body: "Transaction Timeout. Please try again."
  },
  CUSTOMER_COUNTRY_NOT_SUPPORTED: {
    title: "Country not supported",
    body: "Your country is not supported by PayLater. Please choose a different payment method"
  },
  INVALID_CUSTOMER_MOBILE_NUMBER: {
    title: "Invalid mobile number",
    body: "The mobile number entered is invalid. Please ensure that you have entered a valid mobile number and try again"
  },
  SERVER_ERROR: {
    title: "PayLater Payment Error",
    body: "We have encountered some issue. Please try again"
  }
};

export const paylaterInformationLinks: Partial<{
  [key in PaylaterChannelsEnum]: string;
}> = {
  [PaylaterChannelsEnum.KREDIVO]: "https://www.kredivo.id/cara-daftar-kredivo/"
};

export const getPaylaterErrorMessage = (errorCode: string) => {
  return paylaterErrors[errorCode] || paylaterErrors.SERVER_ERROR;
};

export const getPaylaterChannelCode = (
  paymentChannel: PaymentChannelsPropertiesType,
  currency: string
) => {
  const paylaterCountryCode = getPaylaterCountryCode(currency);
  return `${paylaterCountryCode}_${paymentChannel.channel}`;
};

export const getPaylaterCountryCode = (currency: string) => {
  return getCountryOfOperationByCurrency(currency || "IDR")?.alpha2Code;
};

export const mapCustomerDataToFormValues = (
  customer?: Customer | null
): CustomerFormValues | null => {
  const formValues = null;
  if (!customer) {
    return formValues;
  }

  return {
    address: get(customer, "addresses[0].street_line1", ""),
    city: get(customer, "addresses[0].city", ""),
    country: get(customer, "addresses[0].country", ""),
    email: get(customer, "email", ""),
    firstName: get(customer, "given_names", ""),
    lastName: get(customer, "surname", ""),
    mobileNumber: get(customer, "mobile_number", ""),
    postalCode: get(customer, "addresses[0].postal_code", "")
  };
};

export const shouldShowPaylaterInstallmentOptions = (
  channelCode: PaymentChannelsEnum
) => {
  switch (channelCode) {
    case PaymentChannelsEnum.CASHALO:
    case PaymentChannelsEnum.ATOME:
      return false;
    default:
      return true;
  }
};

export const supportsPaylaterCustomerForm = (
  channelCode: PaymentChannelsEnum
) => {
  switch (channelCode) {
    case PaymentChannelsEnum.KREDIVO:
      return false;
    default:
      return true;
  }
};

export const hasRequiredCustomerInfoForPaylater = (
  customer?: Customer | null
): boolean => {
  if (!customer) {
    return false;
  }

  const requiredFields = [
    "given_names",
    "surname",
    "email",
    "mobile_number",
    "addresses[0].country",
    "addresses[0].city",
    "addresses[0].postal_code",
    "addresses[0].street_line1"
  ];

  return requiredFields.every((key) => !!get(customer, key, null));
};

export const hasCustomerId = (customer?: Customer | null): boolean => {
  if (!customer) {
    return false;
  }

  return customer.customer_id ? true : false;
};
