import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormikErrors, FormikHandlers } from "formik";
import { withMask } from "use-mask-input";
import kebabCase from "lodash/kebabCase";

import { PropertiesFormFieldsType } from "@xendit/checkout-utilities/dist/src/types/payment-channels";

import FormField from "../../components/FormField";
import Input from "../../components/Input";

type Props = {
  fields: Array<PropertiesFormFieldsType>;
  values: object;
  errors: FormikErrors<unknown>;
  initializing: boolean;
  handleChange: FormikHandlers["handleChange"];
};

const PaymentFormFields: FC<Props> = (props) => {
  const { t } = useTranslation(["forms"]);
  const { fields, values, errors, initializing, handleChange } = props;

  if (!fields) {
    return null;
  }

  return (
    <>
      {fields.map((data: PropertiesFormFieldsType) => {
        const label = t(data.label, { ns: "forms" });
        const fieldName = data.name;
        const error = errors[fieldName as keyof typeof errors];

        return (
          <div className="flex flex-col space-y-4" key={fieldName}>
            <FormField
              label={label}
              name={fieldName}
              helpText={error}
              state={error ? "error" : "default"}
            >
              <Input
                block
                name={fieldName}
                value={values[fieldName as keyof typeof values]}
                type={data?.type}
                placeholder={data?.placeholder}
                autoComplete={data?.autocomplete}
                onChange={handleChange}
                disabled={initializing}
                hasError={!!error}
                data-testid={kebabCase(fieldName)}
                ref={
                  data?.type === "tel"
                    ? withMask(`9999 9999 9999 9999`, {
                        placeholder: "",
                        onBeforePaste: (value: string) =>
                          !value.startsWith("+") ? `+${value}` : value
                      })
                    : null
                }
              />
            </FormField>
          </div>
        );
      })}
    </>
  );
};

export default PaymentFormFields;
