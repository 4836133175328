import {
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  ReactNode,
  forwardRef
} from "react";
import classnames from "classnames";
import { useFormField } from "../FormField/FormField";

export type InputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  append?: ReactNode;
  block?: boolean;
  hasError?: boolean;
  prepend?: ReactNode;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ append, block, className, hasError, prepend, ...props }, ref) => {
    const formField = useFormField();

    const inputClassName = classnames([
      "px-2 h-10 text-lg text-xen-gray-900 placeholder-xen-gray-600 bg-white disabled:bg-xen-gray-300 disabled:text-xen-gray-700 disabled:bg-opacity-25 border border-xen-gray-400 rounded outline-none focus:border-primary hover:border-xen-gray-600 disabled:hover:border-xen-gray-400 transition-colors peer",
      {
        "w-full flex-1": block,
        "border-xen-red-500": hasError || formField.state === "error",
        "border-r-0 rounded-r-none pr-0": !!append,
        "border-l-0 rounded-l-none pl-0": !!prepend
      },
      className
    ]);

    return (
      <div className="flex">
        <input type="text" className={inputClassName} {...props} ref={ref} />
        {prepend ? (
          <InputDecoration type="prepend">{prepend}</InputDecoration>
        ) : null}
        {append ? (
          <InputDecoration type="append">{append}</InputDecoration>
        ) : null}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;

type InputDecorationProps = {
  children: ReactNode;
  hasError?: boolean;
  type: "append" | "prepend";
};

const InputDecoration: FC<InputDecorationProps> = ({
  children,
  hasError,
  type
}) => {
  const formField = useFormField();
  const className = classnames([
    "h-10 bg-white border flex items-center px-2 border-xen-gray-400 peer-focus:border-primary peer-hover:border-xen-gray-600 peer-disabled:hover:border-xen-gray-400 peer-disabled:bg-xen-gray-300 peer-disabled:bg-opacity-25 transition-colors",
    {
      "border-r-0 rounded-l -order-1": type === "prepend",
      "border-l-0 rounded-r": type === "append",
      "border-xen-red-500": hasError || formField.state === "error"
    }
  ]);

  return <span className={className}>{children}</span>;
};
