import { FC, SVGProps } from "react";

const Card: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.25 5.625H3.75c-.621 0-1.125.504-1.125 1.125v10.5c0 .621.504 1.125 1.125 1.125h16.5c.621 0 1.125-.504 1.125-1.125V6.75c0-.621-.504-1.125-1.125-1.125Z"
        stroke="#777"
      />
      <path d="M2.5 9.5h19v2h-19v-2Z" fill="#fff" stroke="#777" />
      <path d="M4.5 15h6" stroke="#777" strokeLinecap="round" />
      <path d="M19.5 12.75h-3.75v3.75h3.75v-3.75Z" fill="#777" />
      <path d="M19.125 13.125h-3v3h3v-3Z" fill="#777" stroke="#777" />
    </svg>
  );
};

export default Card;
