import { ErrorContent, PaymentToken } from "../types";
import { Either } from "./either";

export function findPTRedirectAction(paymentToken: PaymentToken): Either<
  ErrorContent,
  {
    descriptor: string;
    type: string;
    value: string;
  }
> {
  const redirectAction = paymentToken.actions.find(
    (action) => action.type === "REDIRECT_CUSTOMER"
  );
  if (!redirectAction) {
    return Either.makeLeft<ErrorContent>({
      title: "Error",
      message_1: "There was a problem with the create payment token response.",
      message_2: "REDIRECT_CUSTOMER action type is not present"
    });
  }

  return Either.makeRight(redirectAction);
}

export function isPTStatusFailed(paymentToken: PaymentToken) {
  return paymentToken.status === "FAILED";
}

export function isPTStatusRequiresAction(paymentToken: PaymentToken) {
  return paymentToken.status === "REQUIRES_ACTION";
}
