export const isWebview = (): boolean => {
  if (typeof window === undefined) {
    return false;
  }

  const navigator = window.navigator;

  const userAgent = navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
  const ios_ipad_webview = ios && !safari;

  return ios ? !safari || ios_ipad_webview : userAgent.includes("wv");
};
