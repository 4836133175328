import { FC, useState } from "react";
import { formatDisplayAmountWithCurrencyCode } from "@xendit/checkout-utilities";
import { Trans, useTranslation } from "react-i18next";
import map from "lodash/map";
import min from "lodash/min";

import { titleCase } from "../../utils/strings";
import type { TranslationInterpolation } from "../../types/i18next";

type InstallmentOption = {
  installmentAmount: number;
  interval: string;
  intervalCount: number;
  totalAmount: number;
  totalRecurrence: number;
};
type Props = {
  currency?: string;
  informationLink?: string;
  options: InstallmentOption[];
};

const InstallmentOptions: FC<Props> = (props) => {
  const [detailsShown, setDetailsShown] = useState(true);
  const { t } = useTranslation("paylater");

  const lowestInstallment = formatDisplayAmountWithCurrencyCode(
    min(map(props.options, "installmentAmount")),
    props.currency
  );

  return (
    <div className="flex flex-col space-y-6">
      <div className="flex">
        <div className="flex-1 pr-4 space-y-1">
          <p data-testid="paylater-estimation-amount">
            <Trans t={t}>
              Pay as low as{" "}
              <strong>
                {{ amount: lowestInstallment } as TranslationInterpolation}
              </strong>{" "}
              per installment
            </Trans>
          </p>
          {props.informationLink ? (
            <p className="text-sm" data-testid="more-information-button">
              <a
                href={props.informationLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary"
              >
                <strong>{t("More information")}</strong>
              </a>
            </p>
          ) : null}
        </div>
        <div className="flex-shrink-0">
          <button
            type="button"
            className="whitespace-nowrap font-semibold text-secondary"
            onClick={() => {
              setDetailsShown(!detailsShown);
            }}
            data-testid="hide-details-button"
          >
            {t(detailsShown ? "Hide details" : "Show details")}
          </button>
        </div>
      </div>
      {detailsShown ? (
        <div className="flex flex-col space-y-4">
          <table data-testid="installment-table">
            <thead>
              <tr>
                <th className="border border-xen-gray-200 bg-xen-gray-200 font-semibold py-2 text-left px-2">
                  {t("Terms")}
                </th>
                <th className="border border-xen-gray-200 bg-xen-gray-200 font-semibold py-2 text-right px-2">
                  {t("Installment Amount")}*
                </th>
                <th className="border border-xen-gray-200 bg-xen-gray-200 font-semibold py-2 text-right px-2">
                  {t("Total Amount")}*
                </th>
              </tr>
            </thead>
            <tbody>
              {props.options.map((option, index) => {
                return (
                  <tr key={index} className="group">
                    <td className="border-b border-l border-xen-gray-200 py-3 px-2 bg-white">
                      {t(
                        `{{count}} ${titleCase(
                          option.interval.toLocaleLowerCase()
                        )}`,
                        { count: option.totalRecurrence }
                      )}
                    </td>
                    <td className="border-b border-xen-gray-200 text-right py-3 px-2 bg-white">
                      {formatDisplayAmountWithCurrencyCode(
                        option.installmentAmount,
                        props.currency
                      )}
                    </td>
                    <td className="border-b border-r border-xen-gray-200 text-right py-3 px-2 bg-white">
                      {formatDisplayAmountWithCurrencyCode(
                        option.totalAmount,
                        props.currency
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p className="text-sm leading-6 text-xen-gray-800">
            {t(
              "The installments above are estimates based on the Order Amount. Please note that the final amount of installments will be calculated by and disclosed to you by the chosen provider."
            )}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default InstallmentOptions;
