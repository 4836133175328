import { useEffect, FC, useReducer } from "react";
import { AxiosError } from "axios";
import * as BaseDialog from "@radix-ui/react-dialog";

import { X } from "../../assets/icons";

import { useSimulation } from "../../contexts/SimulationContext";

import OTPInput from "../OTPInput";
import Dialog from "../../components/Dialog";

import { OTP_ERROR, getOTPErrors } from "../../helpers/otp";
import { logFetchUnexpectedResponse } from "../../utils/rum";

type Props = {
  open?: boolean;
  title?: string;
  description?: React.ReactNode;
  numOTPInput: number;
  onVerifyOTP: (otp: string) => void;
  onClose?: () => void;
};

type OTPState = {
  otpCode: string;
  disableOTPInput: boolean;
  otpError: ErrorMessage | null;
};

type OTPReducerAction =
  | { type: "set_otp_code"; payload: string }
  | { type: "set_disable_otp_input"; payload: boolean }
  | { type: "set_otp_error"; payload: SetErrorPayload }
  | { type: "reset_otp_state" };

type SetErrorPayload = {
  error: ErrorMessage;
};

const initialState = {
  otpCode: "",
  disableOTPInput: false,
  otpError: null
} as OTPState;

const OTPReducer = (state: OTPState, action: OTPReducerAction): OTPState => {
  switch (action.type) {
    case "set_otp_code":
      return { ...state, otpCode: action.payload };
    case "set_disable_otp_input":
      return { ...state, disableOTPInput: action.payload };
    case "set_otp_error":
      return {
        ...state,
        otpError: action.payload.error
      };
    case "reset_otp_state":
      return initialState;
    default:
      return state;
  }
};

const OTPDialog: FC<Props> = (props) => {
  const { open, title, numOTPInput, onVerifyOTP, onClose } = props;

  const { status: simulationStatus, onSimulateOTPInput: simulateOTPInput } =
    useSimulation();

  const [state, dispatch] = useReducer(OTPReducer, initialState);

  const handleChange = async (_otpCode: string) => {
    dispatch({
      type: "set_otp_code",
      payload: _otpCode
    });

    if (_otpCode.length === numOTPInput) {
      try {
        dispatch({
          type: "set_disable_otp_input",
          payload: true
        });

        await onVerifyOTP(_otpCode);
      } catch (error) {
        handleOTPError(error as ErrorResponse);
      }
    }
  };

  const handleOTPError = async (error: ErrorResponse) => {
    let errorMessage: ErrorMessage = OTP_ERROR.SERVER_ERROR;

    if (error instanceof AxiosError) {
      const errorResponse = error.response?.data;
      errorMessage = getOTPErrors(errorResponse.error_code);
    } else {
      logFetchUnexpectedResponse(error);
    }

    dispatch({
      type: "set_otp_error",
      payload: { error: errorMessage }
    });
  };

  useEffect(() => {
    dispatch({
      type: "reset_otp_state"
    });
  }, [open]);

  useEffect(() => {
    if (simulationStatus === "done" && open) {
      setTimeout(() => {
        simulateOTPInput();
      }, 100);
    }
  }, [simulationStatus, open]);

  return (
    <BaseDialog.Root open={open}>
      {open ? (
        <>
          <BaseDialog.Portal>
            <BaseDialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 data-[state=open]:animate-dialog-overlay-show" />
            <BaseDialog.Content className="fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[320px] translate-x-[-50%] translate-y-[-50%] bg-white rounded-xl shadow-xl overflow-hidden focus:outline-none py-4 data-[state=open]:animate-dialog-content-show">
              <BaseDialog.Title className="px-4 mb-2 font-semibold text-xl flex items-baseline">
                <span className="flex-1 pr-4">{title}</span>
                {onClose ? (
                  <button
                    type="button"
                    title="Close"
                    className="text-xen-gray-700 hover:text-xen-gray-800"
                    onClick={onClose}
                  >
                    <X className="w-4 h-4" />
                  </button>
                ) : null}
              </BaseDialog.Title>
              <BaseDialog.Description className="px-4 prose-sm mb-4">
                {props.description}
                <OTPInput
                  className="mt-6 mb-4 w-100 text-sm flex justify-between"
                  inputClassName="w-10 text-4xl text-center font-light border border-xen-gray-500"
                  otpCode={state.otpCode}
                  length={numOTPInput}
                  disabled={state.disableOTPInput}
                  onChangeOTP={handleChange}
                />
              </BaseDialog.Description>
            </BaseDialog.Content>
          </BaseDialog.Portal>
          <Dialog
            open={!!state.otpError}
            title={state.otpError?.title}
            description={state.otpError?.body}
            buttons={[
              {
                text: "OK, Got it!",
                variant: "brand-secondary",
                onClick: () => {
                  dispatch({
                    type: "reset_otp_state"
                  });
                }
              }
            ]}
          />
        </>
      ) : null}
    </BaseDialog.Root>
  );
};

export default OTPDialog;
