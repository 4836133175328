import { FC } from "react";
import { useTranslation } from "react-i18next";

import Graphic from "../../assets/graphics/Oops";

const NotFound: FC = () => {
  const { t } = useTranslation("not-found");

  return (
    <div className="flex flex-col items-center px-4 lg:px-0 pt-12 lg:pt-4">
      <Graphic className="text-primary mb-4" />
      <p className="font-semibold text-lg text-center mb-4">
        {t("Oops! Page Not Found")}
      </p>
      <p className="text-center">
        {t("Sorry, but the page you were looking for could not be found.")}
      </p>
    </div>
  );
};

export default NotFound;
