/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Session } from "../types";
import {
  SessionContextInterface,
  SessionState,
  SessionActionTypes
} from "./SessionContextInterface";
import { LoadingSessionState, PollingSessionState } from "./viewState";

export const SessionContext = React.createContext<SessionContextInterface>({
  state: {
    viewState: new LoadingSessionState()
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const useSessionContext = () => React.useContext(SessionContext);

export const sessionReducer = (
  state: SessionState,
  action: { type: SessionActionTypes; payload: Partial<SessionState> }
) => {
  switch (action.type) {
    case SessionActionTypes.SET_INITIAL_STATE:
      return { ...state, ...action.payload };
    case SessionActionTypes.SET_SESSION:
      return {
        ...state,
        session: action.payload.session
      };
    case SessionActionTypes.SET_SUCCEEDED_CHANNEL:
      return {
        ...state,
        succeededChannel: action.payload.succeededChannel
      };
    case SessionActionTypes.SET_ERROR_CONTENT:
      return {
        ...state,
        errorContent: action.payload.errorContent
      };
    case SessionActionTypes.TRANSITION_VIEW:
      return {
        ...state,
        viewState: action.payload.viewState
      };
    case SessionActionTypes.REDIRECT_TO_POLLING:
      return {
        ...state,
        viewState: new PollingSessionState(),
        tokenRequestId: action.payload.tokenRequestId
      };
    case SessionActionTypes.SET_TOKEN_REQUEST_ID:
      return {
        ...state,
        tokenRequestId: action.payload.tokenRequestId
      };
    case SessionActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    default:
      return state;
  }
};

export const isSessionSaveOnly = (session: Session) => {
  return session.session_type === "SAVE";
};

export const isSessionPayOnly = (session: Session) => {
  return session.session_type === "PAY";
};

export const isSessionAuthorizationOnly = (session: Session) => {
  return session.session_type === "AUTHORIZATION";
};
