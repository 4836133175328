import {
  FC,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import clsx from "classnames";
import isEmpty from "lodash/isEmpty";
import { PaymentMethodsEnum } from "@xendit/checkout-utilities/dist/src/enums/payment-methods";
import { CountriesEnum } from "@xendit/checkout-utilities";

import {
  Header,
  HeaderAddon,
  HeaderBackButton,
  HeaderContent,
  HeaderImage,
  HeaderTitle
} from "../../components/Header";
import { MainView, Sidebar } from "../../components/Layout";
import PaymentMethodList, {
  PaymentMethodListLoadingSkeleton
} from "../../components/PaymentMethodList";
import AmountDue from "../../components/AmountDue";
import Button from "../../components/Button";
import LanguageNavigation from "../../components/LanguageNavigation";
import OrderSummary, { OrderSummaryProps } from "../../components/OrderSummary";
import PoweredByXendit from "../../components/PoweredByXendit";
import RollingLoader from "../../components/RollingLoader";
import SimulationBar from "../../components/SimulationBar";
import PaymentInstructionsTabsSkeleton from "../../components/PaymentInstructionsTabsSkeleton";
import PaymentInstructionsStepsSkeleton from "../../components/PaymentInstructionsStepsSkeleton";

import { usePaymentLink } from "../../contexts/PaymentLinkContext";
import { usePaymentMethod } from "../../contexts/PaymentMethodContext";
import CreditCardPaymentProvider, {
  useCreditCardPayment
} from "../../contexts/CreditCardPaymentContext";
import LegacyAsyncPaymentProvider from "../../contexts/LegacyAsyncPaymentContext";
import PaymentRequestProvider from "../../contexts/PaymentRequestContext";
import SimulationProvider from "../../contexts/SimulationContext";

import { APP_ENV_MODE, ENABLE_SIMULATION } from "../../utils/constants";
import {
  ExternalAnalyticsEvent,
  ExternalAnalyticsProvider,
  InternalAnalyticsEvent,
  logExternalAnalyticsEvent,
  logInternalAnalyticsEvent
} from "../../utils/analytics";

import mapPaymentMethodListItems from "./map-payment-method-list-items";
import PaymentRedirect from "../PaymentRedirect";
import Processing from "../../assets/graphics/Processing";
import CustomBadges from "../../components/CustomBadges";
import ErrorDialog from "../../components/ErrorDialog";

import PaymentForm from "../PaymentForm";
import PaymentQrCode from "../PaymentQrCode";
import PaymentInstructionsTabs from "../PaymentInstructionsTabs";
import PaymentInstructionsSteps from "../PaymentInstructionsSteps";
import PaymentDebitCard from "../PaymentDebitCard";
import { isMobileDevice } from "../../helpers/is-mobile-device";

declare global {
  interface Window {
    Xendit?: () => void;
  }
}

const SuspenseFallback: FC = () => {
  return (
    <div className="py-12 px-4 flex justify-center">
      <RollingLoader className="h-12 w-12" />
    </div>
  );
};

const PaymentSection = () => {
  const {
    paymentLink: {
      invoice,
      invoice_settings,
      feature_flags,
      exchange_rates,
      public_api_key
    },
    promotion
  } = usePaymentLink();
  const { status: creditCardStatus } = useCreditCardPayment();
  const {
    checkoutPaymentChannels,
    clearPaymentChannel,
    clearPaymentMethodType,
    paymentChannel,
    paymentMethodType,
    ready,
    selectPaymentChannel,
    selectPaymentMethodType
  } = usePaymentMethod();
  const { t } = useTranslation("common");

  const [amountIsEstimation, amount, amountCurrency] = useMemo(() => {
    if (
      !exchange_rates ||
      !invoice_settings.display_currency ||
      invoice_settings.display_currency === invoice.currency
    ) {
      return [false, invoice.amount, invoice.currency];
    }
    return [
      true,
      invoice.amount * exchange_rates.rates[invoice_settings.display_currency],
      invoice_settings.display_currency
    ];
  }, [
    exchange_rates,
    invoice.amount,
    invoice.currency,
    invoice_settings.display_currency
  ]);

  useEffect(() => {
    if (
      invoice.payment_methods?.includes(PaymentMethodsEnum.CREDIT_CARD) &&
      public_api_key?.api_key &&
      !window.Xendit
    ) {
      const script = document.createElement("script");
      const xenditjs = "https://js.xendit.co/v1/xendit.min.js";
      script.src = xenditjs;
      script.onload = () => {
        if (APP_ENV_MODE.includes("staging")) {
          Xendit._useStagingURL(true);
        }
        Xendit.setPublishableKey(public_api_key?.api_key || "");
      };
      document.body.append(script);
    }
  }, [invoice.payment_methods, public_api_key]);

  const shouldRenderPaymentChannelView =
    paymentChannel &&
    paymentMethodType &&
    ![
      PaymentMethodsEnum.CREDIT_CARD,
      PaymentMethodsEnum.QR_CODE,
      PaymentMethodsEnum.PAYLATER
    ].includes(paymentMethodType);

  const propertiesByCurrencyType =
    paymentChannel?.properties_by_currency &&
    paymentChannel?.properties_by_currency[invoice.currency]?.type;
  let channelPaymentType =
    propertiesByCurrencyType || paymentChannel?.properties.type;

  // MY WeChatPay for mobile has different behavior
  // between desktop and mobile devices.
  if (
    isMobileDevice() &&
    paymentChannel?.channel === "WECHATPAY" &&
    paymentChannel?.country_of_operation ===
      CountriesEnum.Malaysia.toUpperCase()
  ) {
    channelPaymentType = "REDIRECT";
  }

  if (shouldRenderPaymentChannelView) {
    switch (channelPaymentType) {
      case "FORM":
        return (
          <Suspense fallback={<SuspenseFallback />}>
            <PaymentRequestProvider>
              {ENABLE_SIMULATION ? (
                <SimulationProvider>
                  <SimulationBar />
                  <PaymentForm />
                </SimulationProvider>
              ) : (
                <PaymentForm />
              )}
            </PaymentRequestProvider>
          </Suspense>
        );
      case "REDIRECT":
      case "BANK_ACCOUNT":
        return (
          <PaymentRequestProvider
            onErrorDismissed={() => {
              clearPaymentChannel();
            }}
          >
            {ENABLE_SIMULATION ? <SimulationBar /> : null}
            <PaymentRedirect />
          </PaymentRequestProvider>
        );
      case "QR_CODE":
        return (
          <Suspense fallback={<SuspenseFallback />}>
            <PaymentRequestProvider>
              {ENABLE_SIMULATION ? (
                <SimulationProvider>
                  <SimulationBar />
                  <PaymentQrCode />
                </SimulationProvider>
              ) : (
                <PaymentQrCode />
              )}
            </PaymentRequestProvider>
          </Suspense>
        );
      case "TABS":
        return (
          <Suspense fallback={<PaymentInstructionsTabsSkeleton />}>
            <LegacyAsyncPaymentProvider>
              {ENABLE_SIMULATION ? (
                <SimulationProvider>
                  <SimulationBar />
                  <PaymentInstructionsTabs />
                </SimulationProvider>
              ) : (
                <PaymentInstructionsTabs />
              )}
            </LegacyAsyncPaymentProvider>
          </Suspense>
        );
      case "STEPS":
        return (
          <Suspense fallback={<PaymentInstructionsStepsSkeleton />}>
            <LegacyAsyncPaymentProvider>
              {ENABLE_SIMULATION ? (
                <SimulationProvider>
                  <SimulationBar />
                  <PaymentInstructionsSteps />
                </SimulationProvider>
              ) : (
                <PaymentInstructionsSteps />
              )}
            </LegacyAsyncPaymentProvider>
          </Suspense>
        );
      case "DEBIT_CARD":
        return (
          <Suspense fallback={<SuspenseFallback />}>
            <PaymentRequestProvider>
              {ENABLE_SIMULATION ? (
                <SimulationProvider>
                  <SimulationBar />
                  <PaymentDebitCard />
                </SimulationProvider>
              ) : (
                <PaymentDebitCard />
              )}
            </PaymentRequestProvider>
          </Suspense>
        );
      default:
        // for debugging
        // TODO remove this after all channel type is covered
        return (
          <section className="py-12 px-4 lg:px-0">
            <p className="mb-4">
              <strong>Selected channel:</strong>
            </p>
            <pre className="bg-xen-gray-200 overflow-auto p-4 mb-6">
              {JSON.stringify(paymentChannel, null, 2)}
            </pre>
            <div className="flex justify-center">
              <Button
                variant="brand-secondary"
                type="button"
                onClick={clearPaymentChannel}
              >
                Back
              </Button>
            </div>
          </section>
        );
    }
  }

  const paymentMethodItems = mapPaymentMethodListItems(
    invoice,
    invoice_settings,
    checkoutPaymentChannels,
    t
  );

  const shouldRenderSimulationBar =
    ENABLE_SIMULATION &&
    !paymentChannel &&
    paymentMethodType !== PaymentMethodsEnum.CREDIT_CARD;

  return (
    // show hidden when credit card payment state in_review, verified, processing
    <div
      className={clsx({
        hidden: ["in_review", "verified", "processing", "resolved"].includes(
          creditCardStatus
        )
      })}
    >
      <>
        {shouldRenderSimulationBar ? <SimulationBar /> : null}
        <AmountDue
          amount={amount}
          currency={amountCurrency}
          expiryDate={invoice.expiry_date}
          expiryTimeFormat={invoice_settings.payment_due_time_format}
          exactValue={
            amountIsEstimation
              ? {
                  amount: invoice.amount,
                  currency: invoice.currency
                }
              : null
          }
          totalAmount={invoice.amount}
          showExpiry={invoice_settings.should_show_expiry_date_time}
          promotion={promotion}
        />
        <section className="pb-12">
          <div className="w-full max-w-screen-sm mx-auto px-4 lg:px-0">
            <h2 className="font-semibold uppercase mb-4">
              {t("Payment Method")}
            </h2>
            <Suspense
              fallback={
                <PaymentMethodListLoadingSkeleton
                  count={paymentMethodItems.length}
                />
              }
            >
              {ready ? (
                <PaymentMethodList
                  items={paymentMethodItems}
                  onSelectChannel={selectPaymentChannel}
                  onSelectPaymentMethodType={(paymentMethodType) => {
                    if (isEmpty(paymentMethodType)) {
                      clearPaymentMethodType();
                      return;
                    }
                    selectPaymentMethodType(paymentMethodType);
                  }}
                  value={paymentMethodType || ""}
                />
              ) : null}
            </Suspense>
          </div>
        </section>
      </>
    </div>
  );
};

const CheckoutPending: FC = () => {
  const [mobileFooterHeight, setMobileFooterHeight] = useState(0);
  const mobileFooter = useRef<HTMLDivElement>(null);
  const { t } = useTranslation("checkout-processing");

  const {
    paymentLink: { invoice, invoice_settings },
    promotion
  } = usePaymentLink();
  const { paymentChannel } = usePaymentMethod();

  const handleLocaleChange = useCallback((locale: string) => {
    // send analytics event on language change
    logInternalAnalyticsEvent({
      event: InternalAnalyticsEvent.SELECTED_LOCALE,
      locale
    });
  }, []);

  const handleOrderSummaryOpenChange = useCallback((open: boolean) => {
    if (open) {
      // send external view content analytics event on order summary expand
      logExternalAnalyticsEvent({
        event_name: ExternalAnalyticsEvent.VIEW_CONTENT,
        target: [
          ExternalAnalyticsProvider.FACEBOOK,
          ExternalAnalyticsProvider.GOOGLE
        ]
      });
    }
  }, []);

  const orderSummaryBaseProps = {
    currency: invoice.currency,
    description: invoice.description,
    expiryDate: invoice.expiry_date,
    expiryTimeFormat: invoice_settings.payment_due_time_format,
    externalId: invoice.external_id,
    fees: invoice.fees?.map((fee) => ({ ...fee, name: fee.type })),
    items: invoice.items,
    onOpenChange: handleOrderSummaryOpenChange,
    totalAmount: invoice.amount,
    showExpiryDate: invoice_settings.should_show_expiry_date_time,
    promotion: promotion
  } satisfies OrderSummaryProps;

  useEffect(() => {
    // reset scroll position on payment channel change
    if (
      !paymentChannel ||
      (paymentChannel &&
        !["PAYLATER", "QR_CODE"].includes(paymentChannel.payment_method))
    )
      try {
        window.scroll({
          top: 0,
          behavior: "smooth"
        });
      } catch (err) {
        if (err instanceof TypeError) {
          window.scroll(0, 0);
        } else {
          console.error(err);
        }
      }
  }, [paymentChannel]);

  useEffect(() => {
    const footer = mobileFooter.current;

    if (footer) {
      const resizeObserver = new ResizeObserver((entries) => {
        const entry = entries[0];
        if (!entry) {
          return;
        }
        setMobileFooterHeight(entry.contentRect.height);
      });
      resizeObserver.observe(footer);

      return () => {
        resizeObserver.disconnect();
      };
    }
    setMobileFooterHeight(0);
  }, []);

  return (
    <CreditCardPaymentProvider>
      <MainView>
        <Header>
          <HeaderContent>
            {invoice.cancel_redirect_url ? (
              <HeaderBackButton
                title={`Back to ${invoice.merchant_name}`}
                onClick={() => {
                  if (invoice.cancel_redirect_url) {
                    window.location.href = invoice.cancel_redirect_url;
                  }
                }}
              />
            ) : null}
            <HeaderImage
              src={invoice.merchant_profile_picture_url}
              alt={invoice.merchant_name}
            />
            <HeaderTitle>{invoice.merchant_name}</HeaderTitle>
          </HeaderContent>
          <HeaderAddon>
            <LanguageNavigation onChange={handleLocaleChange} />
          </HeaderAddon>
        </Header>
        <div className="lg:hidden">
          <OrderSummary collapsible {...orderSummaryBaseProps} />
        </div>
        <div className="flex-1" style={{ paddingBottom: mobileFooterHeight }}>
          <PaymentSection />

          <div id="three-d-secure-inline-frame" />

          <div id="cards-processing-graphic" className="hidden">
            <Processing className="text-primary mb-4" />
            <p className="font-semibold text-lg text-center mb-4">
              {t("Payment is still processing…")}
            </p>
            <p className="text-center">
              {t(
                "Processing may take a moment. This page will update when the payment is processed."
              )}
            </p>
          </div>

          <div className="flex justify-center pb-12">
            {invoice_settings.branding?.badge_logos ? (
              <CustomBadges
                badgeLogos={invoice_settings.branding?.badge_logos}
              />
            ) : (
              <PoweredByXendit />
            )}
          </div>
        </div>
        <div
          id="mobile-footer-portal"
          ref={mobileFooter}
          className="md:hidden fixed bottom-0 inset-x-0"
        ></div>
      </MainView>
      <Sidebar>
        <OrderSummary {...orderSummaryBaseProps} />
      </Sidebar>
      <ErrorDialog />
    </CreditCardPaymentProvider>
  );
};

export default CheckoutPending;
