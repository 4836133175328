import React, { forwardRef } from "react";
import cx from "classnames";

export const Tag = forwardRef<
  HTMLSpanElement,
  React.HtmlHTMLAttributes<HTMLSpanElement>
>((componentProps, ref) => {
  const { className, children, ...rest } = componentProps;

  return (
    <span
      ref={ref}
      className={cx(
        "inline-flex align-top items-center ps-3 pe-3 bg-primary rounded-md",
        "min-w-[1rem] min-h-[1.5rem] max-w-full",
        "text-sm font-medium text-white",
        className
      )}
      {...rest}
    >
      {children}
    </span>
  );
});

Tag.displayName = "Tag";

export const TagLabel = forwardRef<
  HTMLSpanElement,
  React.HtmlHTMLAttributes<HTMLSpanElement>
>((componentProps, ref) => {
  const { className, children, ...rest } = componentProps;

  return (
    <span
      ref={ref}
      className={cx("leading-tight text-ellipsis overflow-hidden", className)}
      {...rest}
    >
      {children}
    </span>
  );
});

TagLabel.displayName = "TagLabel";

export interface TagIconProps {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  className?: string;
}
export const TagIcon = ({ icon: Icon, className }: TagIconProps) => {
  return (
    <Icon
      className={cx("w-3 h-3 inline-block leading-none shrink-0", className)}
    />
  );
};

export type TagButtonProps = JSX.IntrinsicElements["button"];
export const TagButton = forwardRef<HTMLButtonElement, TagButtonProps>(
  (componentProps, ref) => {
    const { className, children, ...rest } = componentProps;

    return (
      <button
        ref={ref}
        type="button"
        className={cx(
          "flex items-center justify-center outline-none opacity-50 hover:opacity-80",
          className
        )}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

TagButton.displayName = "TagButton";
