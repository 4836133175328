import { PaymentChannelsEnum } from "@xendit/checkout-utilities";
import isNumber from "lodash/isNumber";
import orderBy from "lodash/orderBy";

/**
 * The default channel order number if not defined in the CHANNELS_ORDERING
 * constant.
 */
export const DEFAULT_CHANNEL_ORDER = 1000;

/**
 * Key-value object of channel names mapped to their respective ordering number.
 * Lower numbers appear earlier in the list. Different channels can have the same
 * ordering number, if they are not in the same payment method type (e.g.
 * E-wallet and VA).
 */
export const CHANNELS_ORDERING: {
  [Currency: string]: Partial<{
    [Channel in PaymentChannelsEnum | any]: number; // remove any when all channels are defined on checkout-utilities
  }>;
} = {
  PHP: {
    // ewallets
    GCASH: 0,
    PAYMAYA: 10,
    GRABPAY: 20
  },
  MYR: {
    DD_MAYB2U_FPX: 0,
    DD_CIMB_FPX: 1
  }
};

/**
 * Orders list of channels based on the defined ordering in the CHANNELS_ORDERING
 * constant.
 *
 * @param channels array of channels
 * @param currency currency code
 * @param channelNameKey name of field containing channel code
 * @returns ordered array of channels
 */
export const orderChannels = <T>(
  channels: T[],
  currency: string,
  channelNameKey?: string
): (T & { ordering: number })[] => {
  const defaultChannelNameKey = "name";
  const ordered = orderBy(
    channels.map((channel: any) => {
      return {
        ...channel,
        ordering: isNumber(
          (CHANNELS_ORDERING[currency] as { [key: string]: number })?.[
            channel[channelNameKey || defaultChannelNameKey]
          ]
        )
          ? (CHANNELS_ORDERING[currency] as { [key: string]: number })[
              channel[channelNameKey || defaultChannelNameKey]
            ]
          : DEFAULT_CHANNEL_ORDER
      };
    }),
    "ordering"
  );
  return ordered;
};
