import { FC, ReactNode, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import * as Collapsible from "@radix-ui/react-collapsible";

import { ChevronDown, Clock, FileEdit, ShoppingCart } from "../../assets/icons";
import { CreditCardPromotion } from "../../types/credit-card";
import { formatDate } from "../../helpers/date-strings";
import PriceSummary, { Fee, Item } from "../PriceSummary";
import type { TranslationInterpolation } from "../../types/i18next";

export type OrderSummaryProps = {
  collapsible?: boolean;
  currency?: string;
  description?: string;
  expiryDate: string;
  expiryTimeFormat?: string;
  externalId: string;
  fees?: Fee[];
  onOpenChange?: (open: boolean) => unknown;
  showExpiryDate?: boolean;
  items?: Item[];
  totalAmount: number;
  promotion?: CreditCardPromotion | null;
};

const OrderSummary: FC<OrderSummaryProps> = (props) => {
  const { i18n, t } = useTranslation("common");
  const [expiryDisplay, setExpiryDisplay] = useState("");

  useEffect(() => {
    if (props.showExpiryDate) {
      setExpiryDisplay(
        formatDate(
          new Date(props.expiryDate),
          i18n.language,
          props.expiryTimeFormat
        )
      );
    }
  }, [
    props.expiryDate,
    props.expiryTimeFormat,
    props.showExpiryDate,
    i18n.language
  ]);

  return (
    <div className="relative" data-testid="order-summary-section">
      <div className="pb-4 hidden lg:block">
        <h2 className="text-3xl font-semibold mb-1 pt-3">
          {t("Order Summary")}
        </h2>
        <p className="line-clamp-1">
          <strong data-testid="external-id">{t("Invoice #")}:</strong>{" "}
          {props.externalId}
        </p>
      </div>
      <CollapsibleOrderSummary
        collapsible={props.collapsible}
        onOpenChange={props.onOpenChange}
      >
        <div className="px-4 lg:px-0 py-2">
          <ul className="flex flex-col space-y-4 py-2">
            {!isEmpty(props.description) ? (
              <li data-testid="description">
                <div className="mb-1 flex items-center">
                  <div className="w-6 h-4 hidden lg:block">
                    <FileEdit className="text-primary" />
                  </div>
                  <h3 className="text-lg font-semibold">{t("Description")}</h3>
                </div>
                <p className="lg:pl-6 line-clamp-1 lg:hidden">
                  <strong data-testid="external-id">{t("Invoice #")}:</strong>{" "}
                  {props.externalId}
                </p>
                {props.description ? (
                  <p
                    className="lg:pl-6"
                    dangerouslySetInnerHTML={{ __html: props.description }}
                  />
                ) : null}
              </li>
            ) : null}
            {props.showExpiryDate ? (
              <li>
                <div className="mb-1 flex items-center">
                  <div className="hidden lg:block w-6 h-4">
                    <Clock className="text-primary" />
                  </div>
                  <h3 className="text-lg" data-testid="expiry-date-summary">
                    <Trans t={t}>
                      Pay before{" "}
                      <strong>
                        {
                          {
                            expiry_date: expiryDisplay
                          } as TranslationInterpolation
                        }
                      </strong>
                    </Trans>
                  </h3>
                </div>
              </li>
            ) : null}
          </ul>
        </div>

        <div className="px-4 lg:px-0">
          <hr className="border-xen-gray-500 opacity-20" />
        </div>
        <div className="px-4 lg:px-0 py-4">
          <PriceSummary
            amount={props.totalAmount}
            currency={props.currency}
            fees={props.fees}
            items={props.items}
            promotion={props.promotion}
          />
        </div>
      </CollapsibleOrderSummary>
    </div>
  );
};

export default OrderSummary;

type CollapsibleOrderSummaryProps = {
  collapsible?: boolean;
  children?: ReactNode;
  onOpenChange?: (open: boolean) => unknown;
};

const CollapsibleOrderSummary: FC<CollapsibleOrderSummaryProps> = (props) => {
  const { t } = useTranslation("common");

  if (!props.collapsible) {
    return <>{props.children || null}</>;
  }

  return (
    <Collapsible.Root onOpenChange={props.onOpenChange}>
      <Collapsible.Trigger className="flex lg:hidden px-4 h-16 items-center w-full text-left border-b border-xen-gray-200 lg:border-xen-gray-400 outline-none focus:bg-xen-gray-100 transition-colors [&[data-state=open]>svg]:rotate-180">
        <div className="flex-1 flex items-center">
          <ShoppingCart className="w-4 h-4 mr-2" />
          <span className="font-bold uppercase text-lg">
            {t("Order Summary")}
          </span>
        </div>
        <ChevronDown className="w-6 h-6 transform transition-transform" />
      </Collapsible.Trigger>
      <Collapsible.Content className="bg-xen-gray-100 data-[state=open]:animate-collapsible-down data-[state=closed]:animate-collapsible-up overflow-hidden">
        {props.children}
      </Collapsible.Content>
    </Collapsible.Root>
  );
};
