import { BASE_ASSETS_URL } from "../utils/constants";

interface CardsScheme {
  displayName: string;
  label: string;
  logoUrl: string;
  name: string;
}

interface ExtendedCardsScheme {
  name: string;
  position_index: number;
}

export function extendCardsSchemes(
  defaultSchemes: CardsScheme[],
  extendedSchemes?: ExtendedCardsScheme[]
): CardsScheme[] {
  const schemeMap = new Map<string, CardsScheme>(
    defaultSchemes.map((scheme) => [scheme.name, scheme])
  );

  const orderedSchemes = new Array(
    Math.max(defaultSchemes.length, extendedSchemes?.length || 0)
  );

  defaultSchemes.forEach((scheme, index) => {
    orderedSchemes[index] = scheme.name;
  });

  if (Array.isArray(extendedSchemes)) {
    extendedSchemes.forEach((extendedScheme) => {
      // add new extended scheme
      if (!schemeMap.has(extendedScheme.name)) {
        schemeMap.set(extendedScheme.name, {
          displayName: extendedScheme.name,
          label: extendedScheme.name,
          logoUrl: `${BASE_ASSETS_URL}card-brands/${extendedScheme.name.toLowerCase()}.svg`,
          name: extendedScheme.name
        });
      }

      // update order
      orderedSchemes.splice(
        extendedScheme.position_index,
        0,
        extendedScheme.name
      );
    });
  }

  return orderedSchemes
    .filter((name): name is string => name !== undefined)
    .map((name) => schemeMap.get(name))
    .filter((scheme): scheme is CardsScheme => scheme !== undefined);
}
