import {
  Business,
  Customer,
  ErrorContent,
  PaymentMethod,
  PaymentToken,
  Session,
  Setting,
  SucceededChannel
} from "../types";

interface FullSessionResponse {
  session: Session;
  customer: Customer;
  business: Business;
  settings: Setting;
  payment_methods: PaymentMethod[];
  payment_token?: PaymentToken;
  succeeded_channel: SucceededChannel;
  error_content?: ErrorContent;
}

export function mapFullSessionResponseToSessionState(
  response: Partial<FullSessionResponse>
) {
  return {
    session: response.session,
    customer: response.customer,
    business: response.business,
    settings: response.settings,
    paymentMethods: response.payment_methods,
    succeededChannel: response.succeeded_channel,
    paymentToken: response.payment_token,
    errorContent: response.error_content
  };
}
