import { memo, useEffect, useMemo, useRef } from "react";
import isEmpty from "lodash/isEmpty";

import SingleInput from "./SingleInput";

export interface OtpInputProps {
  className: string;
  inputClassName?: string;
  otpCode: string;
  length: number;
  disabled?: boolean;
  onChangeOTP: (otp: string) => void;
}

export function OtpInput(props: OtpInputProps) {
  const { otpCode, length, inputClassName, disabled, onChangeOTP, ...rest } =
    props;

  const otpInputRef = useRef<HTMLDivElement>(null);
  const regexDigit = new RegExp(/^\d+$/);

  // an array of otp value created from otp code
  // each value is assigned to each input
  const otpValues = useMemo(() => {
    const valueArray = otpCode.split("");
    const items: Array<string> = [];

    for (let i = 0; i < length; i++) {
      const char = valueArray[i];

      if (regexDigit.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [otpCode, length]);

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = regexDigit.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    const nextInputEl = target.nextElementSibling as HTMLInputElement | null;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== "") {
      return;
    }

    // if target value is not valid digit
    // set target value as empty string
    targetValue = isTargetValueDigit ? targetValue : "";

    const targetValueLength = targetValue.length;

    // handle delete
    if (!targetValueLength) {
      const newValue =
        otpCode.substring(0, index) +
        targetValue +
        otpCode.substring(index + 1);

      onChangeOTP(newValue);
      return;
    }

    if (targetValueLength === 1) {
      const newValue =
        otpCode.substring(0, index) +
        targetValue +
        otpCode.substring(index + 1);

      onChangeOTP(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === length) {
      onChangeOTP(targetValue);

      target.blur();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;

    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== "Backspace" || targetValue !== "") {
      return;
    }

    focusToPrevInput(target);
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    // keep focusing back until previous input
    // element has value
    const prevInputEl =
      target.previousElementSibling as HTMLInputElement | null;

    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  useEffect(() => {
    // focus on first otp input on initial render
    if (
      isEmpty(otpCode) &&
      otpInputRef.current &&
      otpInputRef.current.firstElementChild &&
      !disabled
    ) {
      (otpInputRef.current.firstElementChild as HTMLInputElement).focus();
    }
  }, [disabled, otpInputRef, otpCode]);

  return (
    <div ref={otpInputRef} {...rest}>
      {otpValues.map((value, index) => (
        <SingleInput
          name={`one-time-code-input-${index}`}
          key={`SingleInput-${index}`}
          aria-label={`one-time-code-input-${index}`}
          type="text"
          inputMode="numeric"
          value={value}
          maxLength={length}
          onFocus={handleOnFocus}
          onChange={(e) => handleOnChange(e, index)}
          onKeyDown={handleKeyDown}
          className={inputClassName}
          disabled={disabled}
        />
      ))}
    </div>
  );
}

const OTPInput = memo(OtpInput);

export default OTPInput;
