import { PaymentChannelsEnum } from "@xendit/checkout-utilities";
import type { PaymentChannelsInterface } from "@xendit/checkout-utilities/dist/src/types/payment-channels";

import { InvoicePaymentChannel } from "../../types/checkout";

export const getCheckoutPaymentChannel = (
  checkoutPaymentChannels: PaymentChannelsInterface,
  invoicePaymentChannels: InvoicePaymentChannel[],
  channelCode: string
) => {
  const checkoutPaymentChannel =
    checkoutPaymentChannels[channelCode as keyof PaymentChannelsInterface];

  if (checkoutPaymentChannel?.channel === PaymentChannelsEnum.OTHER_BANKS)
    return checkoutPaymentChannel;

  if (
    !checkoutPaymentChannel ||
    !invoicePaymentChannels.includes(channelCode as InvoicePaymentChannel)
  ) {
    return null;
  }
  return checkoutPaymentChannel;
};
