import { FC, SVGProps } from "react";

const Copy: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.375 1.65918H3.375C2.55 1.65918 1.875 2.33418 1.875 3.15918V13.6592H3.375V3.15918H12.375V1.65918ZM14.625 4.65918H6.375C5.55 4.65918 4.875 5.33418 4.875 6.15918V16.6592C4.875 17.4842 5.55 18.1592 6.375 18.1592H14.625C15.45 18.1592 16.125 17.4842 16.125 16.6592V6.15918C16.125 5.33418 15.45 4.65918 14.625 4.65918ZM6.375 16.6592H14.625V6.15918H6.375V16.6592Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Copy;
