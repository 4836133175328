import { useEffect, useRef, useState } from "react";
import { FlowStep } from "./Flow";

export interface RenderElement {
  element: JSX.Element;
  show: boolean;
}

/**
 * Hook to manage the wizard flow
 * @param flows Array of steps to display
 */
export const useWizard = (
  flow: FlowStep[]
): { nextStep: () => void; previousStep: () => void; form: JSX.Element } => {
  const localFlow = useRef(flow);
  const [renderTree, setRenderTree] = useState<RenderElement[]>(
    mapFlowToRenderTree(localFlow.current)
  );
  const currentStepIndex = useRef(0);

  const nextStep = () => {
    const newIndex = currentStepIndex.current + 1;
    currentStepIndex.current = newIndex;

    if (localFlow.current[newIndex].overlay) {
      // Show the next step
      setRenderTree((currentRenderTree) => {
        currentRenderTree[newIndex].show = true;
        return [...currentRenderTree];
      });
    } else {
      // Hide all the previous steps and show the next
      setRenderTree((currentRenderTree) => {
        return currentRenderTree.map((element, index) => {
          return {
            ...element,
            show: index === currentStepIndex.current + 1
          };
        });
      });
    }
  };

  const previousStep = () => {
    // Find the last index of the previous step where overlay is false
    const newIndex = localFlow.current
      .slice(0, currentStepIndex.current)
      .reverse()
      .findIndex((step) => !step.overlay);
    setRenderTree((currentRenderTree) => {
      return currentRenderTree.map((element, index) => {
        return {
          ...element,
          show: index === newIndex
        };
      });
    });

    currentStepIndex.current = newIndex;
  };

  // Re-render if input changes
  useEffect(() => {
    localFlow.current = flow;
    setRenderTree(mapFlowToRenderTree(flow));
    currentStepIndex.current = 0;
  }, [flow]);

  return {
    nextStep,
    previousStep,
    form: <>{renderTree.map(({ element, show }) => (show ? element : null))}</>
  };
};

export const mapFlowToRenderTree = (flow: FlowStep[]): RenderElement[] => {
  return flow.map((step, index) => {
    return {
      element: step.form,
      show: index === 0
    };
  });
};
