import { APP_ENV_MODE } from "../utils/constants";

export const isPaymentChannelDisabled = (channelCode: string) => {
  if (APP_ENV_MODE === "production-live") {
    // NOTE: If any channel need to be disabled, we can add it here in this array
    const disabledList = [] as string[];

    return disabledList.includes(channelCode);
  }

  return false;
};
