import { Flow, FlowStep } from "../../Flow";
import { THDirectDebitFormAValues } from "./formik";
import THDirectDebitFormA from "./THDirectDebitFormA";

export class THDirectDebitAFlow extends Flow {
  buildFlow() {
    this.clearFlow();
    this.appendStep({
      overlay: false,
      form: (
        <THDirectDebitFormA
          key="th-dd-form-a-step-1"
          paymentMethod={this.getSelectedPaymentMethod()}
          onSubmit={this.onFormSubmission}
          onCancel={this.resetSelection}
        />
      )
    });
    return this;
  }

  onSelectFlow(): FlowStep[] {
    return this.flow;
  }

  private onFormSubmission = async (values: THDirectDebitFormAValues) => {
    const createdTokenEither = await this.postPaymentTokenAction(values);
    if (createdTokenEither.isRight()) {
      const createdToken = createdTokenEither.getRight();
      this.exitFlow(createdToken);
    }
  };
}
