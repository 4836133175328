import { PaymentChannelsPropertiesType } from "@xendit/checkout-utilities";
import { useCallback, useEffect, useReducer, useRef } from "react";
import { AxiosError } from "axios";

import { usePaymentLink } from "../contexts/PaymentLinkContext";
import { usePaymentMethod } from "../contexts/PaymentMethodContext";

import {
  createPaylaterCharge,
  getPaylaterCustomer,
  initiatePlans
} from "../utils/fetch-resource";
import { CustomerFormValues } from "../components/CustomerForm";
import {
  getPaylaterChannelCode,
  getPaylaterCountryCode,
  getPaylaterErrorMessage,
  hasRequiredCustomerInfoForPaylater,
  mapCustomerDataToFormValues,
  supportsPaylaterCustomerForm,
  shouldShowPaylaterInstallmentOptions,
  hasCustomerId
} from "../helpers/paylater";
import { getProcessingPageUrl } from "../utils/urls";
import { isMobileDevice } from "../helpers/is-mobile-device";
import { logFetchUnexpectedResponse } from "../utils/rum";
import { Customer } from "../types/checkout";

interface IlumaCustomer extends Customer {
  id: string;
}

type PaymentPaylaterReducerState = {
  creatingCharge: boolean;
  error: ErrorMessage | null;
  fetchingCustomer: boolean;
  initiatingPlans: boolean;
  paylaterPlans: InitiatePlansResponse | null;
  showCustomerForm: boolean;
  showError: boolean;
};
type PaymentPaylaterReducerAction =
  | { type: "create_charge" }
  | { type: "dismiss_error" }
  | { type: "get_customer" }
  | { type: "initiate_plans" }
  | { type: "reset" }
  | { type: "set_error"; payload: SetErrorPayload }
  | {
      type: "set_paylater_plans";
      payload: SetPaymentPaylaterPayload;
    }
  | { type: "show_customer_form" };
type SetErrorPayload = {
  error: ErrorMessage;
};
type SetPaymentPaylaterPayload = {
  paylaterPlans: InitiatePlansResponse;
};

const PaymentPaylaterReducer = (
  state: PaymentPaylaterReducerState,
  action: PaymentPaylaterReducerAction
): PaymentPaylaterReducerState => {
  switch (action.type) {
    case "create_charge":
      return { ...state, creatingCharge: true };
    case "dismiss_error":
      return { ...state, showError: false };
    case "get_customer":
      return { ...state, error: null, fetchingCustomer: true };
    case "initiate_plans":
      return {
        ...state,
        error: null,
        fetchingCustomer: false,
        initiatingPlans: true,
        showCustomerForm: false,
        showError: false
      };
    case "reset":
      return {
        ...state,
        error: null,
        fetchingCustomer: false,
        showError: false,
        initiatingPlans: false,
        paylaterPlans: null
      };
    case "set_error":
      return {
        ...state,
        creatingCharge: false,
        error: action.payload.error,
        fetchingCustomer: false,
        initiatingPlans: false,
        showError: true
      };
    case "set_paylater_plans":
      return {
        ...state,
        error: null,
        fetchingCustomer: false,
        initiatingPlans: false,
        paylaterPlans: action.payload.paylaterPlans,
        showCustomerForm: false
      };
    case "show_customer_form":
      return { ...state, showCustomerForm: true };
    default:
      return state;
  }
};

const usePaylater = () => {
  const {
    customer,
    onSetCustomer,
    paymentLink: {
      invoice: { id, currency, user_id }
    }
  } = usePaymentLink();
  const { paymentChannel, selectPaymentChannel } = usePaymentMethod();
  const [state, dispatch] = useReducer(PaymentPaylaterReducer, {
    creatingCharge: false,
    error: null,
    fetchingCustomer: false,
    initiatingPlans: false,
    paylaterPlans: null,
    showCustomerForm: false,
    showError: false
  });
  const initiatePlansAbortController = useRef<AbortController>();
  const getPaylaterCustomerAbortController = useRef<AbortController>();

  const hasRequiredCustomerInfo = hasRequiredCustomerInfoForPaylater(customer);

  const handleCreateCharge = useCallback(async () => {
    if (!paymentChannel || !state.paylaterPlans) {
      return;
    }
    const paylaterChannelCode = getPaylaterChannelCode(
      paymentChannel,
      currency
    );

    dispatch({ type: "create_charge" });
    initiatePlansAbortController.current = new AbortController();
    let paylaterCharge: CreatePaylaterChargeResponse;
    try {
      paylaterCharge = await createPaylaterCharge<CreatePaylaterChargeResponse>(
        id,
        {
          businessId: user_id,
          channelCode: paylaterChannelCode,
          planId: state.paylaterPlans.id,
          checkoutMethod: "ONE_TIME_PAYMENT",
          successRedirectUrl: getProcessingPageUrl().toString(),
          failureRedirectUrl: window.location.href
        },
        { abortSignal: initiatePlansAbortController.current.signal }
      );
    } catch (error) {
      let errorMessage: ErrorMessage = {
        title: "Error",
        body: "Failed to create paylater charge"
      };
      if (error instanceof AxiosError) {
        const errorResponse = error.response?.data;
        errorMessage = getPaylaterErrorMessage(errorResponse.error_code);
      } else {
        logFetchUnexpectedResponse(error);
      }
      dispatch({
        type: "set_error",
        payload: { error: errorMessage }
      });
      return;
    }

    window.location.href = isMobileDevice()
      ? paylaterCharge.actions.mobile_web_checkout_url
      : paylaterCharge.actions.desktop_web_checkout_url;
  }, [dispatch, currency, paymentChannel, state.paylaterPlans]);

  const handleFetchCustomer = useCallback(async () => {
    dispatch({ type: "get_customer" });
    getPaylaterCustomerAbortController.current = new AbortController();
    const customer = await getPaylaterCustomer<IlumaCustomer>(id, {
      abortSignal: getPaylaterCustomerAbortController.current?.signal
    });

    return customer;
  }, [dispatch, id]);

  const handleInitiatePlans = useCallback(
    async (
      paymentChannel: PaymentChannelsPropertiesType,
      customerFormValues?: CustomerFormValues | null
    ) => {
      if (!paymentChannel) {
        return;
      }
      const paylaterChannelCode = getPaylaterChannelCode(
        paymentChannel,
        currency
      );

      const customer = customerFormValues
        ? {
            given_names: customerFormValues.firstName,
            surname: customerFormValues.lastName,
            email: customerFormValues.email,
            mobile_number: customerFormValues.mobileNumber,
            addresses: [
              {
                country: customerFormValues.country,
                street_line1: customerFormValues.address,
                city: customerFormValues.city,
                postal_code: customerFormValues.postalCode
              }
            ]
          }
        : null;

      initiatePlansAbortController.current = new AbortController();
      try {
        const paylaterPlans = await initiatePlans<InitiatePlansResponse>(
          id,
          paylaterChannelCode,
          customer,
          { abortSignal: initiatePlansAbortController.current.signal }
        );
        dispatch({ type: "set_paylater_plans", payload: { paylaterPlans } });
        onSetCustomer({
          customer_id: paylaterPlans.customer_id as string,
          ...customer
        });
      } catch (error) {
        let errorMessage: ErrorMessage = {
          title: "Error",
          body: "Failed to load paylater installment plans."
        };
        if (error instanceof AxiosError) {
          const errorResponse = error.response?.data;
          errorMessage = getPaylaterErrorMessage(errorResponse.error_code);
        } else {
          logFetchUnexpectedResponse(error);
        }
        dispatch({
          type: "set_error",
          payload: { error: errorMessage }
        });
      }
    },
    [dispatch, currency]
  );

  const handlePaymentChannelSelected = useCallback(
    (paymentChannel: PaymentChannelsPropertiesType) => {
      if (!paymentChannel) {
        return;
      }
      selectPaymentChannel(paymentChannel.channel);

      // force run in next tick to ensure that the UI has been reset
      setTimeout(async () => {
        let hasRequiredPaylaterCustomerInfo = hasRequiredCustomerInfo;
        const hasInvoiceCustomerId = hasCustomerId(customer);

        if (
          supportsPaylaterCustomerForm(paymentChannel.channel) &&
          hasInvoiceCustomerId
        ) {
          const customer = await handleFetchCustomer();
          onSetCustomer({
            customer_id: customer.id as string,
            ...customer
          });
          hasRequiredPaylaterCustomerInfo =
            hasRequiredCustomerInfoForPaylater(customer);

          if (!hasRequiredPaylaterCustomerInfo) {
            dispatch({ type: "show_customer_form" });
            return;
          }
        } else if (supportsPaylaterCustomerForm(paymentChannel.channel)) {
          dispatch({ type: "show_customer_form" });
          return;
        } else {
          hasRequiredPaylaterCustomerInfo = true;
        }

        const shouldInitiatePlans =
          !supportsPaylaterCustomerForm(paymentChannel.channel) ||
          hasRequiredPaylaterCustomerInfo;

        if (shouldInitiatePlans) {
          dispatch({ type: "initiate_plans" });
          handleInitiatePlans(
            paymentChannel,
            state.showCustomerForm
              ? mapCustomerDataToFormValues(customer)
              : null
          );
        }
      }, 0);
    },
    [
      dispatch,
      handleFetchCustomer,
      handleInitiatePlans,
      hasRequiredCustomerInfo,
      selectPaymentChannel,
      state.showCustomerForm
    ]
  );

  useEffect(() => {
    dispatch({ type: "reset" });
  }, [dispatch, paymentChannel]);

  useEffect(() => {
    return () => {
      initiatePlansAbortController.current?.abort();
      getPaylaterCustomerAbortController.current?.abort();
    };
  }, []);

  return {
    ...state,
    onCreateCharge: handleCreateCharge,
    onDismissError: () => {
      dispatch({ type: "dismiss_error" });
    },
    onInitiatePlans: handleInitiatePlans,
    onPaymentChannelSelected: handlePaymentChannelSelected,
    paylaterCountryCode: getPaylaterCountryCode(currency),
    shouldShowPaylaterInstallmentOptions: paymentChannel
      ? shouldShowPaylaterInstallmentOptions(paymentChannel.channel)
      : false
  };
};

export default usePaylater;
