import { FC, SVGProps } from "react";

const UnderConstruction: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width={263} height={180} viewBox="0 0 263 180" fill="none" {...props}>
      <path
        opacity={0.18}
        d="M234.389 36.248c2.5 14.233-11.807 28.269-7.385 42.016 2.544 7.941 10.178 12.893 16.759 18 10.458 8.1 20.108 19.868 18.664 33.005-1.562 14.17-15.073 24.188-28.5 29.019C183.553 176.394 11.064 208.199.842 127.917c-1.947-15.345 6.88-30.55 16.784-42.442 7.878-9.456 51.762-51.955 67.8-59.89C156.877-9.77 227.03-5.647 234.39 36.248z"
        fill="currentColor"
      />
      <path
        opacity={0.05}
        d="M146.877 15.701s94.465-.823 78.619 37.767c-12.274 29.857 1.753 32.046 6.139 46.486 4.386 14.44-.719 30.89-11.285 38.684-10.566 7.794-52.853 6.416-52.853 17.956s-11.768 7.328-11.768 7.328H81.652l13.53-40.748 51.695-107.473z"
        fill="#000405"
      />
      <path
        d="M161.171 159.407H81.652v4.526h79.519v-4.526zM149.025 123.184H95.183v36.223h53.842v-36.223z"
        fill="currentColor"
      />
      <path
        d="M149.025 123.184H95.183v36.223h53.842v-36.223z"
        fill="currentColor"
      />
      <path
        d="M149.025 123.184H95.183v36.223h53.842v-36.223z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M220.35 20.7v94.864a20.71 20.71 0 01-20.738 20.7H41.829a20.714 20.714 0 01-19.144-12.774 20.715 20.715 0 01-1.58-7.926V20.7A20.716 20.716 0 0141.836 0h157.756a20.713 20.713 0 0119.169 12.762 20.712 20.712 0 011.588 7.929v.009z"
        fill="currentColor"
      />
      <path
        d="M220.35 20.7v89.89H21.131V20.7A20.715 20.715 0 0141.86 0h157.734a20.713 20.713 0 0119.169 12.762 20.712 20.712 0 011.588 7.929v.009z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        d="M185.939 18.756H45.183v27.72H185.94v-27.72z"
        fill="url(#prefix__paint2_linear)"
      />
      <path
        d="M109.12 50.014H45.183v55.83h63.937v-55.83z"
        fill="url(#prefix__paint3_linear)"
      />
      <path
        d="M184.804 50.014h-71.167V68.66h71.167V50.014z"
        fill="url(#prefix__paint4_linear)"
      />
      <path
        d="M184.804 72.198h-71.167v18.645h71.167V72.198z"
        fill="url(#prefix__paint5_linear)"
      />
      <path
        d="M184.804 94.38h-71.167v11.464h71.167V94.381z"
        fill="url(#prefix__paint6_linear)"
      />
      <path
        d="M154.193 172.592h3.038l-9.361-117.6-2.567.69.582 7.683-9.224.581-.709-8.954-2.567.69 8.88 116.907h3.04l-1.061-13.334 8.906-.546-.134-2.112-8.941.549-.76-9.529 9.141-.578-.13-2.106-9.176.565-.757-9.548 8.963-.569 2.837 37.211zM136.83 66.045l9.224-.588.725 9.548-9.182.591-.767-9.55zm.926 11.644l9.176-.588.725 9.59-9.132.571-.769-9.573zm.932 11.667l9.128-.584.776 10.193-9.113.553-.791-10.162zm.973 12.261l9.09-.543.674 8.894-9.055.569-.709-8.92zm.875 11.028l9.049-.575.725 9.561-9.014.569-.76-9.555zm1.688 21.21l-.763-9.561 9.011-.575.725 9.567-8.973.569zM76.847 62.463v-7.468h-1.873v7.468h-9.113v2.312h9.113v103.386h1.873V76.813l11.107-12.038h8.5V62.45l-19.607.013zm0 11.082v-8.77h8.448l-8.448 8.77zM169.378 62.463v-7.468h-1.873v7.468h-18v2.312h7.815l10.178 11.022v92.364h1.88V64.775h10.731V62.45l-10.731.013zm-1.873 10.123l-7.529-7.81h7.522l.007 7.81z"
        fill="currentColor"
      />
      <g opacity={0.61}>
        <path
          opacity={0.61}
          d="M154.193 172.592h3.038l-9.361-117.6-2.567.69.582 7.683-9.224.581-.709-8.954-2.567.69 8.88 116.907h3.04l-1.061-13.334 8.906-.546-.134-2.112-8.941.549-.76-9.529 9.141-.578-.13-2.106-9.176.565-.757-9.548 8.963-.569 2.837 37.211zM136.83 66.045l9.224-.588.725 9.548-9.182.591-.767-9.55zm.926 11.644l9.176-.588.725 9.59-9.132.571-.769-9.573zm.932 11.667l9.128-.584.776 10.193-9.113.553-.791-10.162zm.973 12.261l9.09-.543.674 8.894-9.055.569-.709-8.92zm.875 11.028l9.049-.575.725 9.561-9.014.569-.76-9.555zm1.688 21.21l-.763-9.561 9.011-.575.725 9.567-8.973.569z"
          fill="url(#prefix__paint7_linear)"
        />
        <path
          opacity={0.61}
          d="M76.846 62.463v-7.468h-1.873v7.468H65.86v2.312h9.113v103.386h1.873V76.813l11.107-12.038h8.5V62.45l-19.607.013zm0 11.082v-8.77h8.448l-8.448 8.77z"
          fill="url(#prefix__paint8_linear)"
        />
        <path
          opacity={0.61}
          d="M169.377 62.463v-7.468h-1.873v7.468h-18v2.312h7.815l10.178 11.022v92.364h1.88V64.775h10.731V62.45l-10.731.013zm-1.873 10.123l-7.529-7.81h7.522l.007 7.81z"
          fill="url(#prefix__paint9_linear)"
        />
      </g>
      <path
        d="M149.785 86.259l-.216 1.938h-4.477s-.366-1.118.452-1.4c.448-.158.91-.274 1.38-.346l2.861-.192z"
        fill="currentColor"
      />
      <path
        d="M143.534 46.13a14.54 14.54 0 00.318 5.187 2.305 2.305 0 002.153.861v1.29h2.144l.916-6.457s-3.374-4.302-5.531-.881z"
        fill="url(#prefix__paint10_linear)"
      />
      <path
        d="M145.045 46.773c.338.675.553 1.404.636 2.154 0 0 .429-.861.636-.432.207.429-.429 1.293-.429 1.293l.636 1.074h2.179s1.078-4.71.474-5.358c-.604-.648-1.011-.635-1.011-.635s0-.635-1.295-.635-3.015 0-3.661 1.077c-.646 1.077-.862.635-.862.635s.366.8.926.6c.133-.03 1.555-.417 1.771.227z"
        fill="#333"
      />
      <path
        d="M146.013 53.467h3.623s1.53.318 1.743 3.98c.131 2.502.131 5.01 0 7.512v6l-1.743-.581-6.466 1.442-.636-8.8a4.672 4.672 0 01-3.327-2.058 5.338 5.338 0 01-.56-4.841l4.5 2.366.239-1.271a5.4 5.4 0 012.153-3.424l.474-.325z"
        fill="currentColor"
      />
      <path
        d="M139.252 54.93l-1.075-3.442 4.288-.648 1.444 4.69-.757 2.958-2.57-1.337-1.33-2.22z"
        fill="#FCFDFF"
      />
      <path
        d="M138.385 56.856c.073-.72.375-1.398.862-1.934l.216.213h1.371l.216.842-.5.708a1.87 1.87 0 01-2.165.17z"
        fill="url(#prefix__paint11_linear)"
      />
      <path
        d="M140.577 57.152l1.021 2.706 1.549-1.37-2.57-1.336z"
        fill="url(#prefix__paint12_linear)"
      />
      <path
        opacity={0.61}
        d="M142.46 62.869s3.464-1.293 3.464-3.443l.865 9.882-3.667.867-.662-7.306z"
        fill="url(#prefix__paint13_linear)"
      />
      <path
        d="M143.106 70.165l-.646 10.717.862 5.594h2.586l.429-9.23 1.508-3.875-1.075 13.106 3.015.216 1.485-17.394-1.7-.645-6.464 1.51z"
        fill="#333"
      />
      <path
        d="M145.782 86.475v2.144L141.237 87s0-1.185.881-1.185c.477-.003.953.04 1.422.13l2.242.531zM86.446 171.474l.223 1.931h4.485s.366-1.118-.455-1.4a7.414 7.414 0 00-1.377-.343l-2.876-.188z"
        fill="currentColor"
      />
      <path
        d="M89.56 130.931a14.6 14.6 0 011.466 4.952c-.258 1.226-1.768 1.506-1.768 1.506l.41 1.226-2.153.753-2.933-5.81s1.832-5.187 4.978-2.627z"
        fill="url(#prefix__paint14_linear)"
      />
      <path
        d="M88.328 132.03a6.26 6.26 0 000 2.15s-.69-.667-.754-.194c-.064.473.817 1.077.817 1.077l-.258 1.226-1.959.667s-2.545-4.129-2.156-4.926c.39-.797.582-.883.582-.883s-.216-.6 1.011-1.032c1.227-.432 2.863-.953 3.817-.149.954.804 1.011.318 1.011.318s0 .861-.69.883c.071.088-1.42.174-1.42.863z"
        fill="#333"
      />
      <path
        d="M90.335 138.637h-3.616s-1.53.318-1.746 3.98a72.116 72.116 0 000 7.509v6l1.746-.581 6.463 1.442.636-8.8a4.701 4.701 0 003.254-2.195 5.368 5.368 0 00.56-4.844l-4.5 2.366-.239-1.271a5.401 5.401 0 00-2.153-3.421l-.405-.185z"
        fill="currentColor"
      />
      <path
        d="M96.995 140.121l1.075-3.421-4.31-.645-1.422 4.691.776 2.817 2.586-1.29 1.295-2.152z"
        fill="#FCFDFF"
      />
      <path
        d="M97.854 142.081a3.357 3.357 0 00-.862-1.938l-.213.216h-1.358l-.216.839.5.708a1.87 1.87 0 002.15.175z"
        fill="url(#prefix__paint15_linear)"
      />
      <path
        opacity={0.61}
        d="M93.761 148.147s-3.448-1.29-3.448-3.443l.28 11.663 2.522.559.646-8.779z"
        fill="url(#prefix__paint16_linear)"
      />
      <path
        d="M93.116 155.377l.646 10.717-.84 5.593h-2.586l-.429-9.23-1.511-3.875 1.078 13.106-3.015.216-1.508-17.387 1.721-.645 6.444 1.505z"
        fill="#333"
      />
      <path
        d="M90.466 171.686v1.509l4.717-.216s-.213-1.506-1.075-1.506a2.637 2.637 0 01-1.336-.318l-2.306.531z"
        fill="currentColor"
      />
      <path
        d="M166.681 46.352h-11.015a5.662 5.662 0 01-5.665-5.66v-2.411a5.67 5.67 0 013.498-5.228 5.665 5.665 0 012.167-.429h11.015a5.658 5.658 0 015.668 5.654v2.411a5.651 5.651 0 01-3.5 5.23 5.66 5.66 0 01-2.168.43v.003z"
        fill="#fff"
      />
      <path
        d="M151.27 49.334s4.5.216 5.582-3.01h3.34s-2.239 4.087-8.922 3.01z"
        fill="#fff"
      />
      <path
        opacity={0.15}
        d="M153.814 34.746h15.127a.388.388 0 01.388.387v.318a.38.38 0 01-.114.274.384.384 0 01-.274.114h-15.127a.385.385 0 01-.358-.24.38.38 0 01-.03-.148v-.318a.386.386 0 01.388-.387zM153.814 37.328h15.127a.388.388 0 01.388.387v.318a.388.388 0 01-.388.388h-15.127a.385.385 0 01-.388-.388v-.318a.386.386 0 01.388-.387zM153.814 39.888h15.127a.388.388 0 01.388.387v.318a.387.387 0 01-.388.388h-15.127a.386.386 0 01-.388-.388v-.318a.386.386 0 01.388-.387zM153.814 42.471h8.247a.387.387 0 01.385.388v.318a.387.387 0 01-.385.388h-8.247a.386.386 0 01-.388-.388v-.316a.4.4 0 01.113-.275.39.39 0 01.275-.115z"
        fill="#020202"
      />
      <path
        d="M66.29 132.18h11.011a5.659 5.659 0 005.646-5.552v-2.516a5.665 5.665 0 00-3.498-5.228 5.67 5.67 0 00-2.167-.429H66.29a5.677 5.677 0 00-4.006 1.656 5.671 5.671 0 00-1.662 4.004v2.516a5.662 5.662 0 005.668 5.549zM81.652 135.235s-4.5.216-5.582-3.011h-3.301s2.144 4.04 8.883 3.011z"
        fill="#fff"
      />
      <path
        opacity={0.15}
        d="M79.111 121.653H63.984a.388.388 0 01-.388-.388v-.299a.387.387 0 01.388-.388h15.127a.387.387 0 01.388.388v.3a.39.39 0 01-.388.387zM79.111 124.261H63.984a.39.39 0 01-.388-.387v-.314a.389.389 0 01.388-.384h15.127a.387.387 0 01.388.384v.318a.39.39 0 01-.388.387v-.004zM79.111 126.822H63.984a.388.388 0 01-.388-.384v-.3a.385.385 0 01.388-.388h15.127a.387.387 0 01.388.388v.3a.39.39 0 01-.388.384zM79.111 129.404h-8.25a.386.386 0 01-.389-.387v-.3a.388.388 0 01.388-.394h8.254a.387.387 0 01.388.387v.3a.39.39 0 01-.388.388l-.003.006z"
        fill="#020202"
      />
      <path
        d="M95.701 142.274s-.722 3.011-1.59 2.582a2.016 2.016 0 01-.986-1.286l2.576-1.296z"
        fill="url(#prefix__paint17_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={122.104}
          y1={49.434}
          x2={122.104}
          y2={0.678}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.4} />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={54.998}
          y1={187.229}
          x2={112.557}
          y2={-23.343}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={111.761}
          y1={-20.939}
          x2={111.979}
          y2={229.017}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={73.699}
          y1={-47.688}
          x2={78.087}
          y2={202.287}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear"
          x1={145.52}
          y1={-41.999}
          x2={145.911}
          y2={207.964}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear"
          x1={145.378}
          y1={-69.485}
          x2={145.773}
          y2={180.478}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear"
          x1={147.228}
          y1={-6.918}
          x2={147.377}
          y2={243.036}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear"
          x1={171.062}
          y1={-8.512}
          x2={88.04}
          y2={0.632}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.58} />
          <stop offset={0.78} stopOpacity={0.161} />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear"
          x1={118.449}
          y1={-28.069}
          x2={23.183}
          y2={-9.418}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.58} />
          <stop offset={0.78} stopOpacity={0.161} />
        </linearGradient>
        <linearGradient
          id="prefix__paint9_linear"
          x1={195.777}
          y1={0.675}
          x2={100.514}
          y2={19.35}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.58} />
          <stop offset={0.78} stopOpacity={0.161} />
        </linearGradient>
        <linearGradient
          id="prefix__paint10_linear"
          x1={143.517}
          y1={125.54}
          x2={149.15}
          y2={125.54}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7" />
          <stop offset={0.42} stopColor="#EFD4D1" />
          <stop offset={1} stopColor="#F2EAC9" />
        </linearGradient>
        <linearGradient
          id="prefix__paint11_linear"
          x1={138.468}
          y1={118.6}
          x2={141.119}
          y2={118.6}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7" />
          <stop offset={0.42} stopColor="#EFD4D1" />
          <stop offset={1} stopColor="#F2EAC9" />
        </linearGradient>
        <linearGradient
          id="prefix__paint12_linear"
          x1={139.757}
          y1={121.779}
          x2={145.133}
          y2={119.93}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.91} stopOpacity={0.8} />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="prefix__paint13_linear"
          x1={134.512}
          y1={109.323}
          x2={150.917}
          y2={109.469}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.36} stopColor="#00272E" />
          <stop offset={1} stopColor="#69B9EB" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint14_linear"
          x1={84.666}
          y1={39.877}
          x2={91.11}
          y2={39.877}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7" />
          <stop offset={0.42} stopColor="#EFD4D1" />
          <stop offset={1} stopColor="#F2EAC9" />
        </linearGradient>
        <linearGradient
          id="prefix__paint15_linear"
          x1={95.287}
          y1={33.489}
          x2={97.936}
          y2={33.489}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC4D7" />
          <stop offset={0.42} stopColor="#EFD4D1" />
          <stop offset={1} stopColor="#F2EAC9" />
        </linearGradient>
        <linearGradient
          id="prefix__paint16_linear"
          x1={98.754}
          y1={22.998}
          x2={87.614}
          y2={23.109}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.36} stopColor="#00272E" />
          <stop offset={1} stopColor="#69B9EB" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint17_linear"
          x1={94.647}
          y1={142.384}
          x2={93.121}
          y2={146.291}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.91} stopOpacity={0.8} />
          <stop offset={1} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UnderConstruction;
