const OTP_CHANNELS = ["BRI_DIRECT_DEBIT", "BNI_AUTOPAY"];
const TH_DD_FORM_A_CHANNELS = ["KRUNGSRI_DIRECT_DEBIT", "KTB_DIRECT_DEBIT"];
const TH_DD_FORM_B_CHANNELS = ["BBL_DIRECT_DEBIT", "SCB_DIRECT_DEBIT"];

// Channel code helpers
export const isOTPChannel = (channelCode?: string) => {
  return channelCode && OTP_CHANNELS.includes(channelCode);
};

export const isTHDDFormAChannel = (channelCode?: string) => {
  return channelCode && TH_DD_FORM_A_CHANNELS.includes(channelCode);
};

export const isTHDDFormBChannel = (channelCode?: string) => {
  return channelCode && TH_DD_FORM_B_CHANNELS.includes(channelCode);
};

// PM Type helpers
export const isPMTypeCard = (pmType?: string) => {
  return pmType === "CARDS";
};

export const isRedirectChannel = (channelCode?: string) => {
  return (
    channelCode &&
    !isOTPChannel(channelCode) &&
    !isTHDDFormAChannel(channelCode) &&
    !isTHDDFormBChannel(channelCode) &&
    !isPMTypeCard(channelCode)
  );
};
