import { FC, SVGProps } from "react";

const Clock: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2353_53)">
        <path
          d="M7.99333 1.33334C4.31333 1.33334 1.33333 4.32001 1.33333 8.00001C1.33333 11.68 4.31333 14.6667 7.99333 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8.00001C14.6667 4.32001 11.68 1.33334 7.99333 1.33334ZM8 13.3333C5.05333 13.3333 2.66666 10.9467 2.66666 8.00001C2.66666 5.05334 5.05333 2.66668 8 2.66668C10.9467 2.66668 13.3333 5.05334 13.3333 8.00001C13.3333 10.9467 10.9467 13.3333 8 13.3333Z"
          fill="currentColor"
        />
        <path
          d="M8.33333 4.66666H7.33333V8.66666L10.8333 10.7667L11.3333 9.94666L8.33333 8.16666V4.66666Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2353_53">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Clock;
