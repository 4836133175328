import { FC, SVGProps } from "react";

const Warning: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 16 14"
      fill="none"
      data-testid="triangle-prompt-icon"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3332 13.3333L7.99984 0.666656L0.666504 13.3333H15.3332ZM7.33317 11.3333V9.99999H8.6665V11.3333H7.33317ZM7.33317 8.66666H8.6665V5.99999H7.33317V8.66666Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Warning;
