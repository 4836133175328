import { useState, useRef, useEffect, useCallback } from "react";
import copyToClipboard from "copy-to-clipboard";

const useCopyToClipboard = (
  str: string
): [() => void, boolean, (value: boolean) => void] => {
  const [isCopied, setIsCopied] = useState(false);
  const copyValueRef = useRef(str);

  const copyAction = useCallback(() => {
    copyToClipboard(copyValueRef.current);
    setIsCopied(true);
  }, [copyValueRef]);

  useEffect(() => {
    copyValueRef.current = str;

    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [str, isCopied]);

  return [copyAction, isCopied, setIsCopied];
};

export default useCopyToClipboard;
