import { PaymentMethodsEnum } from "@xendit/checkout-utilities";
import axios from "axios";

import {
  API,
  CHECKOUT_UI_GATEWAY_REQUEST_ORIGIN,
  CHECKOUT_UI_GATEWAY_URL
} from "./constants";

import {
  CreditCardSignaturePayload,
  CybersourceChargeRequest
} from "../types/credit-card";
import { CreateInvoiceFromOnDemandPayload } from "../types/on-demand";

const statuses = {
  PENDING: "PENDING",
  RESOLVED: "RESOLVED",
  ERROR: "ERROR"
};

export interface Resource<A> {
  read: () => A | undefined;
}
export type ResourceInvoker<T> = () => Promise<T>;

export function createResource<T>(asyncFn: ResourceInvoker<T>): Resource<T> {
  let status = statuses.PENDING;
  let result: T;
  const promise = asyncFn().then(
    (r) => {
      status = statuses.RESOLVED;
      result = r;
    },
    (e) => {
      status = statuses.ERROR;
      result = e;
    }
  );

  return {
    read() {
      if (status === statuses.PENDING) throw promise;
      if (status === statuses.ERROR) throw result;
      if (status === statuses.RESOLVED) return result;
    }
  };
}

export function fetchInitialData<T>(id: string): Promise<T> {
  return axios
    .get(CHECKOUT_UI_GATEWAY_URL + API.getInitialData(id))
    .then((response) => response.data)
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export function fetchInvoice<T>(
  id: string,
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  return axios
    .get(CHECKOUT_UI_GATEWAY_URL + API.getInvoice(id), {
      signal: opts?.abortSignal
    })
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => response)
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export function createPaymentRequest<T>(
  id: string,
  paymentRequestPayload: object,
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  return axios
    .post(
      CHECKOUT_UI_GATEWAY_URL + API.createPaymentRequest,
      paymentRequestPayload,
      {
        headers: {
          "Content-Type": "application/json",
          "invoice-id": id,
          "request-origin": CHECKOUT_UI_GATEWAY_REQUEST_ORIGIN
        },
        signal: opts?.abortSignal
      }
    )
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export async function simulatePaymentMethod(
  paymentMethodId: string,
  invoiceId: string,
  opts?: {
    abortSignal: AbortSignal;
  }
) {
  return axios
    .post(
      CHECKOUT_UI_GATEWAY_URL + API.simulatePaymentMethod(paymentMethodId),
      undefined,
      {
        headers: {
          "Content-Type": "application/json",
          "invoice-id": invoiceId,
          "request-origin": CHECKOUT_UI_GATEWAY_REQUEST_ORIGIN
        },
        signal: opts?.abortSignal
      }
    )
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export function getPaylaterCustomer<T>(
  id: string,
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  return axios
    .get(CHECKOUT_UI_GATEWAY_URL + API.getPaylaterCustomer, {
      headers: {
        "invoice-id": id
      },
      signal: opts?.abortSignal
    })
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export function initiatePlans<T>(
  id: string,
  channelCode: string,
  customer?: object | null,
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  const data: {
    channel_code: string;
    is_new_customer: boolean;
    customer?: object;
  } = {
    channel_code: channelCode,
    is_new_customer: !!customer
  };
  if (customer) {
    data.customer = customer;
  }
  return axios
    .post(CHECKOUT_UI_GATEWAY_URL + API.initiatePlans, data, {
      headers: {
        "Content-Type": "application/json",
        "invoice-id": id
      },
      signal: opts?.abortSignal
    })
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export function createPaylaterCharge<T>(
  id: string,
  chargeArgs: {
    businessId: string;
    channelCode: string;
    planId: string;
    checkoutMethod: string;
    successRedirectUrl: string;
    failureRedirectUrl: string;
  },
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  return axios
    .post(
      CHECKOUT_UI_GATEWAY_URL + API.createPaylaterCharge,
      {
        business_id: chargeArgs.businessId,
        channel_code: chargeArgs.channelCode,
        plan_id: chargeArgs.planId,
        checkout_method: chargeArgs.checkoutMethod,
        success_redirect_url: chargeArgs.successRedirectUrl,
        failure_redirect_url: chargeArgs.failureRedirectUrl
      },
      {
        headers: {
          "Content-Type": "application/json",
          "invoice-id": id
        },
        signal: opts?.abortSignal
      }
    )
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export async function createLegacyAsyncPaymentCode<T>(
  id: string,
  paymentMethodType:
    | PaymentMethodsEnum.BANK_TRANSFER
    | PaymentMethodsEnum.RETAIL_OUTLET,
  channelCode: string,
  isFixedVa?: boolean,
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  let endpoint;
  if (paymentMethodType === PaymentMethodsEnum.BANK_TRANSFER) {
    if (isFixedVa) {
      endpoint = API.createFixedVirtualAccount(channelCode);
    } else {
      endpoint = API.createPoolVirtualAccount(channelCode);
    }
  }
  if (paymentMethodType === PaymentMethodsEnum.RETAIL_OUTLET)
    endpoint = API.createOtcPaymentCode(channelCode);

  return axios
    .post(CHECKOUT_UI_GATEWAY_URL + endpoint, undefined, {
      headers: {
        "Content-Type": "application/json",
        "invoice-id": id,
        "request-origin": CHECKOUT_UI_GATEWAY_REQUEST_ORIGIN
      },
      signal: opts?.abortSignal
    })
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export async function authorizePaymentMethod<T>(
  id: string,
  paymentMethodId: string,
  authCode: string,
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  return axios
    .post(
      CHECKOUT_UI_GATEWAY_URL + API.authorizePaymentMethod(paymentMethodId),
      {
        auth_code: authCode
      },
      {
        headers: {
          "Content-Type": "application/json",
          "invoice-id": id,
          "request-origin": CHECKOUT_UI_GATEWAY_REQUEST_ORIGIN
        },
        signal: opts?.abortSignal
      }
    )
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export async function simulateVirtualAccountPayment<T>(
  id: string,
  channelCode: string,
  simulateArgs: {
    collectionType?: string | null;
    bankAccountNumber?: string | null;
  },
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  return axios
    .post(
      CHECKOUT_UI_GATEWAY_URL + API.simulateVirtualAccountPayment(channelCode),
      {
        collection_type: simulateArgs.collectionType,
        bank_account_number: simulateArgs.bankAccountNumber
      },
      {
        headers: {
          "Content-Type": "application/json",
          "invoice-id": id,
          "request-origin": CHECKOUT_UI_GATEWAY_REQUEST_ORIGIN
        },
        signal: opts?.abortSignal
      }
    )
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export async function simulateOtcPayment<T>(
  id: string,
  channelCode: string,
  simulateArgs: {
    paymentCode?: string | null;
  },
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  return axios
    .post(
      CHECKOUT_UI_GATEWAY_URL + API.simulateOtcPayment(channelCode),
      {
        payment_code: simulateArgs.paymentCode
      },
      {
        headers: {
          "Content-Type": "application/json",
          "invoice-id": id,
          "request-origin": CHECKOUT_UI_GATEWAY_REQUEST_ORIGIN
        },
        signal: opts?.abortSignal
      }
    )
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export async function createCardCybsCharge<T>(
  invoiceId: string,
  data: Omit<
    CybersourceChargeRequest,
    "set_submitting" | "billing_details_required"
  >,
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  return axios
    .post(CHECKOUT_UI_GATEWAY_URL + API.createCardCybsCharge, data, {
      headers: {
        "Content-Type": "application/json",
        "invoice-id": invoiceId,
        "request-origin": CHECKOUT_UI_GATEWAY_REQUEST_ORIGIN
      },
      signal: opts?.abortSignal
    })
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export async function getCreditCardChargeOptions<T>(
  invoiceId: string,
  query: string,
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  return axios
    .get(CHECKOUT_UI_GATEWAY_URL + API.getCreditCardChargeOptions + query, {
      headers: {
        "Content-Type": "application/json",
        "invoice-id": invoiceId,
        "request-origin": CHECKOUT_UI_GATEWAY_REQUEST_ORIGIN
      },
      signal: opts?.abortSignal
    })
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export async function createSignature<T>(
  invoiceId: string,
  data: CreditCardSignaturePayload,
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  return axios
    .post(CHECKOUT_UI_GATEWAY_URL + API.createSignature, data, {
      headers: {
        "Content-Type": "application/json",
        "invoice-id": invoiceId,
        "request-origin": CHECKOUT_UI_GATEWAY_REQUEST_ORIGIN
      },
      signal: opts?.abortSignal
    })
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export async function createInvoiceFromOnDemand<T>(
  onDemandLink: string,
  data: CreateInvoiceFromOnDemandPayload,
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  return axios
    .post(
      CHECKOUT_UI_GATEWAY_URL + API.createInvoiceFromOnDemand(onDemandLink),
      data,
      {
        headers: {
          "Content-Type": "application/json"
        },
        signal: opts?.abortSignal
      }
    )
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}

export async function getRecurringInvoices<T>(
  recurringId: string,
  query: string,
  opts?: {
    abortSignal: AbortSignal;
  }
): Promise<T> {
  return axios
    .get(
      CHECKOUT_UI_GATEWAY_URL + API.getRecurringInvoices(recurringId) + query,
      {
        headers: {
          "Content-Type": "application/json"
        },
        signal: opts?.abortSignal
      }
    )
    .then((response) => {
      if (response.status > 299) {
        return Promise.reject(response);
      }
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return null;
      }
      return Promise.reject(err);
    });
}
