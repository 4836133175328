import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export type THDirectDebitFormAValues = {
  mobile_number: string;
  identity_document_number: string;
};

export const useTHDirectDebitFormikA = (props: {
  onSubmit: (values: THDirectDebitFormAValues) => Promise<void>;
}) => {
  const { onSubmit } = props;
  const { t } = useTranslation("session");
  const {
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
    values,
    touched,
    isValid,
    dirty
  } = useFormik<THDirectDebitFormAValues>({
    initialValues: (() => {
      return {
        mobile_number: "",
        identity_document_number: ""
      };
    })(),
    onSubmit: async (values: THDirectDebitFormAValues) => {
      // Remove all spaces from mobile_number
      values.mobile_number = values.mobile_number.replace(/\s/g, "");
      await onSubmit(values);
    },
    validationSchema: yup.object({
      mobile_number: yup
        .string()
        .required(
          t("{{field}} is required", { field: t("Mobile Number"), ns: "forms" })
        ),
      identity_document_number: yup.string().required(
        t("{{field}} is required", {
          field: t("Identity Document Number"),
          ns: "forms"
        })
      )
    })
  });

  return {
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
    values,
    touched,
    isValid,
    dirty
  };
};
