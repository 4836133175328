type Options = {
  downloadFilename?: string;
};

const useQrCode = (
  qrCodeRef: React.RefObject<HTMLCanvasElement>,
  opts?: Options
) => {
  const downloadQRCode = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const canvas = qrCodeRef.current;
    const image = canvas?.toDataURL("image/png") as string;
    const anchor = document.createElement("a");

    anchor.href = image;
    anchor.download = opts?.downloadFilename
      ? `${opts.downloadFilename}.png`
      : "qr-code.png";

    document.body.appendChild(anchor);
    anchor.click();

    document.body.removeChild(anchor);
  };

  return { downloadQRCode };
};

export default useQrCode;
