import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Graphic from "../../../assets/graphics/Oops";
import Button from "../../../components/Button";
import { MainView } from "../../../components/Layout";
import PoweredByXendit from "../../../components/PoweredByXendit";
import { SessionContext } from "../../context/context";
import { makeActiveSessionLocalUrl } from "../../lib/make-session-url";
import { ErrorContent } from "../../types";

const PollingFailed: React.FC<{ errorContent: ErrorContent }> = (props) => {
  const { t } = useTranslation("session");
  const { state } = useContext(SessionContext);
  const { session } = state;

  const { errorContent } = props;

  const renderSessionDetails = () => {
    if (session) {
      return (
        <>
          <p className="px-4 text-xs mb-8">
            {t("Reference ID")}: {session.reference_id}
          </p>
          <a
            href={makeActiveSessionLocalUrl(session.payment_session_id)}
            className="text-center text-xen-blue-600 underline"
          >
            {t("Click here to try another payment method")}
          </a>
        </>
      );
    } else {
      return (
        // Reload the page to try again if a session cannot be retrieved
        <Button
          variant="secondary"
          className="mt-4"
          onClick={() => window.location.reload()}
        >
          {t("Click here to try again")}
        </Button>
      );
    }
  };

  return (
    <MainView>
      <div className="flex-1 px-4 lg:px-0">
        <div className="pt-8 pb-12 space-y-8">
          <div className="flex flex-col items-center">
            <Graphic className="text-primary h-32 mb-8 hidden md:block" />
            <h1 className="text-3xl font-semibold mb-2 text-center">
              {t(errorContent.title)}
            </h1>
            <p className="px-4 text-base mb-2">{errorContent.message_1}</p>
            <p className="px-4 text-base mb-2">{errorContent.message_2}</p>
            {renderSessionDetails()}
          </div>
        </div>

        <div className="flex justify-center pb-12">
          <PoweredByXendit />
        </div>
      </div>
    </MainView>
  );
};

export default PollingFailed;
