import { RefObject, useState } from "react";

import useEventListener from "./use-event-listener";

const useHover = <T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>
) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  useEventListener("mouseenter", handleMouseEnter, elementRef);
  useEventListener("mouseleave", handleMouseLeave, elementRef);

  return isHovered;
};

export default useHover;
