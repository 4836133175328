import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipRoot = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  TooltipPrimitive.TooltipContentProps & React.RefAttributes<HTMLDivElement>
>(({ side = "right", sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    side={side}
    sideOffset={sideOffset}
    className="z-50 bg-white px-3 py-1.5 text-sm shadow-sm border border-xen-gray-300 rounded-md animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1"
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export type TooltipProps = {
  children: React.ReactNode;
  contentChildren?: React.ReactNode;
};

const Tooltip = ({
  asChild,
  children,
  contentChildren,
  delayDuration,
  open,
  side
}: TooltipProps &
  TooltipPrimitive.TooltipProps &
  TooltipPrimitive.TooltipContentProps) => {
  return (
    <TooltipProvider delayDuration={delayDuration}>
      <TooltipRoot open={open}>
        <TooltipTrigger asChild={asChild}>{children}</TooltipTrigger>
        {contentChildren ? (
          <TooltipContent side={side}>{contentChildren}</TooltipContent>
        ) : null}
      </TooltipRoot>
    </TooltipProvider>
  );
};

export default Tooltip;
