import { FC, Suspense } from "react";
import type { TFunction } from "i18next";
import isEmpty from "lodash/isEmpty";
import { formatDisplayAmountWithCurrencyCode } from "@xendit/checkout-utilities/dist/src/amount-formatter";
import { PaymentChannelsEnum } from "@xendit/checkout-utilities/dist/src/enums/payment-channels";
import { PaymentMethodsEnum } from "@xendit/checkout-utilities/dist/src/enums/payment-methods";
import {
  getCountryOfOperationByCurrency,
  CountriesEnum
} from "@xendit/checkout-utilities";
import type {
  PaymentChannelsInterface,
  PaymentChannelsPropertiesType
} from "@xendit/checkout-utilities/dist/src/types/payment-channels";

import {
  PaymentMethodListItem,
  PaymentMethodListItemChannel
} from "../../components/PaymentMethodList";

import PaymentFPXAccordion from "./PaymentFPXAccordion";
import PaymentDragonpayAccordion from "./PaymentDragonpayAccordion";
import PaymentPaylaterAccordion from "./PaymentPaylaterAccordion";
import PaymentQrCodeAccordion from "./PaymentQrCodeAccordion";
import CreditCardPayment from "../CreditCardPayment";

import PaymentRequestProvider from "../../contexts/PaymentRequestContext";
import { useCreditCardPayment } from "../../contexts/CreditCardPaymentContext";
import { usePaymentLink } from "../../contexts/PaymentLinkContext";

import {
  AsyncChannelObject,
  BanksInner,
  CryptocurrenciesInner,
  DirectDebitsInner,
  EwalletsInner,
  Invoice,
  InvoiceSettings,
  PaylatersInner,
  QrCodesInner
} from "../../types/checkout";

import {
  BankTransfer,
  Card,
  Ewallet,
  Paylater,
  PayWithCrypto,
  QrCode,
  RetailOutlet
} from "../../assets/icons";

import { evaluateAmountRange } from "../../helpers/amount-validation";
import { isPaymentChannelDisabled } from "../../helpers/payment-channel-availability";
import { orderChannels } from "../../helpers/channels-ordering";
import { shouldUseFPXDirectDebit } from "../../helpers/direct-debit";
import { extendCardsSchemes } from "../../helpers/extend-card-schemes";
import {
  validateTime,
  validateDay
} from "../../helpers/channel-date-time-validation";
import {
  MOBILE_BANKING_CHANNEL_CODE_REGEX,
  BASE_ASSETS_URL
} from "../../utils/constants";

const mapPaymentMethodListItems = (
  invoice: Invoice,
  invoiceSettings: InvoiceSettings,
  checkoutPaymentChannels: PaymentChannelsInterface,
  t: TFunction
) => {
  const items: PaymentMethodListItem[] = [];

  const countryOfOperationByCurrency =
    getCountryOfOperationByCurrency(invoice.currency)?.name ||
    CountriesEnum.Indonesia;

  const isThailand = countryOfOperationByCurrency === CountriesEnum.Thailand;

  const isIndonesia = countryOfOperationByCurrency === CountriesEnum.Indonesia;

  const resolvePaymentChannels = (
    channels: (
      | BanksInner
      | AsyncChannelObject
      | EwalletsInner
      | QrCodesInner
      | DirectDebitsInner
      | PaylatersInner
      | CryptocurrenciesInner
    )[]
  ) => {
    return channels.reduce((agg, channel) => {
      const resolvedPaymentChannel =
        checkoutPaymentChannels[channel.name as PaymentChannelsEnum];
      if (!resolvedPaymentChannel) {
        return agg;
      }

      const { minimumAmount, maximumAmount, isWithinAmountRange } =
        evaluateAmountRange(
          invoice.amount,
          invoice.currency,
          resolvedPaymentChannel
        );

      // Check date and time availability if field exists
      const timeValidation =
        resolvedPaymentChannel.date_and_time_availability &&
        validateTime(resolvedPaymentChannel.date_and_time_availability);
      const dayValidation =
        resolvedPaymentChannel.date_and_time_availability &&
        validateDay(resolvedPaymentChannel.date_and_time_availability);

      // Time and day is automatically available if it's undefined (date_and_time_availability is not set)
      const timeIsCurrentlyAvailable =
        isEmpty(timeValidation) || timeValidation.isAvailable;
      const dayIsCurrentlyAvailable =
        isEmpty(dayValidation) || dayValidation.isAvailable;

      const ADDITIONAL_CHANNELS: PaymentChannelsEnum[] = [
        PaymentChannelsEnum.OTHER_BANKS
      ];
      const listItem: PaymentMethodListItemChannel = {
        name: resolvedPaymentChannel.channel,
        displayName: t(resolvedPaymentChannel.display_name),
        label: resolvedPaymentChannel.label,
        logoUrl: resolvedPaymentChannel.logo_url,
        disabled:
          isPaymentChannelDisabled(resolvedPaymentChannel.channel) ||
          resolvedPaymentChannel.status === "UNAVAILABLE" ||
          !isWithinAmountRange ||
          !timeIsCurrentlyAvailable ||
          !dayIsCurrentlyAvailable,
        isAdditionalChannel: ADDITIONAL_CHANNELS.includes(
          resolvedPaymentChannel.channel
        )
      };

      if (!isWithinAmountRange) {
        listItem.tooltipMessage = t(
          "The accepted amount range is {{minimumAmount}} — {{maximumAmount}}",
          {
            minimumAmount: formatDisplayAmountWithCurrencyCode(
              minimumAmount,
              invoice.currency
            ),
            maximumAmount: formatDisplayAmountWithCurrencyCode(
              maximumAmount,
              invoice.currency
            )
          }
        );
      }

      if (!timeIsCurrentlyAvailable || !dayIsCurrentlyAvailable) {
        listItem.tooltipMessage = t("Currently is outside of operating hours.");
      }

      return [...agg, listItem];
    }, [] as PaymentMethodListItemChannel[]);
  };

  const [
    bankTransferChannels,
    retailOutletChannels,
    ewalletChannels,
    qrCodeChannels,
    directDebitChannels,
    paylaterChannels,
    cryptocurrencyChannels
  ] = [
    resolvePaymentChannels(invoice.banks || []),
    resolvePaymentChannels(invoice.retail_outlets || []),
    resolvePaymentChannels(invoice.ewallets || []),
    resolvePaymentChannels(invoice.qr_codes || []),
    resolvePaymentChannels(invoice.direct_debits || []),
    resolvePaymentChannels(invoice.paylaters || []),
    resolvePaymentChannels(invoice.cryptocurrencies || [])
  ];

  if (!isEmpty(bankTransferChannels)) {
    items.push({
      value: PaymentMethodsEnum.BANK_TRANSFER,
      displayName: t("Bank Transfer"),
      icon: BankTransfer,
      channels: bankTransferChannels
    });
  }

  if (invoice.payment_methods?.includes(PaymentMethodsEnum.CREDIT_CARD)) {
    const defaultCardsSchemes = [
      {
        displayName: "MASTERCARD",
        label: "MASTERCARD",
        logoUrl: `${BASE_ASSETS_URL}card-brands/mastercard.svg`,
        name: "MASTERCARD"
      },
      {
        displayName: "VISA",
        label: "VISA",
        logoUrl: `${BASE_ASSETS_URL}card-brands/visa.svg`,
        name: "VISA"
      },
      {
        displayName: "JCB",
        label: "JCB",
        logoUrl: `${BASE_ASSETS_URL}card-brands/jcb.svg`,
        name: "JCB"
      }
    ];

    const cardsSchemes = extendCardsSchemes(
      defaultCardsSchemes,
      invoiceSettings.additional_credit_card_logo_display
    );

    items.push({
      value: PaymentMethodsEnum.CREDIT_CARD,
      displayName: isIndonesia
        ? t("Credit / Debit Card / Nex Card")
        : t("Credit / Debit Card"),
      icon: Card,
      showNewBadge: isIndonesia,
      channels: cardsSchemes,
      renderContent: () => {
        const {
          creditCardValidationErrors,
          isFetchingChargeOptions,
          onSubmitPayment
        } = useCreditCardPayment();
        const {
          paymentLink: {
            invoice: { channel_properties: channelProperties }
          }
        } = usePaymentLink();

        return (
          <Suspense fallback={<PaymentCreditCardAccordionLoadingSkeleton />}>
            <CreditCardPayment
              onSubmit={onSubmitPayment}
              creditCardValidationErrors={creditCardValidationErrors}
              isFetchingChargeOptions={isFetchingChargeOptions}
              allowFullPayment={
                channelProperties?.cards?.installment_configuration
                  ?.allow_full_payment !== false
              }
            />
          </Suspense>
        );
      }
    });
  }

  if (!isEmpty(retailOutletChannels)) {
    items.push({
      value: PaymentMethodsEnum.RETAIL_OUTLET,
      displayName: t("Retail Outlet"),
      icon: RetailOutlet,
      channels: retailOutletChannels
    });
  }

  if (!isEmpty(ewalletChannels)) {
    items.push({
      value: PaymentMethodsEnum.EWALLET,
      displayName: t("E-Wallet"),
      icon: Ewallet,
      channels: ewalletChannels
    });
  }

  if (!isEmpty(qrCodeChannels)) {
    const checkoutPaymentChannel =
      checkoutPaymentChannels[qrCodeChannels[0].name as PaymentChannelsEnum];
    if (checkoutPaymentChannel) {
      items.push({
        value: PaymentMethodsEnum.QR_CODE,
        displayName: t("QR Payments"),
        icon: QrCode,
        channels: qrCodeChannels,
        renderContent: () => (
          <Suspense fallback={<PaymentQrCodeAccordionLoadingSkeleton />}>
            <PaymentRequestProvider>
              <PaymentQrCodeAccordion channel={checkoutPaymentChannel} />
            </PaymentRequestProvider>
          </Suspense>
        )
      });
    }
  }
  /**
   * DD Channels can be either mobile banking or direct debit channels or many more in the future
   * Currently, only Thailand has separated DD Channels (DD and MB)
   */
  const segregatedDDChannels = directDebitChannels.reduce(
    (accum, currentChannel) => {
      /**
       * Checks if the current payment channel is a mobile banking channel.
       * @param currentChannel - The current payment channel object.
       * @returns A boolean indicating whether the current payment channel is a mobile banking channel.
       */
      const isMobileBankingChannel =
        isThailand &&
        MOBILE_BANKING_CHANNEL_CODE_REGEX.test(currentChannel.name);

      if (isMobileBankingChannel) {
        accum.mobileBankingChannels.push(currentChannel);
      } else {
        accum.directDebitChannels.push(currentChannel);
      }

      return accum;
    },
    {
      mobileBankingChannels: [],
      directDebitChannels: []
    } as {
      mobileBankingChannels: Array<PaymentMethodListItemChannel>;
      directDebitChannels: Array<PaymentMethodListItemChannel>;
    }
  );

  if (!isEmpty(segregatedDDChannels.mobileBankingChannels)) {
    items.push({
      value: `${PaymentMethodsEnum.DIRECT_DEBIT}_MOBILE_BANKING`,
      displayName: t("Mobile Banking"),
      icon: BankTransfer,
      channels: segregatedDDChannels.mobileBankingChannels
    });
  }

  if (!isEmpty(segregatedDDChannels.directDebitChannels)) {
    /**
     * For Malaysia, we have special handling for FPX Direct Debit channels order. E.g.: top 5 mostly used banks will be displayed first.
     */

    if (shouldUseFPXDirectDebit(countryOfOperationByCurrency)) {
      const orderedFPXChannels = orderChannels(
        segregatedDDChannels.directDebitChannels,
        invoice.currency
      );

      const mappedChannels = orderedFPXChannels.map((channel: any) => {
        return {
          ...channel,
          channel: channel.name,
          logo_url: channel.logoUrl,
          display_name: channel.displayName
        };
      }) as PaymentChannelsPropertiesType[];

      items.push({
        value: PaymentMethodsEnum.DIRECT_DEBIT,
        displayName: t("FPX Online Banking"),
        icon: BankTransfer,
        channels: orderedFPXChannels,
        renderContent: () => (
          <Suspense fallback={<PaymentFPXAccordionLoadingSkeleton />}>
            <PaymentFPXAccordion channels={mappedChannels} />
          </Suspense>
        )
      });
    } else {
      // Dragonpay channels all have ONLINE_BANKING suffix
      // We need to filter out those to get all the regular DD channels

      // Regular DD channels
      // ====================
      const regularDDChannels = segregatedDDChannels.directDebitChannels.filter(
        (channel) => !channel.name.includes("ONLINE_BANKING")
      );

      items.push({
        value: PaymentMethodsEnum.DIRECT_DEBIT,
        displayName: t("Direct Debit"),
        icon: BankTransfer,
        channels: regularDDChannels
      });

      // Dragonpay DD channels
      // ====================
      const channelsAllowedForTestMode = [
        "DD_BDO_ONLINE_BANKING",
        "DD_BPI_ONLINE_BANKING"
      ];
      const isLiveMode =
        import.meta.env.VITE_APP_ENV_MODE === "production-live";
      const dragonpayChannels = segregatedDDChannels.directDebitChannels.filter(
        (channel) => {
          return isLiveMode
            ? channel.name.includes("ONLINE_BANKING")
            : channelsAllowedForTestMode.includes(channel.name);
        }
      );

      const mappedDragonpayChannels = dragonpayChannels.map((channel: any) => {
        return {
          ...channel,
          channel: channel.name,
          logo_url: channel.logoUrl,
          display_name: channel.displayName
        };
      }) as PaymentChannelsPropertiesType[];

      items.push({
        value: "ONLINE_BANKING",
        displayName: t("Online Banking"),
        icon: BankTransfer,
        channels: dragonpayChannels,
        renderContent: () => (
          <Suspense fallback={<PaymentFPXAccordionLoadingSkeleton />}>
            <PaymentDragonpayAccordion channels={mappedDragonpayChannels} />
          </Suspense>
        )
      });
    }
  }

  if (!isEmpty(paylaterChannels)) {
    const checkoutPaylaterPaymentChannels = paylaterChannels.reduce(
      (agg, channel) => {
        const checkoutPaylaterPaymentChannel =
          checkoutPaymentChannels[channel.name as PaymentChannelsEnum];
        if (checkoutPaylaterPaymentChannel) {
          return [...agg, checkoutPaylaterPaymentChannel];
        }
        return agg;
      },
      [] as PaymentChannelsPropertiesType[]
    );
    items.push({
      value: PaymentMethodsEnum.PAYLATER,
      displayName: t("PayLater"),
      icon: Paylater,
      channels: paylaterChannels,
      renderContent: () => (
        <Suspense fallback={<PaymentPaylaterAccordionLoadingSkeleton />}>
          <PaymentPaylaterAccordion
            channels={checkoutPaylaterPaymentChannels}
          />
        </Suspense>
      )
    });
  }

  if (!isEmpty(cryptocurrencyChannels)) {
    items.push({
      value: "CRYPTOCURRENCY",
      displayName: t("Pay with Crypto"),
      icon: PayWithCrypto,
      channels: cryptocurrencyChannels
    });
  }

  return items;
};

export default mapPaymentMethodListItems;

const PaymentCreditCardAccordionLoadingSkeleton: FC = () => {
  return (
    <div className="py-4">
      <div className="flex flex-col px-4">
        <div className="mb-2">
          <div className="w-32 bg-xen-gray-200 h-4 rounded mb-2"></div>
          <div className="bg-xen-gray-200 h-10 rounded mb-4"></div>
        </div>
        <div className="mb-2 flex -mx-2">
          <div className="flex-1 px-2">
            <div className="w-32 bg-xen-gray-200 h-4 rounded mb-2"></div>
            <div className="bg-xen-gray-200 h-10 rounded mb-4"></div>
          </div>
          <div className="flex-1 px-2">
            <div className="w-32 bg-xen-gray-200 h-4 rounded mb-2"></div>
            <div className="bg-xen-gray-200 h-10 rounded mb-4"></div>
          </div>
        </div>
        <div className="mb-1">
          <div className="w-32 bg-xen-gray-200 h-4 rounded mb-2"></div>
          <div className="bg-xen-gray-200 h-10 rounded mb-4"></div>
        </div>
        <div className="flex justify-center">
          <div className="w-48 bg-xen-gray-200 h-10 rounded mb-4"></div>
        </div>
      </div>
    </div>
  );
};

const PaymentQrCodeAccordionLoadingSkeleton: FC = () => {
  return (
    <div className="flex flex-col items-center pt-6 pb-12">
      <div className="w-40 bg-xen-gray-200 h-4 rounded mb-4"></div>
      <div className="w-80 bg-xen-gray-200 h-6 rounded mb-3"></div>
      <div className="w-64 bg-xen-gray-200 h-4 rounded mb-4"></div>
      <div className="w-64 h-64 bg-xen-gray-200 rounded mb-4"></div>
      <div className="w-32 bg-xen-gray-200 h-4 rounded"></div>
    </div>
  );
};

const PaymentPaylaterAccordionLoadingSkeleton: FC = () => {
  return (
    <div className="py-6 flex justify-center">
      <div className="w-full max-w-md px-4 pt-1">
        <div className="h-4 rounded bg-xen-gray-200 w-14"></div>
        <div className="py-1">
          <div className="h-10 rounded bg-xen-gray-200"></div>
        </div>
      </div>
    </div>
  );
};

const PaymentFPXAccordionLoadingSkeleton: FC = () => {
  return (
    <div className="py-6 flex justify-center">
      <div className="w-full max-w-md px-4 pt-1">
        <div className="h-10 rounded bg-xen-gray-200 w-32 mx-auto justify-center items-center mb-8"></div>
        <div className="py-1">
          <div className="h-10 rounded bg-xen-gray-200"></div>
        </div>
      </div>
    </div>
  );
};
