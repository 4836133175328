import React from "react";
import { Command as CommandPrimitive } from "cmdk";

export const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>((componentProps, ref) => {
  const { className, ...props } = componentProps;

  return (
    <CommandPrimitive
      ref={ref}
      className={
        "flex h-full w-full flex-col overflow-hidden rounded-md bg-popover text-popover-foreground"
      }
      {...props}
    />
  );
});

Command.displayName = CommandPrimitive.displayName;

export const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>((componentProps, ref) => {
  const { className, ...props } = componentProps;

  return (
    <div className="flex items-center border-b border-xen-gray-400 px-2">
      <CommandPrimitive.Input
        ref={ref}
        className={
          "flex w-full rounded-md bg-transparent py-2 text-base outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
        }
        {...props}
      />
    </div>
  );
});

CommandInput.displayName = CommandPrimitive.Input.displayName;

export const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>((componentProps, ref) => {
  const { className, ...props } = componentProps;

  return (
    <CommandPrimitive.List
      ref={ref}
      className={"max-h-[300px] overflow-y-auto overflow-x-hidden"}
      {...props}
    />
  );
});

CommandList.displayName = CommandPrimitive.List.displayName;

export const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className="py-6 text-center text-base"
    {...props}
  />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

export const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>((componentProps, ref) => {
  const { className, ...props } = componentProps;

  return (
    <CommandPrimitive.Group
      ref={ref}
      className={
        "overflow-hidden p-1 text-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-base [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground"
      }
      {...props}
    />
  );
});

CommandGroup.displayName = CommandPrimitive.Group.displayName;

export const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>((componentProps, ref) => {
  const { className, ...props } = componentProps;

  return (
    <CommandPrimitive.Item
      ref={ref}
      className={
        "relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-base outline-none data-[disabled=true]:pointer-events-none data-[selected='true']:bg-primary data-[selected=true]:text-white data-[disabled=true]:opacity-50"
      }
      {...props}
    />
  );
});

CommandItem.displayName = CommandPrimitive.Item.displayName;
