import { FC, ReactNode } from "react";

type Props = {
  children?: ReactNode;
};

const Layout: FC<Props> = ({ children }) => {
  return (
    <main className="min-h-screen flex items-stretch text-base text-xen-gray-900 bg-white">
      {children}
    </main>
  );
};

export default Layout;

type MainViewProps = {
  children?: ReactNode;
};

export const MainView: FC<MainViewProps> = ({ children }) => {
  return (
    <div className="flex-1 lg:px-8 xl:px-8 lg:py-8 min-h-screen">
      <div className="w-full h-full mx-auto lg:max-w-2xl flex flex-col">
        {children}
      </div>
    </div>
  );
};

type SidebarProps = {
  children?: ReactNode;
};

export const Sidebar: FC<SidebarProps> = ({ children }) => {
  return (
    <aside className="hidden lg:flex flex-col w-2/5 xl:w-1/3 bg-xen-gray-100 px-4 xl:px-8 py-8">
      <div className="w-full mx-auto max-w-sm">{children}</div>
    </aside>
  );
};
