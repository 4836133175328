import { GrowthBook } from "@growthbook/growthbook-react";
import {
  GROWTHBOOK_API_HOST,
  GROWTHBOOK_CLIENT_KEY,
  GROWTHBOOK_ENABLE_DEV_MODE
} from "./constants";
import { shareGrowthBookItemsStore } from "./share-growthbook-items";
import { experimentExposureEvent } from "./nex-events";

/**
 * @description GrowthBook client side configuration
 * SHOULD ONLY BE IMPORTED ONCE
 */
const growthbookClient = new GrowthBook({
  apiHost: GROWTHBOOK_API_HOST,
  clientKey: GROWTHBOOK_CLIENT_KEY,
  enableDevMode: GROWTHBOOK_ENABLE_DEV_MODE,
  trackingCallback: (experiment, result) => {
    const { setState } = shareGrowthBookItemsStore;
    setState({
      experimentId: experiment.key,
      variantId: result.key
    });
    experimentExposureEvent(experiment.key, result.key);
  }
});

export { growthbookClient };
