import { useTranslation } from "react-i18next";
import { useNexBannerControl } from "../../hooks/use-nex-banner";
import { NexBannerPlacements } from "../../types/nex-banner";
import { ENABLE_RUM } from "../../utils/constants";
import { useCallback } from "react";

type BannerPlacementKey = Exclude<keyof NexBannerPlacements, "placement_email">;
interface NexBannerProps {
  onClick?: (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLImageElement>
  ) => void;
  bannerPlacementKey: BannerPlacementKey;
  className?: string;
}

const NexBanner = (props: NexBannerProps) => {
  const { onClick, bannerPlacementKey, className } = props;
  const { i18n } = useTranslation();
  const bannerControl = useNexBannerControl();

  const mappedLocale = i18n.language.includes("en") ? "en" : "id";

  const isBannerEnabledInPlacement = bannerControl[bannerPlacementKey].enabled;
  const bannerUrlObject = bannerControl[bannerPlacementKey].banner_url;
  const bannerUrlLocale = bannerUrlObject?.[mappedLocale];

  /**
   * @see {@link https://docs.datadoghq.com/real_user_monitoring/browser/monitoring_page_performance/?s=a/b%20testing#add-your-own-performance-timing}
   */
  if (ENABLE_RUM && !isBannerEnabledInPlacement) {
    import("@datadog/browser-rum").then((datadog) => {
      datadog.datadogRum.onReady(() => {
        datadog.datadogRum.addTiming(
          `nex-banner-control-${bannerPlacementKey}`
        );
      });
    });
  }

  const handleBannerOnLoad = useCallback(() => {
    if (ENABLE_RUM) {
      import("@datadog/browser-rum").then((datadog) => {
        datadog.datadogRum.onReady(() => {
          datadog.datadogRum.addTiming(`nex-banner-${bannerPlacementKey}`);
        });
      });
    }
  }, [bannerPlacementKey]);

  const isRedirectionUrlEmpty = (url: string) => {
    return url === "" || url === "#";
  };

  if (isBannerEnabledInPlacement && bannerUrlObject && bannerUrlLocale) {
    const content = (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
      <img
        className="cursor-pointer"
        src={bannerUrlLocale.url}
        alt={bannerUrlLocale.alt_text}
        onLoad={handleBannerOnLoad}
        onClick={onClick}
      />
    );

    if (isRedirectionUrlEmpty(bannerUrlLocale.redirection_url)) {
      return (
        <div className={`flex justify-center ${props.className}`}>
          {content}
        </div>
      );
    } else {
      return (
        <a
          href={bannerUrlLocale.redirection_url}
          target="_blank"
          rel="noreferrer"
          onClick={onClick}
          className={`flex justify-center ${props.className}`}
        >
          {content}
        </a>
      );
    }
  }

  return null;
};

export default NexBanner;
