import { Card, Ewallet, BankTransfer } from "../../assets/icons";
import { PaymentMethodListItem } from "../../components/PaymentMethodList";
import { PaymentMethod } from "../types";

/**
 * Returns an icon based on Payment Method Type
 * @param pmType Payment Method Type
 * @returns Icon Component
 */
function getIconByPMType(pmType: string) {
  switch (pmType) {
    case "CARDS":
      return Card;
    case "EWALLET":
      return Ewallet;
    case "DIRECT_DEBIT":
    case "VIRTUAL_ACCOUNT":
    default:
      return BankTransfer;
  }
}

/**
 * Returns a prettified Payment Method Type
 * @param pmType Payment Method Type
 * @returns Prettified Payment Method Type
 */
function getPrettyPMType(pmType: string) {
  switch (pmType) {
    case "CARDS":
      return "Card";
    case "EWALLET":
      return "E-Wallet";
    case "DIRECT_DEBIT":
      return "Direct Debit";
    case "VIRTUAL_ACCOUNT":
      return "Bank Transfer";
    default:
      return "Other Payment Methods";
  }
}

/**
 * Maps a list of Payment Methods to a list of Payment Method List Item Components
 */
export const mapSessionPaymentMethodsToPaymentMethodListItems = (
  paymentMethods: PaymentMethod[]
): PaymentMethodListItem[] => {
  const segmentedChannels = paymentMethods.reduce((acc, channel) => {
    if (channel.channel_code === "CARDS" && channel.card) {
      const cardChannel = channel.card.brands.map((brand) => ({
        value: brand.name,
        displayName: brand.name,
        label: brand.name,
        logoUrl: brand.logo_url
      }));
      acc.push({ pm_type: "CARDS", channels: cardChannel });
    } else {
      const existingChannel = acc.find(
        (item) => item.pm_type === channel.pm_type
      );
      if (existingChannel) {
        existingChannel.channels.push({
          value: channel.channel_code,
          displayName: channel.channel_code,
          label: channel.channel_code,
          logoUrl: channel.logo_url
        });
      } else {
        acc.push({
          pm_type: channel.pm_type,
          channels: [
            {
              value: channel.channel_code,
              displayName: channel.channel_code,
              label: channel.channel_code,
              logoUrl: channel.logo_url
            }
          ]
        });
      }
    }

    return acc;
  }, [] as { pm_type: string; channels: { value: string; displayName: string; label: string; logoUrl: string }[] }[]);

  return segmentedChannels.map((pmType) => {
    return {
      icon: getIconByPMType(pmType.pm_type),
      value: pmType.pm_type,
      displayName: getPrettyPMType(pmType.pm_type),
      channels: pmType.channels.map((channel) => ({
        displayName: channel.label,
        label: channel.label,
        logoUrl: channel.logoUrl,
        name: channel.value
      }))
    };
  });
};
