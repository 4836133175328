import { useState, useEffect } from "react";
import { userArgsType } from "../contexts/PaymentRequestContext";

const SET_TIMEOUT_MAX = 2147483647;

export const useQrCodeRefresh = (
  onInitialize: (
    userArgs?: userArgsType,
    callback?: () => unknown
  ) => AbortController | void,
  paymentLinkExpiryDate: string,
  qrCodeExpireDate: string | undefined
) => {
  // track if the QR code has expired while payment link is still active
  const [hasExpired, setHasExpired] = useState(false);
  const [willRefresh, setWillRefresh] = useState(false);

  const expireAndRefreshQr = (expiryDifferences: number) => {
    setHasExpired(true);

    if (expiryDifferences > 5000) {
      setWillRefresh(true);
      setTimeout(async () => {
        await new Promise<void>((resolve) => {
          onInitialize(undefined, () => {
            resolve();
            setHasExpired(false);
            setWillRefresh(false);
          });
        });
      }, 3000);
    }
  };

  useEffect(() => {
    if (qrCodeExpireDate) {
      // we derive the expiryTimer
      const now = Math.floor(new Date().getTime());
      const qrExpiryDate = new Date(qrCodeExpireDate);
      const qrExpiryTimer = Math.floor(qrExpiryDate.getTime()) - now;

      // we calculate if the QR and checkout expiry time is close
      // we only want to refresh QR if checkout expiry is 5 seconds after QR expiry
      const checkoutExpiryTime = new Date(paymentLinkExpiryDate);
      const expiryDifferences =
        checkoutExpiryTime.getTime() - qrExpiryDate.getTime();

      if (qrExpiryTimer > 0 && qrExpiryTimer <= SET_TIMEOUT_MAX) {
        setTimeout(() => {
          expireAndRefreshQr(expiryDifferences);
        }, qrExpiryTimer);
      }
    }
  }, [qrCodeExpireDate]);

  return { hasExpired, willRefresh };
};
