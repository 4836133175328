import { useTranslation } from "react-i18next";

export const SecureDisclaimer = () => {
  const { t } = useTranslation(["session"]);

  return (
    <div className="flex items-center border rounded-md p-4 bg-blue-50 text-black text-base border-primary max-w-screen-sm mx-4">
      <div className="mr-4">&#x1F512;</div>
      <p>
        {t(
          "Your details will be encrypted and secured to protect your privacy and prevent unauthorized access or use."
        )}
      </p>
    </div>
  );
};
