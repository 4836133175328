import { FC } from "react";
import * as BaseDialog from "@radix-ui/react-dialog";

import { X } from "../../assets/icons";
import Button, { ButtonProps } from "../Button";

export type DialogProps = {
  buttons?: (ButtonProps & { onClick: () => void; text: string })[];
  description?: string;
  content?: React.ReactNode;
  onClose?: () => void;
  open?: boolean;
  title?: string;
  contentCustomClassName?: string;
  buttonCustomClassName?: string;
};

const Dialog: FC<DialogProps> = (props) => {
  return (
    <BaseDialog.Root open={props.open}>
      {props.open ? (
        <BaseDialog.Portal>
          <BaseDialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 data-[state=open]:animate-dialog-overlay-show" />
          <BaseDialog.Content
            className={`fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] bg-white rounded-xl shadow-xl overflow-hidden focus:outline-none py-4 data-[state=open]:animate-dialog-content-show ${props.contentCustomClassName}`}
          >
            {props.title ? (
              <BaseDialog.Title className="px-4 mb-2 font-semibold text-xl flex items-baseline">
                <span className="flex-1 pr-4" data-testid="dialog-title">
                  {props.title}
                </span>
                {props.onClose ? (
                  <button
                    type="button"
                    title="Close"
                    className="text-xen-gray-700 hover:text-xen-gray-800"
                    onClick={props.onClose}
                  >
                    <X className="w-4 h-4" />
                  </button>
                ) : null}
              </BaseDialog.Title>
            ) : null}

            {props.description ? (
              <BaseDialog.Description
                className="px-4 prose-sm mb-4"
                data-testid="dialog-body"
              >
                {props.description}
              </BaseDialog.Description>
            ) : null}

            {props.content}

            {props.buttons ? (
              <div
                className={`flex justify-center space-x-2 ${props.buttonCustomClassName}`}
              >
                {props.buttons.map((button, index) => {
                  return (
                    <Button key={index} {...button}>
                      {button.text}
                    </Button>
                  );
                })}
              </div>
            ) : null}
          </BaseDialog.Content>
        </BaseDialog.Portal>
      ) : null}
    </BaseDialog.Root>
  );
};

export default Dialog;
