import { PaymentChannelsPropertiesType } from "@xendit/checkout-utilities/dist/src/types/payment-channels";

const AUTO_CREATE_PAYMENT_REQUEST_CHANNEL_TYPES = [
  "REDIRECT",
  "QR_CODE",
  "BANK_ACCOUNT"
];

export const shouldAutomaticallyInitialize = (
  paymentChannel: PaymentChannelsPropertiesType
) => {
  return AUTO_CREATE_PAYMENT_REQUEST_CHANNEL_TYPES.includes(
    (paymentChannel.properties as { type: string }).type
  );
};
