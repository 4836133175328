import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { MainView } from "../../components/Layout";
import { usePaymentLink } from "../../contexts/PaymentLinkContext";
import { InvoiceStatus } from "../../types/checkout";

import Graphic from "../../assets/graphics/Processing";

const Processing = () => {
  const { t } = useTranslation("checkout-processing");
  const {
    paymentLink: { invoice }
  } = usePaymentLink();

  useEffect(() => {
    if (invoice && invoice?.status !== InvoiceStatus.Pending) {
      window.location.href = `/web/${invoice.id}`;
      return;
    }
  }, [invoice]);

  return (
    <MainView>
      <div className="flex flex-col items-center px-4 lg:px-0 pt-12 lg:pt-4">
        <Graphic className="text-primary mb-4" />
        <p className="font-semibold text-lg text-center mb-4">
          {t("Payment is still processing…")}
        </p>
        <p className="text-center">
          {t(
            "Processing may take a moment. This page will update when the payment is processed."
          )}
        </p>
      </div>
    </MainView>
  );
};

export default Processing;
