import { FC, SVGProps } from "react";

const Ewallet: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.25 1.875H6.75c-.621 0-1.125.504-1.125 1.125v18c0 .621.504 1.125 1.125 1.125h10.5c.621 0 1.125-.504 1.125-1.125V3c0-.621-.504-1.125-1.125-1.125ZM5.625 4.875h12.75M5.625 17.625h12.75"
        stroke="#777"
      />
      <path d="M9.375 19.875h5.25" stroke="#777" strokeLinecap="round" />
    </svg>
  );
};

export default Ewallet;
