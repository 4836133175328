import { useTranslation } from "react-i18next";

import { MainView } from "../../components/Layout";

import Graphic from "../../assets/graphics/PendingPendingApproval";

const PendingPaymentApproval = () => {
  const { t } = useTranslation("checkout-pending-payment-approval");

  return (
    <MainView>
      <div className="flex flex-col items-center px-4 lg:px-0 pt-12 lg:pt-4">
        <Graphic className="text-primary" />
        <p className="font-semibold text-lg text-center mt-4 mb-4">
          {t(
            "The approval process is currently underway for the pending payment…"
          )}
        </p>
        <p className="text-center">
          {t(
            "Processing may take a moment. This page will update when the payment is approved."
          )}
        </p>
      </div>
    </MainView>
  );
};

export default PendingPaymentApproval;
