import { FC, ReactNode, useEffect, useRef, useCallback } from "react";
import {
  PaymentChannelsEnum,
  PaymentMethodsEnum
} from "@xendit/checkout-utilities";
import { useTranslation } from "react-i18next";

import Barcode from "../../components/Barcode";
import Button from "../../components/Button";
import ChannelCodeInformations from "../../components/ChannelCodeInformations";
import ErrorBoundary from "../../components/ErrorBoundary";
import PaymentView from "../../components/PaymentView";
import InstructionsTabs from "../../components/InstructionsTabs";
import PaymentInstructionsTabsSkeleton from "../../components/PaymentInstructionsTabsSkeleton";
import QrCode from "../../components/QrCode";

import { usePaymentMethod } from "../../contexts/PaymentMethodContext";
import { usePaymentLink } from "../../contexts/PaymentLinkContext";
import { useLegacyAsyncPayment } from "../../contexts/LegacyAsyncPaymentContext";

import { AlternativeDisplayItem } from "../../types/checkout";
import { BASE_ASSETS_URL } from "../../utils/constants";

const INVOICE_POLLING_INTERVAL_MS = 10 * 1000; // 10 seconds
const QUICK_INVOICE_POLLING_INTERVAL_MS = 2 * 1000; // 2 seconds

const VietQR = () => {
  return (
    <img
      src={`${BASE_ASSETS_URL}logos/viet-qr.svg`}
      alt={"Viet QR"}
      className="w-20 sm:w-40 md:w-80"
    />
  );
};

type AlternativeDisplayQRProps = {
  qrString: string;
};
const AlternativeDisplayQR = ({ qrString }: AlternativeDisplayQRProps) => {
  const qrRef = useRef<HTMLCanvasElement>(null);
  return (
    <div className="flex flex-col sm:flex-row sm:items-center space-y-reverse p-6 sm:p-8 border-.5 border-xen-gray-400 rounded-sm mt-6 justify-between">
      <div className="self-center p-0">
        <VietQR />
      </div>
      <div className="flex flex-row justify-center align-middle">
        <QrCode value={qrString} ref={qrRef} />
      </div>
    </div>
  );
};

type AlternativeDisplayItemProps = {
  alternativeDisplay: AlternativeDisplayItem;
};
const AlternativeDisplay = ({
  alternativeDisplay
}: AlternativeDisplayItemProps) => {
  const alternativeDisplayView: Record<string, ReactNode> = {
    QR_STRING: <AlternativeDisplayQR qrString={alternativeDisplay.data} />
  };

  if (!alternativeDisplayView) {
    return null;
  }

  return <>{alternativeDisplayView[alternativeDisplay.type]}</>;
};

type AlternativeDisplaysProps = {
  alternativeDisplays: AlternativeDisplayItem[];
};
const AlternativeDisplays = ({
  alternativeDisplays
}: AlternativeDisplaysProps) => {
  return (
    <>
      {alternativeDisplays.map((alternativeDisplay, index) => {
        return (
          <AlternativeDisplay
            key={`alt-display-${alternativeDisplay.type}-${index}`}
            alternativeDisplay={alternativeDisplay}
          />
        );
      })}
    </>
  );
};

const PaymentInstructionsTabs: FC = () => {
  const { t } = useTranslation("common");

  const {
    paymentLink: { invoice },
    bindingFixedVaChannel,
    onSetPollingInterval,
    onSetBindingFixedVaChannel
  } = usePaymentLink();

  const { fixed_va: fixedVa, callback_virtual_account_collection_id: cvacId } =
    invoice;

  const {
    checkoutPaymentChannels,
    clearPaymentChannel,
    paymentChannel,
    paymentMethodType
  } = usePaymentMethod();

  const {
    isCreating,
    otherBanksChannel,
    paymentInstrument,
    onUpdateFixedVaPaymentDestination
  } = useLegacyAsyncPayment();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (fixedVa && !isCreating) {
      if (!paymentInstrument.payment_destination) {
        onSetPollingInterval(QUICK_INVOICE_POLLING_INTERVAL_MS);
        onSetBindingFixedVaChannel(paymentInstrument.name);
      }
    }
  }, [fixedVa, isCreating, paymentInstrument.payment_destination]);

  useEffect(() => {
    if (cvacId) {
      onUpdateFixedVaPaymentDestination(bindingFixedVaChannel);
    }
    onSetPollingInterval(INVOICE_POLLING_INTERVAL_MS);
    onSetBindingFixedVaChannel(null);
  }, [cvacId]);

  if (!paymentChannel) {
    return null;
  }

  // for new fixed VA scenario, we skip payment_destination check
  // we are not expecting payment_destination to be provided
  // payment_destination are updated on async update
  const isLoadingState = fixedVa
    ? isCreating
    : !paymentInstrument.payment_destination || isCreating;

  if (isLoadingState) {
    return <PaymentInstructionsTabsSkeleton />;
  }

  return (
    <div>
      <PaymentView
        paymentChannelName={paymentChannel.display_name}
        footer={
          <div className="flex space-x-4">
            <Button
              type="button"
              variant="brand-secondary"
              className="flex-1"
              onClick={clearPaymentChannel}
            >
              {t("Back")}
            </Button>
          </div>
        }
      >
        <ChannelCodeInformations
          paymentMethodType={paymentMethodType as PaymentMethodsEnum}
          paymentCode={paymentInstrument.payment_destination as string}
          merchantName={paymentInstrument.merchant_name}
          amount={invoice.amount}
          currency={invoice.currency as string}
          visual={
            paymentMethodType === PaymentMethodsEnum.BANK_TRANSFER ? (
              <img
                src={
                  paymentChannel.channel === PaymentChannelsEnum.OTHER_BANKS
                    ? checkoutPaymentChannels[otherBanksChannel]?.logo_url
                    : paymentChannel?.logo_url
                }
                alt={
                  paymentChannel.channel === PaymentChannelsEnum.OTHER_BANKS
                    ? checkoutPaymentChannels[otherBanksChannel]?.channel
                    : paymentChannel?.channel
                }
                className="w-20 sm:w-40 md:w-80"
                data-testid="va-logo"
              />
            ) : (
              <Barcode
                value={paymentInstrument.payment_destination as string}
              />
            )
          }
        />

        {paymentInstrument.alternative_displays ? (
          <AlternativeDisplays
            alternativeDisplays={paymentInstrument.alternative_displays}
          />
        ) : null}

        <ErrorBoundary fallback={<div />}>
          <InstructionsTabs
            currency={invoice.currency}
            channelCode={paymentChannel?.channel as PaymentChannelsEnum}
            paymentCode={paymentInstrument.payment_destination as string}
          />
        </ErrorBoundary>

        <div className="hidden md:flex justify-center lg:justify-start mt-10">
          <Button
            type="button"
            variant="brand-secondary"
            className="w-36"
            onClick={clearPaymentChannel}
            data-testid="back"
            disabled={!paymentInstrument.payment_destination}
          >
            {t("Back")}
          </Button>
        </div>
      </PaymentView>
    </div>
  );
};

export default PaymentInstructionsTabs;
