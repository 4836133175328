import { FC } from "react";
import { PaymentMethodListItemChannel } from "../PaymentMethodList";

type Props = {
  channels: PaymentMethodListItemChannel[];
  maxTilesCount?: number;
};

const ChannelsPreview: FC<Props> = ({ channels, maxTilesCount = 4 }) => {
  const overflowChannels =
    channels.length > maxTilesCount ? channels.length - (maxTilesCount - 1) : 0;

  return (
    <div className="flex space-x-1" data-role="preview">
      {channels
        .slice(0, -overflowChannels || channels.length)
        .map((channel) => {
          return (
            <span
              key={channel.name}
              className="w-9 h-6 bg-white border border-xen-gray-300 flex items-stretch justify-center rounded-sm px-1 py-0.5"
            >
              <img
                src={channel.logoUrl}
                alt={channel.label}
                className="object-contain"
              />
            </span>
          );
        })}
      {overflowChannels > 0 ? (
        <span className="w-9 h-6 bg-white border border-xen-gray-300 flex items-center justify-center rounded-sm text-sm text-xen-gray-700">
          +{overflowChannels}
        </span>
      ) : null}
    </div>
  );
};

export default ChannelsPreview;
