import type {
  CheckoutInitialData,
  Customer,
  Invoice
} from "../../types/checkout";
import { CreditCardPromotion } from "../../types/credit-card";

type PaymentLinkReducerState = {
  paymentLink: CheckoutInitialData;

  // promotion
  promotion: CreditCardPromotion | null;

  // end-user-submitted customer information
  customer: null | Customer;

  // flag to signal enabling fetch by polling
  connectedToRealtime: boolean;

  // flag to indicate that invoice updates by realtime or polling should be attempted
  shouldEnableInvoiceUpdates: boolean;

  // fixed VA channel that is in binding process
  bindingFixedVaChannel: null | string;

  // the polling interval to get payment link
  pollingInterval: number;
};

type PaymentLinkReducerAction =
  | {
      type: "set_invoice";
      payload: SetInvoicePayload;
    }
  | {
      type: "set_customer";
      payload: SetCustomerPayload;
    }
  | {
      type: "set_promotion";
      payload: SetPromotionPayload;
    }
  | {
      type: "set_connected_to_realtime";
      payload: SetConnectedToRealtimePayload;
    }
  | {
      type: "set_should_enable_invoice_updates";
      payload: SetShouldEnableInvoiceUpdates;
    }
  | {
      type: "set_binding_fixed_va_channel";
      payload: SetBindingFixedVaChannel;
    }
  | {
      type: "set_polling_interval";
      payload: SetPollingInterval;
    };

type SetInvoicePayload = { invoice: Partial<Invoice> };

type SetCustomerPayload = {
  customer: Customer;
};

type SetPromotionPayload = {
  promotion: CreditCardPromotion | null;
};

type SetConnectedToRealtimePayload = {
  connectedToRealtime: boolean;
};

type SetShouldEnableInvoiceUpdates = {
  shouldEnableInvoiceUpdates: boolean;
};

type SetBindingFixedVaChannel = {
  bindingFixedVaChannel: string | null;
};

type SetPollingInterval = {
  pollingInterval: number;
};

const PaymentLinkReducer = (
  state: PaymentLinkReducerState,
  action: PaymentLinkReducerAction
): PaymentLinkReducerState => {
  switch (action.type) {
    case "set_invoice":
      return {
        ...state,
        paymentLink: {
          ...state.paymentLink,
          invoice: {
            ...state.paymentLink.invoice,
            ...action.payload.invoice
          }
        }
      };
    case "set_customer":
      return {
        ...state,
        customer: { ...state.customer, ...action.payload.customer }
      };
    case "set_promotion":
      return {
        ...state,
        promotion: action.payload.promotion
      };
    case "set_connected_to_realtime":
      return {
        ...state,
        connectedToRealtime: action.payload.connectedToRealtime
      };
    case "set_should_enable_invoice_updates":
      return {
        ...state,
        shouldEnableInvoiceUpdates: action.payload.shouldEnableInvoiceUpdates
      };
    case "set_binding_fixed_va_channel":
      return {
        ...state,
        bindingFixedVaChannel: action.payload.bindingFixedVaChannel
      };
    case "set_polling_interval":
      return {
        ...state,
        pollingInterval: action.payload.pollingInterval
      };
    default:
      return state;
  }
};

export default PaymentLinkReducer;
