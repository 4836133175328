import React, { useEffect, useReducer } from "react";
import Dialog from "../../components/Dialog";
import ErrorBoundary from "../../components/ErrorBoundary";
import RollingLoader from "../../components/RollingLoader";
import { SessionContext, sessionReducer } from "../../session/context/context";
import { SessionActionTypes } from "../../session/context/SessionContextInterface";
import { LoadingSessionState } from "../../session/context/viewState";
import SessionInvalid from "../../session/states/Terminal/Invalid";
import { APP_VERSION } from "../../utils/constants";

const Session: React.FC = () => {
  // Initialize the state
  const [state, dispatch] = useReducer(sessionReducer, {
    viewState: new LoadingSessionState()
  });

  const { viewState } = state;

  useEffect(() => {
    if (!viewState) {
      throw new Error("viewState is not defined");
    }
  }, [viewState]);

  /**
   * Error Dialog
   */
  const renderErrorDialog = () => {
    const { errorContent } = state;
    const content = (
      <>
        <p className="px-4 text-base mb-2">{errorContent?.message_1}</p>
        <p className="px-4 text-base mb-4">{errorContent?.message_2}</p>
      </>
    );
    return (
      <Dialog
        title={errorContent?.title ?? ""}
        content={content}
        open={!!errorContent}
        onClose={() => {
          dispatch({
            type: SessionActionTypes.SET_ERROR_CONTENT,
            payload: {
              errorContent: undefined
            }
          });
        }}
      />
    );
  };

  /**
   * Loader
   */
  const renderOverlayLoader = () => {
    const { isLoading } = state;

    if (isLoading) {
      return (
        <div className="fixed left-0 top-0 flex flex-1 h-full w-full items-center justify-center bg-xen-black-400 opacity-50">
          <RollingLoader className="text-primary w-24 h-24 mt-4" />
        </div>
      );
    }
  };

  return (
    <SessionContext.Provider value={{ state, dispatch }}>
      <div className="relative">
        <ErrorBoundary fallback={<SessionInvalid />}>
          {viewState?.renderView({ state, dispatch })}
        </ErrorBoundary>
        <div className="absolute right-0 bottom-0 text-xs text-white">
          <p>App version: {APP_VERSION}</p>
        </div>
        {renderErrorDialog()}
        {renderOverlayLoader()}
      </div>
    </SessionContext.Provider>
  );
};

export default Session;
