import { useEffect, useReducer, useRef } from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

interface State<T> {
  data?: T;
  error?: Error | AxiosError;
}

type Cache<T> = { [url: string]: T };

// discriminated union type
type Action<T> =
  | { type: "loading" }
  | { type: "fetched"; payload: T }
  | { type: "error"; payload: Error };

/**
 *
 * @example
 * interface Post {
 *  userId: number
 *  id: number
 *  title: string
 *  body: string
 * }
 *
 * export default function Component() {
 *   const { data, error } = useFetch<Post[]>(url)
 *
 *   if (error) return <p>There is an error.</p>
 *   if (!data) return <p>Loading...</p>
 *   return <p>{data[0].title}</p>
 * }
 */

function useFetch<T = unknown>(
  url?: string,
  config?: AxiosRequestConfig
): State<T> {
  const cache = useRef<Cache<T>>({});

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false);

  const initialState: State<T> = {
    error: undefined,
    data: undefined
  };

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case "loading":
        return { ...initialState };
      case "fetched":
        return { ...initialState, data: action.payload };
      case "error":
        return { ...initialState, error: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    // Do nothing if the url is not given
    if (!url) return;

    cancelRequest.current = false;

    const fetchData = async () => {
      dispatch({ type: "loading" });

      // If a cache exists for this url, return it
      if (cache.current[url]) {
        dispatch({ type: "fetched", payload: cache.current[url] });
        return;
      }

      try {
        const response = await axios.request({
          url,
          ...config
        });
        if (response.status > 299) {
          throw new Error(response.statusText);
        }

        const data = response.data as T;
        cache.current[url] = data;
        if (cancelRequest.current) return;

        dispatch({ type: "fetched", payload: data });
      } catch (error) {
        if (cancelRequest.current) return;
        if (axios.isCancel(error)) return;

        dispatch({ type: "error", payload: error as Error });
      }
    };

    void fetchData();

    // Use the cleanup function for avoiding a possibly...
    // ...state update after the component was unmounted
    return () => {
      cancelRequest.current = true;
    };
  }, [url]);

  return state;
}

export default useFetch;
