import { Flow } from "../../Flow";
import CardForm from "./CardForm";
import { CardChannelProperties } from "./formik";

export class CardFlow extends Flow {
  buildFlow() {
    this.clearFlow();
    this.appendStep({
      overlay: false,
      form: (
        <CardForm
          key="card-form-step-1"
          onSubmit={this.onFormSubmission}
          onCancel={this.resetSelection}
        />
      )
    });
    return this;
  }

  onSelectFlow() {
    return this.flow;
  }

  private onFormSubmission = async (values: CardChannelProperties) => {
    const createdTokenEither = await this.postPaymentTokenAction(values);
    if (createdTokenEither.isRight()) {
      const createdToken = createdTokenEither.getRight();
      this.exitFlow(createdToken);
    }
  };
}
