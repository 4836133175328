import { FC, useState } from "react";

import { PaymentChannelsPropertiesType } from "@xendit/checkout-utilities/dist/src/types/payment-channels";
import { useTranslation } from "react-i18next";
import kebabCase from "lodash/kebabCase";

import { usePaymentMethod } from "../../contexts/PaymentMethodContext";

import Dropdown, { DropdownOption } from "../../components/Dropdown";
import FormField from "../../components/FormField";
import Tooltip from "../../components/Tooltip";

import Button from "../../components/Button/Button";

type Props = {
  channels: PaymentChannelsPropertiesType[];
};

const DragonpayAccordionContent: FC<Props> = (props) => {
  type BankOption = (typeof channels)[0];

  const { selectPaymentChannel } = usePaymentMethod();

  const { t } = useTranslation("common");

  const [selectedBank, setSelectedBank] = useState<BankOption | null>(null);

  const channels = props.channels.map((channel: any) => {
    return {
      channel,
      disabled: channel?.disabled,
      tooltipMessage: channel?.tooltipMessage
    };
  });

  const isLiveMode = import.meta.env.VITE_APP_ENV_MODE === "production-live";

  return (
    <>
      {channels && channels.length > 0 ? (
        <div className="py-6 flex justify-center">
          <div className="w-full max-w-md px-4 flex flex-col space-y-6">
            <FormField>
              <Dropdown
                data-testid="select-bank"
                options={channels}
                keyExtractor={(option) =>
                  (option as BankOption).channel.channel
                }
                value={selectedBank?.channel}
                onChange={(channel: any) => {
                  return setSelectedBank(channel);
                }}
                block
                placeholder={t("Select Bank")}
                renderOption={(option, helpers) => {
                  const { tooltipMessage, channel } = option as BankOption;

                  return (
                    <Tooltip
                      contentChildren={tooltipMessage}
                      delayDuration={0}
                      side="bottom"
                      asChild
                    >
                      <div className="w-full">
                        <DropdownOption
                          {...helpers}
                          className="flex items-center space-x-2"
                          data-testid={kebabCase(channel.label.toLowerCase())}
                        >
                          {channel.logo_url ? (
                            <div className="w-8 h-6 bg-white rounded flex items-center justify-center">
                              <img
                                src={channel.logo_url}
                                alt={channel.label}
                                className="w-7 h-5"
                              />
                            </div>
                          ) : null}
                          <span>{channel.display_name}</span>
                        </DropdownOption>
                      </div>
                    </Tooltip>
                  );
                }}
                renderSelection={(selection) => {
                  const { display_name, logo_url, label } =
                    selection as PaymentChannelsPropertiesType;

                  return (
                    <div className="flex items-center space-x-2">
                      {logo_url ? (
                        <div className="w-8 h-6 bg-white rounded flex items-center justify-center">
                          <img src={logo_url} alt={label} className="w-7 h-5" />
                        </div>
                      ) : null}
                      <span>{display_name}</span>
                    </div>
                  );
                }}
                optionDisabled={(option) => (option as BankOption).disabled}
              />
            </FormField>

            {/* Element when a bank is selected */}
            {selectedBank ? (
              <>
                <Button
                  block
                  variant="brand-secondary"
                  className="flex-1 md:flex-initial"
                  data-testid="continue"
                  onClick={() =>
                    selectPaymentChannel(selectedBank?.channel.channel)
                  }
                >
                  {t("Continue")}
                </Button>
              </>
            ) : null}

            {/* Note for test mode only */}
            {!isLiveMode ? (
              <p className="text-xen-red-500">
                {t(
                  "Note: In test mode, there will only be 2 channels for you to test the redirect flow (BPI) or the payment instructions flow (BDO). \n In the payment instructions flow, you'll be required to click the link in the payment instructions page (under step 2) to simulate a successful payment."
                )}
              </p>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DragonpayAccordionContent;
