import { FC, SVGProps } from "react";

const FileEdit: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#clip0_2353_59)">
        <path
          d="M11.0144 6.875C11.0144 6.52981 10.7346 6.25 10.3894 6.25H3.95186C3.60667 6.25 3.32686 6.52981 3.32686 6.875C3.32686 7.22019 3.60667 7.5 3.95186 7.5H10.3894C10.7346 7.5 11.0144 7.22019 11.0144 6.875Z"
          fill="currentColor"
        />
        <path
          d="M3.95186 8.75C3.60667 8.75 3.32686 9.02981 3.32686 9.375C3.32686 9.72019 3.60667 10 3.95186 10H7.86157C8.20676 10 8.48657 9.72019 8.48657 9.375C8.48657 9.02981 8.20676 8.75 7.86157 8.75H3.95186Z"
          fill="currentColor"
        />
        <path
          d="M5.42059 14.75H3.33031C2.64106 14.75 2.08031 14.1892 2.08031 13.5V2.5C2.08031 1.81075 2.64106 1.25 3.33031 1.25H11.0143C11.7036 1.25 12.2643 1.81075 12.2643 2.5V6.34375C12.2643 6.68894 12.5442 6.96875 12.8893 6.96875C13.2345 6.96875 13.5143 6.68894 13.5143 6.34375V2.5C13.5143 1.1215 12.3928 0 11.0143 0H3.33031C1.95181 0 0.830311 1.1215 0.830311 2.5V13.5C0.830311 14.8785 1.95181 16 3.33031 16H5.42059C5.76578 16 6.04559 15.7202 6.04559 15.375C6.04559 15.0298 5.76578 14.75 5.42059 14.75Z"
          fill="currentColor"
        />
        <path
          d="M14.6214 9.04912C13.8903 8.31806 12.7008 8.31803 11.9702 9.04862L8.53888 12.4724C8.466 12.5451 8.41225 12.6346 8.38232 12.7331L7.63504 15.1933C7.56879 15.4114 7.6265 15.6483 7.78569 15.8114C7.90494 15.9336 8.06694 16 8.2331 16C8.28869 16 8.34482 15.9925 8.39991 15.9773L10.9226 15.2785C11.0264 15.2497 11.121 15.1946 11.1973 15.1185L14.6214 11.7008C15.3525 10.9697 15.3525 9.78024 14.6214 9.04912ZM10.4305 14.1177L9.16138 14.4693L9.53279 13.2465L11.8481 10.9364L12.7321 11.8204L10.4305 14.1177ZM13.738 10.8166L13.6169 10.9374L12.733 10.0535L12.8537 9.93305C13.0973 9.68937 13.4938 9.68937 13.7375 9.93305C13.9812 10.1767 13.9812 10.5732 13.738 10.8166Z"
          fill="currentColor"
        />
        <path
          d="M10.3894 3.75H3.95186C3.60667 3.75 3.32686 4.02981 3.32686 4.375C3.32686 4.72019 3.60667 5 3.95186 5H10.3894C10.7346 5 11.0144 4.72019 11.0144 4.375C11.0144 4.02981 10.7346 3.75 10.3894 3.75Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2353_59">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FileEdit;
